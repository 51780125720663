import React, { useState, useEffect } from 'react';
import { Box, FormControl, Select, MenuItem, Typography, ListItemIcon, Divider } from '@mui/material';
import MonitorRounded from '@mui/icons-material/MonitorRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TikTokIcon from '@mui/icons-material/CameraAlt';
import FacebookIcon from '@mui/icons-material/Facebook';

// Define types for the props
type AspectRatioSelectorProps = {
    selectedRatio: number;
    isMobile: boolean;
    onSelect: (ratio: number) => void;
};

const AspectRatioSelector: React.FC<AspectRatioSelectorProps> = ({ isMobile, selectedRatio, onSelect }) => {
    const [selectedIndex, setSelectedIndex] = useState<number>(0);

    // Define the aspect ratio options inside the component
    const aspectRatioOptions = [
        { platform: 'Standard', ratioText: '1/1', ratio: 1, Icon: MonitorRounded },
        { platform: 'Portrait', ratioText: '9/16', ratio: 9/16, Icon: MonitorRounded },
        { platform: 'Landscape', ratioText: '16/9', ratio: 16/9, Icon: MonitorRounded },
        { platform: '', ratioText: '', ratio: -1, Icon: MonitorRounded }, // divider
        { platform: 'Twitter', ratioText: '1/1', ratio: 1, Icon: TwitterIcon },
        { platform: 'Facebook Video', ratioText: '1/1', ratio: 1, Icon: FacebookIcon },
        { platform: 'Facebook Post', ratioText: '1.19', ratio: 1.19, Icon: FacebookIcon },
        { platform: 'Facebook Story', ratioText: '9/16', ratio: 9/16, Icon: FacebookIcon },
        { platform: 'Instagram Post', ratioText: '1/1', ratio: 1, Icon: InstagramIcon },
        { platform: 'Instagram Story', ratioText: '9/16', ratio: 9 / 16, Icon: InstagramIcon },
        { platform: 'Instagram Reel', ratioText: '9/16', ratio: 9 / 16, Icon: InstagramIcon },
        { platform: 'YouTube', ratioText: '16/9', ratio: 16 / 9, Icon: YouTubeIcon },
        { platform: 'YouTube Short', ratioText: '9/16', ratio: 9 / 16, Icon: YouTubeIcon },
        { platform: 'TikTok', ratioText: '9/16', ratio: 9 / 16, Icon: TikTokIcon },
    ];

    useEffect(() => {
        const initialIndex = aspectRatioOptions.findIndex(option => option.ratio === selectedRatio);
        setSelectedIndex(initialIndex);
    }, [selectedRatio]);

    //// Find the index of the selected ratio in the options
    //const selectedIndex = aspectRatioOptions.findIndex(option => option.id === selectedRatio);

    return (
        <FormControl variant="outlined" sx={{ margin: 2, maxWidth: 240, minWidth: 240, height: '100%' }}>
            <Select
                labelId="aspect-ratio-selector-label"
                value={selectedIndex}
                onChange={(e) => {
                    const selectedOptionIndex = e.target.value as number;
                    const selectedOption = aspectRatioOptions[selectedOptionIndex];
                    if (selectedOption) {
                        setSelectedIndex(selectedOptionIndex);
                        onSelect(selectedOption.ratio);
                    }
                }}
                sx={{ height: '100%' }}
            >
                {aspectRatioOptions.map((option, index) => (
                    option.platform ? (
                        <MenuItem key={index} value={index} sx={{ height: '100%' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <ListItemIcon sx={{flexGrow: 0, minWidth: '20px', marginRight: '5px'}}>
                                    <option.Icon />
                                </ListItemIcon>
                                <Typography variant="inherit" sx={{flexGrow: 1}}>
                                    {option.platform} ({option.ratioText})
                                </Typography>
                            </Box>
                        </MenuItem>
                    ) : (
                        <Divider key={-1}></Divider>
                    )
                ))}
            </Select>
        </FormControl>
    );
};

export default AspectRatioSelector;
