import React from 'react';

interface SelectionBoxProps {
    startX: number;
    startY: number;
    endX: number;
    endY: number;
    isVisible: boolean;
}

const SelectionBox: React.FC<SelectionBoxProps> = ({ startX, startY, endX, endY, isVisible }) => {
    if (!isVisible) return null; // Don't render if not visible

    const left = Math.min(startX, endX);
    const top = Math.min(startY, endY);
    const width = Math.abs(endX - startX);
    const height = Math.abs(endY - startY);

    return (
        <div
            style={{
                position: 'absolute',
                left,
                top,
                width,
                height,
                backgroundColor: 'rgba(0, 255, 255, 0.1)',
                border: '1px solid rgba(0, 255, 255, 0.5)',
                pointerEvents: 'none',
            }}
        />
    );
};

export default SelectionBox;
