import { IconButton, useTheme, useMediaQuery } from '@mui/material';
import { keyframes } from '@emotion/react';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.05);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

interface EditorIconButtonProps {
    isMobile: boolean;
    onClick: () => void;
    children: React.ReactNode;
    disabled?: boolean;
    isLoading?: boolean;
    color?: string;
    cursor?: string;
}

const EditorIconButton: React.FC<EditorIconButtonProps> = ({ isMobile, onClick, children, disabled = false, isLoading = false, color, cursor }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery('(max-width: 330px)'); // Check if the screen width is 330px or less

    return (
        <IconButton
            disabled={disabled}
            sx={{
                color: color,
                cursor: cursor,
                marginRight: isSmallScreen ? 0.9 : 1,  // Adjust margin for small screens
                marginLeft: isSmallScreen ? 0.9 : 1,
                height: isSmallScreen ? 16 : 20, // Adjust icon size for small screens
                width: isSmallScreen ? 16 : 20,
                animation: isLoading ? `${pulseAnimation} 1s infinite ease-in-out` : 'none',
                backgroundColor: isLoading ? theme.palette.primary.light : 'transparent',
                transition: 'all 0.3s ease',
                '&:hover': !disabled && !isMobile ? {
                    '& svg': {
                        color: color || theme.palette.primary.main,
                    }
                } : '',
            }}
            onClick={onClick}
        >
            {children}
        </IconButton>
    );
}

export default EditorIconButton;
