import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, useTheme, useMediaQuery, IconButton } from '@mui/material';
import { AddCircle, UploadFile } from '@mui/icons-material';

interface MediaAddComponentProps {
  onAddMedia: (file: File) => void;
  isMobile: boolean;
}

const MediaAddComponent: React.FC<MediaAddComponentProps> = ({ isMobile, onAddMedia }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLElement>(null);
  const theme = useTheme();
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      const boundingRect = containerRef.current?.getBoundingClientRect();
      if (boundingRect && boundingRect.height < 120) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }
  }, [])

  const handleAddMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      onAddMedia(file); // Call the callback function with the video file
    }
  };

  const handleOpenFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box ref={containerRef} sx={{
      height: '100%',
      width: '100%',
      padding: 1,
      boxSizing: 'border-box',
      overflow: 'hidden'
    }}
    >
    <Box
      onClick={handleOpenFileDialog}
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.background.paper, // Using theme background
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: theme.shape.borderRadius, // Using theme border radius
        border: `2px dashed ${theme.palette.primary.main}`, // Theme primary color for the border
        cursor: 'pointer',
        boxSizing: 'border-box',
        overflow: 'hidden'
      }}
    >
      <IconButton>
        {isMobile ? (
        <AddCircle sx={{height: '35px', width: '35px', color: theme.palette.primary.main}} />
        ) : (
        <UploadFile sx={{height: '35px', width: '35px', color: theme.palette.primary.main}} />
        )}
      </IconButton>
      {!isSmallScreen && (
        <>
          <Typography variant="h6" sx={{ color: theme.palette.primary.main, fontSize: isSmallScreen ? '1rem' : '1.25rem' }}>
            Add Media
          </Typography>
          <Typography variant="body2" sx={{ color: theme.palette.text.secondary, fontSize: isSmallScreen ? '0.75rem' : '0.875rem' }}>
            {isMobile ? 'Tap to upload media' : 'Drag & drop media files here'}
          </Typography>
        </>
      )}

      {/* Hidden file input */}
      <input
        ref={fileInputRef}
        type="file"
        accept="video/*,image/*"
        style={{ display: 'none' }}
        onChange={handleAddMedia}
      />
    </Box>
    </Box>
  );
};

export default MediaAddComponent;
