import angular from '../assets/transitions/angular.glsl.webp'
import BookFlip from '../assets/transitions/BookFlip.glsl.webp'
import Bounce from '../assets/transitions/Bounce.glsl.webp'
import BowTieHorizontal from '../assets/transitions/BowTieHorizontal.glsl.webp'
import BowTieVertical from '../assets/transitions/BowTieVertical.glsl.webp'
import BowTieWithParameter from '../assets/transitions/BowTieWithParameter.glsl.webp'
import burn from '../assets/transitions/burn.glsl.webp'
import ButterflyWaveScrawler from '../assets/transitions/ButterflyWaveScrawler.glsl.webp'
import cannabisleaf from '../assets/transitions/cannabisleaf.glsl.webp'
import CircleCrop from '../assets/transitions/CircleCrop.glsl.webp'
import circle from '../assets/transitions/circle.glsl.webp'
import circleopen from '../assets/transitions/circleopen.glsl.webp'
import colorphase from '../assets/transitions/colorphase.glsl.webp'
import ColourDistance from '../assets/transitions/ColourDistance.glsl.webp'
import coordFromIn from '../assets/transitions/coord-from-in.glsl.webp'
import CrazyParametricFun from '../assets/transitions/CrazyParametricFun.glsl.webp'
import crosshatch from '../assets/transitions/crosshatch.glsl.webp'
import crosswarp from '../assets/transitions/crosswarp.glsl.webp'
import CrossZoom from '../assets/transitions/CrossZoom.glsl.webp'
import cube from '../assets/transitions/cube.glsl.webp'
import directionalEasing from '../assets/transitions/directional-easing.glsl.webp'
import Directional from '../assets/transitions/Directional.glsl.webp'
import directionalwarp from '../assets/transitions/directionalwarp.glsl.webp'
import directionalwipe from '../assets/transitions/directionalwipe.glsl.webp'
import displacement from '../assets/transitions/displacement.glsl.webp'
import DoomScreenTransition from '../assets/transitions/DoomScreenTransition.glsl.webp'
import doorway from '../assets/transitions/doorway.glsl.webp'
import Dreamy from '../assets/transitions/Dreamy.glsl.webp'
import DreamyZoom from '../assets/transitions/DreamyZoom.glsl.webp'
import EdgeTransition from '../assets/transitions/EdgeTransition.glsl.webp'
import fadecolor from '../assets/transitions/fadecolor.glsl.webp'
import fade from '../assets/transitions/fade.glsl.webp'
import fadegrayscale from '../assets/transitions/fadegrayscale.glsl.webp'
import FilmBurn from '../assets/transitions/FilmBurn.glsl.webp'
import flyeye from '../assets/transitions/flyeye.glsl.webp'
import GlitchDisplace from '../assets/transitions/GlitchDisplace.glsl.webp'
import GlitchMemories from '../assets/transitions/GlitchMemories.glsl.webp'
import heart from '../assets/transitions/heart.glsl.webp'
import hexagonalize from '../assets/transitions/hexagonalize.glsl.webp'
import HorizontalClose from '../assets/transitions/HorizontalClose.glsl.webp'
import HorizontalOpen from '../assets/transitions/HorizontalOpen.glsl.webp'
import InvertedPageCurl from '../assets/transitions/InvertedPageCurl.glsl.webp'
import kaleidoscope from '../assets/transitions/kaleidoscope.glsl.webp'
import LeftRight from '../assets/transitions/LeftRight.glsl.webp'
import LinearBlur from '../assets/transitions/LinearBlur.glsl.webp'
import luma from '../assets/transitions/luma.glsl.webp'
import luminance_melt from '../assets/transitions/luminance_melt.glsl.webp'
import morph from '../assets/transitions/morph.glsl.webp'
import Mosaic from '../assets/transitions/Mosaic.glsl.webp'
import mosaic_transition from '../assets/transitions/mosaic_transition.glsl.webp'
import multiply_blend from '../assets/transitions/multiply_blend.glsl.webp'
import Overexposure from '../assets/transitions/Overexposure.glsl.webp'
import perlin from '../assets/transitions/perlin.glsl.webp'
import pinwheel from '../assets/transitions/pinwheel.glsl.webp'
import pixelize from '../assets/transitions/pixelize.glsl.webp'
import polar_function from '../assets/transitions/polar_function.glsl.webp'
import PolkaDotsCurtain from '../assets/transitions/PolkaDotsCurtain.glsl.webp'
import powerKaleido from '../assets/transitions/powerKaleido.glsl.webp'
import Radial from '../assets/transitions/Radial.glsl.webp'
import randomNoisex from '../assets/transitions/randomNoisex.glsl.webp'
import randomsquares from '../assets/transitions/randomsquares.glsl.webp'
import RectangleCrop from '../assets/transitions/RectangleCrop.glsl.webp'
import Rectangle from '../assets/transitions/Rectangle.glsl.webp'
import ripple from '../assets/transitions/ripple.glsl.webp'
import Rolls from '../assets/transitions/Rolls.glsl.webp'
import rotate_scale_fade from '../assets/transitions/rotate_scale_fade.glsl.webp'
import RotateScaleVanish from '../assets/transitions/RotateScaleVanish.glsl.webp'
import rotateTransition from '../assets/transitions/rotateTransition.glsl.webp'
import scaleIn from '../assets/transitions/scale-in.glsl.webp'
import SimpleZoom from '../assets/transitions/SimpleZoom.glsl.webp'
import Slides from '../assets/transitions/Slides.glsl.webp'
import squareswire from '../assets/transitions/squareswire.glsl.webp'
import squeeze from '../assets/transitions/squeeze.glsl.webp'
import StaticFade from '../assets/transitions/StaticFade.glsl.webp'
import static_wipe from '../assets/transitions/static_wipe.glsl.webp'
import StereoViewer from '../assets/transitions/StereoViewer.glsl.webp'
import swap from '../assets/transitions/swap.glsl.webp'
import Swirl from '../assets/transitions/Swirl.glsl.webp'
import tangentMotionBlur from '../assets/transitions/tangentMotionBlur.glsl.webp'
import TopBottom from '../assets/transitions/TopBottom.glsl.webp'
import undulatingBurnOut from '../assets/transitions/undulatingBurnOut.glsl.webp'
import VerticalClose from '../assets/transitions/VerticalClose.glsl.webp'
import VerticalOpen from '../assets/transitions/VerticalOpen.glsl.webp'
import WaterDrop from '../assets/transitions/WaterDrop.glsl.webp'
import wind from '../assets/transitions/wind.glsl.webp'
import windowblinds from '../assets/transitions/windowblinds.glsl.webp'
import windowslice from '../assets/transitions/windowslice.glsl.webp'
import wipeDown from '../assets/transitions/wipeDown.glsl.webp'
import wipeLeft from '../assets/transitions/wipeLeft.glsl.webp'
import wipeRight from '../assets/transitions/wipeRight.glsl.webp'
import wipeUp from '../assets/transitions/wipeUp.glsl.webp'
import x_axis_translation from '../assets/transitions/x_axis_translation.glsl.webp'
import ZoomInCircles from '../assets/transitions/ZoomInCircles.glsl.webp'
import ZoomLeftWipe from '../assets/transitions/ZoomLeftWipe.glsl.webp'
import ZoomRigthWipe from '../assets/transitions/ZoomRigthWipe.glsl.webp'

type Transition = {
    name: string;
    image: string;
    description: string;
};

const transitionsData: Transition[] = [
  {
      name: 'angular',
      image: angular,
      description: '',
  },
  {
      name: 'BookFlip',
      image: BookFlip,
      description: '',
  },
  {
      name: 'Bounce',
      image: Bounce,
      description: '',
  },
  {
      name: 'BowTieHorizontal',
      image: BowTieHorizontal,
      description: '',
  },
  {
      name: 'BowTieVertical',
      image: BowTieVertical,
      description: '',
  },
  {
      name: 'BowTieWithParameter',
      image: BowTieWithParameter,
      description: '',
  },
  {
      name: 'burn',
      image: burn,
      description: '',
  },
  {
      name: 'ButterflyWaveScrawler',
      image: ButterflyWaveScrawler,
      description: '',
  },
  {
      name: 'cannabisleaf',
      image: cannabisleaf,
      description: '',
  },
  {
      name: 'CircleCrop',
      image: CircleCrop,
      description: '',
  },
  {
      name: 'circle',
      image: circle,
      description: '',
  },
  {
      name: 'circleopen',
      image: circleopen,
      description: '',
  },
  {
      name: 'colorphase',
      image: colorphase,
      description: '',
  },
  {
      name: 'ColourDistance',
      image: ColourDistance,
      description: '',
  },
  {
      name: 'coord-from-in',
      image: coordFromIn,
      description: '',
  },
  {
      name: 'CrazyParametricFun',
      image: CrazyParametricFun,
      description: '',
  },
  {
      name: 'crosshatch',
      image: crosshatch,
      description: '',
  },
  {
      name: 'crosswarp',
      image: crosswarp,
      description: '',
  },
  {
      name: 'CrossZoom',
      image: CrossZoom,
      description: '',
  },
  {
      name: 'cube',
      image: cube,
      description: '',
  },
  {
      name: 'directional-easing',
      image: directionalEasing,
      description: '',
  },
  {
      name: 'Directional',
      image: Directional,
      description: '',
  },
  {
      name: 'directionalwarp',
      image: directionalwarp,
      description: '',
  },
  {
      name: 'directionalwipe',
      image: directionalwipe,
      description: '',
  },
  {
      name: 'displacement',
      image: displacement,
      description: '',
  },
  {
      name: 'DoomScreenTransition',
      image: DoomScreenTransition,
      description: '',
  },
  {
      name: 'doorway',
      image: doorway,
      description: '',
  },
  {
      name: 'Dreamy',
      image: Dreamy,
      description: '',
  },
  {
      name: 'DreamyZoom',
      image: DreamyZoom,
      description: '',
  },
  {
      name: 'EdgeTransition',
      image: EdgeTransition,
      description: '',
  },
  {
      name: 'fadecolor',
      image: fadecolor,
      description: '',
  },
  {
      name: 'fade',
      image: fade,
      description: '',
  },
  {
      name: 'fadegrayscale',
      image: fadegrayscale,
      description: '',
  },
  {
      name: 'FilmBurn',
      image: FilmBurn,
      description: '',
  },
  {
      name: 'flyeye',
      image: flyeye,
      description: '',
  },
  {
      name: 'GlitchDisplace',
      image: GlitchDisplace,
      description: '',
  },
  {
      name: 'GlitchMemories',
      image: GlitchMemories,
      description: '',
  },
  {
      name: 'heart',
      image: heart,
      description: '',
  },
  {
      name: 'hexagonalize',
      image: hexagonalize,
      description: '',
  },
  {
      name: 'HorizontalClose',
      image: HorizontalClose,
      description: '',
  },
  {
      name: 'HorizontalOpen',
      image: HorizontalOpen,
      description: '',
  },
  {
      name: 'InvertedPageCurl',
      image: InvertedPageCurl,
      description: '',
  },
  {
      name: 'kaleidoscope',
      image: kaleidoscope,
      description: '',
  },
  {
      name: 'LeftRight',
      image: LeftRight,
      description: '',
  },
  {
      name: 'LinearBlur',
      image: LinearBlur,
      description: '',
  },
  {
      name: 'luma',
      image: luma,
      description: '',
  },
  {
      name: 'luminance_melt',
      image: luminance_melt,
      description: '',
  },
  {
      name: 'morph',
      image: morph,
      description: '',
  },
  {
      name: 'Mosaic',
      image: Mosaic,
      description: '',
  },
  {
      name: 'mosaic_transition',
      image: mosaic_transition,
      description: '',
  },
  {
      name: 'multiply_blend',
      image: multiply_blend,
      description: '',
  },
  {
      name: 'Overexposure',
      image: Overexposure,
      description: '',
  },
  {
      name: 'perlin',
      image: perlin,
      description: '',
  },
  {
      name: 'pinwheel',
      image: pinwheel,
      description: '',
  },
  {
      name: 'pixelize',
      image: pixelize,
      description: '',
  },
  {
      name: 'polar_function',
      image: polar_function,
      description: '',
  },
  {
      name: 'PolkaDotsCurtain',
      image: PolkaDotsCurtain,
      description: '',
  },
  {
      name: 'powerKaleido',
      image: powerKaleido,
      description: '',
  },
  {
      name: 'Radial',
      image: Radial,
      description: '',
  },
  {
      name: 'randomNoisex',
      image: randomNoisex,
      description: '',
  },
  {
      name: 'randomsquares',
      image: randomsquares,
      description: '',
  },
  {
      name: 'RectangleCrop',
      image: RectangleCrop,
      description: '',
  },
  {
      name: 'Rectangle',
      image: Rectangle,
      description: '',
  },
  {
      name: 'ripple',
      image: ripple,
      description: '',
  },
  {
      name: 'Rolls',
      image: Rolls,
      description: '',
  },
  {
      name: 'rotate_scale_fade',
      image: rotate_scale_fade,
      description: '',
  },
  {
      name: 'RotateScaleVanish',
      image: RotateScaleVanish,
      description: '',
  },
  {
      name: 'rotateTransition',
      image: rotateTransition,
      description: '',
  },
  {
      name: 'scale-in',
      image: scaleIn,
      description: '',
  },
  {
      name: 'SimpleZoom',
      image: SimpleZoom,
      description: '',
  },
  {
      name: 'Slides',
      image: Slides,
      description: '',
  },
  {
      name: 'squareswire',
      image: squareswire,
      description: '',
  },
  {
      name: 'squeeze',
      image: squeeze,
      description: '',
  },
  {
      name: 'StaticFade',
      image: StaticFade,
      description: '',
  },
  {
      name: 'static_wipe',
      image: static_wipe,
      description: '',
  },
  {
      name: 'StereoViewer',
      image: StereoViewer,
      description: '',
  },
  {
      name: 'swap',
      image: swap,
      description: '',
  },
  {
      name: 'Swirl',
      image: Swirl,
      description: '',
  },
  {
      name: 'tangentMotionBlur',
      image: tangentMotionBlur,
      description: '',
  },
  {
      name: 'TopBottom',
      image: TopBottom,
      description: '',
  },
  {
      name: 'undulatingBurnOut',
      image: undulatingBurnOut,
      description: '',
  },
  {
      name: 'VerticalClose',
      image: VerticalClose,
      description: '',
  },
  {
      name: 'VerticalOpen',
      image: VerticalOpen,
      description: '',
  },
  {
      name: 'WaterDrop',
      image: WaterDrop,
      description: '',
  },
  {
      name: 'wind',
      image: wind,
      description: '',
  },
  {
      name: 'windowblinds',
      image: windowblinds,
      description: '',
  },
  {
      name: 'windowslice',
      image: windowslice,
      description: '',
  },
  {
      name: 'wipeDown',
      image: wipeDown,
      description: '',
  },
  {
      name: 'wipeLeft',
      image: wipeLeft,
      description: '',
  },
  {
      name: 'wipeRight',
      image: wipeRight,
      description: '',
  },
  {
      name: 'wipeUp',
      image: wipeUp,
      description: '',
  },
  {
      name: 'x_axis_translation',
      image: x_axis_translation,
      description: '',
  },
  {
      name: 'ZoomInCircles',
      image: ZoomInCircles,
      description: '',
  },
  {
      name: 'ZoomLeftWipe',
      image: ZoomLeftWipe,
      description: '',
  },
  {
      name: 'ZoomRigthWipe',
      image: ZoomRigthWipe,
      description: '',
  },
];

export {
  type Transition,
  transitionsData,
};
