import { ASS_Style } from 'jassub';
import Media, { Crop, MediaType, SERIALIZABLE_FOR_RENDER_KEY, SerializeForRender } from './Media';

export default class Subtitles extends Media {
    mediaType: MediaType = 'subtitles';
    @SerializeForRender()
    width: number;
    @SerializeForRender()
    height: number;

    subtitles: {subtitles: any[], style: ASS_Style};
    constructor ({
        id,
        url,
        row,
        duration,
        start,
        leftBackgroundOffset, 
        rightBackgroundOffset, 
        videoRef,
        startBase, 
        endBase, 
        isResizing, 
        dirty, 
        framesOffset, 
        playOffset, 
        onLoadedCalled,
        effects,
        height,
        width,
        scaledHeight,
        scaledWidth,
        baseHeight,
        baseWidth,
        x,
        y,
        rotation,
        crop,
        subtitles,
        style
    }: {
        id?: string,
        url: string,
        row: number,
        start: number,
        leftBackgroundOffset: number,
        rightBackgroundOffset: number,
        startBase: number,
        endBase: number,
        videoRef: any;
        duration: number,
        isResizing: boolean,
        dirty: boolean,
        playOffset?: number;
        framesOffset?: number;
        onLoadedCalled?: boolean;
        effects?: string[];
        height: number;
        width: number;
        scaledHeight: number | null;
        scaledWidth: number | null;
        baseHeight: number;
        baseWidth: number;
        x: number;
        y: number;
        rotation: number;
        crop: Crop;
        subtitles: any;
        style: any;
    }) {
        super({
            id, 
            name: 'subtitles',
            sha256: '',
            url,
            type: 'subtitles',
            row, 
            duration, 
            start,
            leftBackgroundOffset, 
            rightBackgroundOffset, 
            videoRef,
            startBase, 
            endBase, 
            isResizing, 
            dirty, 
            framesOffset, 
            playOffset, 
            onLoadedCalled,
            effects,
            height,
            width,
            scaledHeight,
            scaledWidth,
            baseHeight,
            baseWidth,
            x,
            y,
            rotation,
            crop,
            resizable: true,
            cropable: true,
            uploadComplete: true,
            speed: 1,
            hasVideoComponent: false,
            hasAudioComponent: false,
        });
        this.subtitles = {subtitles: [...subtitles], style: {...style}};
        this.width = width;
        this.height = height;
    }

    hasVideo = (): boolean => {
        return false;
    }

    hasAudio = (): boolean => {
        return false;
    }

    audioEnabled = (): boolean => {
        return false;
    }

    getEffectString = (prependVideoNormalization: boolean = false): string => {
        return ``;
    }

    deepCopy = ():  Subtitles => {
        return new Subtitles({
          ...this,
          subtitles: [...this.subtitles.subtitles],
          style: {...this.subtitles.style}
        });
    }

    serialize = (): Partial<Subtitles> => {
        const cleanVideo = this.deepCopy();
        return {...cleanVideo};
    }

    serializeForRender = (): Partial<Subtitles> => {
      const cleanVideo = this.deepCopy();
      const serializableFields: (keyof Subtitles)[] = Reflect.getMetadata(SERIALIZABLE_FOR_RENDER_KEY, this) || [];

      return serializableFields.reduce((obj, key) => {
        obj[key] = cleanVideo[key];
        return obj;
      }, {} as Partial<Subtitles>);
    }

    public static async fromSerialized(serializedMedia: any, options?: any): Promise<Subtitles> {
        return new Subtitles({
            subtitles: [],
            url: '',
            row: 0,
            duration: 0,
            left : 0,
            leftBackgroundOffset: 0,
            rightBackgroundOffset: 0,
            leftShrink: 0,
            rightShrink: 0,
            videoRef: null,
            startBase: 0,
            endBase: 0,
            isResizing: false,
            dirty: false,
            framesOffset: 0,
            playOffset: 0,
            onLoadedCalled: true,
            effects: [],
            scaledHeight: 0,
            scaledWidth: 0,
            baseHeight: 0,
            baseWidth: 0,
            rotation: 0,
            crop: {top: 0, bottom: 0, left: 0, right: 0},
            ...serializedMedia,
        });
    }

    requiresWasm = (): boolean => {
        return false;
    }
}
