import React, { useEffect, useState, useRef } from 'react';
import { Slider, SliderThumb, Box } from '@mui/material';
import { styled } from '@mui/system';

import { useEditorContext } from './VideosContext';

const CustomSlider = styled(Slider)({
  padding: 0,
  height: 0,
  '& .MuiSlider-root': {
    padding: '0 !important'
  },
  '& .MuiSlider-thumb': {
    transition: 'none',
    width: 0.5,
    height: 0.3,
    '&:hover, &.Mui-focusVisible, &.Mui-active': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-track': {
    display: 'none', // Hide the filled track
  },
  '& .MuiSlider-rail': {
    display: 'block',
    backgroundColor: 'transparent',
    top: '-20px',
    height: '20px',
  },
  '@media (pointer: coarse)': {
    padding: '0 !important',
    '& .MuiSlider-root': {
      padding: '0 !important',
    },
  },
});

function ThumbComponent({thumbHeight, ...props}: any) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <Box
        sx={{
          width: 2,
          height: 0,
          borderLeft: '10px solid transparent',
          borderRight: '10px solid transparent',
          borderTop: '16px solid black',
          position: 'relative',
          top: '-15px',
        }}
      />
      <Box
        sx={{
          width: '26px',
          left: '-23px',
          top: '-10px',
          height: `${thumbHeight-1}px`,
          backgroundColor: 'transparent',
          position: 'relative',
          transform: 'translateY(50%)',
        }}
      >
        <Box
          sx={{
            width: '2px',
            left: '12px',
            height: `${thumbHeight-1}px`,
            backgroundColor: 'black',
            position: 'relative',
          }}
        />
      </Box>
    </SliderThumb>
  );
}

interface FrameSliderProps {
  min: number;
  totalDuration: number;
  maxSliderOffset: number;
  step: number;
  numMarkers: number;
  scrollPos: number;
  visibleDuration: number;
  sliderHeight: number;
  paddingLeft: number;
  paddingRight: number;
  onChange: (event: Event, newValue: number | number[]) => void;
}

const FrameSlider: React.FC<FrameSliderProps> = ({ min, totalDuration, maxSliderOffset, step, numMarkers, scrollPos, visibleDuration, sliderHeight, paddingLeft, paddingRight, onChange }) => {
  const { currentTime, runningState, state } = useEditorContext();
  const currentFrameId = useRef<number>(0);
  const monitorEndTime = useRef<number>(0);

  const [sliderValue, setSliderValue] = useState<number>(0);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const maxSliderOffsetRef = useRef<number>(maxSliderOffset);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  // Use a callback ref to get notified when the div is mounted
  const setSliderRef = (node: HTMLDivElement | null) => {
    if (node) {
      // Node is now available
      sliderRef.current = node;

      // Set up the ResizeObserver
      const resizeObserver = new ResizeObserver(() => {
        drawMarkers();
      });

      resizeObserver.observe(node);
      resizeObserverRef.current = resizeObserver;

      // Initial drawing
      drawMarkers();
    } else {
      // Node is unmounted, clean up
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }
      sliderRef.current = null;
    }
  };

  const monitorTimeForPeriod = (period: number) => {
    const pollTimeAndSet = () => {
      setSliderValue(Math.min(currentTime.current, maxSliderOffsetRef.current));

      const currentTimeMillis = Date.now();
      if (monitorEndTime.current < currentTimeMillis) {
        cancelAnimationFrame(currentFrameId.current);
        currentFrameId.current = 0;
      } else {
        currentFrameId.current = requestAnimationFrame(pollTimeAndSet);
      }
    }

    const currentTimeMillis = Date.now();
    monitorEndTime.current = Math.max(monitorEndTime.current, currentTimeMillis + period);

    // if there is no pending animation frame - start listening to events
    if (!currentFrameId.current) {
      currentFrameId.current = requestAnimationFrame(pollTimeAndSet);
    }

  }

  useEffect(() => {
    if (runningState.isRunning) {
      monitorTimeForPeriod(Infinity);
    } else {
      monitorEndTime.current = 0;
    }
  }, [runningState.isRunning]);

  useEffect(() => {
    drawMarkers();
  }, [scrollPos, totalDuration, min]);
  useEffect(() => {
    maxSliderOffsetRef.current = maxSliderOffset;
  }, [maxSliderOffset]);
  useEffect(() => {
  }, [sliderHeight]);
  useEffect(() => {
    setSliderValue(Math.min(currentTime.current, maxSliderOffsetRef.current));
  }, [state]);

  const drawMarkers = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    if (!sliderRef.current) return;
    const totalWidth = sliderRef.current.clientWidth - paddingLeft - paddingRight;

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Set font style
    ctx.font = '0.8em Arial'; // Font size, weight, and family
    ctx.fillStyle = 'black'; // Font color

    const markerGap = totalWidth / numMarkers;
    const markerDuration = visibleDuration / numMarkers;
    const startTime = scrollPos * totalDuration;
    const remainder = startTime % markerDuration;
    const firstMarkerTimeOffset = startTime < markerDuration ? startTime : markerDuration - remainder;
    const firstMarkerOffset = firstMarkerTimeOffset / visibleDuration * totalWidth;

    const visibleStartMarker = scrollPos * totalDuration;

    for (let i = 0; i <= numMarkers; i++) {
      const time = visibleStartMarker + i * markerDuration;
      const x =  paddingLeft + firstMarkerOffset + i * markerGap;

      // Draw time label
      ctx.fillText(formatTime(time), x - 10, 25);

      // Draw marker line
      ctx.beginPath();
      ctx.moveTo(x, 29);
      ctx.lineTo(x, 39);
      ctx.strokeStyle = 'black';
      ctx.lineWidth = 1;
      ctx.stroke();
    }
  }

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleChange = (event: any, newValue: any, commitValue: boolean) => {
    // for each slider change - monitor for the next half second
    monitorTimeForPeriod(500);
    newValue = Math.min(newValue, maxSliderOffsetRef.current);
    if (commitValue) {
      setSliderValue(newValue);
    }
    onChange(event, newValue);
  };

  return (
    <div
      ref={setSliderRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '50px',
        cursor: 'pointer',
        pointerEvents: 'auto',
        userSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          height: '100%',
          pointerEvents: 'none',
        }}
      >
        <canvas
          ref={canvasRef}
          width={sliderRef.current?.clientWidth}
          height={50}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: 50,
            pointerEvents: 'none',
          }}
        />
      </Box>

      <Box sx={{paddingLeft: `${paddingLeft}px`, paddingRight: `${ paddingRight}px`}}>
        <CustomSlider
          value={sliderValue}
          components={{ Thumb: ThumbComponent }}
          componentsProps={{
            thumb: { thumbHeight: sliderHeight } as any, // Pass visibility prop to ThumbComponent
          }}
          min={scrollPos * totalDuration}
          max={scrollPos * totalDuration + visibleDuration}
          step={step}
          onChange={(event: any, newValue: any) => handleChange(event, newValue, true)}
          onChangeCommitted={(event: any, newValue: any) => handleChange(event, newValue, false)}
          sx={{
            position: 'relative',
            top: 0,
            height: '100%',
            zIndex: 3,
            '& .MuiSlider-thumb': {
              visibility: sliderValue >= scrollPos * totalDuration && sliderValue <= scrollPos * totalDuration + visibleDuration ? 'visible' : 'hidden'
            },
            display: 'block',
            '&:hover, &:focus, &:active': {
              backgroundColor: 'transparent',
            },
            transition: 'none',
          }}
        />
      </Box>

    </div>
  );
};

export { FrameSlider };
