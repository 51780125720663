import { getSignedVideoDownloadUrl } from '../../api/ServerApi';
import { Point, calculateXYDifferenceFromCenter, createImageMedia } from '../../utils/utils';
import Media, { Crop, MediaType, NON_SERIALIZABLE_KEY } from './Media';

export default class Image extends Media {
    mediaType: MediaType = 'image';
    constructor ({
        id,
        name,
        sha256,
        url,
        type,
        row,
        duration,
        start,
        leftBackgroundOffset, 
        rightBackgroundOffset, 
        videoRef,
        startBase, 
        endBase, 
        isResizing, 
        dirty, 
        framesOffset, 
        playOffset, 
        onLoadedCalled,
        effects,
        height,
        width,
        scaledHeight,
        scaledWidth,
        baseHeight,
        baseWidth,
        x,
        y,
        rotation,
        crop,
        leftTrim,
        rightTrim,
    }: {
        id?: string,
        name: string,
        sha256: string,
        url: string,
        type: string,
        row: number,
        start: number,
        leftBackgroundOffset: number,
        rightBackgroundOffset: number,
        startBase: number,
        endBase: number,
        videoRef: any;
        duration: number,
        isResizing: boolean,
        dirty: boolean,
        playOffset?: number;
        framesOffset?: number;
        onLoadedCalled?: boolean;
        effects?: string[];
        height: number;
        width: number;
        scaledHeight: number | null;
        scaledWidth: number | null;
        baseHeight: number;
        baseWidth: number;
        x: number;
        y: number;
        rotation: number;
        crop: Crop;
        leftTrim?: number,
        rightTrim?: number,
    }) {
        super({
            id, 
            name,
            sha256,
            url,
            type,
            row, 
            duration, 
            start,
            leftBackgroundOffset, 
            rightBackgroundOffset, 
            videoRef,
            startBase, 
            endBase, 
            isResizing, 
            dirty, 
            framesOffset, 
            playOffset, 
            onLoadedCalled,
            effects,
            height,
            width,
            scaledHeight,
            scaledWidth,
            baseHeight,
            baseWidth,
            x,
            y,
            rotation,
            crop,
            resizable: true,
            cropable: true,
            uploadComplete: true,
            speed: 1,
            hasVideoComponent: true,
            hasAudioComponent: false,
            leftTrim,
            rightTrim,
        });
    }

    setRotation = async (degrees: number) => {
        this.rotation = degrees;
    }

    getRotatedCoords = () => {
        const height = this.scaledHeight || 0;
        const width = this.scaledWidth || 0;
        const relativeCorners: [Point, Point, Point, Point] = [
            { x: 0, y: 0 }, // Top-left
            { x: width, y: 0 },  // Top-right
            { x: width, y: height },   // Bottom-right
            { x: 0, y: height }   // Bottom-left
        ];
        const { xDifference, yDifference, cornerDifferences, newCenter, newWidth, newHeight, boundingX, boundingY } = calculateXYDifferenceFromCenter(relativeCorners,
        width / 2, height / 2, 0, this.rotation
        );
        const corners = cornerDifferences.map((corner: Point) => {
        return {x: this.x + corner.x, y: this.y + corner.y };
        })
        const center = {x: this.x + newCenter.x, y: this.y + newCenter.y };
        return {xCord: xDifference + this.x, yCord: yDifference + this.y, corners, center, width: newWidth, height: newHeight, boundingX: this.x + boundingX, boundingY: this.y + boundingY};
    }

    hasVideo = (): boolean => {
        return true;
    }

    hasAudio = (): boolean => {
        return false;
    }

    audioEnabled = (): boolean => {
        return false;
    }

    getEffectString = (prependVideoNormalization: boolean = false, addRotation: boolean = false): string => {
        return `scale=${this.scaledWidth}:${this.scaledHeight},rotate=${this.rotation}*PI/180:ow='iw*abs(cos(${this.rotation}*PI/180))+ih*abs(sin(${this.rotation}*PI/180))':oh='iw*abs(sin(${this.rotation}*PI/180))+ih*abs(cos(${this.rotation}*PI/180)):c=none'`;
    }

    deepCopy = ():  Image => {
        return new Image({...this});
    }

    serialize = (): Partial<Image> => {
        const cleanVideo = this.deepCopy();
        const nonSerializableFields: Array<keyof Image> = Reflect.getMetadata(NON_SERIALIZABLE_KEY, this) || [];

        nonSerializableFields.forEach((key) => {
        delete cleanVideo[key];
        })

        return cleanVideo;
    }

    serializeForRender = (): Image => {
        const cleanImage = this.deepCopy();
        // need to assign  the x,y cords after the video was rotated (this is how the backend overlays the videos)
        const { boundingX, boundingY }  = this.getRotatedCoords();
        cleanImage.x = boundingX;
        cleanImage.y = boundingY;
        cleanImage.videoRef = null;
        return cleanImage;
    }

    public static async fromSerialized(serializedMedia: any, options?: any) {
        const presignedUrl = await getSignedVideoDownloadUrl(serializedMedia.sha256);
        // assign to a local url to prevent fetching from remote storage again
        const blobUrl = window.URL.createObjectURL(await (await fetch(presignedUrl)).blob());
        delete serializedMedia.url;

        const imageComponent = await createImageMedia(blobUrl, serializedMedia.row, serializedMedia.duration, serializedMedia);
        return imageComponent;
    }

    requiresWasm = (): boolean => {
        return false;
    }
}
