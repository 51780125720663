import React, { useState, useEffect } from 'react';
import {
  Grid,
  CardActionArea,
  Typography,
  Box,
  useTheme,
  Divider,
  Tooltip,
} from '@mui/material';
import Video from '../media/Video';
import { transitionsData } from '../Transitions';
import { useEditorContext } from '../VideosContext';
import CheckIcon from '@mui/icons-material/Check';

type TransitionsMenuProps = {
  selectedVideo: Video | null;
  isMobileLayout: boolean;
  onSelect: (
    effectName: string,
    time: number,
    duration: number,
    row: number
  ) => void;
};

const TransitionsMenu: React.FC<TransitionsMenuProps> = ({
  selectedVideo,
  isMobileLayout,
  onSelect,
}) => {
  const theme = useTheme();
  const [selectedEffect, setSelectedEffect] = useState<string | null>(null);

  const { currentTime, setIsDroppingTransition } = useEditorContext();

  const isDisabled = false;

  useEffect(() => {
    if (!selectedVideo || selectedVideo.effects.length === 0) {
      setSelectedEffect(null);
      return;
    }
    setSelectedEffect(selectedVideo.effects[0]);
  }, [selectedVideo]);

  const handleSelect = (effectName: string) => {
    // selection is not used - only drag & drop
    return;
    if (!selectedVideo) {
      setSelectedEffect(null);
      return;
    }

    if (selectedEffect === effectName) {
      setSelectedEffect(null);
    } else {
      setSelectedEffect(effectName);
    }

    onSelect(effectName, currentTime.current, 3, 0);
  };

  const handleDragEnd = (e: React.DragEvent) => {
    setIsDroppingTransition(false);
  };

  const handleDragStart = (e: React.DragEvent, transitionName: string) => {
    e.dataTransfer.setData('text/plain', 'transition');
    e.dataTransfer.setData('dataType', 'transition');
    e.dataTransfer.setData('transitionName', transitionName);
    setIsDroppingTransition(true);
  };

  return (
    <Box
      sx={{
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: '#fafafa',
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          padding: 2,
          direction: 'ltr',
          backgroundColor: '#fafafa',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Choose a Transition
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
          To apply a transition, drag and drop it between two videos on the timeline.
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />

        <Grid container spacing={1}>
          {transitionsData.map((effect) => (
            <Grid item xs={3} key={effect.name}>
              <Tooltip title={effect.description || ''} arrow placement="top">
                <div
                  draggable={true}
                  onDragStart={(e) => handleDragStart(e, effect.name)}
                  onDragEnd={handleDragEnd}
                  //onClick={isDisabled ? undefined : () => handleSelect(effect.name)}
                  style={{
                    position: 'relative',
                    height: '80px',
                    borderRadius: theme.spacing(0.5),
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.shadows[1],
                    overflow: 'hidden',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    opacity: isDisabled ? 0.5 : 1,
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                    border:
                      effect.name === selectedEffect
                        ? `2px solid ${theme.palette.primary.main}`
                        : 'none',
                  }}
                  onMouseOver={(e) => {
                    if (!isDisabled) {
                      e.currentTarget.style.boxShadow = theme.shadows[3];
                      e.currentTarget.style.transform = 'scale(1.05)';
                    }
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.boxShadow = theme.shadows[1];
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                >
                  <CardActionArea
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'relative',
                    }}
                  >
                    <Box
                      component="img"
                      src={effect.image}
                      alt={effect.name}
                      sx={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: '#fff',
                        textAlign: 'center',
                        fontSize: '0.7rem',
                        padding: '2px 0',
                      }}
                    >
                      {effect.name}
                    </Box>
                    {effect.name === selectedEffect && (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 4,
                          right: 4,
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: '50%',
                          width: 16,
                          height: 16,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CheckIcon sx={{ color: '#fff', fontSize: '12px' }} />
                      </Box>
                    )}
                  </CardActionArea>
                </div>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default TransitionsMenu;
