import { Box } from "@mui/material";
import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef, useCallback } from "react";
import { ResizableBox } from "react-resizable";
import JASSUB, { ASS_Style } from "jassub";
import { mat4 } from 'gl-matrix';

import RotatableBox, { DirectionType, cropDirections } from "./RotatableBox";
import Video from "./media/Video";
import Audio from "./media/Audio";
import Media from "./media/Media";
import ImageMedia from "./media/Image";
import TextMedia from "./media/Text";
import { useEditorContext } from "./VideosContext";
import { VideoAnimationCommandBase, debounceSubtitlesChangeHistoryAction } from "./undo/UndoInterface";

import CommandHistory from './undo/UndoInterface';
import getEngine from "./EffectsEngine";
import { MediaLayer, Segment } from "../utils/serializer";
import { Point, calculateXYDifferenceFromCenter, getActiveSegment, handleMediaResize, resizeTextComponent, seekAllVideos, waitForSeek } from "../utils/utils";
import Subtitles from "./media/Subtitles";
import { initialASSStyle } from "./menus/SubtitlesMenu";
import { produceAssFileForSegments } from "../utils/assparser";
import { TextStyleChangeOptions } from "./menus/TextMenu";
import SelectionBox from "./SelectionBox";
import RightClickMenu, { MenuOpenConfig } from "./RightClickMenu";
import Transition from "./media/Transition";

const Engine = await getEngine();

const shaderProgramCache: { [key: string]: WebGLProgram } = {};

let gl: WebGLRenderingContext | null;
let program: WebGLProgram;
let texture: WebGLTexture | null;

function getTransitionProgram(gl: WebGLRenderingContext, transitionName: string): WebGLProgram {
    if (shaderProgramCache[transitionName]) {
        // Return the cached program
        return shaderProgramCache[transitionName];
    }

    let fragmentShaderSource = `
        precision mediump float;
        varying vec2 v_texCoord;
        uniform sampler2D from;
        uniform sampler2D to;
        uniform float progress;

        vec4 getFromColor(vec2 uv) {
            return texture2D(from, uv);
        }

        vec4 getToColor(vec2 uv) {
            return texture2D(to, uv);
        }

    `;
    // Load the fragment shader source (synchronously for simplicity)
    fragmentShaderSource += loadShaderSourceSync(`assets/transition_shaders/${transitionName}.glsl`);

    fragmentShaderSource += `
      void main() {
          gl_FragColor = transition(v_texCoord);
      }
    `

    // Use the common transition vertex shader source
    const transitionVertexShaderSource = `
        attribute vec2 a_position;
        attribute vec2 a_texCoord;
        varying vec2 v_texCoord;
        uniform vec2 u_translation;
        uniform vec2 u_scale;
        uniform mat4 u_rotationMatrix;

        void main() {
            vec2 scaledPosition = (a_position * u_scale) + u_translation;
            gl_Position = u_rotationMatrix * vec4(scaledPosition, 0.0, 1.0);
            v_texCoord = vec2(a_texCoord.x, 1.0 - a_texCoord.y);
        }
    `;

    // Compile the shader program
    const program = createShaderProgram(gl, transitionVertexShaderSource, fragmentShaderSource);

    // Cache the program
    shaderProgramCache[transitionName] = program;

    return program;
}

// Function to synchronously load shader source (simplified)
function loadShaderSourceSync(url: string): string {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, false); // Synchronous request
    xhr.send(null);

    if (xhr.status === 200) {
        return xhr.responseText;
    } else {
        throw new Error(`Failed to load shader source from ${url}`);
    }
}

function initWebGLCanvas(canvas: HTMLCanvasElement): void {
    gl = canvas.getContext('webgl', {
        alpha: true,
        premultipliedAlpha: true,
    });
    if (!gl) {
        throw new Error('WebGL not supported');
    }

    gl.enable(gl.BLEND);
    gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

    const vertexShaderSource = `
        attribute vec2 a_position;
        attribute vec2 a_texCoord;
        varying vec2 v_texCoord;
        uniform vec2 u_translation;
        uniform vec2 u_scale;
        uniform mat4 u_rotationMatrix;

        void main() {
            vec2 scaledPosition = (a_position * u_scale) + u_translation;
            gl_Position = u_rotationMatrix * vec4(scaledPosition, 0.0, 1.0);
            v_texCoord = a_texCoord;
        }
    `;

    // Existing fragment shader for normal rendering
    const fragmentShaderSource = `
        precision mediump float;
        varying vec2 v_texCoord;
        uniform sampler2D u_texture;
        void main() {
            gl_FragColor = texture2D(u_texture, v_texCoord);
        }
    `;

    // Create and compile the default shader program
    program = createShaderProgram(gl, vertexShaderSource, fragmentShaderSource);

    gl.useProgram(program);

    // Setup position buffer
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = new Float32Array([
        -1.0, -1.0, // Bottom left
         1.0, -1.0, // Bottom right
        -1.0,  1.0, // Top left
         1.0,  1.0  // Top right
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);

    const positionLocation = gl.getAttribLocation(program, 'a_position');
    gl.enableVertexAttribArray(positionLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    // Setup texture coordinate buffer
    const texCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    const texCoords = new Float32Array([
        0.0, 1.0,
        1.0, 1.0,
        0.0, 0.0,
        1.0, 0.0
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, texCoords, gl.STATIC_DRAW);

    const texCoordLocation = gl.getAttribLocation(program, 'a_texCoord');
    gl.enableVertexAttribArray(texCoordLocation);
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);

    texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

    const textureLocation = gl.getUniformLocation(program, 'u_texture');
    gl.uniform1i(textureLocation, 0);

    // Initialize rotation matrix
    const rotationMatrix = mat4.create();
    mat4.identity(rotationMatrix);

    const rotationMatrixLocation = gl.getUniformLocation(program, 'u_rotationMatrix');
    gl.uniformMatrix4fv(rotationMatrixLocation, false, rotationMatrix);
}

function createShaderProgram(gl: WebGLRenderingContext, vertexShaderSource: string, fragmentShaderSource: string): WebGLProgram {
    const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vertexShaderSource) as WebGLShader;
    const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource) as WebGLShader;
    const shaderProgram = gl.createProgram() as WebGLProgram;
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    gl.linkProgram(shaderProgram);

    // Check if the program was linked successfully
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
        console.error('Unable to initialize the shader program:', gl.getProgramInfoLog(shaderProgram));
        return null as any;
    }

    return shaderProgram;
}

function loadShader(gl: WebGLRenderingContext, type: number, source: string): WebGLShader | null {
    const shader = gl.createShader(type) as WebGLShader;
    gl.shaderSource(shader, source);
    gl.compileShader(shader);

    // Check if the shader compiled successfully
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error('An error occurred compiling the shaders:', gl.getShaderInfoLog(shader));
        gl.deleteShader(shader);
        return null;
    }

    return shader;
}

let framebuffer: WebGLFramebuffer | null = null;
function setupFramebuffer(width: number, height: number) {
    // Create the framebuffer if it doesn't exist
    if (!framebuffer && gl) {
        framebuffer = gl.createFramebuffer();

        // Create a texture to attach to the framebuffer
        const framebufferTexture = gl.createTexture();
        gl.bindTexture(gl.TEXTURE_2D, framebufferTexture);
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

        // Bind the framebuffer and attach the texture
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, framebufferTexture, 0);

        // Check if the framebuffer is complete
        if (gl.checkFramebufferStatus(gl.FRAMEBUFFER) !== gl.FRAMEBUFFER_COMPLETE) {
            throw new Error('Framebuffer is not complete');
        }

        // Unbind the framebuffer for now
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    }
}

function renderVideoFrameGL(
    dataSource: HTMLVideoElement | Uint8Array | HTMLCanvasElement,
    x: number,
    y: number,
    width: number,
    height: number,
    rotation: number,
    canvas: HTMLCanvasElement,
    out: Uint8Array | null,
    renderOffscreen: boolean = false,
    crop: any = { top: 0, bottom: 0, left: 0, right: 0 },
    transitionParams?: {
        fromDataSource: HTMLVideoElement | Uint8Array | HTMLCanvasElement,
        progress: number,
        transitionName: string, // Add transitionName parameter
    },
): void {
    if (!gl) {
        throw new Error('WebGL not initialized');
    }

    // Determine if we're doing a transition
    const isTransition = !!transitionParams;

    // Use the appropriate shader program
    let currentProgram: WebGLProgram;
    if (isTransition) {
        const transitionName = transitionParams!.transitionName;
        currentProgram = getTransitionProgram(gl, transitionName);
    } else {
        currentProgram = program; // Use the default program
    }

    gl.useProgram(currentProgram);

    // Bind the framebuffer
    if (renderOffscreen && framebuffer) {
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    } else {
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    }

    // Set up textures
    if (isTransition) {
        // Bind 'from' texture (texture unit 0)
        const fromTexture = gl.createTexture();
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, fromTexture);
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
        loadTextureData(gl, transitionParams!.fromDataSource, width, height);
        setTextureParameters(gl);

        // Bind 'to' texture (texture unit 1)
        const toTexture = gl.createTexture();
        gl.activeTexture(gl.TEXTURE1);
        gl.bindTexture(gl.TEXTURE_2D, toTexture);
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
        loadTextureData(gl, dataSource, width, height);
        setTextureParameters(gl);

        // Set uniform samplers
        const fromLocation = gl.getUniformLocation(currentProgram, 'from');
        gl.uniform1i(fromLocation, 0); // Texture unit 0

        const toLocation = gl.getUniformLocation(currentProgram, 'to');
        gl.uniform1i(toLocation, 1); // Texture unit 1

        // Set the progress uniform
        const progressLocation = gl.getUniformLocation(currentProgram, 'progress');
        gl.uniform1f(progressLocation, transitionParams!.progress);
    } else {
        // Normal rendering
        gl.activeTexture(gl.TEXTURE0);
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
        loadTextureData(gl, dataSource, width, height);
        setTextureParameters(gl);

        // Set the sampler uniform
        const textureLocation = gl.getUniformLocation(currentProgram, 'u_texture');
        gl.uniform1i(textureLocation, 0);
    }

    // Setup position attribute
    const positionLocation = gl.getAttribLocation(currentProgram, 'a_position');
    gl.enableVertexAttribArray(positionLocation);

    // Use the same position buffer
    const positionBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
    const positions = new Float32Array([
        -1.0, -1.0, // Bottom left
         1.0, -1.0, // Bottom right
        -1.0,  1.0, // Top left
         1.0,  1.0  // Top right
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, positions, gl.STATIC_DRAW);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    // Setup texture coordinate attribute
    const texCoordLocation = gl.getAttribLocation(currentProgram, 'a_texCoord');
    gl.enableVertexAttribArray(texCoordLocation);

    const texCoordBuffer = gl.createBuffer();
    gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
    const texCoords = new Float32Array([
        crop.left / width, (height - crop.top) / height,
        (width - crop.right) / width, (height - crop.top) / height,
        crop.left / width, crop.bottom / height,
        (width - crop.right) / width, crop.bottom / height
    ]);
    gl.bufferData(gl.ARRAY_BUFFER, texCoords, gl.STATIC_DRAW);
    gl.vertexAttribPointer(texCoordLocation, 2, gl.FLOAT, false, 0, 0);

    // Set transformation uniforms (translation, scale, rotation)
    const translationLocation = gl.getUniformLocation(currentProgram, 'u_translation');
    const scaleLocation = gl.getUniformLocation(currentProgram, 'u_scale');
    const rotationMatrixLocation = gl.getUniformLocation(currentProgram, 'u_rotationMatrix');

    // Compute translation and scale
    const croppedWidth = width - crop.left - crop.right;
    const croppedHeight = height - crop.top - crop.bottom;

    gl.uniform2f(
        translationLocation,
        ((2 * (x + croppedWidth / 2)) / canvas.width) - 1,
        1 - ((2 * (y + croppedHeight / 2)) / canvas.height)
    );

    gl.uniform2f(scaleLocation, croppedWidth / canvas.width, croppedHeight / canvas.height);

    let radians = -((rotation / 360) * Math.PI * 2);
    const rotationMatrix = mat4.create();

    // Aspect ratio adjustment
    const aspectRatio = canvas.width / canvas.height;
    // Adjust for aspect ratio, translate, rotate, and translate back
    mat4.translate(rotationMatrix, rotationMatrix, [
      (x + croppedWidth / 2) / (canvas.width / 2) - 1,
      1 - (y + croppedHeight / 2) / (canvas.height / 2),
      0,
    ]);
    mat4.scale(rotationMatrix, rotationMatrix, [1 / aspectRatio, 1, 1]); // Aspect ratio correction
    mat4.rotateZ(rotationMatrix, rotationMatrix, radians);
    mat4.scale(rotationMatrix, rotationMatrix, [aspectRatio, 1, 1]); // Aspect ratio correction
    mat4.translate(rotationMatrix, rotationMatrix, [
      -(x + croppedWidth / 2) / (canvas.width / 2) + 1,
      -(1 - (y + croppedHeight / 2) / (canvas.height / 2)),
      0,
    ]);


    gl.uniformMatrix4fv(rotationMatrixLocation, false, rotationMatrix);

    // Draw the quad
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);

    // Read pixels if needed
    if (out) {
        gl.readPixels(x, canvas.height - height - y, croppedWidth, croppedHeight, gl.RGBA, gl.UNSIGNED_BYTE, out);
    }
}

function loadTextureData(
    gl: WebGLRenderingContext,
    dataSource: HTMLVideoElement | Uint8Array | HTMLCanvasElement,
    width: number,
    height: number
): void {
    if (dataSource instanceof Uint8Array) {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, dataSource);
    } else if (dataSource instanceof HTMLVideoElement || dataSource instanceof HTMLCanvasElement) {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, dataSource);
    }
}

function setTextureParameters(gl: WebGLRenderingContext): void {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
}

interface EditorCanvasProps {
  isMobileLayout: boolean;
  onTextStyleChanged: ({text, style, width, height, x, y, start, end, commit}: TextStyleChangeOptions) => Promise<number | undefined>
  onVideoSelected: (selectedMedia: Media | null, multipleSelectionMode?: boolean) => void;
  onDuplicate: () => void;
  onDelete: () => void;
  onCopy: () => void;
  onPaste: () => void;
}

export interface EditorCanvasRef {
  setVideoTime: (newTime: number) => Promise<void>;
  playVideo: () => void;
  pauseVideo: () => void;
  setCanvasAspectRatioFunc: (newAspectRatio: number, width?: number, height?: number, subtitlesCanvasWidth?: number, subtitlesCanvasHeight?: number) => {newCanvasWidth: number, newCanvasHeight: number};
  onSubtitlesStyleChanged: (subtitles: any, style: ASS_Style, commit?: boolean) => Promise<void>;
  loadSegmentSubtitles: (segments: Segment[]) => Promise<void>;
  clearAllSubtitles: () => void;
  renderOneFrame: (
    seconds: number,
    shouldSeek: boolean,
    reApplyFilters: boolean,
    newWidth?: number,
    newHeight?: number,
    segments?: Segment[],
    shouldResizeSubtitles?: boolean) => Promise<boolean | undefined>;
  renderVideosWithFilter: (renderVideos: Video[], newWidth?: number, newHeight?: number, shouldResizeSubtitles?: boolean, shouldPauseSubtitles?: boolean)  => Promise<void>;
  updateBoundingBoxes: (videos: Media[], subtitles: Subtitles | null) => void,
  resizeRenderCanvas: (width: number, height: number, realTime: boolean) => void,
  recalculateSubtitlesSize: () => Promise<void>,
}

const EditorCanvas = forwardRef<EditorCanvasRef, EditorCanvasProps>((props, ref) => {
  const {
    onTextStyleChanged,
    onVideoSelected,
    onDelete,
    onCopy,
    onPaste,
    onDuplicate
  } = props;

  // Menu state
  const [menuOpenConfig, setMenuOpenConfig] = useState<MenuOpenConfig>({
    isOpen: false,
    shouldActivateSubtitlesDownload: false,
    shouldActivateCopy: false,
    shouldActivatePaste: false,
    shouldActivateDuplicate: false,
    shouldActivateDelete: false
  });
  const [menuPosition, setMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({
    mouseX: null,
    mouseY: null,
  });

  // Mouse selection state
  const [selectionStart, setSelectionStart] = useState<Point | null>(null);
  const [selectionEnd, setSelectionEnd] = useState<Point | null>(null);
  const [isSelecting, setIsSelecting] = useState(false);

  const [boundingBoxes, setBoundingBoxes] = useState<{[key:string]: any}>({});
  const rotatableBoxMultiplier = useRef<any>({width: 1, height: 1, initialWidth: 1, initialHeight: 1});
  const canvasBoxRef = useRef<any>(null);
  const renderCanvas = useRef<any>(null);
  const draftCanvas = useRef<any>(null);
  const subtitlesCanvas = useRef<any>(null);
  const moveableBoxRef = useRef<any>(null);
  const subtitlesRenderer = useRef<JASSUB | null>(null);
  const shouldRender = useRef<{renderFrame: boolean, keepRunning: boolean}>({renderFrame: false, keepRunning: false});
  const playStartTime = useRef<number | null>(0);
  const playStartBase = useRef<number | null>(0);
  const animationId = useRef<any>(null);

  const beforeResizeVideosRef = useRef<any[]>([]);
  const subtitlesStyleStateBeforeAfter = useRef<{
    beforeStyle: any,
    afterStyle: any,
    beforeDimensions: any,
    afterDimensions: any,
    beforeHtmlHeight: any}>({
      beforeStyle: null,
      afterStyle: null,
      beforeDimensions: null,
      afterDimensions: null,
      beforeHtmlHeight: null
    });

  const initialSubtitlesWidthPercent = 0.7;
  const initialSubtitlesYOffset = 35;

  const {
    setVideos,
    getCopyBuffer,
    getVideos,
    setCanvasState,
    setSubtitlesCanvasState,
    getSubtitlesContainer,
    getCanvasBackground,
    setCurrentTime,
    setGlobalIsRunning,
    getSelectedVideos,
    state,
    totalTracksDuration,
    canvasState,
    subtitlesCanvasState,
    currentTime,
    videoSegments,
    initialWidth,
    initialHeight
  } = useEditorContext();

  const requiresWasm = (media: Media) => {
    return media.requiresWasm();
  }

  const getTextDimensions = useCallback((canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D): Promise<number> => {
    return new Promise((resolve) => {
      subtitlesRenderer.current?.getEvents((err: any, events: any) => {
        if (!ctx || !state.subtitlesContainer) {
          resolve(0);
          return;
        }
        const filteredEvents = events.filter((event: any) => event.Start <= currentTime.current * 1000 && event.Start + event.Duration >= currentTime.current * 1000);
        if (filteredEvents.length) {
          // Get pixel data of the canvas
          const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
          const pixels = imageData.data;

          let firstNonBackgroundY = -1;
          let lastNonBackgroundY = -1;

          // Scan vertically to find the first and last non-background pixels
          for (let y = 0; y < canvas.height; y++) {
            for (let x = 0; x < canvas.width; x++) {
              const index = (y * canvas.width + x) * 4; // Each pixel is represented by 4 values (R, G, B, A)
              const alpha = pixels[index + 3]; // A is the 4th value (index + 3)

              if (alpha > 0) { // Non-background pixel (alpha > 0)
                if (firstNonBackgroundY === -1) {
                  firstNonBackgroundY = y; // First non-background pixel
                }
                lastNonBackgroundY = y; // Last non-background pixel
                break;
              }
            }
          }

          const textHeight = lastNonBackgroundY - firstNonBackgroundY;

          resolve(textHeight);
        } else {
          resolve(0);
        }
      })
    })
  }, [currentTime, state.subtitlesContainer]);

  const recalculateSubtitlesSize = useCallback(async (): Promise<void> => {
    return new Promise(async (resolve) => {
      const ctx = subtitlesCanvas.current.getContext('2d', { alpha: true });
      if (!ctx) {
        return;
      }
      const totalHeight = await getTextDimensions(subtitlesCanvas.current, ctx);
      const usedHeight = totalHeight;
      const subtitlesContainer = getSubtitlesContainer();
      const newContainer = {...subtitlesContainer, y: subtitlesContainer.y + subtitlesContainer.height - usedHeight, height: usedHeight};
      const selectedVideoId = state.selectedVideoLayers[0] instanceof Subtitles ? 'subtitles' : state.selectedVideoLayers[0]?.id;
      setVideos(getVideos(), [selectedVideoId], undefined, newContainer);
      resolve();
    })
  }, [setVideos, getTextDimensions, getSubtitlesContainer, getVideos, state]);

  const renderSubtitles = useCallback((isPaused: boolean = true, shouldResize: boolean = false) => {
    if (subtitlesRenderer.current && subtitlesCanvas.current && state.subtitlesContainer) {
      subtitlesRenderer.current.setCurrentTime(isPaused, currentTime.current);
      if (shouldResize) {
        recalculateSubtitlesSize();
      }
    }
  }, [state.subtitlesContainer, recalculateSubtitlesSize, currentTime]);

  const renderVideosWithFilter = useCallback(async (renderVideos: Media[], newWidth?: number, newHeight?: number, shouldResizeSubtitles: boolean = false, shouldPauseSubtitles: boolean = false): Promise<void>  => {
    return new Promise(async (resolve) => {
      if (gl && newHeight && newWidth) {
        gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
        gl.viewport(0, 0, newWidth, newHeight);
        gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        gl.viewport(0, 0, newWidth, newHeight);
      }
      for (const curVideo of renderVideos) {
        if (curVideo instanceof Transition) {
          if (gl) {
            if (curVideo.fromStorage?.length !== renderCanvas.current.width * renderCanvas.current.height * 4) {
              curVideo.fromStorage = new Uint8Array(renderCanvas.current.width * renderCanvas.current.height * 4);
            }
            if (curVideo.toStorage?.length !== renderCanvas.current.width * renderCanvas.current.height * 4) {
              curVideo.toStorage = new Uint8Array(renderCanvas.current.width * renderCanvas.current.height * 4);
            }
            // clear to transparent background - so when we layer, the background does not interfere
            gl.clearColor(0,0,0,0);
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

            const fromCrop = {left: curVideo.fromVideo.crop.left * (curVideo.fromVideo.baseWidth as number), right: curVideo.fromVideo.crop.right * (curVideo.fromVideo.baseWidth as number), bottom: curVideo.fromVideo.crop.bottom * (curVideo.fromVideo.baseHeight as number), top: curVideo.fromVideo.crop.top * (curVideo.fromVideo.baseHeight as number)}
            // paint the left video on the screen
            renderVideoFrameGL(curVideo.fromVideo.videoRef, curVideo.fromVideo.x, curVideo.fromVideo.y, curVideo.fromVideo.scaledWidth as number, curVideo.fromVideo.scaledHeight as number, curVideo.fromVideo.rotation, renderCanvas.current, null, false, fromCrop);
            // get the pixels of the entire screen onto fromStorage
            gl.readPixels(0, 0, renderCanvas.current.width, renderCanvas.current.height, gl.RGBA, gl.UNSIGNED_BYTE, curVideo.fromStorage);

            // clear to transparent background - so when we layer, the background does not interfere
            gl.clearColor(0,0,0,0);
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

            const toCrop = {left: curVideo.toVideo.crop.left * (curVideo.toVideo.baseWidth as number), right: curVideo.toVideo.crop.right * (curVideo.toVideo.baseWidth as number), bottom: curVideo.toVideo.crop.bottom * (curVideo.toVideo.baseHeight as number), top: curVideo.toVideo.crop.top * (curVideo.toVideo.baseHeight as number)}
            // paint the right video on the screen
            renderVideoFrameGL(curVideo.toVideo.videoRef, curVideo.toVideo.x, curVideo.toVideo.y, curVideo.toVideo.scaledWidth as number, curVideo.toVideo.scaledHeight as number, curVideo.toVideo.rotation, renderCanvas.current, null, false, toCrop);
            // get the pixels of the entire screen onto toStorage
            gl.readPixels(0, 0, renderCanvas.current.width, renderCanvas.current.height, gl.RGBA, gl.UNSIGNED_BYTE, curVideo.toStorage);
          }
        } else if ((curVideo instanceof Video) && requiresWasm(curVideo)) {
          if (!curVideo.storageBuffer || curVideo.storageBuffer.length !== (curVideo.scaledWidth as number) * (curVideo.scaledHeight as number) * 4) {
            curVideo.storageBuffer = new Uint8Array((curVideo.scaledWidth as number)  * (curVideo.scaledHeight as number) * 4);
          }

          if (!curVideo.videoRef) {
            // this can happen if the video hasn't finished loading yet
            continue;
          }

          // TODO: it seems i cant get the pixels out if i call getPixels outside if i dont pass "out" here - not sure why
          renderVideoFrameGL(curVideo.videoRef, curVideo.x, curVideo.y, curVideo.scaledWidth as number, curVideo.scaledHeight as number, 0, renderCanvas.current, curVideo.storageBuffer, true);
        }
      }

      if (gl && renderVideos.length) {
        // run wams only on frames that have filters or crops
        const filterVideos = renderVideos.filter((vid: Media) => requiresWasm(vid)) as Video[];

        let data = [];
        if (filterVideos.length) {
          const frames = filterVideos.map((vid: Video) => vid.storageBuffer as Uint8Array);
          const args = filterVideos.map((vid: Video) => [vid.videoFdRef, vid.scaledWidth, vid.scaledHeight]);
          data = await Engine.foo((args as unknown) as string[], frames);
        }
        if (newWidth && newHeight) {
          renderCanvas.current.height = newHeight;
          renderCanvas.current.width = newWidth;
          draftCanvas.current.height = newHeight;
          draftCanvas.current.width = newWidth;
          subtitlesCanvas.current.height = newHeight;
          const subtitlesWidth = initialSubtitlesWidthPercent * newWidth;
          const subtitlesXOffset = newWidth * ((1 - initialSubtitlesWidthPercent) / 2) // center the subtitles

          subtitlesRenderer.current?.resize(subtitlesWidth, subtitlesCanvas.current.height, newHeight - initialSubtitlesYOffset, subtitlesXOffset);
        }
        requestAnimationFrame(() => {
          if (gl) {
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
            gl.clearColor(...getCanvasBackground());
            gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
          }
          let j = 0;
          for (let i = 0; i < renderVideos.length; i++) {
            const video = renderVideos[i];
            if ((video instanceof Video) && requiresWasm(video)) {
              video.storageBuffer = data[j++]; // re assign the storage buffer - cant use it again anyway
              renderVideoFrameGL(video.storageBuffer as Uint8Array, video.x, video.y, video.scaledWidth as number, video.scaledHeight as number, video.rotation, renderCanvas.current, null, false);
            } else {
              const crop = {left: video.crop.left * (video.baseWidth as number), right: video.crop.right * (video.baseWidth as number), bottom: video.crop.bottom * (video.baseHeight as number), top: video.crop.top * (video.baseHeight as number)}
              if (video instanceof Transition) {
                const progress = (currentTime.current - video.start) / video.duration;
                const transitionParams = {fromDataSource: video.fromStorage, progress: progress, transitionName: video.transition}
                renderVideoFrameGL(video.toStorage, 0, 0, renderCanvas.current.width, renderCanvas.current.height, 0, renderCanvas.current, null, false, crop, transitionParams);
              } else {
                const crop = {left: video.crop.left * (video.baseWidth as number), right: video.crop.right * (video.baseWidth as number), bottom: video.crop.top * (video.baseHeight as number), top: video.crop.bottom * (video.baseHeight as number)}
                renderVideoFrameGL(video.videoRef as Uint8Array, video.x, video.y, video.baseWidth as number, video.baseHeight as number, video.rotation, renderCanvas.current, null, false, crop);
              }
            }
          }

          renderSubtitles(shouldPauseSubtitles, shouldResizeSubtitles);
          return resolve();
        });
      }
    });
  }, [renderSubtitles, getCanvasBackground, currentTime]);

  const renderOneFrame = useCallback(async (
    seconds: number,
    shouldSeek: boolean = false,
    reApplyFilters: boolean = false,
    newWidth?: number,
    newHeight?: number,
    segments?: Segment[],
    shouldResizeSubtitles?: boolean) => {
    // either render the provided segments or the existing segments
    const renderSegments = segments ? segments : videoSegments.current;

    const curSegment = getActiveSegment(renderSegments, seconds);

    if (curSegment && curSegment.layers.length && shouldSeek) {
      const seekedLayers = curSegment.layers.filter((layer: any) => (layer.video instanceof Video || layer.video instanceof Audio) && layer.video.videoRef && layer.video.videoRef.currentTime !== layer.video.getPlayOffset(seconds));

      seekedLayers.forEach((layer: any) => layer.video.videoRef.currentTime = layer.video.getPlayOffset(seconds));
      const seekPromises = seekedLayers.map((layer: any) => waitForSeek(layer.video.videoRef));

      await Promise.all(seekPromises);
    }

    const mediaWithVideo = curSegment && curSegment.layers.filter((layer: any) => layer.video?.hasVideo());
    if (!mediaWithVideo || !mediaWithVideo.length) {
      if (gl) {
        gl.clearColor(...getCanvasBackground());
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }
      return false;
    }

    if (reApplyFilters) {
      const renderVideos = curSegment.layers.filter((layer: MediaLayer) => !layer.isPartOfTransition).map((layer: MediaLayer) => layer.video);
      const validVideos = renderVideos.filter((video: Media) => video.videoRef !== null && video.hasVideo())
      if (validVideos.length) {
        await renderVideosWithFilter(validVideos, newWidth, newHeight, shouldResizeSubtitles, true);
      }
    } else {
      if (gl) {
        gl.clearColor(...getCanvasBackground());
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }

      for (const layer of curSegment.layers) {
        // TODO: need to implement transitions here
        if (layer.isPartOfTransition) {
          continue;
        }
        const curVideo = layer.video;

        if (!curVideo || !curVideo.videoRef || !curVideo.hasVideo()) {
          return;
        }

        renderVideoFrameGL(curVideo.videoRef, curVideo.x, curVideo.y, curVideo.scaledWidth as number, curVideo.scaledHeight as number, curVideo.rotation, renderCanvas.current, null);
      }
      renderSubtitles(true, shouldResizeSubtitles);
    }
  }, [renderSubtitles, renderVideosWithFilter, getCanvasBackground, videoSegments]);

  const start = useRef<Date>();
  const numFrames = useRef<number>(0);
  const renderFrame = async () => {
    if (start.current === undefined) {
      start.current = new Date();
    }

    if (!shouldRender.current.keepRunning) {
      return;
    }

    if (!shouldRender.current.renderFrame) {
      setTimeout(renderFrame, 0);
      return;
    }

    let seconds = 0;
    if (playStartTime.current !== null && playStartBase.current !== null) {
      const playOffset = (((new Date().getTime() / 1000) - playStartTime.current as number));
      seconds = playOffset + playStartBase.current;
      setCurrentTime(seconds);
    }

    if (seconds >= totalTracksDuration) {
      getVideos().forEach((video: Media) => {
        if ((!video.videoRef || !(video instanceof Video)) &&
            (!video.videoRef || !(video instanceof Audio))) {
          return;
        }
        video.videoRef.pause()
      });
      shouldRender.current = {renderFrame: false, keepRunning: false};
      setGlobalIsRunning(false, false);
      if (animationId.current) {
        cancelAnimationFrame(animationId.current);
      }
      // we're done playing
      return;
    }

    const curSegment = getActiveSegment(videoSegments.current, seconds);
    const mediaWithVideo = curSegment && curSegment.layers.filter((layer: any) => layer.video.hasVideo());
    if (!mediaWithVideo || !mediaWithVideo.length) {
      // No media with video... paint a black screen
      if (gl) {
        gl.clearColor(...getCanvasBackground());
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
      }
      if (!curSegment || !curSegment.layers.length) {
        // If there is no media at all - stop running
        // TODO: the slider is getting too many events when we do this, need to buffer
        animationId.current = requestAnimationFrame(renderFrame);
        return;
      }
    }

    const segmentVideos = curSegment.layers.map((layer: any) => layer.video);

    // pause sound of all videos that should not play
    for (let vid of getVideos() as Video[]) {
      if (segmentVideos.indexOf(vid) === -1){
        if ((vid instanceof Audio || vid instanceof Video) && vid.videoRef && !vid.videoRef.paused) {
          vid.videoRef.pause()
        }
      }
    }

    // start sound of all videos that should play
    for (const layer of curSegment.layers) {
      const vid = layer.video;
      if (vid.videoRef && (vid instanceof Video || vid instanceof Audio) && vid.videoRef.paused) {
        vid.videoRef.play().then(() => {
        });
      }
    }

    const renderVideos = curSegment.layers.filter((layer: MediaLayer) => !layer.isPartOfTransition).map((layer: any) => layer.video).filter((vid: Video) => vid.hasVideo());
    await renderVideosWithFilter(renderVideos);

    numFrames.current++;
    if (numFrames.current % 30 === 0)  {
      const timepassed = (new Date().getTime() - (start.current as Date).getTime()) / 1000;
      // TODO: add mixpanel data send
      console.log(`passed: ${timepassed} fps: ${numFrames.current / timepassed}`);
    }
    //animationId.current = requestAnimationFrame(renderFrame)

    setTimeout(renderFrame, 0);
  };

  const playVideo = () => {
    shouldRender.current = {keepRunning:true, renderFrame: true};

    playStartTime.current = new Date().getTime() / 1000;
    playStartBase.current = currentTime.current;

    start.current = new Date();
    numFrames.current = 0;
    renderFrame();
  }

  const pauseVideo = () => {
    shouldRender.current = {keepRunning:false, renderFrame: true};
    playStartTime.current = null;
    playStartBase.current = null;
    if (animationId.current) {
      cancelAnimationFrame(animationId.current);
    }
    for (const video of getVideos() as Video[]) {
      if ((video.videoRef && video instanceof Video) ||
          (video.videoRef && video instanceof Audio)) {
        video.videoRef.pause();
      }
    }
  }

  const loadSegmentSubtitles = async (segments: Segment[]): Promise<void> => {
    if (!state.subtitlesContainer) {
      return;
    }
    return produceAssFileForSegments(segments,
      {
        Fontsize: state.subtitlesContainer.subtitles.style.FontSize.toString(),
        Fontname: state.subtitlesContainer.subtitles.style.FontName,
        MarginV: state.subtitlesContainer.subtitles.style.MarginV.toString(),
        MarginR: state.subtitlesContainer.subtitles.style.MarginR.toString(),
        MarginL: state.subtitlesContainer.subtitles.style.MarginL.toString(),
        Alignment: state.subtitlesContainer.subtitles.style.Alignment.toString(),
      }, {
        PlayResX: subtitlesCanvas.current.width.toString(),
        PlayResY: subtitlesCanvas.current.height.toString(),
        //scaleX: (canvasState.canvasHeight / subtitlesCanvas.current.height).toString(),
        //scaleY: (canvasState.canvasWidth / subtitlesCanvas.current.width).toString(),
        //PlayResX: (renderCanvas.current.width * 2).toString(),
        //PlayResY: (renderCanvas.current.height * 2).toString(),
        WrapStyle: '1'
      }
    ).then((assContent: any) => {
      if (!state.subtitlesContainer) {
        return;
      }

      if (assContent) {
        console.log(assContent)
        if (subtitlesRenderer.current) {
          subtitlesRenderer.current.destroy();
        }
        subtitlesRenderer.current = new JASSUB({
          canvas: subtitlesCanvas.current,
          subContent: assContent,
          fonts: [
            // add fonts: 400 - regular font, 700 bold font, latin
            'https://fonts.gstatic.com/s/kanit/v15/nKKZ-Go6G5tXcraVGwA.woff2',
            'https://fonts.gstatic.com/s/alice/v20/OpNCnoEEmtHa6GcOrg4.woff2',
            'https://fonts.gstatic.com/s/ubuntu/v20/4iCs6KVjbNBYlgoKfw72.woff2',
            'https://fonts.gstatic.com/s/roboto/v32/KFOmCnqEu92Fr1Mu4mxK.woff2',
            'https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrJJfecg.woff2',
            'https://fonts.gstatic.com/s/lora/v35/0QIvMX1D_JOuMwr7Iw.woff2',
            'https://fonts.gstatic.com/s/opensans/v20/mem8YaGs126MiZpBA-UFVZ0b.woff2'
          ],
          availableFonts: {
          },
          workerUrl: './assets/subtitles/jassub-worker.js',
          wasmUrl: 'jassub-worker.wasm',
          debug: false,
        })

        subtitlesRenderer.current.setStyle({
          ...initialASSStyle,
          ScaleX: 1,
          ScaleY: 1,
          FontSize: state.subtitlesContainer.subtitles.style.FontSize,
          FontName: state.subtitlesContainer.subtitles.style.FontName,
          MarginV: state.subtitlesContainer.subtitles.style.MarginV,
          MarginR: state.subtitlesContainer.subtitles.style.MarginR,
          MarginL: state.subtitlesContainer.subtitles.style.MarginL,
          Alignment: state.subtitlesContainer.subtitles.style.Alignment,
          Outline: state.subtitlesContainer.subtitles.style.Outline,
          Shadow: state.subtitlesContainer.subtitles.style.Shadow,
          BorderStyle: state.subtitlesContainer.subtitles.style.BorderStyle,
          Bold: state.subtitlesContainer.subtitles.style.Bold,
          Italic: state.subtitlesContainer.subtitles.style.Italic,
          Underline: state.subtitlesContainer.subtitles.style.Underline,
          PrimaryColour: state.subtitlesContainer.subtitles.style.PrimaryColour,
          BackColour: state.subtitlesContainer.subtitles.style.BackColour,

        }, 0);
        subtitlesRenderer.current.setStyle({
          ...initialASSStyle,
          ScaleX: 1,
          ScaleY: 1,
          FontSize: state.subtitlesContainer.subtitles.style.FontSize,
          FontName: state.subtitlesContainer.subtitles.style.FontName,
          MarginV: state.subtitlesContainer.subtitles.style.MarginV,
          MarginR: state.subtitlesContainer.subtitles.style.MarginR,
          MarginL: state.subtitlesContainer.subtitles.style.MarginL,
          Alignment: state.subtitlesContainer.subtitles.style.Alignment,
          Outline: state.subtitlesContainer.subtitles.style.Outline,
          Shadow: state.subtitlesContainer.subtitles.style.Shadow,
          BorderStyle: state.subtitlesContainer.subtitles.style.BorderStyle,
          Bold: state.subtitlesContainer.subtitles.style.Bold,
          Italic: state.subtitlesContainer.subtitles.style.Italic,
          Underline: state.subtitlesContainer.subtitles.style.Underline,
          PrimaryColour: state.subtitlesContainer.subtitles.style.PrimaryColour,
          BackColour: state.subtitlesContainer.subtitles.style.BackColour,
        }, 1);
        // false will render the subtitles right away
        // TODO: it will switch to next subtitles if within a few seconds... need a better solution to render it right away
        renderSubtitles(false, false);
      }
    })
  }

  const onSubtitlesStyleChanged = async (subtitles: any, style: ASS_Style, commit: boolean = true) => {
    subtitlesRenderer.current?.getStyles(async (err: any, styles: any) => {
      if (!subtitlesRenderer.current) {
        return;
      }
      const newStyle = {
        ...styles[0],
        FontSize: style.FontSize,
        PrimaryColour: style.PrimaryColour,
        BackColour: style.BackColour,
        Underline: style.Underline,
        Italic: style.Italic,
        Bold: style.Bold,
        Alignment: style.Alignment,
        MarginL: style.MarginL,
        MarginR: style.MarginR,
        MarginV: style.MarginV,
        FontName: style.FontName
      };
      subtitlesRenderer.current.setStyle(newStyle, 0);
      subtitlesRenderer.current.setStyle(newStyle, 1);

      if (state.subtitlesContainer) {
        const ctx: CanvasRenderingContext2D = subtitlesCanvas.current.getContext('2d', { alpha: true });
        if (!ctx) {
          return;
        }

        const totalHeight = await getTextDimensions(subtitlesCanvas.current, ctx);

        const subtitles = {subtitles: [...state.subtitlesContainer.subtitles.subtitles], style: {...newStyle}};
        const newSubtitlesContainer = {...state.subtitlesContainer, subtitles, y: state.subtitlesContainer.y - (totalHeight - state.subtitlesContainer.height), height: totalHeight};

        debounceSubtitlesChangeHistoryAction(state.subtitlesContainer, newSubtitlesContainer, (newContainer: Subtitles) => {
          setVideos(getVideos(), ['subtitles'], undefined, newContainer);
          if (subtitlesRenderer.current) {
            subtitlesRenderer.current.setStyle({...newContainer.subtitles.style}, 0);
            subtitlesRenderer.current.setStyle({...newContainer.subtitles.style}, 1);
          }
          renderSubtitles(true);
        })

        if (commit) {
          setVideos(getVideos(), ['subtitles'], false, newSubtitlesContainer);
        } else {
          setVideos(getVideos(), ['subtitles'], true, newSubtitlesContainer);
        }
        renderSubtitles(true);
        updateBoundingBoxes(getVideos(), newSubtitlesContainer);
      }
    });
  }

  const clearAllSubtitles = () => {
    if (subtitlesRenderer.current) {
      subtitlesRenderer.current.destroy();
      subtitlesRenderer.current = null;
    }
  }

  const setCanvasAspectRatioFunc = useCallback((newAspectRatio: number, width?: number, height?: number, subtitlesCanvasWidth?: number, subtitlesCanvasHeight?: number): {newCanvasWidth: number, newCanvasHeight: number} => {
      const newHeight = height || initialHeight.current;
      const newWidth = width || newHeight * newAspectRatio;
      setCanvasState({
        canvasAspectRatio: newAspectRatio,
        canvasWidth: Math.floor(newHeight * newAspectRatio),
        canvasHeight: Math.floor(newHeight),
      });
      if (renderCanvas.current) {
        const subtitlesWidth = initialSubtitlesWidthPercent * newWidth;
        const subtitlesXOffset = newWidth * ((1 - initialSubtitlesWidthPercent) / 2) // center the subtitles

        renderCanvas.current.height = newHeight;
        renderCanvas.current.width = newWidth;
        draftCanvas.current.height = newHeight;
        draftCanvas.current.width = newWidth;
        subtitlesCanvas.current.height = subtitlesCanvasHeight || newHeight;
        subtitlesCanvas.current.width = subtitlesCanvasWidth || newWidth;

        if (state.subtitlesContainer) {
          clearAllSubtitles();
          loadSegmentSubtitles(videoSegments.current);
          subtitlesRenderer.current?.resize(subtitlesWidth, subtitlesCanvas.current.height, 0, subtitlesXOffset);

          const newSubtitlesContainer = {...state.subtitlesContainer,
            width: subtitlesWidth,
            x: subtitlesXOffset,
            y: newHeight - state.subtitlesContainer.height - initialSubtitlesYOffset}

          subtitlesCanvas.current.top = newSubtitlesContainer.y + newSubtitlesContainer.height - subtitlesCanvas.current.height;
          const ids = state.selectedVideoLayers.map((vid: Media) => vid.id);
          setSubtitlesCanvasState({...subtitlesCanvasState, canvasHeight: newHeight});
          setVideos(getVideos(), ids, undefined, newSubtitlesContainer);
        }

        // Update the WebGL viewport to match the new canvas size
        if (gl) {
          // set the viewport for both the framebuffer and the real screen
          gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
          gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
          gl.bindFramebuffer(gl.FRAMEBUFFER, null);
          gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);

          gl.clearColor(...getCanvasBackground());
          gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
        }
      }
      renderOneFrame(currentTime.current, false, true);
      return {newCanvasWidth: newWidth, newCanvasHeight: newHeight};
  }, [currentTime, setVideos, getVideos, renderOneFrame, getCanvasBackground, state, setCanvasState, initialHeight]);

  const calculateMediaZIndex = (video: Media, index: number) => {
    // TODO: this is called every render - should profile to see if slowing us down
    const curVideo = state.videos[index];
    const activeSegment = getActiveSegment(videoSegments.current, currentTime.current);
    if (!activeSegment) {
      return state.selectedVideoLayers.some((vid: Media) => vid.id  === video.id) ? 0 : -1;
    }
    const maxRow = Math.max(...state.videos.map(v => v.row));

    // if the video is selected, it can be moved / interacted with regardless of any other condition
    if (state.selectedVideoLayers[0]?.id === video.id) {
      return maxRow + 1;
    }

    const activeVideos = activeSegment.layers.map((layer: any) => layer.video);
    if (activeVideos.indexOf(curVideo) === -1) {
      return state.selectedVideoLayers.some((vid: Media) => vid.id  === video.id) ? 0 : -1;
    }
    // it can go below because high row
    return maxRow - video.row;
  }

  const resizeRenderCanvas = (width: number, height: number, realTime: boolean) => {
    if (realTime) {
      canvasBoxRef.current.style.width = `${width}px`;
      canvasBoxRef.current.style.height = `${height}px`;
      renderCanvas.current.style.width = `${width}px`;
      renderCanvas.current.style.height = `${height}px`;
      return;
    }
    // TODO: odd - i guess we must make sure we set the aspect ratio as well
    setCanvasState({...canvasState, canvasHeight: height, canvasWidth: width })

    if (renderCanvas.current) {
      const subtitlesWidth = initialSubtitlesWidthPercent * width;
      const subtitlesXOffset = width * ((1 - initialSubtitlesWidthPercent) / 2) // center the subtitles
      renderOneFrame(currentTime.current, false, true, width, height);
      if (state.subtitlesContainer) {
        const newContainer = {...state.subtitlesContainer, width: subtitlesWidth, x: subtitlesXOffset, y: height - initialSubtitlesYOffset};
        setVideos(getVideos(), undefined, undefined, newContainer);
      }
    }
  }

  const handleVideoRotate = async (degrees: number, videos: Media[], commit: boolean) => {
    const oldVideos = [...state.videos];

    const newVids = (await Promise.all(videos.map(async (video: Media) => {
      if (!(video.hasVideo)) {
        // if this component doesnt have visual, just return it as is, no need to rotate anything
        return video;
      }

      const curVideo = video.deepCopy();

      const x = getBoxX(state.selectedVideoLayers);
      const y = getBoxY(state.selectedVideoLayers);
      const width = getBoxWidth(state.selectedVideoLayers);
      const height = getBoxHeight(state.selectedVideoLayers);

      const { xCord, yCord } = (video as Video).getRotatedCoords()

      const relativeCorners: [Point, Point, Point, Point] = [
          { x: xCord, y: yCord }, // Top-left
          { x: xCord + (video.scaledWidth as number), y: yCord },  // Top-right
          { x: xCord + (video.scaledWidth as number), y: yCord + (video.scaledHeight as number)},   // Bottom-right
          { x: xCord, y: yCord + (video.scaledHeight as number) }   // Bottom-left
      ];

      const xCenter = x + width / 2;
      const yCenter = y + height / 2;
      const { xDifference, yDifference } = calculateXYDifferenceFromCenter(relativeCorners,
        xCenter, yCenter, 0, degrees
      );

      curVideo.x += xDifference;
      curVideo.y += yDifference;

      if (videos.length === 1) {
        // if we're rotating one element, the degrees given are the degrees for the element.
        await curVideo.setRotation(degrees);
      } else {
        // we're rotating as part of a multi-rotation, need to add the rotate degrees to the element
        await curVideo.setRotation(video.rotation + degrees);
      }
      return curVideo;
    })))
    const ids = newVids.map((video: Media) => video.id);

    const newVideos = [...(state.videos.filter((vid) => !videos.includes(vid))), ...newVids];

    if (commit) {
      const vidAnimationDragCommand = new VideoAnimationCommandBase(oldVideos, newVideos,(newVids: Media[]) => {
        setVideos(newVids, ids, false);
        renderOneFrame(currentTime.current, false, true);
      });

      CommandHistory.push(vidAnimationDragCommand);
    }

    updateBoundingBoxes(newVideos, state.subtitlesContainer);
    setVideos(newVideos, ids, !commit);
    renderOneFrame(currentTime.current, false, true);
  };

  const handleVideoDragStop = (xBefore: number, xAfter: number, yBefore: number, yAfter:number, videos: Media[]) => {
    const oldVideos = [...state.videos];

    const xOffset = xAfter - xBefore;
    const yOffset = yAfter - yBefore;

    const newVids = videos.map((video: Media) => {
      const newVideo = video.deepCopy();
      newVideo.x += xOffset;
      newVideo.y += yOffset;
      return newVideo;
    })

    const newVideos = [...(state.videos.filter((vid) => !videos.includes(vid))), ...newVids];

    const vidAnimationDragCommand = new VideoAnimationCommandBase(oldVideos, newVideos,(newVids: Media[]) => {
      setVideos(newVids, state.selectedVideoLayers.map((video: Media) => video.id), false);
      renderOneFrame(currentTime.current, false, true);
    });

    CommandHistory.push(vidAnimationDragCommand);

    setVideos(newVideos, state.selectedVideoLayers.map((vid: Media) => vid.id), false);
  };

  // Not sure, but it seems that changes here are persistent - somehow changing newVideos remains?
  // That's fine for now but I don't know why that would happen
  const handleVideoDrag = (xBefore: number, xAfter: number, yBefore: number, yAfter: number, videos: Media[]) => {
    const xOffset = xAfter - xBefore;
    const yOffset = yAfter - yBefore;

    const newVids = videos.map((video: Media) => {
      const newVideo = video.deepCopy();
      newVideo.x += xOffset;
      newVideo.y += yOffset;
      return newVideo;
    })

    const newVideos = [...(state.videos.filter((vid) => !videos.includes(vid))), ...newVids];
    setVideos(newVideos, undefined, true);

    updateBoundingBoxes(newVideos, state.subtitlesContainer);
    renderOneFrame(currentTime.current, false, true);
  };

  const handleVideoResizeStart = (x: number, y: number, width: number, height: number) => {
    rotatableBoxMultiplier.current = { height: height, width: width, initialHeight: height, initialWidth: width };

    beforeResizeVideosRef.current = state.videos.map((vid: Media) => vid.deepCopy());
  }

  const handleVideoResizeStop = async (
    xBefore: number,
    yBefore: number,
    xAfter: number,
    yAfter: number,
    widthBefore: number,
    heightBefore: number,
    widthAfter: number,
    heightAfter: number,
    direction: DirectionType,
    videos: Media[],
    commit: boolean,
      crop: boolean = true,
      ): Promise<void> => {
    return new Promise(async (resolve) => {
      let widthMultiplier = widthAfter / rotatableBoxMultiplier.current.width;
      let heightMultiplier = heightAfter / rotatableBoxMultiplier.current.height;

      const newVids = (await Promise.all(videos.map(async (vid: Media) => {
        let adjustedX = xAfter;
        let adjustedY = yAfter;
        let adjustedWidth = widthAfter;
        let adjustedHeight = heightAfter;

        if (videos.length > 1) {
          adjustedWidth = (vid.scaledWidth || 0) * widthMultiplier;
          adjustedHeight = (vid.scaledHeight || 0) * heightMultiplier;
          // multiselection requires calculations relative to the multiple selection box
          switch (direction) {
              case 'sw': // Scaling from bottom-left
                  adjustedX = xAfter + (vid.x - xBefore) * widthMultiplier;
                  adjustedY = yAfter + (vid.y - yAfter) * heightMultiplier;
                  break;
              case 'ne': // Scaling from top-right
                  adjustedX = xAfter + (vid.x - xAfter) * widthMultiplier;
                  adjustedY = yAfter + (vid.y - yBefore) * heightMultiplier;
                  break;
              case 'nw': // Scaling from top-left
                  adjustedX = xAfter + (vid.x - xBefore) * widthMultiplier;
                  adjustedY = yAfter + (vid.y - yBefore) * heightMultiplier;
                  break;
              case 'se': // Scaling from bottom-right (default)
                  // No adjustments needed for bottom-right scaling
                  adjustedX = xAfter + (vid.x - xAfter) * widthMultiplier;
                  adjustedY = yAfter + (vid.y - yAfter) * heightMultiplier;
                  break;
              case 'left':
              case 'top':
              case 'right':
              case 'bottom':
                  adjustedX = xAfter;
                  adjustedY = yAfter;
                  adjustedWidth = widthAfter;
                  adjustedHeight = heightAfter;
                  break;
          }
        }

        const heightBefore = vid.scaledHeight;
        const newVideo = handleMediaResize(adjustedX, adjustedY, adjustedWidth, adjustedHeight, direction, vid as Video, crop);
        if (newVideo instanceof Video && newVideo.videoFdRef !== null && newVideo.videoFdRef !== undefined && requiresWasm(newVideo)) {
          const effectString = newVideo.getEffectString();
          await Engine.set_effect(newVideo.videoFdRef as number, newVideo.scaledWidth as number, newVideo.scaledHeight as number, effectString).then((res) => {
            if (res === -1) {
              console.error("failed to set effect");
              return;
            }
          });
          return newVideo;
        } else if (newVideo instanceof TextMedia) {
          const isCrop = cropDirections.includes(direction || '');
          const scaleFactor = canvasState.canvasHeight / 640;
          const calculateHeight = isCrop ? true : false;
          const [newTextComponent, _] = await resizeTextComponent(newVideo, heightBefore as number, adjustedHeight, adjustedWidth, direction, scaleFactor, calculateHeight);
          return newTextComponent;
        } else {
          return newVideo;
        }
      }))) as Video[];

      const ids = newVids.map((video: Media) => video.id);

      const newVideos = [...(state.videos.filter((vid) => !videos.includes(vid))), ...newVids];

      if (commit) {
        const vidAnimationDragCommand = new VideoAnimationCommandBase(beforeResizeVideosRef.current, newVideos,(newVids: Media[]) => {
          setVideos(newVids, ids, false);
          renderOneFrame(currentTime.current, false, true);
        });
        CommandHistory.push(vidAnimationDragCommand);
        // clear the start videos cache -  no longer needed
        beforeResizeVideosRef.current = [];
      }

      const onlyRealTime = !commit;
      updateBoundingBoxes(newVideos, state.subtitlesContainer);
      setVideos(newVideos, ids, onlyRealTime);
      renderOneFrame(currentTime.current, false, true);
      resolve();
    });
  };

  const setVideoTime = async (newTime: number): Promise<void> => {
    return new Promise(async (resolve) => {
      playStartBase.current = newTime;
      playStartTime.current = new Date().getTime() / 1000;
      setCurrentTime(newTime, false);

      // seek all videos to the slider time
      // prevent running while doing it - to prevent stuttering & "black screen artifacts" on some browsers
      shouldRender.current.renderFrame = false;
      await seekAllVideos(getVideos(), currentTime.current, videoSegments.current);
      shouldRender.current.renderFrame = true;
      resolve();
    })
  }

  const getBoxY = (videos: Media[]) => {
    let minY = Infinity;
    for (let media of videos) {
      let y = media.y;
      if (media.rotation && ((media instanceof Video) || (media instanceof ImageMedia) || (media instanceof TextMedia))) {
        const { boundingY } = media.getRotatedCoords()
        y = boundingY;
      }
      minY = Math.min(y, minY);
    }
    return minY;
  }

  const getBoxX = (videos: Media[]) => {
    let minX = Infinity;
    for (let media of videos) {
      let x = media.x;
      if (media.rotation && ((media instanceof Video) || (media instanceof ImageMedia) || (media instanceof TextMedia))) {
        const { boundingX } = media.getRotatedCoords()
        x = boundingX;
      }
      minX = Math.min(x, minX);
    }
    return minX;
  }

  const getBoxWidth = (videos: Media[]) => {
    let minX = Infinity;
    let maxX = 0;
    for (let media of videos) {
      let x = media.x;
      let videoWidth = (media.scaledWidth || 0);
      if (media.rotation && ((media instanceof Video) || (media instanceof ImageMedia) || (media instanceof TextMedia))) {
        const { boundingX, width } = media.getRotatedCoords()
        videoWidth = width;
        x = boundingX;
      }
      minX = Math.min(x, minX);
      maxX = Math.max(x + videoWidth, maxX);
    }
    return maxX - minX;
  }

  const getBoxHeight = (videos: Media[]) => {
    let minY = Infinity;
    let maxY = 0;
    for (let media of videos) {
      let y = media.y;
      let videoHeight = (media.scaledHeight || 0);
      if (media.rotation && ((media instanceof Video) || (media instanceof ImageMedia) || (media instanceof TextMedia))) {
        const { boundingY, height } = media.getRotatedCoords()
        videoHeight = height;
        y = boundingY;
      }
      minY = Math.min(y, minY);
      maxY = Math.max(y + videoHeight, maxY);
    }
    return maxY - minY;
  }

  const getSelectedVideo = (xPos: number, yPos: number, videos: Media[]): Media | null => {
    const videoX = xPos - renderCanvas.current.getBoundingClientRect().x;
    const videoY = yPos - renderCanvas.current.getBoundingClientRect().y;
    const videosUnderClick = videos.filter((video: Media) => {
      return (
        (video.x <= videoX) &&
        (videoX <= video.x + video.baseWidth) &&
        (video.y <= videoY) &&
        (videoY <= video.y + video.baseHeight)
      );
    })
    if (!videosUnderClick.length) {
      return null;
    }
    return videosUnderClick.reduce((prevVideo, curVideo) => prevVideo.row < curVideo.row ? prevVideo : curVideo);
  }

   const isVideoInSelection = (video: Media, start: Point, end: Point): boolean => {
      const [minX, maxX] = [Math.min(start.x, end.x), Math.max(start.x, end.x)];
      const [minY, maxY] = [Math.min(start.y, end.y), Math.max(start.y, end.y)];
      const videoRect = { left: video.x, right: video.x + (video.scaledWidth || 0), top: video.y, bottom: video.y + (video.scaledHeight || 0) };
      return videoRect.left < maxX && videoRect.right > minX && videoRect.top < maxY && videoRect.bottom > minY;
  };

  const handleMouseDown = (e: React.MouseEvent) => {
    if (menuOpenConfig.isOpen) {
      handleClose();
      return;
    }
    if (e.button === 2) {
      if (!menuOpenConfig.isOpen) {
        handleContextMenu(e, null);
      }
    } else if (e.button === 0) {
      const rect = renderCanvas.current?.getBoundingClientRect();
      const startX = e.clientX - (rect?.left || 0);
      const startY = e.clientY - (rect?.top || 0);
      setSelectionStart({ x: startX, y: startY });
      setSelectionEnd(null); // Reset end point on new selection start
      setIsSelecting(true);  // Enable selection mode
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
      if (!isSelecting || !selectionStart) return;
      const rect = renderCanvas.current?.getBoundingClientRect();
      const endX = e.clientX - (rect?.left || 0);
      const endY = e.clientY - (rect?.top || 0);
      const currentSelectionEnd = { x: endX, y: endY };
      setSelectionEnd(currentSelectionEnd);

      const activeSegment = getActiveSegment(videoSegments.current, currentTime.current);
      if (!activeSegment || !activeSegment.layers) {
        return;
      }

      const selectedIds = activeSegment.layers.filter(layer => isVideoInSelection(layer.video, selectionStart, currentSelectionEnd)).map((layer: MediaLayer) => layer.video.id);
      const newVideos = [...getVideos()];
      setVideos(newVideos, selectedIds, true);
      updateBoundingBoxes(newVideos, state.subtitlesContainer);
  };

  const handleMouseUp = (): boolean => {
      setIsSelecting(false); // Disable selection mode
      setSelectionStart(null); // Reset selection box
      setSelectionEnd(null);
      if (!selectionStart || !selectionEnd) return false;
      const activeSegment = getActiveSegment(videoSegments.current, currentTime.current);

      if (!activeSegment || !activeSegment.layers) {
        return false;
      }

      const selectedIds = activeSegment.layers.filter(layer => isVideoInSelection(layer.video, selectionStart, selectionEnd)).map((layer: MediaLayer) => layer.video.id);
      const newVideos = [...getVideos()];
      setVideos(newVideos, selectedIds);
      return true;
  };

  const updateBoundingBoxes = useCallback((videos: Media[], subtitles: Subtitles | null) => {
    const boundingBoxesMap: {[key: string]: any} = {};
    videos.forEach((video: Media) => {
      boundingBoxesMap[video.id] = {
        x: video.x,
        y: video.y,
        rotation: video.rotation,
        scaledWidth: video.scaledWidth,
        scaledHeight: video.scaledHeight,
        width: video.scaledWidth,
        height: video.scaledHeight,
        baseWidth: video.baseWidth,
        baseHeight: video.baseHeight,
        crop: video.crop
      }
    })
    const subtitlesBox = {
      x: subtitles?.x,
      y: subtitles?.y,
      rotation: subtitles?.rotation,
      scaledWidth: subtitles?.scaledWidth,
      scaledHeight: subtitles?.scaledHeight,
      width: subtitles?.width,
      height: subtitles?.height,
      baseWidth: subtitles?.baseWidth,
      baseHeight: subtitles?.baseHeight,
      crop: subtitles?.crop
    }
    boundingBoxesMap['subtitles'] = subtitlesBox;
    setBoundingBoxes(boundingBoxesMap);
  }, []);


  const handleClose = () => {
    setMenuOpenConfig({...menuOpenConfig, isOpen: false});
  };

  const handleContextMenu = (event: React.MouseEvent, media: Media | null) => {
    event.preventDefault();
    setMenuPosition({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
    setMenuOpenConfig({
      isOpen: true,
      shouldActivateSubtitlesDownload: !!(media && (media as Video).subtitlesUrl?.length),
      shouldActivateCopy: (media !== null) && getSelectedVideos().length !== 0,
      shouldActivatePaste: getCopyBuffer().length > 0,
      shouldActivateDuplicate: media !== null,
      shouldActivateDelete: media !== null,
    });
  };

  useEffect(() => {
    const cheight = initialHeight.current;
    const cwidth = initialWidth.current;
    renderCanvas.current.height = cheight;
    renderCanvas.current.width = cwidth * canvasState.canvasAspectRatio;
    draftCanvas.current.height = cheight;
    draftCanvas.current.width = cwidth * canvasState.canvasAspectRatio;
    subtitlesCanvas.current.height = cheight;
    subtitlesCanvas.current.width = cwidth * canvasState.canvasAspectRatio;

    initWebGLCanvas(renderCanvas.current);
    //setupFramebuffer(cwidth, cheight);
    // TODO: i have no idea whats going on here - effects
    // are showing on what seems to be a small viewport and i cant seem to fix it
    // by changing the viewport later
    setupFramebuffer(3000, 3000);

    if (gl) {
      gl.clearColor(...getCanvasBackground());
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    setCanvasAspectRatioFunc(canvasState.canvasAspectRatio, cwidth, cheight);
  }, [])

  useEffect(() => {
    // keep the bounding boxes in sync with any changes to the videos
    updateBoundingBoxes(state.videos, state.subtitlesContainer);
    // TODO: we have to render stuffsometimes after the state has changed - there is no way to do this
    //       from the place of the change because the change is async - need to check if this move is ok.
    //       essentially the renderOneFrame must not change any state at all - need to make sure
    renderOneFrame(currentTime.current, false, true);
  }, [updateBoundingBoxes, state.videos, state.subtitlesContainer, renderOneFrame, currentTime])

  useImperativeHandle(ref, () => ({
    clearAllSubtitles,
    loadSegmentSubtitles,
    onSubtitlesStyleChanged,
    setCanvasAspectRatioFunc,
    setVideoTime,
    playVideo,
    pauseVideo,
    renderOneFrame,
    renderVideosWithFilter,
    updateBoundingBoxes,
    resizeRenderCanvas,
    recalculateSubtitlesSize,
  }));

  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        const changedSelection = handleMouseUp();
        if (!changedSelection) {
          setVideos(getVideos(), []);
        }
      }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      sx={{ display: 'flex', flexGrow: 1, width: '100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginBottom: '20px', marginTop: '10px', maxWidth: '100%', overflow: 'hidden' }}
    >

        <Box ref={canvasBoxRef} style={{position: 'relative', height: canvasState.canvasHeight, width: canvasState.canvasWidth}}>
          {state.videos.map ((video: Media, index: number) => {
            if (video instanceof Video || video instanceof ImageMedia) {
              return (
                <RotatableBox
                  key={video.id}
                  ref={moveableBoxRef}
                  isMultiSelected={getSelectedVideos().some((vid) => vid.id === video.id && getSelectedVideos().length > 1)}
                  isActive={getSelectedVideos().length === 1 && getSelectedVideos().some((vid) => vid.id === video.id)}
                  width={boundingBoxes[video.id]?.scaledWidth}
                  maxWidth={Infinity}
                  maxHorizontalSize={boundingBoxes[video.id]?.baseWidth}
                  height={boundingBoxes[video.id]?.scaledHeight}
                  maxHeight={Infinity}
                  cropDimensions={video.crop}
                  maxVerticalSize={video.baseHeight}
                  initialX={boundingBoxes[video.id]?.x}
                  initialY={boundingBoxes[video.id]?.y}
                  initialRotation={boundingBoxes[video.id]?.rotation}
                  zIndex={calculateMediaZIndex(video, index)}
                  resizable={state.selectedVideoLayers.length === 1 && video.resizable}
                  rotatable={state.selectedVideoLayers.length === 1}
                  handles={video.cropable ?
                    ['se', 'sw', 'ne', 'nw', 'e', 'w', 's', 'n'] :
                    ['se', 'sw', 'ne', 'nw', 'e']}
                  lockAspectRatio={true}

                  onMouseDown={(event: React.MouseEvent) => {
                    if (event.button === 2) {
                      onVideoSelected(video, false);
                      handleContextMenu(event, video);
                    }
                  }}
                  onSelect={((event: React.MouseEvent<HTMLDivElement>) => {
                    // stop the propogation so we can identify if someone clicked on the background elements to unselect
                    event.stopPropagation();

                    const activeSegment = getActiveSegment(videoSegments.current, currentTime.current);
                    if (!activeSegment || !activeSegment.layers) {
                      return;
                    }

                    const videos = activeSegment.layers.map((layer: MediaLayer) => layer.video);

                    const selectedVideo = getSelectedVideo(event.clientX, event.clientY, videos);
                    onVideoSelected(selectedVideo, event.ctrlKey);
                  })}
                  onDragStop={(xBefore, xAfter, yBefore, yAfter) => {
                    handleVideoDragStop(xBefore, xAfter, yBefore, yAfter, state.selectedVideoLayers);
                  }}
                  onDrag={(xBefore, xAfter, yBefore, yAfter) => {
                    handleVideoDrag(xBefore, xAfter, yBefore, yAfter, state.selectedVideoLayers);
                  }}
                  onDragStart={(x, y) => {
                    beforeResizeVideosRef.current = [...state.videos];
                  }}
                  onResizeStart={handleVideoResizeStart}
                  onResize={async (xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction): Promise<undefined> => {
                    if (requiresWasm(video)) {
                      // if it requires wasm rendering then doing it real time causes corrupt picture due to resize not being 100% synced
                      return;
                    }
                    await handleVideoResizeStop(xBefore, yBefore, xAfter, yAfter, widthBefore, heightBefore, widthAfter, heightAfter, direction, state.selectedVideoLayers, false, cropDirections.includes(direction || ''));
                    }}
                  onResizeStop={async (xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction): Promise<undefined> => {
                    await handleVideoResizeStop(xBefore, yBefore, xAfter, yAfter, widthBefore, heightBefore, widthAfter, heightAfter, direction, state.selectedVideoLayers, true, cropDirections.includes(direction || ''));
                  }}
                  onRotate={(degrees) => {
                    handleVideoRotate(degrees, state.selectedVideoLayers, false);
                  }}
                  onRotateStop={(degrees) => {
                    handleVideoRotate(degrees, state.selectedVideoLayers, true);
                  }}
                />
              )
            } else if (video instanceof TextMedia) {
              return (
                <RotatableBox
                  key={video.id}
                  ref={moveableBoxRef}
                  isMultiSelected={getSelectedVideos().some((vid) => vid.id === video.id && getSelectedVideos().length > 1)}
                  isActive={getSelectedVideos().length === 1 && getSelectedVideos().some((vid) => vid.id === video.id)}
                  width={boundingBoxes[video.id]?.scaledWidth}
                  maxWidth={Infinity}
                  maxHorizontalSize={Infinity}
                  height={boundingBoxes[video.id]?.scaledHeight}
                  maxHeight={Infinity}
                  cropDimensions={{left: 0, right: 0, top: 0, bottom: 0}}
                  maxVerticalSize={Infinity}
                  initialX={boundingBoxes[video.id]?.x}
                  initialY={boundingBoxes[video.id]?.y}
                  initialRotation={boundingBoxes[video.id]?.rotation}
                  zIndex={calculateMediaZIndex(video, index)}
                  resizable={state.selectedVideoLayers.length === 1}
                  rotatable={state.selectedVideoLayers.length === 1}
                  handles={['se', 'sw', 'ne', 'nw', 'e', 'w']}
                  lockAspectRatio={true}
                  onMouseDown={(event: React.MouseEvent) => {
                    if (event.button === 2) {
                      onVideoSelected(video, false);
                      handleContextMenu(event, video);
                    }
                  }}
                  onSelect={((event: React.MouseEvent<HTMLDivElement>) => {
                    // stop the propogation so we can identify if someone clicked on the background elements to unselect
                    event.stopPropagation();

                    const activeSegment = getActiveSegment(videoSegments.current, currentTime.current);
                    if (!activeSegment || !activeSegment.layers) {
                      return;
                    }

                    const videos = activeSegment.layers.map((layer: MediaLayer) => layer.video);

                    const selectedVideo = getSelectedVideo(event.clientX, event.clientY, videos);
                    onVideoSelected(selectedVideo, event.ctrlKey);
                  })}
                  onDragStop={(xBefore, xAfter, yBefore, yAfter) => {
                    handleVideoDragStop(xBefore, xAfter, yBefore, yAfter, state.selectedVideoLayers);
                  }}
                  onDrag={(xBefore, xAfter, yBefore, yAfter) => {
                    handleVideoDrag(xBefore, xAfter, yBefore, yAfter, state.selectedVideoLayers);
                  }}
                  onDragStart={(x, y) => {
                    beforeResizeVideosRef.current = [...state.videos];
                  }}
                  onResizeStart={(x, y, width, height) => {
                    beforeResizeVideosRef.current = state.videos.map((vid: Media) => vid.deepCopy());
                    subtitlesStyleStateBeforeAfter.current = {beforeStyle: {...video.style} , afterStyle: null, beforeDimensions: {x, y, width, height}, afterDimensions: null, beforeHtmlHeight: null};
                  }}
                  onResize={async (xBefore, yBefore, widthBefore, heightBefore, x, y, width, height, direction): Promise<number | undefined> => {
                    return await new Promise(async (resolve) => {
                      const isCrop = cropDirections.includes(direction || '');
                      if (video.style.fontSize) {
                        const scaleFactor = canvasState.canvasHeight / 640;
                        const calculateHeight = isCrop ? true : false;
                        let newTextComponent: TextMedia;
                        let imageHeight;
                        if (!isCrop) {
                          video.videoRef.style.width = `${width}px`;
                          video.videoRef.style.height = `${height}px`;
                          video.baseWidth = width;
                          video.scaledWidth = width;
                          video.width = width;
                          video.height = height;
                          video.baseHeight = height;
                          video.scaledHeight = height;
                          newTextComponent = video;
                          imageHeight = height;
                        } else {
                          [newTextComponent, imageHeight] = await resizeTextComponent(video, heightBefore, height, width, direction, scaleFactor, calculateHeight);
                        }
                        newTextComponent = handleMediaResize(x, y, newTextComponent.baseWidth, imageHeight, direction, (newTextComponent as unknown) as Video, false) as TextMedia;
                        // modify the real time reference of the videos so that they would render with their updated form on screen even when running video
                        // careful that this must sync at resizeStop as it is dangerous (causing a missmatch between the states that should always be in sync)
                        const newVideos = state.videos.map((vid: Media) => {
                          return vid.id === newTextComponent.id ? newTextComponent : vid;
                        })
                        setVideos(newVideos, [video.id], true);

                        renderOneFrame(currentTime.current, false, true);
                        resolve(imageHeight);
                      }
                      resolve(undefined);
                    })
                  }}
                  onResizeStop={async (xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction): Promise<number | undefined> => {
                    return await new Promise(async (resolve) => {
                      const isCrop = cropDirections.includes(direction || '');
                      if (video.style.fontSize) {
                        const fontSize = parseFloat(video.style.fontSize as string);
                        const beforeFontSize = parseFloat(subtitlesStyleStateBeforeAfter.current.beforeStyle.fontSize as string);
                        const newFontSize = isCrop ? fontSize : Math.floor(beforeFontSize * heightAfter / heightBefore);

                        const textHeight = await onTextStyleChanged({
                          text: video.text,
                          style: {...video.style, fontSize: `${newFontSize}px`},
                          height: isCrop ? undefined : heightAfter,
                          width: widthAfter,
                          x: xAfter,
                          y: yAfter,
                          calculateHeight: isCrop ? true : false,
                          calculateWidth: false,
                          commit: true
                        });
                        resolve(textHeight);
                      }
                    });
                  }}
                  onRotate={(degrees) => {
                    handleVideoRotate(degrees, state.selectedVideoLayers, false);
                  }}
                  onRotateStop={(degrees) => {
                    handleVideoRotate(degrees, state.selectedVideoLayers, true);
                  }}
                />
              )
            }
            return null;
          })}
          {state.videos.some((vid) => (vid as Video).subtitlesActive) && state.subtitlesContainer && (
            <RotatableBox
              key={'subtitlesBox'}
              ref={moveableBoxRef}
              isMultiSelected={false}
              isActive={state.selectedVideoLayers.some((vid: Media) => vid.id === state.subtitlesContainer?.id)}
              width={boundingBoxes['subtitles']?.width}
              maxWidth={Infinity}
              maxHorizontalSize={Infinity}
              height={boundingBoxes['subtitles']?.height}
              maxHeight={Infinity}
              cropDimensions={{left: 0, right: 0, top: 0, bottom: 0}}
              maxVerticalSize={Infinity}
              initialX={boundingBoxes['subtitles']?.x}
              initialY={boundingBoxes['subtitles']?.y}
              initialRotation={0}
              zIndex={100}
              resizable={true}
              rotatable={false}
              handles={['e', 'w', 'nw', 'ne', 'sw', 'se']}
              lockAspectRatio={true}
              onSelect={(async (event: React.MouseEvent<HTMLDivElement>) => {
                if (!state.subtitlesContainer) {
                  return;
                }
                event.stopPropagation();
                const ctx = subtitlesCanvas.current.getContext('2d', { alpha: true, willReadFrequently: true });
                const height = await getTextDimensions(subtitlesCanvas.current, ctx);
                const y = state.subtitlesContainer.y + (state.subtitlesContainer.height - height);
                const newSubtitlesContainer = new Subtitles({...state.subtitlesContainer, y, height, style: state.subtitlesContainer.subtitles.style, subtitles: state.subtitlesContainer.subtitles.subtitles});
                setVideos(getVideos(), ['subtitles'], false, newSubtitlesContainer);
              })}
              onDragStart={(any) => {}}
              onDragStop={(xBefore, xAfter, yBefore, yAfter) => {
                if (state.subtitlesContainer) {
                  console.log(yAfter, yBefore, subtitlesCanvas.current.height - renderCanvas.current.height);
                  const newSubtitlesContainer = {...state.subtitlesContainer, x: xAfter, y: yAfter};//- (subtitlesCanvas.current.height - renderCanvas.current.height)};
                  const beforeSubtitlesContainer = {
                    ...state.subtitlesContainer,
                    x: xBefore,
                    y: yBefore,
                    subtitles: {subtitles: [...state.subtitlesContainer.subtitles.subtitles], style: {...state.subtitlesContainer.subtitles.style}}
                  };
                  debounceSubtitlesChangeHistoryAction(beforeSubtitlesContainer, newSubtitlesContainer, (newContainer: Subtitles) => {
                    setVideos(getVideos(), ['subtitles'], false, newContainer);
                    renderOneFrame(currentTime.current, false, true, undefined, undefined, undefined, true);
                  })

                  setVideos(getVideos(), ['subtitles'], undefined, newSubtitlesContainer);
                }
              }}
              onDrag={(xBefore, xAfter, yBefore, yAfter) => {
                if (state.subtitlesContainer) {
                  subtitlesCanvas.current.style.left = `${xAfter}px`;
                  subtitlesCanvas.current.style.top = `${yAfter + state.subtitlesContainer.height - subtitlesCanvas.current.height}px`;
                }
              }}
              onResizeStart={(x: number, y: number, width: number, height: number) => {
                subtitlesRenderer.current?.getStyles(async (err: any, styles: any) => {
                  if (!subtitlesRenderer.current || !state.subtitlesContainer) {
                    return;
                  }
                  const curStyle = styles[0];
                  subtitlesStyleStateBeforeAfter.current = {beforeStyle: {...curStyle} , afterStyle: null, beforeDimensions: {x, y, width, height}, afterDimensions: null, beforeHtmlHeight: subtitlesCanvas.current.style.height};
                })
              }}
              onResizeStop={async (xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction): Promise<undefined> => {
                if (state.subtitlesContainer) {
                  const beforeStyle = {...subtitlesStyleStateBeforeAfter.current.beforeStyle};
                  const afterStyle = {...subtitlesStyleStateBeforeAfter.current.afterStyle};
                  const beforeSubtitlesContainer = {
                    ...state.subtitlesContainer,
                    x: xBefore,
                    y: yBefore,
                    width: widthBefore,
                    height: heightBefore,
                    subtitles: {subtitles: [...state.subtitlesContainer.subtitles.subtitles], style: beforeStyle}
                  };
                  const newSubtitlesContainer = {
                    ...state.subtitlesContainer,
                    ...subtitlesStyleStateBeforeAfter.current.afterDimensions,
                    subtitles: {...state.subtitlesContainer.subtitles, style: afterStyle}}

                  const isCrop = cropDirections.includes(direction || '');
                  const newCanvasHeight = isCrop ? subtitlesCanvas.current.height : Math.floor(parseFloat(subtitlesStyleStateBeforeAfter.current.beforeHtmlHeight) * heightAfter / heightBefore);
                  setSubtitlesCanvasState({...subtitlesCanvasState, canvasHeight: newCanvasHeight });
                  subtitlesRenderer.current?.resize(widthAfter, newCanvasHeight, state.subtitlesContainer?.y, state.subtitlesContainer?.x);
                  subtitlesCanvas.current.style.top = `${yAfter + state.subtitlesContainer.height - (isCrop ? subtitlesCanvas.current.height : newCanvasHeight)}px`;

                  debounceSubtitlesChangeHistoryAction(beforeSubtitlesContainer, newSubtitlesContainer, (newContainer: Subtitles) => {
                    setVideos(getVideos(), ['subtitles'], undefined, newContainer);
                    if (subtitlesRenderer.current) {
                      // TODO: not working
                      subtitlesRenderer.current.setStyle({...newContainer.subtitles.style}, 0);
                      subtitlesRenderer.current.setStyle({...newContainer.subtitles.style}, 1);
                      subtitlesRenderer.current.resize(newContainer.width, subtitlesCanvas.current.height, newContainer.y, newContainer.x);
                      //subtitlesCanvas.current.width = newContainer.width;
                      subtitlesCanvas.current.style.width = `${newContainer.width}px`;
                      subtitlesCanvas.current.style.left = `${newContainer.x}px`;
                      //subtitlesCanvas.current.style.top = `${newContainer.y + newContainer.height - subtitlesCanvas.current.height}px`;

                      const newCanvasHeight = parseFloat(subtitlesStyleStateBeforeAfter.current.beforeHtmlHeight) * heightAfter / heightBefore;
                      subtitlesCanvas.current.style.top = `${newContainer.y + newContainer.height - newCanvasHeight}px`;
                      renderSubtitles();
                    }
                  })
                  console.log(newSubtitlesContainer.style);
                  setVideos(getVideos(), ['subtitles'], undefined, newSubtitlesContainer);
                }
                subtitlesStyleStateBeforeAfter.current = {beforeStyle: null , afterStyle: null, beforeDimensions: null, afterDimensions: null, beforeHtmlHeight: null};
              }}
              onResize={async (xBefore, yBefore, widthBefore, heightBefore, x, y, width, height, direction): Promise<number | undefined> => {
                return await new Promise(async (resolve) => {
                  const beforeStyle = subtitlesStyleStateBeforeAfter.current.beforeStyle;
                  const isCrop = cropDirections.includes(direction || '');
                    if (!subtitlesRenderer.current || !state.subtitlesContainer || !beforeStyle) {
                      resolve(undefined);
                      return;
                    }
                    const ctx = subtitlesCanvas.current.getContext('2d', { alpha: true, willReadFrequently: true });
                    const totalHeight = await getTextDimensions(subtitlesCanvas.current, ctx);

                    state.subtitlesContainer.height = isCrop ? totalHeight : height;
                    let newFontSize = Math.floor(beforeStyle.FontSize * height / heightBefore);

                    const newCanvasHeight = isCrop ? subtitlesCanvas.current.height : Math.floor(parseFloat(subtitlesStyleStateBeforeAfter.current.beforeHtmlHeight) * height / heightBefore);

                    const newStyle = {
                      ...beforeStyle,
                      FontSize: isCrop ? beforeStyle.FontSize : newFontSize,
                      ScaleY: beforeStyle.ScaleY * heightBefore / height,
                      ScaleX: beforeStyle.ScaleX * widthBefore / width,
                    };
                    if (!isCrop) {
                      console.log(newStyle.ScaleX, newStyle.ScaleY);
                      subtitlesRenderer.current.setStyle(newStyle, 0);
                      subtitlesRenderer.current.setStyle(newStyle, 1);
                    } else {
                      subtitlesRenderer.current.resize(width, subtitlesCanvas.current.height, state.subtitlesContainer?.y, state.subtitlesContainer?.x);
                    }

                    if (state.subtitlesContainer) {

                      subtitlesCanvas.current.style.height = `${newCanvasHeight}px`;

                      subtitlesCanvas.current.style.width = `${width}px`;
                      subtitlesCanvas.current.style.left = `${x}px`;

                      //subtitlesCanvas.current.style.top = `${y + state.subtitlesContainer.height - subtitlesCanvas.current.height}px`;
                      subtitlesCanvas.current.style.top = `${y + state.subtitlesContainer.height - (isCrop ? subtitlesCanvas.current.height : newCanvasHeight)}px`;
                      subtitlesStyleStateBeforeAfter.current.afterDimensions = {x, y, width, height: isCrop ? totalHeight : height};
                      renderSubtitles(true);
                      resolve(isCrop ? totalHeight : height);
                    }
                    subtitlesStyleStateBeforeAfter.current.afterStyle = {...newStyle};
                    resolve(undefined);
                })
              }}
              onRotate={(degrees) => {}}
              onRotateStop={(degrees) => {}}
              />
          )}
          {(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length > 1 && (
                <RotatableBox
                  ref={moveableBoxRef}
                  isMultiSelectionBox={true}
                  isMultiSelected={false}
                  isActive={true}
                  width={(() => {
                    if (!(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length) {
                      return 0;
                    }
                    return getBoxWidth((isSelecting ? getSelectedVideos() : state.selectedVideoLayers));
                  })()}
                  maxWidth={canvasState.canvasWidth}
                  maxHorizontalSize={(() => {
                    if (!(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length) {
                      return 0;
                    } else if ((isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length === 1) {
                      return (isSelecting ? getSelectedVideos() : state.selectedVideoLayers)[0].baseWidth;
                    } else {
                      let minX = Infinity;
                      let maxX = 0;
                      for (let media of (isSelecting ? getSelectedVideos() : state.selectedVideoLayers)) {
                        minX = Math.min(media.x, minX);
                        maxX = Math.max(media.x + (media.scaledWidth || 0), maxX);
                      }
                      return maxX - minX;
                    }
                  })()}
                  height={(() => {
                    if (!(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length) {
                      return 0;
                    }
                    return getBoxHeight((isSelecting ? getSelectedVideos() : state.selectedVideoLayers));
                  })()}
                  maxHeight={canvasState.canvasHeight}
                  cropDimensions={{top: 0, bottom: 0, left: 0, right: 0}}
                  maxVerticalSize={(() => {
                    if (!(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length) {
                      return 0;
                    } else if ((isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length === 1) {
                      return (isSelecting ? getSelectedVideos() : state.selectedVideoLayers)[0].baseHeight;
                    } else {
                      let minY = Infinity;
                      let maxY = 0;
                      for (let media of (isSelecting ? getSelectedVideos() : state.selectedVideoLayers)) {
                        minY = Math.min(media.y, minY);
                        maxY = Math.max(media.y + (media.scaledHeight || 0), maxY);
                      }
                      return maxY - minY;
                    }
                  })()}
                  initialX={(() => {
                    if (!(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length) {
                      return 0;
                    }
                    return getBoxX((isSelecting ? getSelectedVideos() : state.selectedVideoLayers));
                  })()}
                  initialY={(() => {
                    if (!(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length) {
                      return 0;
                    }
                    return getBoxY((isSelecting ? getSelectedVideos() : state.selectedVideoLayers));
                  })()}
                  initialRotation={0}
                  zIndex={100}
                  resizable={(isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length > 1 ? true : getSelectedVideos()[0]?.resizable}
                  rotatable={true}
                  handles={((isSelecting ? getSelectedVideos() : state.selectedVideoLayers).length > 1 ? false : getSelectedVideos()[0]?.cropable) ?
                    ['se', 'sw', 'ne', 'nw', 'e', 'w', 's', 'n'] :
                    ['se', 'sw', 'ne', 'nw']}
                  lockAspectRatio={true}
                  onSelect={((event: React.MouseEvent<HTMLDivElement>) => {
                    // stop the propogation so we can identify if someone clicked on the background elements to unselect
                    event.stopPropagation();

                    const activeSegment = getActiveSegment(videoSegments.current, currentTime.current);
                    if (!activeSegment || !activeSegment.layers) {
                      return;
                    }

                    const videos = activeSegment.layers.map((layer: MediaLayer) => layer.video);

                    const foundVideo = getSelectedVideo(event.clientX, event.clientY, videos);
                    if (foundVideo) {
                      onVideoSelected(foundVideo, event.ctrlKey);
                    } else {
                      setVideos(state.videos, []);
                    }
                  })}
                  onDragStop={(xBefore, xAfter, yBefore, yAfter) => {
                    handleVideoDragStop(xBefore, xAfter, yBefore, yAfter, state.selectedVideoLayers);
                  }}
                  onDrag={(xBefore, xAfter, yBefore, yAfter) => {
                    handleVideoDrag(xBefore, xAfter, yBefore, yAfter, state.selectedVideoLayers);
                  }}
                  onDragStart={(x, y) => {
                    beforeResizeVideosRef.current = [...state.videos];
                  }}
                  onResizeStart={handleVideoResizeStart}
                  onResize={async (xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction): Promise<undefined> => {
                    if (state.selectedVideoLayers.some((video) => requiresWasm(video))) {
                      // if it requires wasm rendering then doing it real time causes corrupt picture due to resize not being 100% synced
                      return;
                    }
                    await handleVideoResizeStop(xBefore, yBefore, xAfter, yAfter, widthBefore, heightBefore, widthAfter, heightAfter, direction, state.selectedVideoLayers, false, cropDirections.includes(direction || ''));
                    }}
                  onResizeStop={async (xBefore, yBefore, widthBefore, heightBefore, xAfter, yAfter, widthAfter, heightAfter, direction): Promise<undefined> => {
                    await handleVideoResizeStop(xBefore, yBefore, xAfter, yAfter, widthBefore, heightBefore, widthAfter, heightAfter, direction, state.selectedVideoLayers, true, cropDirections.includes(direction || ''));
                  }}
                  onRotate={(degrees) => {
                    handleVideoRotate(degrees, state.selectedVideoLayers, false);
                  }}
                  onRotateStop={(degrees) => {
                    handleVideoRotate(degrees, state.selectedVideoLayers, true);
                  }}
                />)}
          <SelectionBox
            startX={selectionStart?.x || 0}
            startY={selectionStart?.y || 0}
            endX={selectionEnd?.x || 0}
            endY={selectionEnd?.y || 0}
            isVisible={isSelecting && !!selectionEnd}
          />
          <canvas
            ref={renderCanvas} style={{height: canvasState.canvasHeight, width: canvasState.canvasWidth, display: 'block'}}
          />
          <canvas
            ref={draftCanvas} style={{height: canvasState.canvasHeight, width: canvasState.canvasWidth, display: 'none'}}
          />
          <canvas
            id={'subtitlesCanvas'}
            ref={subtitlesCanvas}
            style={{
              pointerEvents: 'none',
              position: 'absolute',
              left: state.subtitlesContainer?.x,
              top: `${(state.subtitlesContainer?.y - canvasState.canvasHeight + state.subtitlesContainer?.height + (canvasState.canvasHeight - subtitlesCanvasState.canvasHeight)) || 0}px`,
              height: subtitlesCanvasState.canvasHeight,
              width: state.subtitlesContainer?.width,
              display:  state.videos.some((vid) => (vid as Video).subtitlesActive) ? 'block' : 'none'
            }}
          />
        </Box>
      <RightClickMenu
        openConfig={menuOpenConfig}
        position={menuPosition}
        onCopy={onCopy}
        onPaste={onPaste}
        onClose={handleClose}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
      />
    </Box>
  );
});

export default EditorCanvas;

