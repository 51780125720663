import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Typography, Box, useTheme, Divider, Button } from '@mui/material';
import { throttle } from 'lodash';
import { styled } from '@mui/material/styles';
import SpeedIcon from '@mui/icons-material/Speed';
import OpacityIcon from '@mui/icons-material/Opacity';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import Brightness5Icon from '@mui/icons-material/Brightness5';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import TimerIcon from '@mui/icons-material/Timer';

import { Container, Section, SectionHeader, StyledSlider, ControlLabel } from './Styles';
import TimelineControls from './TimelineControls';
import Video from '../media/Video';
import Audio from '../media/Audio';
import TimeInput from '../TimeInput';
import EmptyMediaMenu from './EmptyMediaMenu';

// Define the interface for SpeedButton's transient prop
interface SpeedButtonProps {
  $active: boolean;
}

// Styled SpeedButton using transient prop $active
const SpeedButton = styled(Button)<SpeedButtonProps>(({ theme, $active }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: $active ? theme.palette.primary.main : theme.palette.grey[300],
  color: $active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: $active ? theme.palette.primary.dark : theme.palette.grey[400],
  },
  minWidth: 50, // Consistent button width
}));

type MediaAttributes = {
  speed: number;
  opacity: number | undefined;
  hue: number | undefined;
  brightness: number | undefined;
  volume: number;
  start: number;
  end: number;
}

type SelectedMediaType = {
  media: Video | Audio | null;
  start: number,
  end: number
}

interface MediaMenuProps {
  selectedMedia: SelectedMediaType | null;
  canvasBackgroundColor: [number, number, number, number],
  mediaStart: number | null,
  mediaEnd: number | null,
  isMobileLayout: boolean;
  onMediaChanged: (media: Video, start: number | null, end: number | null, commit: boolean) => void;
  onChangeBackground: (color: [number, number, number, number]) => void;
  onAddMedia: (file: File) => void;
}

const MediaMenu: React.FC<MediaMenuProps> = ({
  selectedMedia,
  isMobileLayout,
  onMediaChanged,
  onAddMedia,
}) => {
  const theme = useTheme();
  const [mediaAttributes, setMediaAttributes] = useState<MediaAttributes | null>(null);

  const defaultSpeed = 1;
  const defaultVolume = 1;
  const defaultStart = 0;
  const defaultEnd = 0;
  const defaultOpacity = 1;
  const defaultHue = 0;
  const defaultBrightness = 0;

  useEffect(() => {
    if (selectedMedia) {
      const speed = selectedMedia?.media?.speed || defaultSpeed;
      const volume = selectedMedia?.media?.volume || defaultVolume;
      const start = selectedMedia.start || defaultStart;
      const end = selectedMedia.end || defaultEnd;
      const opacity = selectedMedia.media instanceof Video ? selectedMedia.media.opacity || defaultOpacity : undefined;
      const hue = selectedMedia.media instanceof Video ? selectedMedia.media.hue || defaultHue : undefined;
      const brightness = selectedMedia.media instanceof Video ? selectedMedia.media.brightness || defaultBrightness : undefined;
      setMediaAttributes({
        speed,
        volume,
        start,
        end,
        opacity,
        hue,
        brightness
      });
    }
  }, [selectedMedia]);

  const handleMediaAttributeChange = (updatedAttributes: Partial<MediaAttributes>, start: number, end: number, commit: boolean) => {
    const newAttributes: any = { ...mediaAttributes, ...updatedAttributes };

    if (selectedMedia) {
      const speed = newAttributes.speed || defaultSpeed;
      const volume = newAttributes.volume || defaultVolume;
      const opacity = newAttributes.opacity || defaultOpacity;
      const hue = newAttributes.hue || defaultHue;
      const brightness = newAttributes.brightness || defaultBrightness;
      setMediaAttributes({
        speed,
        volume,
        start,
        end,
        opacity,
        hue,
        brightness
      });
      onMediaChanged({...selectedMedia.media as Video, speed, volume, opacity, hue, brightness}, start, end, commit)
    }
  };

  const triggerTimelineChange = (newStart: number, newEnd: number) => {
    if (selectedMedia && mediaAttributes && selectedMedia.media) {
        if (selectedMedia.media instanceof Video) {
          onMediaChanged({...selectedMedia.media, ...mediaAttributes as any}, newStart, newEnd, true);
        }
        else if (selectedMedia.media instanceof Audio) {
          onMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, hue: 0, brightness: 0, opacity: 0}, newStart, newEnd, true);
        }
    }
  };

  return (
    !selectedMedia ? (
      <EmptyMediaMenu onAddMedia={onAddMedia} />
    ) : (
    <Container>
        <Box style={{ direction: 'ltr' }}>
        <Typography
            variant={isMobileLayout ? 'h6' : 'h5'}
            gutterBottom
            sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
            Video Settings
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />

        {selectedMedia.media && (
            <Box>
            {/* Playback Controls */}
            <Section elevation={3}>
                <SectionHeader>
                    <SpeedIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                    Playback Controls
                </SectionHeader>
                
                {/* Speed Control */}
                <Box mb={3}>
                    <ControlLabel>
                        <SpeedIcon sx={{ marginRight: 1 }} />
                        Speed
                    </ControlLabel>
                    {/* Preset Speed Buttons */}
                    <Box display="flex" flexWrap="wrap" flexGrow={1} gap={0.5}>
                    {/*TODO: need to support 0.5 (slower speeds...) - requires increasing the sizeof trails dynamically*/}
                    {[0.5, 1.5, 2, 4, 8].map((speed) => (
                        <SpeedButton
                        key={speed}
                        variant="contained"
                        size="small"
                        $active={(mediaAttributes ? mediaAttributes.speed : defaultSpeed) === speed}
                        onClick={() => onMediaChanged({...selectedMedia.media as Video, ...mediaAttributes, speed}, selectedMedia.start, selectedMedia.end, true)}
                        >
                        {speed}x
                        </SpeedButton>
                    ))}
                    </Box>

                    <Box display="flex" alignItems="center">
                        <StyledSlider
                            value={mediaAttributes ? mediaAttributes.speed : defaultSpeed}
                            // TODO: lower than 0.5 requires applying atempo more than once - need to add that (others often don't have that feature)
                            min={0.5}
                            max={8}
                            step={0.1}
                            onChange={(event, speed: number | number[]) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, speed: (speed as unknown) as number}, selectedMedia.start, selectedMedia.end, false)}
                            onChangeCommitted={(event, speed: number | number[]) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, speed: (speed as unknown) as number}, selectedMedia.start, selectedMedia.end, true)}
                            valueLabelDisplay="auto"
                            aria-labelledby="speed-slider"
                            sx={{ flexGrow: 1 }}
                        />
                        <Typography variant="body2" sx={{ ml: 2, width: '25px', minWidth: '25px', maxWidth: '25px' }}>
                            {mediaAttributes ? mediaAttributes.speed : defaultSpeed}x
                        </Typography>
                    </Box>
                </Box>

                {/* Volume Control */}
                <Box>
                    <ControlLabel>
                        <VolumeUpIcon sx={{ marginRight: 1 }} />
                        Volume
                    </ControlLabel>
                    <Box display="flex" alignItems="center">
                        <StyledSlider
                        value={Math.round((mediaAttributes ? mediaAttributes.volume : defaultVolume) * 100)}
                        min={0}
                        max={100}
                        step={1}
                        onChange={(event, newVolume) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, volume: newVolume as number / 100}, selectedMedia.start, selectedMedia.end, false)}
                        onChangeCommitted={(event, newVolume) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, volume: newVolume as number / 100}, selectedMedia.start, selectedMedia.end, true)}
                        valueLabelDisplay="auto"
                        aria-labelledby="volume-slider"
                        sx={{ flexGrow: 1 }}
                        />
                        <Typography variant="body2" sx={{ ml: 2, width: '25px', minWidth: '25px', maxWidth: '25px' }}>
                        {Math.round((mediaAttributes ? mediaAttributes.volume : defaultVolume) * 100)}%
                        </Typography>
                    </Box>
                </Box>
            </Section>

            {/* Visual Adjustments */}
            {selectedMedia.media instanceof Video && (
            <Section elevation={3}>
                <SectionHeader>
                <ColorLensIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
                Visual Adjustments
                </SectionHeader>
                <Grid container spacing={3}>
                {/* Brightness Control */}
                <Grid item xs={12} sm={4}>
                    <ControlLabel>
                    <Brightness5Icon sx={{ marginRight: 1 }} />
                    Brightness
                    </ControlLabel>
                    <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledSlider
                        value={mediaAttributes ? mediaAttributes.brightness : defaultBrightness}
                        min={-10}
                        max={10}
                        step={1}
                        onChange={(event, brightness: any) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, brightness}, selectedMedia.start, selectedMedia.end, false)}
                        onChangeCommitted={(event, brightness: any) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, brightness}, selectedMedia.start, selectedMedia.end, true)}
                        valueLabelDisplay="auto"
                        aria-labelledby="brightness-slider"
                        sx={{ width: '100%' }}
                    />
                    <Typography variant="body2">{mediaAttributes ? mediaAttributes.brightness : defaultBrightness}%</Typography>
                    </Box>
                </Grid>

                {/* Opacity Control */}
                <Grid item xs={12} sm={4}>
                    <ControlLabel>
                    <OpacityIcon sx={{ marginRight: 1 }} />
                    Opacity
                    </ControlLabel>
                    <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledSlider
                        value={mediaAttributes ? mediaAttributes.opacity: defaultOpacity}
                        min={0}
                        max={1}
                        step={0.01}
                        onChange={(event, opacity: any) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, opacity}, selectedMedia.start, selectedMedia.end, false)}
                        onChangeCommitted={(event, opacity: any) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, opacity}, selectedMedia.start, selectedMedia.end, true)}
                        valueLabelDisplay="auto"
                        aria-labelledby="opacity-slider"
                        sx={{ width: '100%' }}
                    />
                    <Typography variant="body2">{((mediaAttributes ? mediaAttributes.opacity || defaultOpacity : defaultOpacity) * 100).toFixed(0)}%</Typography>
                    </Box>
                </Grid>

                {/* Hue Control */}
                <Grid item xs={12} sm={4}>
                    <ControlLabel>
                    <ColorLensIcon sx={{ marginRight: 1 }} />
                    Hue
                    </ControlLabel>
                    <Box display="flex" flexDirection="column" alignItems="center">
                    <StyledSlider
                        value={mediaAttributes ? mediaAttributes.hue : defaultHue}
                        min={-180}
                        max={180}
                        step={1}
                        onChange={(event, hue: any) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, hue}, selectedMedia.start, selectedMedia.end, false)}
                        onChangeCommitted={(event, hue: any) => handleMediaAttributeChange({...selectedMedia.media as Video, ...mediaAttributes, hue}, selectedMedia.start, selectedMedia.end, true)}
                        valueLabelDisplay="auto"
                        aria-labelledby="hue-slider"
                        sx={{ width: '100%' }}
                    />
                    <Typography variant="body2">{mediaAttributes ? mediaAttributes.hue : defaultHue}°</Typography>
                    </Box>
                </Grid>
                </Grid>
            </Section>
            )}

            {/* Timeline Controls */}
            <TimelineControls
              start={mediaAttributes ? mediaAttributes.start : defaultStart}
              end={mediaAttributes ? mediaAttributes.end : defaultEnd}
              maxEnd={(mediaAttributes ? mediaAttributes.start : defaultStart) + selectedMedia?.media.duration}
              triggerTimelineChange={triggerTimelineChange}
            />
            </Box>
        )}
        </Box>
    </Container>
    )
  );
};

export default MediaMenu;
