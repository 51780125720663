import React, { useState, useEffect } from 'react';
import { InputBase } from '@mui/material';
import InputMask from 'react-input-mask';

interface PhoneInputProps {
  value: number; // Value in seconds (including decimal)
  maxValue?: number;
  onChange: (newSeconds: number) => void; // Expect number in seconds (including decimal)
}

const TimeInput: React.FC<PhoneInputProps> = ({ value, maxValue, onChange }) => {
  const [timeString, setTimeString] = useState<string>("");

  const convertSecondsToTimeFormat = (seconds: number) => {
    const hrs = Math.floor(value / 3600);
    const mins = Math.floor((value % 3600) / 60);
    const secs = (value % 60).toFixed(1); // Keep milliseconds

    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.padStart(4, '0')}`;
  }

  // Convert seconds to HH:MM:SS.s format
  useEffect(() => {
    const timeFormat = convertSecondsToTimeFormat(value);
    setTimeString(timeFormat);
  }, [value]);

  // Convert time string back to seconds
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = e.target.value;

    const [hrs, mins, secs] = newTime.split(':').map((part) => parseFloat(part));
    if (!isNaN(hrs) && !isNaN(mins) && !isNaN(secs)) {
      const totalSeconds = hrs * 3600 + mins * 60 + secs;
      if (!maxValue || totalSeconds <= maxValue) {
        // if below maxValue or doesn't exist - set the value
        setTimeString(newTime);
        onChange(totalSeconds); // Send seconds (including decimal) to parent
      } else {
        // if invalid value - set the max value
        setTimeString(convertSecondsToTimeFormat(maxValue));
        onChange(maxValue); // Send seconds (including decimal) to parent
      }
    }
  };

  return (
    <InputMask
      mask="99:99:99.9"
      maskChar="0"
      alwaysShowMask={true}
      value={timeString}
      onChange={handleChange}
    >
      <InputBase
        size="small"
        inputProps={{
          style: { textAlign: 'center', fontSize: '13px', padding: 0 },
        }}
        sx={{ width: 80, color: '#000', borderBottom: '0.5px solid #ccc' }} // Keeping width unchanged
      />
    </InputMask>
  );
};

export default TimeInput;
