import config from '../constants/constants'

type presignedUrlResponse = {
  generatedName: string | null;
  presignedUrl: string | null;
}

const fetchIntegrations = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.LIST_SOURCES_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch integrations');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch integrations', error);
    return null;
  }
}

const fetchDestinations = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.LIST_DESTINATIONS_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch integrations');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch integrations', error);
    return null;
  }
}

const fetchRemoteExports = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.FETCH_REMOTE_EXPORTS_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch exports');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch exports', error);
    return null;
  }
}

const fetchLocalExports = async () => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.FETCH_LOCAL_EXPORTS_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch exports');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch exports', error);
    return null;
  }
}

const fetchRawData = async (metrics: string[], dimension: string, platforms: string[], startDate: Date, endDate: Date) => {
  try {
    const params = {
      metrics,
      dimension,
      platforms,
      startDate,
      endDate
    }

    const response = await fetch(`${config.SERVER_ADDRESS}${config.FETCH_RAW_DATA_PATH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      throw new Error('Failed to fetch raw data');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch raw data', error);
    return null;
  }
}

const deleteExport = async (id: string) => {
  try {
    if (!id) {
      return null;
    }
    const response = await fetch(`${config.SERVER_ADDRESS}${config.REMOVE_EXPORT_PATH}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ id })
    });

    if (!response.ok) {
      throw new Error('Failed to delete export');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to delete export', error);
    return null;
  }
}

const deleteIntegration = async (id: string): Promise<void> => {
  try {
    const response = await fetch(config.SERVER_ADDRESS + config.REMOVE_INTEGRATION_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        'id': id
      }).toString(),
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Removing integration failed');
    }

    const data = await response;
    console.log('Removing integration successful:', data);
  } catch(error) {
    console.error("Failed to contact server", error);
  }
};

const deleteUser = async (id: string) => {
  if (!id) {
    return null;
  }
  const response = await fetch(`${config.SERVER_ADDRESS}${config.REMOVE_USER_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ id })
  });

  if (!response.ok) {
    throw new Error('Failed to delete export');
  }

  return await response.json();
}

const updateProfile = async (email: string, username: string, fullName: string, password: string) => {
  if (!email || !username) {
    return null;
  }
  // TODO: need to implement this
  const profilePicture = '';
  const response = await fetch(`${config.SERVER_ADDRESS}${config.UPDATE_PROFILE_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ email, username, fullName, profilePicture, password })
  });

  if (!response.ok) {
    throw new Error('Failed to update user profile');
  }

  return await response.json();
}

const registerViaToken = async (token: string, username: string, password: string) => {
  if (!token || !username || !password) {
    throw new Error('parameters to registerViaToken cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.REGISTER_USER_VIA_TOKEN_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ token, username, password })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via token');
  }

  return await response.json();
}

const resendRegistration = async (email: string) => {
  if (!email) {
    throw new Error('parameters to resendRegistration cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.RESEND_REGISTRATION}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ email })
  });

  if (!response.ok) {
    throw new Error(`Failed to resend registration for ${email}`);
  }

  return await response.json();
}

const resetPasswordViaToken = async (token: string, password: string) => {
  if (!token || !password) {
    throw new Error('parameters to resetPasswordViaToken cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.RESET_PASSWORD_VIA_TOKEN_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ token, password })
  });

  if (!response.ok) {
    throw new Error('Failed to reset user password via token');
  }

  return await response.json();
}

const activateViaToken = async (token: string) => {
  if (!token) {
    throw new Error('parameters to registerViaToken cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.ACTIVATE_USER_VIA_TOKEN_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ token })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via token');
  }

  return await response.json();
}

const registerUser = async (fullName: string, email: string, username: string, password: string) => {
  if (!fullName || !email || !username || !password) {
    throw new Error('parameters to registerUser cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.REGISTER_USER_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ fullName, email, username, password })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via token');
  }

  return await response.json();
}

const loginUser = async (email: string, password: string) => {
  if (!email || !password) {
    throw new Error('parameters to registerUser cannot be null');
  }

  const response = await fetch(config.SERVER_ADDRESS + config.LOGIN_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      'email': email,
      'password': password,
    }).toString(),
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return await response.json();
}

const resetUserPassword = async (email: string) => {
  if (!email) {
    throw new Error('parameters to resetUserPassword cannot be null');
  }

  const response = await fetch(config.SERVER_ADDRESS + config.RESET_PASSWORD_PATH, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: new URLSearchParams({
      'email': email,
    }).toString(),
    credentials: 'include'
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return await response.json();
}

const registerViaGoogleOAuth = async (accessToken: string) => {
  if (!accessToken) {
    throw new Error('parameters to registerViaGoogleOAuth cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.REGISTER_VIA_GOOGLE_OAUTH_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ accessToken })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via google oauth');
  }

  return await response.json();
}

const registerViaMicrosoftOAuth = async (accessToken: string) => {
  if (!accessToken) {
    throw new Error('parameters to registerViaMicrosoftOAuth cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.REGISTER_VIA_MICROSOFT_OAUTH_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ accessToken })
  });

  if (!response.ok) {
    throw new Error('Failed to reigster user via microsoft oauth');
  }

  return await response.json();
}

const downloadVideo = async (serializedContext: string) => {
  if (!serializedContext) {
    throw new Error('parameters to downloadVideo cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.DOWNLOAD_VIDEO_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: serializedContext
  });

  if (!response.ok) {
    throw new Error('Failed to download video');
  }

  return await response.json();
}

const downloadSubtitles = async (videoHash: string) => {
  if (!videoHash) {
    throw new Error('parameters to downloadSubtitles cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.DOWNLOAD_SUBTITLES_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ videoHash })
  });

  if (!response.ok) {
    throw new Error('Failed to download video');
  }

  return await response.json();
}

const getRenderSubtitlesUploadUrl = async () => {
  const response = await fetch(`${config.SERVER_ADDRESS}${config.GET_SIGNED_RENDER_SUBTITLES_UPLOAD_URL_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to get signed upload url');
  }

  return await response.json();
}

const getSignedUploadUrl = async (fileName: string, fileType: string, sha256: string) => {
  if (!fileName || !fileType || !sha256) {
    throw new Error('parameters to getSignedUploadUrl cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.GET_SIGNED_UPLOAD_URL_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ fileName, fileType, sha256 })
  });

  if (!response.ok) {
    throw new Error('Failed to get signed upload url');
  }

  return await response.json();
}

const getSignedSubtitlesUploadUrl = async (fileName: string, fileType: string, sha256: string) => {
  if (!fileName || !fileType || !sha256) {
    throw new Error('parameters to getSignedUploadUrl cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.GET_SIGNED_SUBTITLES_UPLOAD_URL_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ fileName, fileType, sha256 })
  });

  if (!response.ok) {
    throw new Error('Failed to get signed upload url');
  }

  return await response.json();
}

const getSignedSubtitlesDownloadUrl = async (sha256: string, fileType: string): Promise<presignedUrlResponse> => {
  if (!fileType || !sha256) {
    throw new Error('parameters to getSignedDownloadUrl cannot be null');
  }

  const url = new URL(`${config.SERVER_ADDRESS}${config.GET_SIGNED_SUBTITLES_DOWNLOAD_URL_PATH}`);
  url.searchParams.append('fileType', fileType);
  url.searchParams.append('sha256', sha256);

  const response = await fetch(url.toString(), {
    method: 'GET',
    credentials: 'include',
  });

  if (!response.ok) {
    return {presignedUrl: null, generatedName: null};
  }

  return response.json();
}

const setVideoUploaded = async (sha256: string) => {
  if (!sha256) {
    throw new Error('parameters to setVideoUploaded cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.SET_VIDEO_UPLOADED_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ sha256 })
  });

  if (!response.ok) {
    throw new Error('Failed to set video as uploaded');
  }

  return await response.json();
}

const getVideoInfo = async (sha256: string) => {
  if (!sha256) {
    throw new Error('Parameters to getVideoInfo cannot be null.');
  }

  try {
    // Construct the URL with the hash as a query parameter
    const url = new URL(`${config.SERVER_ADDRESS}${config.GET_VIDEO_INFO_PATH}`);
    url.searchParams.append('hash', sha256);

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
    });

    if (response.status === 404) {
      // Video not found; return null
      return null;
    }

    if (!response.ok) {
      // For other error statuses, throw an error
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to get video info.');
    }

    // Parse and return the video data
    return await response.json();
  } catch (err: any) {
    console.error(`Error fetching video info: ${err.message}`);
    throw err; // Re-throw the error after logging
  }
};

const getRenderTasks = async () => {
  const response = await fetch(`${config.SERVER_ADDRESS}${config.GET_RENDER_TASKS_PATH}`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to get rendered videos info');
  }

  return await response.json();
}

const getSubtitlesTasks = async () => {
  const response = await fetch(`${config.SERVER_ADDRESS}${config.GET_SUBTITLES_TASKS_PATH}`, {
    method: 'GET',
    credentials: 'include',
  });

  if (!response.ok) {
    throw new Error('Failed to get rendered videos info');
  }

  return await response.json();
}

const getSignedVideoDownloadUrl = async (sha256: string): Promise<any> => {
  if (!sha256) {
    throw new Error('Parameter sha256 to getSignhedVideoDownloadUrl cannot be empty.');
  }

  const url = new URL(`${config.SERVER_ADDRESS}${config.GET_SIGNED_VIDEO_DOWNLOAD_URL_PATH}`);
  url.searchParams.append('hash', sha256);

  try {
    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch projects');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch projects', error);
    return [];
  }
}

const fetchProjects = async (): Promise<any[]> => {
  try {
    const response = await fetch(`${config.SERVER_ADDRESS}${config.LIST_PROJECTS_PATH}`, {
      method: 'GET',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to fetch projects');
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch projects', error);
    return [];
  }
}

const getProject = async (id: string) => {
  if (!id) {
    throw new Error('Parameter id to getProject cannot be null.');
  }

  try {
    // Construct the URL with the hash as a query parameter
    const url = new URL(`${config.SERVER_ADDRESS}${config.GET_PROJECT_PATH}`);
    url.searchParams.append('id', id);

    const response = await fetch(url.toString(), {
      method: 'GET',
      credentials: 'include',
    });

    if (response.status === 404) {
      // Project not found; return null
      return null;
    }

    if (!response.ok) {
      // For other error statuses, throw an error
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to get project info.');
    }

    // Parse and return the video data
    return await response.json();
  } catch (err: any) {
    console.error(`Error fetching project info: ${err.message}`);
    throw err; // Re-throw the error after logging
  }
};

/*
 * update project and get project id from the server. If id parameter of this function is
 * null a new project is created and the id is returned by the backend
 */
const updateProject = async (data: any, timestamp: number, id: string | null, name: string) => {
  if (!data) {
    throw new Error('parameter data for updateProject cannot be null');
  }

  const response = await fetch(`${config.SERVER_ADDRESS}${config.UPDATE_PROJECT_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify({ id, name, timestamp, data })
  });

  if (!response.ok) {
    throw new Error('Failed to set video as uploaded');
  }

  return await response.json();
}

export {
  type presignedUrlResponse,
  registerViaGoogleOAuth,
  registerViaMicrosoftOAuth,
  getProject,
  updateProject,
  fetchProjects,
  setVideoUploaded,
  getVideoInfo,
  getSignedVideoDownloadUrl,
  getRenderSubtitlesUploadUrl,
  getSignedUploadUrl,
  getSignedSubtitlesUploadUrl,
  getSignedSubtitlesDownloadUrl,
  getRenderTasks,
  getSubtitlesTasks,
  downloadVideo,
  downloadSubtitles,
  fetchIntegrations,
  fetchDestinations,
  fetchRemoteExports,
  fetchLocalExports,
  fetchRawData,
  deleteExport,
  deleteUser,
  deleteIntegration,
  updateProfile,
  registerViaToken,
  activateViaToken,
  registerUser,
  resendRegistration,
  loginUser,
  resetUserPassword,
  resetPasswordViaToken
};

