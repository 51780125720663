import react from 'react';
import { ControlLabel, Section, SectionHeader } from './Styles';
import { Box, Divider, Typography, useTheme } from '@mui/material';
import { PlayArrow, Timer, Stop } from '@mui/icons-material';
import TimeInput from '../TimeInput';

interface TimelineControlsProps {
  start: number,
  end: number,
  maxEnd: number,
  triggerTimelineChange: (start: number, end: number) => void;
}

const TimelineControls: React.FC<TimelineControlsProps> = ({start, end, maxEnd, triggerTimelineChange}) => {
  const theme = useTheme();

  const onTimeChanged = (newStart: number, newEnd: number) => {

    // User changed the start ?
    if (newStart !== start) {
        // Set the end according to the existing duration
        newEnd = newStart + (end - start);
    }
    triggerTimelineChange(newStart, newEnd);
  };

  return (
    <Section elevation={3}>
        <SectionHeader>
            <Timer sx={{ marginRight: 1, color: theme.palette.primary.main }} />
            Timeline Controls
        </SectionHeader>

        {/* Container for Start and End Time */}
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            //justifyContent: 'space-between',
            flexWrap: 'wrap',
            borderRadius: '8px',         // Rounded corners
            border: 'solid 0.5px'
        }}>
            
            {/* Start Time Input with Play Icon */}
            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                //backgroundColor: '#f9f9f9',  // Light background color for distinction
                padding: 1,                  // Padding inside each section
                borderRadius: '8px',         // Rounded corners
                //border: 'solid 0.5px'
            }}>
                <ControlLabel sx={{margin:0}}>
                    <PlayArrow /> {/* Start Icon */}
                    <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '1.75', marginLeft: '5px'}} >Start</Typography>
                    <TimeInput value={start} onChange={(newStart) => onTimeChanged(newStart, end)} />
                </ControlLabel>
            </Box>

            {/* Vertical Divider (Separator) */}
            <Divider
                orientation="vertical" // Make it vertical
                flexItem               // Ensure it takes up the height of the surrounding content
                sx={{ backgroundColor: '#eee', width: '1px', margin: '8px'}} // Styling
            />
            
            {/* End Time Input with Stop Icon */}
            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                //backgroundColor: '#f9f9f9',  // Light background color for distinction
                padding: 1,
                borderRadius: '8px',
                //border: 'solid 0.5px'
            }}>
                <ControlLabel sx={{margin:0}}>
                    <Stop/> {/* End Icon */}
                    <Typography variant="body1" sx={{ fontSize: '14px', lineHeight: '1.75', marginLeft: '5px'}} >End</Typography>
                    <TimeInput
                      value={end}
                      maxValue={maxEnd}
                      onChange={(newEnd) => onTimeChanged(start, newEnd)}
                    />
                </ControlLabel>
            </Box>
        </Box>
    </Section>
  );
}

export default TimelineControls;
