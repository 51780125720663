import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef, useCallback, useReducer } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { VideoFramesComponent } from './VideoFramesComponent';
import RGL, { WidthProvider, Layout } from 'react-grid-layout';
import { FrameSlider } from './FrameSlider';
import CommandHistory, { ResizeVideoCommand, AddVideoCommand } from './undo/UndoInterface';
import { pollSubtitlesAndDownload, calculateSha256, createVideoFd, downloadFromUrl, createVideoAudioMedia, applyStyleToTextComponent, normalizeMedia, calculateConversionMetadata, deleteTransition } from '../utils/utils';
import { downloadSubtitles, getVideoInfo, getSignedUploadUrl, getSignedSubtitlesUploadUrl, getSignedSubtitlesDownloadUrl, setVideoUploaded, presignedUrlResponse } from '../api/ServerApi';
import { saveFileToIndexedDB } from './localstorage/IndexedDB';
import { v4 as uuidv4 } from 'uuid';
import { CloudUpload } from '@mui/icons-material';
import EffectSelector from './EffectSelector';
import MediaAddComponent from './MediaAddComponent';
import { uploadVideo, createTextImage, getPendingSubtitleTasks, createImageMedia } from '../utils/utils';
import { isWebVTT } from '../utils/assparser';

import { ASS_Style } from 'jassub';

import RightClickMenu, { MenuOpenConfig } from './RightClickMenu';
import VideoEditorControls from './VideoEditorControls';
import getEngine from './EffectsEngine';
import Video from './media/Video';
import Audio from './media/Audio';
import TextMedia from './media/Text';
import Media from './media/Media';
import { useEditorContext } from './VideosContext';

import { debounce } from 'lodash';
import mixpanel from 'mixpanel-browser';

import 'react-resizable/css/styles.css';
import './VideoLayers.css';
import { TextStyleChangeOptions } from './menus/TextMenu';
import Transition from './media/Transition';
import AutoScrollContainer from './AutoScrollContainer';

const Engine = await getEngine();

const ResponsiveGridLayout = WidthProvider(RGL);

type CanvasDimensionType = {
  aspectRatio: number;
  width: number;
  height: number;
}

interface VideoLayersProps {
  onToggleSubtitles: (media: Video | Audio) => void;
  onEffectSelected: (effectString: string) => void;
  onTransitionSelected: (transition: string, time: number, duration: number, row: number) => void;
  onVideoAdded: (media: Video, isFirstMedia: boolean) => void;
  onLayersChanged: (layers: any[], totalDuration: number) => void;
  onDelete: () => void;
  onDuplicate: () => void;
  onCopy: () => void;
  onPaste: () => void;
  onSliderChange: (event: Event, newValue: number | number[]) => void;
  onLayerSelected: (layer: Media | null, multipleSelectionMode?: boolean) => void;
  handlePlayPause: () => void;
  handleSkipFrame: (direction: 'forward' | 'backward') => void;
  onChangeBackground: (color: [number, number, number, number]) => void;
  onSubtitlesStyleChanged: (subtitles: any, style: ASS_Style) => void;
  onTextStyleChanged: (options: TextStyleChangeOptions) => Promise<number | undefined>;
  onMediaChanged: (media: Video, start: number | null, end: number | null, commit: boolean) => void;
  isRunning: boolean;
  canvasDimensions: CanvasDimensionType;
  selectedVideos: Media[];
  canvasBackgroundColor: [number, number, number, number],
  isMobile: boolean;
  numColumns: number;
}

export interface VideoLayersRef {
  onTextCreated: (text: string, style: React.CSSProperties) => Promise<void>;
  handleAddSubtitles: (_: any) => void;
  onSubtitlesUpload: (file: File) => void;
  onAddMedia: (file: File) => void;
}

const VideoLayers = forwardRef<VideoLayersRef, VideoLayersProps>((props, ref) => {
  const {
    onToggleSubtitles,
    onEffectSelected,
    onTransitionSelected,
    onVideoAdded,
    onDelete,
    onDuplicate,
    onCopy,
    onPaste,
    onLayersChanged,
    onSliderChange,
    onLayerSelected,
    handlePlayPause,
    handleSkipFrame,
    onChangeBackground,
    onSubtitlesStyleChanged,
    onTextStyleChanged,
    onMediaChanged,
    isRunning,
    selectedVideos,
    canvasBackgroundColor,
    isMobile,
    numColumns
  } = props;

  const [__, setRenderEvent] = useState<number>(0);
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const [maxSliderOffset, setMaxSliderOffset] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const [gridLayout, setLayout] = useState<Layout[]>([]);
  const [activeHandleDirection, setActiveHandleDirection] = useState<'w' | 'e' | null>(null);
  const [isLoadingVideo, setIsLoadingVideo] = useState<boolean>(false);
  const [videoFileLoadingInfo, setVideoFileLoadingInfo] = useState<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [sliderScrollPos, setSliderScrollPos] = useState(0);

  const [menuPosition, setMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({
    mouseX: null,
    mouseY: null,
  });
  const [menuOpenConfig, setMenuOpenConfig] = useState<MenuOpenConfig>({
    isOpen: false,
    shouldActivateSubtitlesDownload: false,
    shouldActivateCopy: false,
    shouldActivatePaste: false,
    shouldActivateDelete: false,
    shouldActivateDuplicate: false
  });

  const [gridWidth, setGridWidth] = useState<number>(0);
  const [zoomValue, setZoomValue] = useState<number>(1);

  const beforeResizeVideosRef = useRef<any[]>([]);
  const dragRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const scrollBoxRef = useRef<HTMLDivElement | null>(null);
  const gridParentRef = useRef<any>(null);
  const gridRef = useRef<any>(null);
  const isDraggingVideoTrack = useRef<boolean>(false);

  const {
    getCopyBuffer,
    setVideos,
    getVideos,
    setTotalDuration,
    state,
    currentTime,
    totalDuration,
    canvasState,
    isDroppingTransition,
    getIsDroppingTransition
  } = useEditorContext();

  const minGridItemPixelSize = 40;
  const layersPadding = 15;
  const numFrames = isMobile ? 10 : 20;
  const fullViewDuration = (totalDuration / zoomValue || 60);
  const stickinessThreshold = 10 / columnWidth;

  const calculateColumnWidth = useCallback(() => {
    if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth - 2*layersPadding;
        const widthPerColumn = containerWidth / numColumns;
        setColumnWidth(widthPerColumn);
    }
  }, [setColumnWidth, numColumns]);

  const handleDragEnter = useCallback((e: DragEvent) => {
    if (getIsDroppingTransition() || !e.dataTransfer?.types.includes('Files')) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, [getIsDroppingTransition]);

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const forceRenderComponent = () => {
    setRenderEvent(Math.random());
  }

  const onAddMedia = async (file: File | undefined) => {

    if (!file || getIsDroppingTransition()) {
      return;
    }

    mixpanel.track('fileDropped', {
      'numberOfVideos': state.videos.length,
      'name': file.name,
      'size': file.size,
      'type': file.type,
    });

    if (isLoadingVideo) {
      // we currently do not support loading videos at the same time
      return;
    }

    // TODO: for now disable this until we have the feature ready
    if (file && file.type.startsWith('image/')) {
      const imageURL = URL.createObjectURL(file);
      const row = state.videos.reduce((max: number, v: Media) => v.row > max ? v.row : max, -1) + 1;
      const duration = totalDuration ? 0.25 * totalDuration : 10;
      const imageComponent = await createImageMedia(imageURL, row, totalDuration, duration);
      debouncedMediaUpload(imageComponent.name, imageComponent.type, imageComponent.sha256, imageComponent.url);

      const maxDuration = Math.max(...[...state.videos, imageComponent].map((video) => video.getEnd()));
      const normalizedVideos = normalizeMedia(state.videos, totalDuration, maxDuration);

      const newVideos = pushNewMedia(normalizedVideos, imageComponent);

      const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
      const addVideosCommand = new AddVideoCommand(originalVideos as Video[], newVideos, newVideos.length-1, (newVideos: Video[]) => {
        setVideos(newVideos);
      });

      CommandHistory.push(addVideosCommand);

      setVideos(newVideos);
      setTotalDuration(maxDuration);

      // Select the Image element
      onLayerSelected(imageComponent as unknown as Video);
    }
    if (file && (file.type.startsWith('video/') || file.type.startsWith('audio/'))) {
      setIsLoadingVideo(true);
      setVideoFileLoadingInfo({name: file.name, size: file.size});

      const sha256 = await calculateSha256(file);

      // TODO: we upload to server inside framesComponent - probably need to move it out here or something...
      // save to storage
      saveFileToIndexedDB(file, sha256)

      const videoInfo = await getVideoInfo(sha256);
      const row = state.videos.reduce((max: number, v: Media) => v.row > max ? v.row : max, -1) + 1;
      const newVideoObject = await createVideoAudioMedia(file, sha256, videoInfo, row);

      const maxDuration = Math.max(...[...state.videos, newVideoObject].map((video) => video.getEnd()));
      const normalizedVideos = normalizeMedia(state.videos, totalDuration, maxDuration);

      const newVideos = pushNewMedia(normalizedVideos, newVideoObject);

      const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
      const addVideosCommand = new AddVideoCommand(originalVideos as Video[], newVideos, newVideos.length-1, (newVideos: Video[]) => {
        setVideos(newVideos);
      });

      CommandHistory.push(addVideosCommand);

      setVideos(newVideos);
      setTotalDuration(maxDuration);
    }
  };

  const handleResizeStart = (layout: any, oldItem: any, newItem: any, placeholder: any, e: any) => {
    const index = state.videos.findIndex((video: Media) => video.id === oldItem.i) ;
    if (state.videos[index].isResizing) {
        console.error("resizing while still in resizing!")
    }

    beforeResizeVideosRef.current = state.videos.map((vid: Media) => vid.deepCopy());

    const newVideos = [...state.videos];

    newVideos[index].isResizing = true;
    setVideos(newVideos);
  }

  const getAdjacentLayoutItem = (item: any, direction: 'left' | 'right', layout: Layout[]): Layout => {
    let foundItem: any = null;
    for (const candidate of layout) {
      if (candidate.i === item.i || candidate.y !== item.y) {
        continue;
      }
      if (direction === 'left') {
        const candidateRight = candidate.x + candidate.w;
        if (candidateRight <= item.x && (!foundItem || candidateRight >= foundItem.x + foundItem.w) ) {
          foundItem = candidate;
        }
      } else {
        const candidateLeft = candidate.x;
        if (candidateLeft >= item.x + item.w && (!foundItem || candidateLeft <= foundItem.x) ) {
          foundItem = candidate;
        }
      }
    }

    return foundItem;
  }

  const getLayoutItemToTheRight = (item: Layout, layout: Layout[]) => {
    return getAdjacentLayoutItem(item, 'right', layout);
  }

  const getLayoutItemToTheLeft = (item: Layout, layout: Layout[]) => {
    return getAdjacentLayoutItem(item, 'left', layout);
  }

  const calculateOffsets = (layout: Layout[], oldItem: Layout, newItem: Layout, isFinalResize: boolean = false, allowInfiniteIncrease: boolean = false) => {
    const index = state.videos.findIndex((video: Media) => video.id === oldItem.i) ;

    let leftBackgroundOffset = null;
    let rightBackgroundOffset = null;
    let maxW = null;

    const increaseAmount = newItem.w - oldItem.w;

    const leftShrink = state.videos[index].getLeftTrim() / totalDuration * numColumns;
    const rightShrink = state.videos[index].getRightTrim() / totalDuration * numColumns;

    // the right side
    if (activeHandleDirection === 'e') {
        if (increaseAmount > rightShrink && !allowInfiniteIncrease) {
          rightBackgroundOffset = 0;
          maxW = oldItem.w + rightShrink;
        } else {
          rightBackgroundOffset = oldItem.w - newItem.w +state.videos[index].getEndBase();
          if (!isFinalResize) {
            const item = getLayoutItemToTheRight(oldItem, layout);
            if (item) {
              const itemLeft = item.x;
              const oldItemRight = oldItem.x + oldItem.w;
              if (allowInfiniteIncrease) {
                // increase up to the adjacent element
                maxW = oldItem.w + (itemLeft - oldItemRight);
              } else {
                // cant increase further than adjacent element && no increase beyond the right shrink
                maxW = Math.min(oldItem.w + (itemLeft - oldItemRight), oldItem.w + rightShrink);
              }
            }
          } else {
            maxW = oldItem.w + rightShrink + leftShrink;
          }
        }
    }
    // the left side
    else {
        if (increaseAmount > leftShrink && !allowInfiniteIncrease) {
            leftBackgroundOffset = 0;
            maxW = oldItem.w + leftShrink;
        } else {
            leftBackgroundOffset = newItem.w - oldItem.w +state.videos[index].getStartBase();
            if (!isFinalResize) {
              const item = getLayoutItemToTheLeft(oldItem, layout);
              if (item) {
                const itemRight = item.x + item.w;
                if (allowInfiniteIncrease) {
                  // increase up to the adjacent element
                  maxW = oldItem.w + (oldItem.x - itemRight);
                } else {
                  // cant increase further than adjacent element && no increase beyond the left shrink
                  maxW = Math.min(oldItem.w + (oldItem.x - itemRight), oldItem.w + leftShrink);
                }
              }
            } else {
              maxW = oldItem.w + rightShrink + leftShrink;
            }
        }
    }

    return [leftBackgroundOffset, rightBackgroundOffset, maxW];
  }

  const handleItemDrop = (containingItem: Layout, event: React.DragEvent) => {
    const dataType = event.dataTransfer?.getData('dataType')
    if (dataType === 'transition') {
      const transitionName = event.dataTransfer?.getData('transitionName');

      if (!transitionName) {
        return;
      }
      const duration = 0.5;
      // we get the container on which this transition was dropped, adding +1 to the x will put us somewhere inside...
      const dropTime = (containingItem.x + 1) / numColumns * totalDuration;
      const row = containingItem.y;

      onTransitionSelected(transitionName, dropTime, duration, row);
    }
  }

  const handleResizeStop = (layout: Layout[], oldItem: Layout, newItem: any) => {
    const index = state.videos.findIndex((video: Media) => video.id === oldItem.i) ;
    if (!state.videos[index].isResizing) {
        console.error("resizing without having isResizing enabled")
        newItem.w = oldItem.w;
        newItem.x = oldItem.x;
        return;
    }

    const newVideos = [...state.videos];
    const curVideo = newVideos[index];

    const rightItem = getAdjacentLayoutItem(oldItem, 'right', gridLayout);
    const leftItem = getAdjacentLayoutItem(oldItem, 'left', gridLayout);

    if (leftItem) {
      const leftIndex = newVideos.findIndex((video: Media) => video.id === leftItem.i) ;
      const leftVideo = newVideos[leftIndex];
      if (leftVideo && (leftVideo instanceof Transition) && leftVideo.toVideo.id === curVideo.id) {
        // set the duration of the transition to the full gap
        leftVideo.duration = (newItem.x / numColumns * totalDuration - leftVideo.start);
      }
    } else if (rightItem) {
      const rightIndex = newVideos.findIndex((video: Media) => video.id === rightItem.i) ;
      const rightVideo = newVideos[rightIndex];
      if (rightVideo && (rightVideo instanceof Transition) && rightVideo.fromVideo.id === curVideo.id) {
        const afterTransitionItem = getAdjacentLayoutItem(rightItem, 'right', gridLayout);
        // set the duration of the transition to the full gap
        //const left = Math.ceil(newItem.x + newItem.w);
        const left = newItem.x + newItem.w + 1;
        // TODO: we currently can have minor gaps in the timeline due to this - but i guess it doesnt matter
        // as they will be under a frame and the user in any case is choosing where to place items and cant do anything else
        rightVideo.start = left / numColumns * totalDuration;
        // the start should be set exactly at the end of the last video - we dont want gaps in our timeline
        rightVideo.duration = (afterTransitionItem.x - left) / numColumns * totalDuration;
      }
    }

    // Handle images and other kinds that do not need a background
    if (!(state.videos[index] instanceof Video) && !(state.videos[index] instanceof Audio)) {
      newVideos[index].duration = newItem.w / numColumns * totalDuration;
      newVideos[index].start = (newItem.x / numColumns * totalDuration);
      newVideos[index].isResizing = false;
      setVideos(newVideos);
      return;
    }

    const [leftBackgroundOffset, rightBackgroundOffset, maxW] = calculateOffsets(layout, oldItem, newItem, true);
    const newMaxW = maxW !== null ? maxW : newItem.maxw;

    // For some reason newItem.maxw can be undefined? this causes a bug if we dont check
    if (newMaxW) {
      newItem.maxW = newMaxW;
    }

    if (leftBackgroundOffset !== null) {
        const leftBackgroundOffsetTime = leftBackgroundOffset / numColumns * totalDuration;
        newVideos[index].setLeftBackgroundOffset(leftBackgroundOffset);
        newVideos[index].setStartBase(leftBackgroundOffset);
        // shrinked from left
        if (oldItem.x < newItem.x && oldItem.w > newItem.w) {
            newVideos[index].setLeftTrim(-leftBackgroundOffsetTime);
            newVideos[index].start = (newItem.x / numColumns * totalDuration);
        }
        // increase the left side
        if (newItem.x < oldItem.x && newItem.w > oldItem.w) {
            newVideos[index].setLeftTrim(-leftBackgroundOffsetTime);
            newVideos[index].start = (newItem.x / numColumns * totalDuration);
        }
    }
    if (rightBackgroundOffset !== null) {
        const rightBackgroundOffsetTime = rightBackgroundOffset / numColumns * totalDuration;
        newVideos[index].setRightBackgroundOffset(rightBackgroundOffset);
        newVideos[index].setEndBase(rightBackgroundOffset);
        // shrinked from right
        if (oldItem.x === newItem.x && oldItem.w > newItem.w) {
            newVideos[index].setRightTrim(rightBackgroundOffsetTime);
        }
        // increase the right side
        if (newItem.x === oldItem.x && newItem.w > oldItem.w) {
            newVideos[index].setRightTrim(rightBackgroundOffsetTime);
        }
    }

    newVideos[index].isResizing = false;

    // set as dirty so it gets seeked to the offset;
    newVideos[index].dirty = true;

    const resizeCommand = new ResizeVideoCommand(beforeResizeVideosRef.current as Video[], newVideos, (newVideos: Video[]) => {
      setVideos(newVideos)
    });

    CommandHistory.push(resizeCommand);

    setVideos(newVideos);
  }

  const handleResize = (layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: any, e: any) => {
    const index = state.videos.findIndex((video: Media) => video.id === oldItem.i) ;

    const newVideos = [...state.videos];

    const rightItem = getAdjacentLayoutItem(oldItem, 'right', gridLayout);
    const leftItem = getAdjacentLayoutItem(oldItem, 'left', gridLayout);

    // hide any relevant transitions
    const curVideo = newVideos[index];
    if (leftItem) {
      const leftIndex = newVideos.findIndex((video: Media) => video.id === leftItem.i) ;
      const leftVideo = newVideos[leftIndex];
      if (leftVideo && (leftVideo instanceof Transition) && leftVideo.toVideo.id === curVideo.id) {
        layout.forEach((element: Layout) => {
          if (element.i === leftItem.i) {
            element.w = 0;
          }
        })
      }
    } else if (rightItem) {
      const rightIndex = newVideos.findIndex((video: Media) => video.id === rightItem.i) ;
      const rightVideo = newVideos[rightIndex];
      if (rightVideo && (rightVideo instanceof Transition) && rightVideo.fromVideo.id === curVideo.id) {
        layout.forEach((element: Layout) => {
          if (element.i === rightItem.i) {
            element.x = element.x + element.w;
            element.w = 0;
          }
        })
      }
    }

    if (!(state.videos[index] instanceof Video) && !(state.videos[index] instanceof Audio)) {
      const [_, __, maxW] = calculateOffsets(layout, oldItem, newItem, false, true);
      if (maxW) {
        newItem.maxW = Math.ceil(maxW);
        newItem.w = Math.min(maxW, newItem.w);
      }
      newVideos[index].duration = newItem.w / numColumns * totalDuration;
      setVideos(newVideos, undefined, true);
      forceRenderComponent();
      return;
    }
    const [leftBackgroundOffset, rightBackgroundOffset, maxW] = calculateOffsets(layout, oldItem, newItem);

    const newMaxW = maxW !== null ? maxW : newItem.maxW;
    // For some reason newItem.maxw can be undefined? this causes a bug if we dont check
    if (newMaxW) {
      newItem.maxW = newMaxW;
    }

    if (leftBackgroundOffset === null && rightBackgroundOffset === null) {
        newItem.x = oldItem.x;
        newItem.w = oldItem.w;
        return;
    }

    if (leftBackgroundOffset !== null) {
        newVideos[index].setLeftBackgroundOffset(leftBackgroundOffset);
    }
    if (rightBackgroundOffset !== null) {
        newVideos[index].setRightBackgroundOffset(rightBackgroundOffset);
    }

    setVideos(newVideos, undefined, true);
    forceRenderComponent();
  };

  const handleDragStart = (layout: Layout[], oldItem: Layout, newItem: Layout, placeholder: any, e: any) => {
    isDraggingVideoTrack.current = true;
    beforeResizeVideosRef.current = state.videos.map((vid: Media) => vid.deepCopy());
    dragState.current = {width: gridWidth || scrollBoxRef.current?.clientWidth || 0, duration: totalDuration, zoom: zoomValue, multiplier: 1};
  }

  const dragState = useRef<any>({width: 0, duration: 0, zoom: 0, miltiplier: 1});
  const handleDrag = (layout: Layout[], oldItem: Layout, newItem: Layout) => {
    const rightItem = getAdjacentLayoutItem(oldItem, 'right', gridLayout);
    const leftItem = getAdjacentLayoutItem(oldItem, 'left', gridLayout);

    // hide any relevant transitions
    const newVideos = [...getVideos()];
    const index = newVideos.findIndex((video: Media) => video.id === oldItem.i) ;
    const curVideo = newVideos[index];
    if (leftItem) {
      const leftIndex = newVideos.findIndex((video: Media) => video.id === leftItem.i) ;
      const leftVideo = newVideos[leftIndex];
      if (leftVideo && (leftVideo instanceof Transition) && leftVideo.toVideo.id === curVideo.id) {
        layout.forEach((element: Layout) => {
          if (element.i === leftItem.i) {
            element.w = 0;
          }
        })
      }
    } else if (rightItem) {
      const rightIndex = newVideos.findIndex((video: Media) => video.id === rightItem.i) ;
      const rightVideo = newVideos[rightIndex];
      if (rightVideo && (rightVideo instanceof Transition) && rightVideo.fromVideo.id === curVideo.id) {
        layout.forEach((element: Layout) => {
          if (element.i === rightItem.i) {
            element.x = element.x + element.w;
            element.w = 0;
          }
        })
      }
    }

    // handle stickiness
    if (rightItem && rightItem.y == newItem.y && newItem.x > oldItem.x) {
      // drag right
      if (rightItem.x - (newItem.x + newItem.w)<= stickinessThreshold) {
        newItem.x = rightItem.x - newItem.w;
      }
    }
    if (leftItem && leftItem.y === newItem.y && newItem.x < oldItem.x) {
      // drag right
      if (newItem.x - (leftItem.x + leftItem.w) <= stickinessThreshold) {
        newItem.x = leftItem.x + leftItem.w;
      }
    }

    // handle scrolling to the right
    if (newItem.x + newItem.w === numColumns) {
      if (scrollBoxRef.current) {
        dragState.current.multiplier += 0.05;

        //// normalize the multiplier such that it will produce a rounded gridWidth (so all columns have rounded width)
        //const newWidth = Math.round(dragState.current.width * dragState.current.multiplier);
        //dragState.current.multiplier = newWidth / dragState.current.width;

        const changePercent = dragState.current.multiplier / (dragState.current.multiplier - 0.05)

        if (scrollBoxRef.current?.clientWidth) {
          const newVideos = [...getVideos()];
          layout.forEach((element: Layout, index: number) => {
            element.x /= changePercent
            newVideos[index].leftBackgroundOffset /= changePercent;
            newVideos[index].rightBackgroundOffset /= changePercent;
            newVideos[index].endBase /= changePercent;
            newVideos[index].startBase /= changePercent;
          });
          newItem.w = newItem.w / changePercent;

          containerRef.current.style.width = `${dragState.current.width * dragState.current.multiplier}px`;
          scrollBoxRef.current.scrollLeft += 50;
          const index = state.videos.findIndex((video: Media) => video.id === oldItem.i);
          newVideos[index].start = (newItem.x / numColumns * totalDuration);
          newVideos[index].row = (newItem.y);
          setVideos(newVideos)

          setGridWidth(dragState.current.width * dragState.current.multiplier);
          setTotalDuration(dragState.current.duration * dragState.current.multiplier);
          setZoomValue(dragState.current.zoom * dragState.current.multiplier);
        }
      }
    }
  }

  // TODO: we change the videos inplace here - which is not a good idea
  const handleDragStop = (layout: Layout[], oldItem: Layout, newItem: Layout) => {
    isDraggingVideoTrack.current = false;
    if (!(oldItem.x !== newItem.x || oldItem.w !== newItem.w || oldItem.y !== newItem.y)) {
      return;
    }

    const rightItem = getAdjacentLayoutItem(oldItem, 'right', gridLayout);
    const leftItem = getAdjacentLayoutItem(oldItem, 'left', gridLayout);

    let newVideos = [...getVideos()];
    const index = newVideos.findIndex((video: Media) => video.id === oldItem.i);
    const curVideo = newVideos[index];

    // handle stickiness
    if (rightItem && rightItem.y == newItem.y && newItem.x > oldItem.x) {
      // drag right
      if (rightItem.x - (newItem.x + newItem.w)<= stickinessThreshold) {
        newItem.x = rightItem.x - newItem.w;
      }
    }
    if (leftItem && leftItem.y === newItem.y && newItem.x < oldItem.x) {
      // drag right
      if (newItem.x - (leftItem.x + leftItem.w) <= stickinessThreshold) {
        newItem.x = leftItem.x + leftItem.w;
      }
    }

    /* assign the final location to the item */
    curVideo.start = (newItem.x / numColumns * totalDuration);
    curVideo.row = (newItem.y);

    if (curVideo.transitionId) {
      const transitionIndex = newVideos.findIndex((video: Media) => curVideo.transitionId === video.id);
      if (transitionIndex !== -1) {
        const transition = newVideos[transitionIndex] as Transition;
        if (transition.row !== newItem.y) {
          newVideos = deleteTransition(transition, newVideos);
        }
      }
    }

    /* handle any transitions */
    if (leftItem) {
      const leftIndex = newVideos.findIndex((video: Media) => video.id === leftItem.i) ;
      const leftVideo = newVideos[leftIndex];
      if (leftVideo && (leftVideo instanceof Transition) && leftVideo.toVideo.id === curVideo.id) {
        // set the duration of the transition to the full gap
        leftVideo.duration = curVideo.start - leftVideo.start;
      }
    } else if (rightItem) {
      const rightIndex = newVideos.findIndex((video: Media) => video.id === rightItem.i) ;
      const rightVideo = newVideos[rightIndex];
      if (rightVideo && (rightVideo instanceof Transition) && rightVideo.fromVideo.id === curVideo.id) {
        const afterTransitionItem = getAdjacentLayoutItem(rightItem, 'right', gridLayout);
        // set the duration of the transition to the full gap
        const left = Math.ceil(newItem.x + newItem.w);
        rightVideo.start = left / numColumns * totalDuration;
        rightVideo.duration = (afterTransitionItem.x - left) / numColumns * totalDuration;
      }
    }

    // add undo action only if there was a change in position
    const resizeCommand = new ResizeVideoCommand(beforeResizeVideosRef.current as Video[], newVideos, (newVideos: Video[]) => {
      setVideos(newVideos)
    });
    CommandHistory.push(resizeCommand);

    setVideos(newVideos);
  }

  const convertLayoutToLayers = useCallback((layout: Layout[]) => {
    return layout.filter((l: Layout) => l.i !== '__dropping-elem__' && !l.i.startsWith('box_')).map((l: Layout) => calculateConversionMetadata(state.videos[state.videos.findIndex((video: Media) => video.id === l.i)]));
  }, [state])

  const handleLayoutChange = useCallback((currentLayout: Layout[]) => {
    setLayout(currentLayout);
    const layers = convertLayoutToLayers(currentLayout);
    const maxVideoEnd = Math.max(...layers.map((layer) => layer.end));
    setMaxSliderOffset(maxVideoEnd);

    onLayersChanged(layers, totalDuration);
  }, [convertLayoutToLayers, setMaxSliderOffset, onLayersChanged, totalDuration])

  const onLoadedVideo = (video: Video, frames: any[], audioFrames: any[], index: number) => {
    setIsLoadingVideo(false);
    setVideoFileLoadingInfo(null);
    const curVideos = getVideos();
    const isNew = !(curVideos[index].onLoadedCalled === true);
    video.onLoadedCalled = true;
    // update the video track frames with the loaded frames
    video.frames = frames;
    video.audioFrames = audioFrames;
    // set volume: muting overrides the set volume
    video.videoRef.volume = video.isMuted ? 0 : video.volume;
    video.videoRef.playbackRate = video.speed;

    if (!isNew) {
      const selectedIndex = curVideos.findIndex((vid: Media) => vid.id === video.id) ;
      if (selectedIndex === -1) {
        // this could happen if the user deletes the video while it's loading
        return;
      }

      const newVideos = [...curVideos];
      newVideos[selectedIndex] = video;
      setVideos(newVideos);

    } else {
      // TODO: type should be from the server
      getSignedSubtitlesDownloadUrl(video.sha256, 'text/vtt').then((subtitlesUrl: presignedUrlResponse) => {
        if (subtitlesUrl.presignedUrl) {
          fetch(subtitlesUrl.presignedUrl).then(async (res) => {
            if (res.ok) {
              const url = window.URL.createObjectURL(await res.blob());

              const selectedIndex = getVideos().findIndex((vid: Media) => vid.id === video.id) ;
              if (selectedIndex === -1) {
                // this could happen if the user deletes the video while it's loading
                return;
              }

              const curVideo: Media = getVideos()[selectedIndex];

              if (!(curVideo instanceof Video || curVideo instanceof Audio)) {
                // should not happen - subtitles request for non video / audio type
                return;
              }

              curVideo.subtitlesUrl = url;
              curVideo.subtitlesActive = true;
              curVideo.subtitlesLoaded = true;

              const newVideos = getVideos();
              newVideos[selectedIndex] = curVideo;
              setVideos(newVideos);

              // let the editor know that subtitles were loaded so it can load the and display the subtitles
              onToggleSubtitles(curVideo);
            }
          })
        }
      })
      getPendingSubtitleTasks().then((pendingSubtitleTasks: any[]) => {
        if (pendingSubtitleTasks.length) {
          video.downloadingSubtitles = true;
          pollSubtitlesAndDownload(pendingSubtitleTasks[0].fileName, 1000, async (url: string | null) => {
            if (url) {
              const selectedIndex = getVideos().findIndex((vid: Media) => vid.id === video.id) ;
              if (selectedIndex === -1) {
                // this could happen if the user deletes the video while it's loading
                return;
              }

              const curVideo: Media = getVideos()[selectedIndex];

              if (!(curVideo instanceof Video || curVideo instanceof Audio)) {
                // should not happen - subtitles request for non video / audio type
                return;
              }

              // Append the track to the video element
              curVideo.subtitlesLoaded = true;
              curVideo.downloadingSubtitles = false;

              curVideo.subtitlesActive = true;
              curVideo.subtitlesUrl = url;

              const newVideos = getVideos();
              newVideos[selectedIndex] = curVideo;
              setVideos(newVideos);

              // let the editor know that subtitles were loaded so it can load the and display the subtitles
              onToggleSubtitles(curVideo);

              // Clean up Blob URL when not needed anymore
              window.addEventListener('unload', () => {
                URL.revokeObjectURL(url);
              });
            }
          })
        }

        const selectedIndex = getVideos().findIndex((vid: Media) => vid.id === video.id) ;
        if (selectedIndex === -1) {
          // this could happen if the user deletes the video while it's loading
          return;
        }

        const isFirstMedia = getVideos().filter((vid: Media) => vid.id !== video.id).length === 0;

        // video will be saved by the parent
        onVideoAdded(video, isFirstMedia);
      })
    }
  }

  const onVideoUploadComplete = (video: Media, newlyUploaded: boolean) => {
    if (newlyUploaded) {
      mixpanel.track('VideoUploaded', {
        'name': video.name,
        // TODO: need to add the signed url
      });
    }

    setVideoUploaded(video.sha256);

    // use the latest videos to prevent stale videos
    const videos = getVideos();
    const selectedIndex = videos.findIndex((vid: Media) => vid.id === video.id) ;
    if (selectedIndex === -1) {
      // this could happen if the user deletes the media while it's loading
      return;
    }

    const newVideos = [...videos];
    newVideos[selectedIndex].uploadComplete = true;
    setVideos(newVideos)
  }

  const handleToggleSound = async () => {

    mixpanel.track('VideoSound', {
      'numberOfVideos': state.videos.length,
    });

    if (selectedVideos.length === 0) {
      return;
    }

    const modifiedVideos = selectedVideos.map((video: Media) => {
      const curVideo = video.deepCopy();
      if (!curVideo.hasAudioComponent || !curVideo.videoRef) {
        // No audio... do nothing
        return curVideo;
      }

      const isCurrentlyMuted = curVideo.isMuted;
      curVideo.isMuted = !isCurrentlyMuted;
      curVideo.videoRef.volume = isCurrentlyMuted ? 1 : 0;
      return curVideo;
    })

    const modifiedIds = modifiedVideos.map((video: Media) => video.id);
    const newVideos = [...state.videos.filter((video: Media) => !modifiedIds.includes(video.id)), ...modifiedVideos];

    const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
    const addVideosCommand = new AddVideoCommand(originalVideos, newVideos, 0, (changedVideos: Video[]) => {
      changedVideos.forEach((video: Media) => {
        if (((video instanceof Video) || (video instanceof Audio)) && video.videoRef) {
          video.videoRef.volume = video.isMuted ? 0 : 1;
        }
      })
      setVideos(changedVideos);
    });

    CommandHistory.push(addVideosCommand);

    setVideos(newVideos);
  }

  const downloadSubtitlesForVideo = (video: Video) => {
    downloadSubtitles(video.sha256).then(({videoName}) => {
      mixpanel.track('Add Subtitles Server Response', {
        'name': videoName
      });
      pollSubtitlesAndDownload(videoName, 1000, async (url: string | null) => {
        if (url) {
          const newVideos = [...state.videos];
          const videoIndex = newVideos.findIndex(vid => vid.id === video.id);

          const curVideo =state.videos[videoIndex];
          if (!(curVideo instanceof Video) && !(curVideo instanceof Audio)) {
            return;
          }

          if (videoIndex !== -1) {
            curVideo.subtitlesLoaded = true;
            curVideo.downloadingSubtitles = false;
            curVideo.subtitlesActive = true;
            curVideo.subtitlesUrl = url;
            newVideos[videoIndex] = curVideo;
            setVideos(newVideos);
          }

          // let the editor know that subtitles were loaded so it can load the and display the subtitles
          onToggleSubtitles(curVideo);

          // Clean up Blob URL when not needed anymore
          window.addEventListener('unload', () => {
            URL.revokeObjectURL(url);
          });
        }
      })
    }).catch((err: any) => {
      console.error(err);
    })
  }

  const handleAddSubtitles = async () => {
    if (selectedVideos.length === 0) {
      return;
    }
    if (!(selectedVideos[0] instanceof Video) as boolean && !(selectedVideos[0] instanceof Audio)) {
      return;
    }

    let newVideos = [...state.videos];

    const selectedIndex = state.videos.findIndex((video: Media) => video.id === selectedVideos[0].id) ;
    const curVideo = selectedVideos[0].deepCopy() as Video | Audio;
    if ((curVideo as Video).subtitlesLoaded) {
      (curVideo as Video).subtitlesActive = !(curVideo as Video).subtitlesActive;

      newVideos[selectedIndex] = curVideo;


      const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
      const addVideosCommand = new AddVideoCommand(originalVideos as Video[], newVideos, selectedIndex, (newVideos: Video[]) => {
        newVideos[selectedIndex].subtitlesActive = !newVideos[selectedIndex].subtitlesActive;
        // TODO: need the ids of the selected elements... otherwise it wont update the selected element (and the visual)
        setVideos(newVideos);
      });

      CommandHistory.push(addVideosCommand);

      setVideos(newVideos);
    } else {
      (curVideo as Video).downloadingSubtitles = true;
      newVideos[selectedIndex] = curVideo;
      setVideos(newVideos, [curVideo.id]);
      downloadSubtitlesForVideo(curVideo as Video);
    }

    onToggleSubtitles(curVideo);
  }

  const onSubtitlesUpload = (file: File) => {

    mixpanel.track('SubtitlesUpload', {
      'numberOfVideos': state.videos.length,
      'name': file.name,
      'size': file.size,
      'type': file.type,
    });

    const selectedIndex = state.videos.findIndex((video: Media) => video.id === selectedVideos[0]?.id) ;

    if (selectedIndex === -1) {
      return;
    }

    const curVideo = state.videos[selectedIndex];

    if (!(curVideo instanceof Video) && !(curVideo instanceof Audio)) {
      return;
    }

    const url = URL.createObjectURL(file);

    getSignedSubtitlesUploadUrl(curVideo.name, file.type, curVideo.sha256).then( async (uploadInformation: presignedUrlResponse) => {
      if (uploadInformation.presignedUrl) {
        const file = await (await fetch(url)).blob();
        // upload the video
        uploadVideo(uploadInformation.presignedUrl, file, file.type, () => {});

        const trackElement = document.createElement('track');
        trackElement.kind = 'subtitles';
        trackElement.label = 'English';
        trackElement.srclang = 'en';
        trackElement.src = url;
        trackElement.default = true;

        // Append the track to the video element
        curVideo.videoRef.appendChild(trackElement);

        // TODO: should wait till the upload is over
        curVideo.subtitlesLoaded = true;
        curVideo.subtitlesActive = !curVideo.subtitlesActive;
        curVideo.subtitlesUrl = url;

        const newVideos = [...state.videos];
        newVideos[selectedIndex] = curVideo;
        setVideos(newVideos);

        // let the editor know that subtitles were loaded so it can load the and display the subtitles
        onToggleSubtitles(curVideo);
      }
    })
  }

  const onSubtitlesDownload = async () => {

    const selectedIndex = state.videos.findIndex((video: Media) => video.id === selectedVideos[0]?.id) ;

    if (selectedIndex === -1) {
      return;
    }

    const curVideo = state.videos[selectedIndex];

    if (!(curVideo instanceof Video) && !(curVideo instanceof Audio)) {
      return;
    }

    mixpanel.track('SubtitlesDownload', {
      'numberOfVideos': state.videos.length,
      'name': curVideo.name,
      // TODO: for cut videos this doesnt include the left cut duration...
      'duration': curVideo.getUnderlyingMediaDuration(),
      'subtitlesUrl': curVideo.subtitlesUrl,
    });

    if (curVideo.subtitlesUrl) {
      const content = await fetch(curVideo.subtitlesUrl as string).then(
        (res) => res.text()
      );
      let fileName;
      if (isWebVTT(content)) {
        fileName = 'subtitles.vtt';
      } else {
        fileName = 'subtitles.ass';
      }
      downloadFromUrl(curVideo.subtitlesUrl, fileName)
    }
  }

  const splitFrameImage = async (frameUrl: string, weight: number) => {
    // Create an image element and load the Blob URL
    const image = new Image();
    image.src = frameUrl;

    return new Promise<[string, string]>((resolve, reject) => {
      image.onload = () => {
        // Create a canvas to manipulate the image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          return reject(new Error('Canvas context not found'));
        }

        // Set canvas width and height to match the image dimensions
        const { width, height } = image;
        canvas.width = width;
        canvas.height = height;

        // Calculate the cut point (weight is the fraction)
        const cutWidth = Math.floor(width * weight);

        // Create two new canvases for the two parts
        const firstCanvas = document.createElement('canvas');
        const secondCanvas = document.createElement('canvas');

        // Set the dimensions for both canvases
        firstCanvas.width = cutWidth;
        firstCanvas.height = height;
        secondCanvas.width = width - cutWidth;
        secondCanvas.height = height;

        const firstCtx = firstCanvas.getContext('2d');
        const secondCtx = secondCanvas.getContext('2d');

        if (!firstCtx || !secondCtx) {
          return reject(new Error('Canvas context not found'));
        }

        // Draw the first part of the image on the first canvas
        firstCtx.drawImage(image, 0, 0, cutWidth, height, 0, 0, cutWidth, height);

        // Draw the second part of the image on the second canvas
        secondCtx.drawImage(image, cutWidth, 0, width - cutWidth, height, 0, 0, width - cutWidth, height);

        // Convert both canvases to Blob URLs
        firstCanvas.toBlob((firstBlob) => {
          if (!firstBlob) return reject(new Error('Failed to create Blob from the first part'));
          const firstBlobUrl = URL.createObjectURL(firstBlob);

          secondCanvas.toBlob((secondBlob) => {
            if (!secondBlob) return reject(new Error('Failed to create Blob from the second part'));
            const secondBlobUrl = URL.createObjectURL(secondBlob);

            // Resolve with both Blob URLs (but no cleanup of Blob URLs)
            resolve([firstBlobUrl, secondBlobUrl]);

            // Cleanup: Simply free up the canvases by resetting their width and height
            firstCanvas.width = 0;
            firstCanvas.height = 0;
            secondCanvas.width = 0;
            secondCanvas.height = 0;

          });
        });
      };

      // Handle errors in loading the image
      image.onerror = reject;
    });
  };

  const handleCut = async (cutSpot: number) => {
    const selectedIndex = state.videos.findIndex((video: Media) => video.id === selectedVideos[0]?.id);

    if (selectedIndex === -1) {
      return;
    }
    const video =state.videos[selectedIndex] as Video;
    const updatedVideos = [...state.videos];

    const videoStart = video.getStart(true);
    const videoEnd = video.getEnd(true);

    mixpanel.track('VideoCut', {
      'numberOfVideos': state.videos.length,
      'name': video.name
    });

    //const cutPositionTime = (cutPosition / numColumns * totalDuration);
    //const cutSpot = Math.round(currentTime.current / totalDuration * numColumns);
    const cutPositionTime = cutSpot / numColumns * totalDuration;

    // If the cutPosition is within the video bounds
    if (cutPositionTime > videoStart && cutPositionTime < videoEnd) {
      let firstPartDuration = +(cutPositionTime - videoStart);
      let secondPartDuration = +(video.getUnderlyingMediaDuration() - firstPartDuration);


      // make sure all the offsets align with the columns perfectly - so we dont have any overlapping parts or gaps
      const firstPartDurationCols = Math.floor(firstPartDuration / totalDuration * numColumns);
      const secondPartStartCols = Math.floor(cutSpot);
      const secondPartDurationCols = Math.floor(secondPartDuration / totalDuration * numColumns);
      firstPartDuration = firstPartDurationCols / numColumns * totalDuration;
      secondPartDuration = secondPartDurationCols / numColumns * totalDuration;
      const secondPartStart = secondPartStartCols / numColumns * totalDuration;


      const numVideoFramesFraction = numFrames * (video.getUnderlyingMediaDuration() / totalDuration);
      const numVideoFrames = Math.ceil(numVideoFramesFraction);

      // TODO: on exact cut this doesnt work... fix it
      // offset the timevalue according to the already cut time of the existing cut frame
      let framesOffset = (firstPartDuration - video.frameCutOffset / numVideoFramesFraction * video.getUnderlyingMediaDuration()) / video.getUnderlyingMediaDuration() * numVideoFramesFraction + Number(video.frameCutOffset!==0);
      const firstPartFramesCount = Math.floor(framesOffset);
      const secondPartFramesCount = Math.floor(numVideoFrames - framesOffset);

      const firstPartFrameFraction = framesOffset - Math.floor(framesOffset);
      const secondPartFrameFraction = 1 - firstPartFrameFraction;

      let firstPartFrame = null;
      let firstPartAudioFrame = null;
      let secondPartFrame = null;
      let secondPartAudioFrame = null;
      if (video.frames && video.frames.length) {
        const [firstVideoUrl, secondVideoUrl] = await splitFrameImage(video.frames[firstPartFramesCount].url, +firstPartFrameFraction);
        firstPartFrame = { ...(video.frames[firstPartFramesCount]), weight: firstPartFramesCount ? firstPartFrameFraction * 10 : 10, url: firstVideoUrl};
        secondPartFrame = { ...(video.frames[firstPartFramesCount]), weight: secondPartFramesCount ? secondPartFrameFraction * 10 : 10, url: secondVideoUrl};
      }
      if (video.audioFrames && video.audioFrames.length) {
        const [firstAudioUrl, secondAudioUrl] = await splitFrameImage(video.audioFrames[firstPartFramesCount].url, firstPartFrameFraction);
        firstPartAudioFrame = { ...(video.audioFrames[firstPartFramesCount]), weight: firstPartFramesCount ? firstPartFrameFraction * 10 : 10, url: firstAudioUrl};
        secondPartAudioFrame = { ...(video.audioFrames[firstPartFramesCount]), weight: secondPartFramesCount ? secondPartFrameFraction * 10 : 10, url: secondAudioUrl};
      }

      // TODO: need to destroy transition on cut or do something with it
      const firstVideo: Video = new Video({
        ...video.deepCopy(),
        id: uuidv4(),
        transitionId: null,
        playOffset: +video.playOffset,
        framesOffset: +video.framesOffset,
        duration: firstPartDuration,
        endBase: 0,
        rightBackgroundOffset: 0,
        rightTrim: 0,
        row: video.row,
        dirty: true,
        frames: video.frames.length ? [...video.frames.slice().splice(0, firstPartFramesCount), firstPartFrame] : [],
        audioFrames: video.audioFrames.length ? [...video.audioFrames.slice().splice(0, firstPartFramesCount), firstPartAudioFrame] : [],
      });

      // TODO: should change time of first and second videos
      const secondVideo: Video = new Video({
        ...video.deepCopy(),
        id: uuidv4(),
        transitionId: null,
        //start: (cutPosition) / numColumns * totalDuration,
        start: secondPartStart,
        // any left truncated or previously cut parts + total duration of first part including left truncated - left truncated = left truncated / cut + total duration of first without cuts
        playOffset: +(video.playOffset + firstPartDuration),
        framesOffset: +(video.framesOffset + firstPartDuration),
        frameCutOffset: secondPartFrameFraction,
        duration: +(secondPartDuration), // TODO: this causes small black spots in video
        startBase: 0,
        leftBackgroundOffset: 0,
        leftTrim: 0,
        row: video.row,
        dirty: true,
        frames: video.frames && video.frames.length ? [secondPartFrame, ...video.frames.slice().splice(firstPartFramesCount+1, secondPartFramesCount)] : [],
        audioFrames: video.audioFrames && video.audioFrames.length ? [secondPartAudioFrame, ...video.audioFrames.slice().splice(firstPartFramesCount+1, secondPartFramesCount)] : [],
        onLoadedCalled: false, // it will go through the loading process
      });

      const fd = await createVideoFd(secondVideo);
      if (fd !== -1) {
        secondVideo.videoFdRef = fd;
        const effectString = secondVideo.getEffectString();
        await Engine.set_effect(fd, secondVideo.scaledWidth as number, secondVideo.scaledHeight as number, effectString)
      }

      const selectedIndex = state.videos.findIndex((video: Media) => video.id === selectedVideos[0]?.id) ;
      updatedVideos[selectedIndex] = firstVideo;
      updatedVideos.push(secondVideo);

      const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
      const resizeCommand = new ResizeVideoCommand(originalVideos as Video[], updatedVideos, (newVideos: Video[]) => {
        setVideos(newVideos)
      });
      CommandHistory.push(resizeCommand);

      // the previously selected video is invalid now
      onLayerSelected(null);
      setVideos(updatedVideos);
    }
  };

  const handleVideoSelect = (event: any, video: Media) => {
    if (event.ctrlKey) {
      onLayerSelected(video as Video, true);
    } else {
      if (state.selectedVideoLayers.length === 1 && state.selectedVideoLayers[0] === video) {
        // if nothing changed no need to update anything
        return;
      }
      onLayerSelected(video as Video, false);
    }
  };

  const handleVideoContainerClick = (event: React.MouseEvent) => {
    const target = event.target as HTMLElement;
    // if pressed on the grid but not on an item
    if (target === gridRef.current?.elementRef?.current || target === gridParentRef.current) {
      onLayerSelected(null);
    }
  };

  const onSkipFrameClicked = (direction: 'forward' | 'backward'): void => {
    if (!isLoadingVideo) {
      handleSkipFrame(direction);
    }
  }

  const handleZoomChange = useCallback((zoom: number) => {
    if (scrollBoxRef.current) {
      const newWidth = Math.round(Math.max(scrollBoxRef.current.clientWidth * zoom, scrollBoxRef.current.clientWidth));

      zoom = newWidth / scrollBoxRef.current.clientWidth;

      const location = currentTime.current / totalDuration * newWidth - (scrollBoxRef.current.clientWidth / 2);
      containerRef.current.style.width = `${newWidth}px`;
      scrollBoxRef.current.scrollLeft = scrollBoxRef.current.scrollLeft / containerRef.current.scrollWidth * newWidth;
      scrollBoxRef.current.scrollLeft = location;
      setZoomValue(zoom);
      setGridWidth(newWidth);
    }
  }, [setZoomValue, setGridWidth, totalDuration]);

 const handleContextMenu = (event: React.MouseEvent, media: Media | null) => {
    event.preventDefault();
    setMenuPosition({
      mouseX: event.clientX,
      mouseY: event.clientY,
    });
    onLayerSelected(media as Video);

    setMenuOpenConfig({
      isOpen: true,
      shouldActivateSubtitlesDownload: !!(media && (media as Video).subtitlesUrl?.length),
      shouldActivateCopy: (media !== null) && selectedVideos.length !== 0,
      shouldActivatePaste: getCopyBuffer().length > 0,
      shouldActivateDuplicate: media !== null,
      shouldActivateDelete: media !== null,
    });
  };

  const debouncedMediaUpload = useCallback(
      debounce((name: string, type: string, sha256: string, blobUrl: string) => {
        getSignedUploadUrl(name, type, sha256).then( async (uploadInformation: { generatedName: string, url: string}) => {
          if (uploadInformation.url) {
            const file = await (await fetch(blobUrl)).blob();
            // upload the video
            uploadVideo(uploadInformation.url, file, file.type, () => {});
          }
        })
      }, 2000),
      []
  );

  const pushNewMedia = (mediaArray: Media[], media: Media): Media[] => {
    media.row = 0;
    const newVideos = mediaArray.map(vid => {
      vid.row += 1;
      return vid;
    });
    return [...newVideos, media];
  }

  const onTextCreated = useCallback(async (text: string, style: React.CSSProperties): Promise<void> => {
    return new Promise(async (resolve) => {
      const row = getVideos().reduce((max: number, v: Media) => v.row > max ? v.row : max, -1) + 1;
      const canvas = document.createElement('canvas');
      const padding = 20;
      const scaleFactor = canvasState.canvasHeight / 640;
      const [image, textWidth, textHeight] = await createTextImage({
        canvas,
        text,
        style,
        scaleFactor: scaleFactor,
        hPadding: padding,
        vPadding: padding
      });
      fetch(image).then(async (res) => {
        const imageBlob = await res.blob();
        const duration = totalDuration ? 0.25 * totalDuration : 10;
        const textComponent: TextMedia = new TextMedia({name: "Text", text: text, videoRef: canvas, duration, start: Math.max(currentTime.current-0.01, 0), leftBackgroundOffset: 0, rightBackgroundOffset: 0,
          startBase: 0, endBase: 0, isResizing: false, row: row, framesOffset: 0, playOffset: 0,
          effects: [], dirty: false, height: textHeight, width: textWidth, url: image, type: imageBlob.type, sha256: uuidv4(), // each text media has a unique uuidv4 which identifies it to the server and locally
          scaledHeight: textHeight, scaledWidth: textWidth, baseWidth: 0, baseHeight: 0, style: {...style}, textWidth, textHeight, hPadding: padding, vPadding: padding, scaleFactor,
          x: (canvasState.canvasWidth - textWidth) / 2, y: (canvasState.canvasHeight - textHeight) / 2, rotation: 0, crop: {left: 0, right: 0, top: 0, bottom: 0}});

          let [newTextComponent, _, __] = await applyStyleToTextComponent({
            textComponent,
            text: textComponent.text,
            style: textComponent.style,
            width: textComponent.scaledWidth as number,
            height: textComponent.scaledHeight as number,
            hPadding: textComponent.hPadding,
            vPadding: textComponent.vPadding,
            scaleFactor: canvasState.canvasHeight / 640,
            calculateHeight: false,
            calculateWidth: false
          })

          debouncedMediaUpload(newTextComponent.name, newTextComponent.type, newTextComponent.sha256, newTextComponent.url);

          const newVideos = pushNewMedia(getVideos(), newTextComponent);

          const originalVideos = getVideos().map((vid: Media) => vid.deepCopy());
          const addVideosCommand = new AddVideoCommand(originalVideos as Video[], newVideos, newVideos.length-1, (newVideos: Video[]) => {
            setVideos(newVideos);
          });

          CommandHistory.push(addVideosCommand);


          setVideos(newVideos, [textComponent.id]);

          if (newVideos.length === 1) {
            // if it's the only media - set the duration to the media duration
            setTotalDuration(textComponent.getPlayDuration());
          }
          // select the Text element
          resolve();
      });
    })
  }, [canvasState, debouncedMediaUpload, getVideos, setTotalDuration, setVideos, totalDuration]);

  const getBetweenBoxes = (layout: Layout[]): Layout[] => {
    // split to rows
    const videoRows: {[key: number]: Layout[]} = {};
    layout.forEach((l: Layout) => {
      if (l.i === '__dropping-elem__' || l.i.startsWith('box_')) {
        return;
      }
      if (!videoRows[l.y]) {
        videoRows[l.y] = [];
      }
      videoRows[l.y].push(l);
    })

    const boxes = Object.entries(videoRows).map(([row, rowVideos]) => {
      const sortedVideos = rowVideos.sort((vidA, vidB) => vidA.x - vidB.x);

      return sortedVideos.slice(0, -1).map((video: Layout, index: number) => {
        const nextVideo = sortedVideos[index+1];
        const itemW = nextVideo.x - (video.x + video.w) - 1;
        if (!itemW) {
          return undefined;
        }
        const itemX = video.x + video.w +1;
        const newItem: Layout = {
          i: `box_${row}_${index}`,
          x: itemX,
          y: video.y,
          w: itemW,
          h: 1,
        }
        return newItem;
      }).filter((l) => l?.w) as Layout[];
    }).flat();

    return boxes;
  }

  const renderBetweenBoxes = () => {
    const boxes = getBetweenBoxes(gridLayout);

    return boxes.map((box: any) => (
      <Box
        onDrop={(event: React.DragEvent) => handleItemDrop(box, event)}
        key={box.i}
        data-grid={{
          ...box,
          x: Math.floor(box.x),
          w: Math.floor(box.w),
          h: 1,
          minH: 1,
          isDraggable: false,
        }}
        sx={{
          backgroundColor: 'rgba(9, 227, 227, 0.1)', // Light cyan overlay
          border: '2px dashed #09e3e3', // Cyan dashed border for placeholder
          width: '100%',
          height: '100%',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          transition: 'transform 0.2s ease',
          '&:hover': {
            transform: 'scale(1.05)',
            borderColor: 'rgba(9, 227, 227, 0.8)', // Darker cyan on hover
          }
        }}
      >
        {/* Animated Left-Pointing Arrow */}
        <Box
          sx={{
            position: 'absolute',
            right: '5px', // Position arrow on the right side
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '24px',
            color: '#09e3e3',
            animation: 'shiftLeft 0.8s infinite alternate', // Animation for shifting left
          }}
        >
          ⬅️
        </Box>

        {/* Center Text Instruction */}
        <Typography
          variant="body2"
          sx={{
            color: '#09e3e3',
            textAlign: 'center',
            fontWeight: 'bold',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            padding: '2px 8px',
            borderRadius: '4px',
            zIndex: 2, // Keep on top
          }}
        >
          Drop transition here
        </Typography>

        {/* Animation Keyframes for Arrow Shifting Left */}
        <style>
          {`
            @keyframes shiftLeft {
              0% { transform: translateY(-50%) translateX(0); }
              100% { transform: translateY(-50%) translateX(-5px); }
            }
          `}
        </style>
      </Box>
    ));
  };

  const handleClose = () => {
    setMenuOpenConfig({...menuOpenConfig, isOpen: false});
  };

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      calculateColumnWidth();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    // Add event listeners to the resize handles
    const leftHandles = document.querySelectorAll('.react-resizable-handle-w');
    leftHandles.forEach((handle: any) => {
        handle.addEventListener('mousedown', () => setActiveHandleDirection('w'));
        handle.addEventListener('touchstart', () => setActiveHandleDirection('w'));
    });

    const rightHandles = document.querySelectorAll('.react-resizable-handle-e');
    rightHandles.forEach((handle: any) => {
        handle.addEventListener('mousedown', () => setActiveHandleDirection('e'));
        handle.addEventListener('touchstart', () => setActiveHandleDirection('e'));
    });

    window.addEventListener('dragenter', handleDragEnter);
    window.addEventListener('drop', handleDrop);


    return () => {
      window.removeEventListener('resize', calculateColumnWidth);
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('drop', handleDrop);
    };
  }, [gridLayout, selectedVideos, calculateColumnWidth, handleDragEnter]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollBoxRef.current) {
        const scrollPercent = scrollBoxRef.current.scrollLeft / scrollBoxRef.current.scrollWidth;
        setSliderScrollPos(scrollPercent);
      }
    }

    const outerBox = scrollBoxRef.current;
    if (outerBox) {
      outerBox.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (outerBox) {
        outerBox.removeEventListener('scroll', handleScroll);
      }
    };
  }, [])

  useEffect(() => {
    // We keep the current video index on the selectedVideoIndex because we need to have this updated in real time
    // as some of the other components (EffectSelector for example) can trigger a function of our component when they
    // are already aware of the new videoIndex... If we use a separate state to set index it may come too late.
    // We need to force any UI components that depend on a new selection to also re-render so we force it here
    forceUpdate();
  }, [selectedVideos])

  // Expose these methods as we need the EffectSelector to call these when it's a non-mobile
  // layout. in that case the EffectSelector is outside of the component...
  useImperativeHandle(ref, () => ({
    handleAddSubtitles,
    onSubtitlesUpload,
    onTextCreated,
    onAddMedia
  }));

  return (
    <Box sx={{userSelect: 'none',height: '100%', width: '100%', padding: 0, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'start', overflowX: 'visible', overflowY: 'visible'}}>
      <VideoEditorControls
        maxZoom={totalDuration > 200 ? totalDuration / 30 : totalDuration}
        isMobileLayout={isMobile}
        subtitlesButtonType={(((selectedVideos[0] instanceof Video) as any || selectedVideos[0] instanceof Audio) && (selectedVideos[0] as Video).downloadingSubtitles ?
          'download' :
          (((selectedVideos[0] instanceof Video) as any || selectedVideos[0] instanceof Audio)) && (selectedVideos[0] as Video).subtitlesActive ? 'remove' : 'add')}
        volumeButtonType={(((selectedVideos[0] instanceof Video) as any || selectedVideos[0] instanceof Audio) && (selectedVideos[0] as Video).isMuted) ? 'add' : 'remove'}
        shouldEnableVideoActions={selectedVideos[0] !== null}
        shouldEnableCutAction={
          !isRunning &&
          (selectedVideos.length === 1) &&
          ((selectedVideos[0] instanceof Video) as any || selectedVideos[0] instanceof Audio) &&
          selectedVideos[0]?.onLoadedCalled &&
          selectedVideos[0]?.uploadComplete
        }
        shouldEnableDeleteAction={(selectedVideos.length > 0) && !isRunning}
        shouldEnableSubtitlesAction={
          (selectedVideos.length === 1) &&
          ((selectedVideos[0] instanceof Video) as any || selectedVideos[0] instanceof Audio) &&
          selectedVideos[0]?.hasAudio() &&
          selectedVideos[0]?.onLoadedCalled &&
          selectedVideos[0]?.uploadComplete &&
          (selectedVideos[0] as Video)?.subtitlesLoaded
        }
        shouldEnableAudioAction={(selectedVideos.length > 0) && selectedVideos[0]?.hasAudio() && !(selectedVideos[0] instanceof Audio)}
        handleCut={() => handleCut(Math.round((currentTime.current / totalDuration) * numColumns))}
        handleDelete={onDelete}
        handleZoomChange={handleZoomChange}
        handleRemoveSound={handleToggleSound}
        handleAddSubtitles={handleAddSubtitles}
        handlePlayPause={handlePlayPause}
        handleSkipFrame={onSkipFrameClicked}
      />
    <Box sx={{minHeight: '40px', height: '40px', pointerEvents: 'none', borderBottom: '1px solid #ddd' }}>
      <FrameSlider
        onChange={onSliderChange}
        min={zoomValue * gridWidth}
        totalDuration={totalDuration || 60}
        maxSliderOffset={maxSliderOffset}
        step={totalDuration / numColumns} // Adjust the step based on how fine-grained ou want the control
        numMarkers={(scrollBoxRef.current?.clientWidth || 400) / 50}
        visibleDuration={fullViewDuration}
        scrollPos={sliderScrollPos}
        sliderHeight={1000} // large height to let it overflow from bottom (hidden overflow stops it from affecting layout)
        paddingLeft={gridRef.current ? Math.max(0, ((gridRef.current?.elementRef.current.getBoundingClientRect().left || 0) - (scrollBoxRef.current?.getBoundingClientRect().left || 0))) : layersPadding}
        paddingRight={gridRef.current ? Math.max(0, (scrollBoxRef.current?.getBoundingClientRect().right || 0) - (gridRef.current?.elementRef.current.getBoundingClientRect().right || 0)) : layersPadding}
        aria-labelledby="video-slider"
      />
    </Box>
    <Box sx={{height: '100%', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'start', overflowX: 'hidden', overflowY: 'auto'}}>
      <Box ref={scrollBoxRef} sx={{ height: '100%', overscrollBehavior: 'none', overflowX:'auto', overflowY: 'auto'}}>
        <Box
            ref={containerRef}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(event: React.DragEvent<HTMLDivElement>) => {
              event.preventDefault();
              onAddMedia(event.dataTransfer.files[0])
            }}
            sx={{
                display: 'flex',
                userSelect: 'none',
                flexDirection: 'column',
                justifyContent: 'space-around',
                width: gridWidth || '100%',
                height: '100%',
                overflowY: 'hidden',
                overflowX: 'auto',
            }}
        >
          <AutoScrollContainer scrollRef={scrollBoxRef} shouldScroll={isDraggingVideoTrack}/>
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, backgroundColor: "#ffffff", overflowY: 'hidden', overflowX: 'hidden'}}>
            <Box
              ref={gridParentRef}
              onContextMenu={(event: React.MouseEvent) => event.preventDefault()}
              onMouseDown={handleVideoContainerClick}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                paddingLeft: `${layersPadding}px`,
                paddingRight: `${layersPadding}px`,
                overflowX: 'hidden',
                overflowY: 'auto',

                }}
              >
                {(state.videos.length === 0) ? (
                  <MediaAddComponent onAddMedia={onAddMedia} isMobile={isMobile}/>
                ) : (
              <ResponsiveGridLayout
                ref={gridRef}
                className="layout"
                cols={numColumns}
                rowHeight={60}
                preventCollision={true}
                resizeHandles={["e", "w"]}
                compactType={null}
                isDraggable={true}
                margin={[0, 8]}
                onDrag={handleDrag}
                onDragStart={handleDragStart}
                onDragStop={handleDragStop}
                onResizeStop={handleResizeStop}
                onResize={handleResize}
                onResizeStart={handleResizeStart}
                onLayoutChange={handleLayoutChange}
                style={{
                  position:'relative',
                  //height: '100%',
                  // TODO: this is not updated on resize of the resizable box - need to listen to updates or something and set grid height variable
                  height: gridParentRef.current.scrollHeight,
                  width: gridWidth || '100%',
                  backgroundImage: `repeating-linear-gradient(
                      to bottom,
                      white,              /* 2px white space */
                      white 8px,
                      rgba(240, 240, 240, 0.7) 8px,  /* Solid gray track */
                      rgba(240, 240, 240, 0.7) 68px, /* 60px track height */
                      white 68px,         /* 2px white space after track */
                      white 76px          /* End of one "track" unit */
                  )`,
                  backgroundSize: `100% 68px`, // Repeat every 60px
                }}
                draggableHandle=".drag-handle"
              >
                {state.videos.map((video: Media, index: number) => (
                  <Box
                    onContextMenu={(event: React.MouseEvent) => handleContextMenu(event, video)}
                    className={`drag-handle video-track ${video.isResizing ? 'resizing' : ''} ${selectedVideos.some((vid) => vid.id === video.id) ? 'selected' : 'drag-handle'}`}
                    key={video.id}
                    data-grid={{
                      i: `${video.id}`,
                      x: video.start / totalDuration * numColumns, // make sure we dont have x at a fraction of column
                      y: video.row,
                      w: video.getPlayDuration() / totalDuration * numColumns,
                      h: 1,
                      minW: minGridItemPixelSize / columnWidth,
                      maxW: video instanceof Video ? (video.getUnderlyingMediaDuration() / totalDuration) * numColumns : numColumns,
                      resizeHandles: (video instanceof Transition) ? [] : ['e', 'w'],
                      isBounded: true,
                      isDraggable: !(video instanceof Transition) && (isMobile ? selectedVideos[0]?.id === video.id : true) // for mobile, only allow dragging once selected. for desktop can drag at all times
                    }}
                    sx={{
                      overflow: video.isResizing ? 'visible' : 'hidden',
                    }}
                  >
                    <div
                      //onClick={(event: any) => handleVideoSelect(event, index, video)}
                      onTouchStart={(event: any) => handleVideoSelect(event, video)}
                      onMouseDown={(event: any) => handleVideoSelect(event, video)}
                    >
                      <VideoFramesComponent
                        video={video}
                        numFrames={video instanceof TextMedia ? Math.ceil((video.duration / totalDuration * numColumns * columnWidth) / 3000) || 1 : Math.ceil(numFrames * (video.getUnderlyingMediaDuration() / fullViewDuration))}
                        leftBackgroundOffset={video.getLeftBackgroundOffset() * columnWidth}
                        rightBackgroundOffset={video.getRightBackgroundOffset() * columnWidth}
                        size={(video.getUnderlyingMediaDuration() / totalDuration) * numColumns * columnWidth}
                        hasVideoComponent={video.hasVideo()}
                        hasAudioComponent={video.audioEnabled()}
                        onLoadedVideo={(vid: Video, frames: any[], audioFrames: any[]) => onLoadedVideo(vid, frames, audioFrames, index)}
                        onVideoUploadComplete={onVideoUploadComplete}
                        preloadedFrames={video.frames.length ? video.frames : undefined}
                        preloadedAudioFrames={video.audioFrames.length ? video.audioFrames : undefined}
                      />
                    </div>
                  </Box>
                ))}
                {isDroppingTransition && renderBetweenBoxes()}
              </ResponsiveGridLayout>
              )}
            </Box>
          </Box>
          <video ref={videoRef} style={{ display: 'none' }} />
          {videoFileLoadingInfo && (
          <Box sx={{ position: 'absolute', bottom: isMobile ? 0 : 0, left: 0, zIndex: 1, width: '100%', background: '#F5F5F5' }}>
            <Typography variant="body2" align="left">Loading {videoFileLoadingInfo.name} ({(videoFileLoadingInfo.size / (1024 * 1024)).toFixed(2)} MB)</Typography>
            <LinearProgress variant="indeterminate" />
          </Box>
          )}
          <RightClickMenu
            openConfig={menuOpenConfig}
            position={menuPosition}
            onClose={handleClose}
            onDownloadSubtitles={onSubtitlesDownload}
            onDelete={onDelete}
            onDuplicate={onDuplicate}
            onCopy={onCopy}
            onPaste={onPaste}
          />
          {isDragging && (
            <div
              ref={dragRef}
              onDragLeave={handleDragLeave}
              className="drop-overlay"
            >
              <div className="drop-content" style={{pointerEvents: 'none'}}>
                <CloudUpload className="upload-icon" style={{fontSize: 120, pointerEvents: 'none'}} />
                <h2 className="drop-title" style={{pointerEvents: 'none'}}>Drop your file here</h2>
                <p className="drop-subtitle" style={{pointerEvents: 'none'}}>or drag and drop anywhere on the screen</p>
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Box>
      <Box sx={{width: '100%', borderTop: '1px solid #ddd' }}>
        {isMobile && (
        <EffectSelector
          isDisabled={false} // this component is active only on mobile, where the menu is closed on load so no need to disable it
          videoStart={(selectedVideos.length !== 0) ? selectedVideos[0].start : null}
          videoEnd={(selectedVideos.length !== 0) ? selectedVideos[0].getEnd() : null}
          selectedVideo={(selectedVideos.length !== 0) ? selectedVideos[0] : null}
          canvasBackgroundColor={canvasBackgroundColor}
          onSelect={onEffectSelected}
          onSelectTransition={onTransitionSelected}
          onAddMedia={onAddMedia}
          onMediaChanged={onMediaChanged}
          onChangeBackground={onChangeBackground}
          onTextCreated={onTextCreated}
          onTextStyleChanged={onTextStyleChanged}
          onSubtitlesGenerate={handleAddSubtitles}
          onSubtitlesUpload={onSubtitlesUpload}
          onSubtitlesStyleChanged={onSubtitlesStyleChanged}
          isMobileLayout={isMobile} />) }
      </Box>
    </Box>
  );
});

export {
  type CanvasDimensionType
};

export default VideoLayers;
