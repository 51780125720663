import { v4 as uuidv4 } from 'uuid';
import 'reflect-metadata';

const SERIALIZABLE_FOR_RENDER_KEY = Symbol('serializable');
const NON_SERIALIZABLE_KEY = Symbol('nonserializable');

function SerializeForRender() {
  return function (target: Object, propertyKey: string | symbol) {
    const existingFields = Reflect.getMetadata(SERIALIZABLE_FOR_RENDER_KEY, target) || [];
    Reflect.defineMetadata(SERIALIZABLE_FOR_RENDER_KEY, [...existingFields, propertyKey], target);
  };
}

function nonSerializable() {
  return function (target: Object, propertyKey: string | symbol) {
    const existingFields = Reflect.getMetadata(NON_SERIALIZABLE_KEY, target) || [];
    Reflect.defineMetadata(NON_SERIALIZABLE_KEY, [...existingFields, propertyKey], target);
  };
}

type MediaType = 'video' | 'audio' | 'image' | 'text' | 'subtitles' | 'transition';

type Crop = {
  left: number,
  right: number,
  top: number,
  bottom: number
}

export default abstract class Media {
    @SerializeForRender()
    public id: string;
    public name: string;
    @SerializeForRender()
    public sha256: string;
    @SerializeForRender()
    public type: string;
    public abstract mediaType: MediaType;
    public url: string;
    @nonSerializable()
    public videoRef: any;
    public row: number;
    @SerializeForRender()
    public start: number;
    public leftBackgroundOffset: number;
    public rightBackgroundOffset: number;
    public startBase: number;
    public endBase: number;
    public rightTrim: number = 0;
    public leftTrim: number = 0;
    @SerializeForRender()
    public duration: number;
    public isResizing: boolean;
    public dirty: boolean;
    @SerializeForRender()
    public playOffset: number;
    public framesOffset: number;
    public frameCutOffset: number;
    @nonSerializable()
    public onLoadedCalled: boolean;
    public effects: string[];
    public height: number;
    public width: number;
    public aspectRatio: number;
    public scaledHeight: number | null;
    public scaledWidth: number | null;
    public baseHeight: number;
    public baseWidth: number;
    @SerializeForRender()
    public x: number;
    @SerializeForRender()
    public y: number;
    public rotation: number;
    public crop: Crop;
    public resizable: boolean;
    public cropable: boolean;
    @nonSerializable()
    public frames: any[];
    @nonSerializable()
    public audioFrames: any[];
    public uploadComplete: boolean;
    @SerializeForRender()
    public speed: number;
    @SerializeForRender()
    public hasVideoComponent: boolean;
    @SerializeForRender()
    public hasAudioComponent: boolean;
    public transitionId: string | null;

    constructor ({
        id = uuidv4(),
        name,
        sha256,
        url,
        type,
        row,
        start,
        leftBackgroundOffset,
        rightBackgroundOffset,
        startBase,
        endBase,
        videoRef,
        duration,
        isResizing = false,
        dirty = false,
        playOffset = 0,
        framesOffset = 0,
        frameCutOffset = 0,
        onLoadedCalled = false,
        effects = [],
        height,
        width,
        scaledHeight = null,
        scaledWidth = null,
        baseHeight = 0,
        baseWidth = 0,
        x,
        y,
        rotation,
        crop,
        resizable,
        cropable,
        frames = [],
        audioFrames = [],
        uploadComplete = false,
        speed = 1,
        hasVideoComponent,
        hasAudioComponent,
        transitionId = null,
        leftTrim = 0,
        rightTrim = 0,
    }: {
        id?: string,
        name: string,
        sha256: string;
        url: string;
        type: string;
        row: number,
        start: number,
        leftBackgroundOffset: number,
        rightBackgroundOffset: number,
        startBase: number,
        endBase: number,
        videoRef: any;
        duration: number,
        isResizing: boolean,
        dirty: boolean,
        playOffset?: number;
        frameCutOffset?: number,
        framesOffset?: number;
        onLoadedCalled?: boolean;
        effects?: string[];
        height: number;
        width: number;
        scaledHeight: number | null;
        scaledWidth: number | null;
        baseHeight: number;
        baseWidth: number;
        x: number;
        y: number;
        rotation: number;
        crop: Crop;
        resizable: boolean;
        cropable: boolean;
        frames?: any[];
        audioFrames?: any[];
        uploadComplete?: boolean;
        speed: number;
        hasVideoComponent: boolean;
        hasAudioComponent: boolean;
        transitionId?: string | null;
        leftTrim?: number;
        rightTrim?: number;
    }) {
        this.id = id ? id : uuidv4();
        this.name = name;
        this.sha256 = sha256;
        this.url = url;
        this.type = type;
        this.row = row;
        this.start = start;
        this.leftBackgroundOffset = leftBackgroundOffset;
        this.rightBackgroundOffset = rightBackgroundOffset;
        this.startBase = startBase;
        this.endBase = endBase;
        this.videoRef = videoRef;
        this.duration = duration;
        this.isResizing = isResizing;
        this.dirty = dirty;
        this.framesOffset = framesOffset;
        this.frameCutOffset = frameCutOffset;
        this.playOffset = playOffset;
        this.onLoadedCalled = onLoadedCalled;
        this.effects = effects;
        this.height = height;
        this.width = width;
        this.aspectRatio = width / height;
        this.scaledHeight = scaledHeight;
        this.scaledWidth = scaledWidth;
        this.baseHeight = baseHeight;
        this.baseWidth = baseWidth;
        this.x = x;
        this.y = y;
        this.rotation = rotation;
        this.crop = {... crop};
        this.resizable = resizable;
        this.cropable = cropable;
        this.frames = frames;
        this.audioFrames = audioFrames;
        this.uploadComplete = uploadComplete;
        this.speed = speed;
        this.hasVideoComponent = hasVideoComponent;
        this.hasAudioComponent = hasAudioComponent;
        this.transitionId = transitionId;
        this.leftTrim = leftTrim;
        this.rightTrim = rightTrim;
    }

    public getEndBase = (): number => {
      return this.endBase;
    }

    public setEndBase = (value: number) => {
      this.endBase = value;
    }

    public getStartBase = (): number => {
      return this.startBase;
    }

    public setStartBase = (value: number) => {
      this.startBase = value;
    }

    public getLeftBackgroundOffset = () => {
      return this.leftBackgroundOffset;
    }

    public setLeftBackgroundOffset = (value: number) => {
      this.leftBackgroundOffset = value;
    }

    public getRightBackgroundOffset = () => {
      return this.rightBackgroundOffset;
    }

    public setRightBackgroundOffset = (value: number) => {
      this.rightBackgroundOffset = value;
    }

    public setPlaybackSpeed = (value: number) => {
      const speedChange = this.speed / value;
      this.speed = value;
      this.duration = this.getUnderlyingMediaDuration() / speedChange;
      this.playOffset /= speedChange;
      this.startBase /= speedChange;
      this.endBase /= speedChange;
      this.leftTrim /= speedChange;
      this.rightTrim /= speedChange;
      this.leftBackgroundOffset /= speedChange;
      this.rightBackgroundOffset /= speedChange;
    }

    public getRightTrim = () => {
      return this.rightTrim;
    }

    public setRightTrim = (value: number) => {
      this.rightTrim = value;
    }

    public getLeftTrim = () => {
      return this.leftTrim;
    }

    public setLeftTrim = (value: number) => {
      this.leftTrim = value;
    }

    public getUnderlyingMediaDuration = () => {
      return this.duration;
    }

    public getPlayDuration = (): number => {
      return (this.duration - this.leftTrim - this.rightTrim)// / this.speed;
    }

    public getStart = (includeTrim: boolean = false): number => {
      return includeTrim ? this.start - this.leftTrim : this.start;
    }

    public getEnd = (includeTrim: boolean = false): number => {
      return includeTrim ? this.start + this.getPlayDuration() + this.getRightTrim() : this.start + this.getPlayDuration();
    }

    public getPlayStart = (speedNorm=true): number => {
      return (this.playOffset + this.getLeftTrim()) * (speedNorm ? this.speed : 1);
    }

    public getPlayEnd = (): number => {
      return (this.getPlayStart() + this.getPlayDuration()) * this.speed;
    }

    public getPlayOffset = (globalTime: number): number => {
      if (globalTime < this.start) {
        return this.getPlayStart();
      }

      if (globalTime > this.getEnd()) {
        return this.getPlayEnd();
      }

      return (globalTime - this.start + this.playOffset + this.leftTrim) * this.speed;
    }

    public static async unSerialize(serializedMedia: any, scaleFactor: number): Promise<any> {
      const Video = await require('./Video');
      const Audio = await require('./Audio');
      const TextMedia = await require('./Text');
      const ImageMedia = await require('./Image');
      const Transition = await require('./Transition');
      switch (serializedMedia.mediaType) {
        case 'audio':
          return await Audio.default.fromSerialized(serializedMedia);
        case 'video':
          return await Video.default.fromSerialized(serializedMedia);
        case 'text':
          return await TextMedia.default.fromSerialized(serializedMedia, {scaleFactor: scaleFactor});
        case 'image':
          return await ImageMedia.default.fromSerialized(serializedMedia);
        case 'transition':
          return await Transition.default.fromSerialized(serializedMedia);
        default:
          break;
      }
      return null;
    }

    public static async fromSerialized(serializedMedia: any, options?: any): Promise<any> {
      throw new Error("fromSerialized method not implemented");
    }

    abstract getEffectString(prependVideoNormalization: boolean, addRotation: boolean): string;
    abstract requiresWasm() : boolean;
    abstract hasVideo() : boolean;
    abstract hasAudio() : boolean;
    abstract audioEnabled() : boolean;
    abstract deepCopy() : Media;
    abstract serialize() : any;
    abstract serializeForRender() : any;
}

export {
    type Crop,
    type MediaType,
    nonSerializable,
    SerializeForRender,
    NON_SERIALIZABLE_KEY,
    SERIALIZABLE_FOR_RENDER_KEY
}
