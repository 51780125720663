import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, Card, CircularProgress, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ChatIcon from '@mui/icons-material/Chat';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import { captureVideoFrame, createVideoFd, uploadVideo } from '../utils/utils';
import { getSignedUploadUrl } from '../api/ServerApi';
import { useTheme } from '@mui/material/styles';
import Video from './media/Video';
import Audio from './media/Audio';
import ImageMedia from './media/Image';
import Media from './media/Media';
import TextMedia from './media/Text';
import { Frame } from './Frame';
import getEngine from './EffectsEngine';
import { blobUrlToUint8Array } from '../utils/utils';
import Transition from './media/Transition';

const Engine = await getEngine();

interface Frame {
  weight: number;
  url: string;
  index: number;
  time: number;
  img: HTMLImageElement | null;
}

interface VideoFramesComponentProps {
  video: Media;
  numFrames: number;
  leftBackgroundOffset: number;
  rightBackgroundOffset: number;
  size: number;
  hasVideoComponent: boolean;
  hasAudioComponent: boolean;
  preloadedFrames?: any[];
  preloadedAudioFrames?: any[];
  onLoadedVideo: (video: Video, frames: any[], audioFrames: any[]) => void;
  onVideoUploadComplete: (video: Media, newlyUploaded: boolean) => void;
}

const VideoFramesComponent: React.FC<VideoFramesComponentProps> = ({
  video,
  numFrames,
  leftBackgroundOffset,
  rightBackgroundOffset,
  size,
  hasVideoComponent,
  hasAudioComponent,
  preloadedFrames,
  preloadedAudioFrames,
  onLoadedVideo,
  onVideoUploadComplete,
}) => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState<boolean>(preloadedAudioFrames ? false : true);
  const [initialNumFrames, setInitialNumFrames] = useState<number>(preloadedFrames?.length || Math.min(numFrames, 20));
  const [frames, setFrames] = useState<any[]>(preloadedFrames || []);
  const [audioFrames, setAudioFrames] = useState<any[]>(preloadedAudioFrames || []);
  const [hasAudio, setHasAudio] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const videoRef = useRef<any>(null);
  const fromVideo = useRef<any>(null);
  const toVideo = useRef<any>(null);
  const canvasRef = useRef<any>(null);
  const videoFrameHeight = 40;
  const audioFrameHeight = 20;
  const numFrequencyBins = 128;

  const videoCanvasRefs = useRef<HTMLCanvasElement[]>([]);
  const audioCanvasRefs = useRef<HTMLCanvasElement[]>([]);

  // Calculate the number of canvases based on the total size
  const maxCanvasWidth = 20000; // Maximum width for each canvas (you can adjust this)
  const numCanvases = Math.ceil(size / maxCanvasWidth);
  const canvasWidth = size / numCanvases;

  // State to hold cached images
  const [cachedImages, setCachedImages] = useState<Frame[]>([]);
  const [cachedAudioImages, setCachedAudioImages] = useState<Frame[]>([]);

  const createVideoFdIfNotExists = async (vid: Video) => {
    if (vid.videoFdRef === null) {
      return await createVideoFd(vid);
    }
    return -1;
  };

  const createTextTrackOverlay = useCallback(async (
    ctx: CanvasRenderingContext2D,
    text: string,
    style: React.CSSProperties,
    canvasWidth: number
  ): Promise<string> => {
    return new Promise((resolve) => {
      canvasRef.current.width = canvasWidth;
      canvasRef.current.height = videoFrameHeight + audioFrameHeight;
      const width = canvasRef.current!.width;
      const height = canvasRef.current!.height;

      // Set the background color if provided in style, otherwise default to grey
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = style.backgroundColor || '#FFFFFF'; // Use backgroundColor from style or default to light grey
      ctx.fillRect(0, 0, width, height);

      // Top Part (Black Text on White Background)
      ctx.font = `${style.fontStyle || 'normal'} ${style.fontWeight || 'normal'} ${
        style.fontSize || '24px'
      } ${style.fontFamily || 'Arial'}`;
      ctx.fillStyle = '#000000'; // Black text color

      // Truncate text if too long
      const truncateText = (
        ctx: CanvasRenderingContext2D,
        text: string,
        maxWidth: number
      ): string => {
        let truncated = text;
        let textWidth = ctx.measureText(truncated).width;

        while (textWidth > maxWidth && truncated.length > 0) {
          truncated = truncated.slice(0, -1); // Remove last character
          textWidth = ctx.measureText(truncated + '...').width; // Measure with ellipsis
        }
        return truncated.length < text.length ? truncated + '...' : truncated; // Append ellipsis if truncated
      };

      const truncatedText = truncateText(ctx, text, width - 20); // Leave small margin
      const topTextWidth = ctx.measureText(truncatedText).width;
      const topTextX = (width - topTextWidth) / 2; // Center horizontally
      const topTextY = height / 2; // Center vertically

      ctx.fillText(truncatedText, topTextX, topTextY);

      // Bottom Overlay (White Text on Primary Background)
      ctx.fillStyle = theme.palette.primary.main;
      ctx.fillRect(0, height - 20, width, 20);

      ctx.font = 'bold 20px Arial';
      ctx.fillStyle = '#FFFFFF';
      const label = 'Text';
      const labelWidth = ctx.measureText(label).width;
      const labelX = (width - labelWidth) / 2;
      const labelY = height - 3;

      ctx.fillText(label, labelX, labelY);

      // Convert the canvas to a data URL
      const frame = canvasRef.current!.toDataURL('image/png');
      resolve(frame);
    });
  }, [theme.palette.primary.main]);

  const loadTextComponent = useCallback((
    ctx: any,
    text: string,
    style: any,
    width: number,
    callOnLoaded: boolean
  ) => {
    createTextTrackOverlay(ctx, text, { ...style, fontSize: '32px' }, width / numFrames).then((overlay) => {
      const newFrames = Array.from({ length: numFrames }, (_, i) => {return { weight: 1, url: overlay as string, index: 0, time: 0 }});

      setFrames(newFrames);
      setIsLoading(false);
      if (!video.onLoadedCalled) {
        onLoadedVideo(video as unknown as Video, [], []);
      }
    });
  }, [numFrames, onLoadedVideo, video, createTextTrackOverlay]);

  const loadTransitionComponent = (video: Transition, callOnLoaded: boolean) => {
    const newFrames: Frame[] = [];
    if (video.fromVideo.frames && video.toVideo.frames) {
      for (let i = 0; i < numFrames; i++) {
        newFrames.push({
          weight: 1,
          url: video.fromVideo.frames[video.fromVideo.frames.length - 1]?.url as string,
          index: 0,
          time: 0,
          img: null
        });
        newFrames.push({
          weight: 1,
          url: video.toVideo.frames[0]?.url as string,
          index: 0,
          time: 0,
          img: null,
        });
      }
    }
    setFrames(newFrames);
    setIsLoading(false);
    onLoadedVideo(video as unknown as Video, [], []);
  };

  useEffect(() => {
    const handleCanPlayThrough = () => {
      videoRef.current?.removeEventListener('canplaythrough', handleCanPlayThrough);
      handleLoadedMetadata(video as Video);
    };

    if (video instanceof Video || video instanceof Audio) {
      videoRef.current.muted = true;
      videoRef.current.play().then(() => {
        videoRef.current.pause();
        videoRef.current.muted = false;
        if (videoRef.current.readyState >= 3) {
          handleLoadedMetadata(video as Video);
        } else {
          videoRef.current.addEventListener('canplaythrough', handleCanPlayThrough);
        }
      });
    } else if (video instanceof TextMedia) {
      const ctx = canvasRef.current.getContext('2d');
      loadTextComponent(ctx, video.text, video.style, size, true);
    } else if (video instanceof ImageMedia) {
      const newFrames: Frame[] = [];
      for (let i = 0; i < numFrames; i++) {
        newFrames.push({ weight: 1, url: video.url as string, index: 0, time: 0, img: null });
      }
      setFrames(newFrames);
      setIsLoading(false);
      onLoadedVideo(video as unknown as Video, [], []);
    } else if (video instanceof Transition) {
      loadTransitionComponent(video, false);
    }
  }, []);

  useEffect(() => {
    if (video instanceof TextMedia) {
      const ctx = canvasRef.current.getContext('2d');
      loadTextComponent(ctx, video.text, video.style, size, false);
    }
  }, [
    loadTextComponent,
    video instanceof TextMedia ? (video as TextMedia).text : null,
    video instanceof TextMedia ? (video as TextMedia).style : null,
    video,
    size
  ]);

  useEffect(() => {
    setHasAudio(hasAudioComponent);
    setHasVideo(hasVideoComponent);
  }, [hasVideoComponent, hasAudioComponent]);

  const handleUploadProgress = (progress: number) => {
    setUploadProgress(progress);
    if (progress === 100) {
      onVideoUploadComplete(video, true);
    }
  };

  const handleLoadedMetadata = (vid: Video) => {
    if (!video.onLoadedCalled && uploadProgress === null) {
      // Get signed URL
      getSignedUploadUrl(vid.name, vid.type, vid.sha256).then(
        async (uploadInformation: { generatedName: string; url: string }) => {
          if (uploadInformation.url) {
            const file = await (await fetch(vid.url)).blob();
            // Upload the video
            uploadVideo(uploadInformation.url, file, file.type, handleUploadProgress);
          } else {
            onVideoUploadComplete(video, false);
          }
        }
      );
    }

    if (vid.hasVideo()) {
      setHasVideo(true);
      createVideoFdIfNotExists(vid).then((fd: number) => {
        if (fd !== -1) {
          vid.videoFdRef = fd;
        }

        //const progressiveLoad =
        //  !preloadedFrames || preloadedFrames?.length === numFrames; // Do not progressively load if the number of frames changed (speed change for example)
        const framesCount =
          preloadedFrames?.reduce((prevWeight, frame) => prevWeight + frame.weight, 0) / 10;
        //const shouldPreloadFrames =
        //  !!(preloadedFrames || preloadedAudioFrames) &&
        //  (framesCount === numFrames || Math.ceil(framesCount) === numFrames);
        const shouldPreloadFrames = (preloadedFrames && preloadedFrames.length) ? true : false;
        const progressiveLoad = true;
        captureBothFrames(
          vid,
          shouldPreloadFrames,
          true,
          true,
          progressiveLoad
        ).then(([capturedFrames, capturedAudioFrames]: [any[], any[]]) => {
          vid.videoRef = videoRef.current;
          setIsLoading(false);
          onLoadedVideo(vid, capturedFrames, capturedAudioFrames);
        });
      });
    } else {
      setHasVideo(false);
      const progressiveLoad =
        !preloadedAudioFrames || preloadedAudioFrames?.length === numFrames; // Do not progressively load if the number of frames changed (speed change for example)
      captureBothFrames(
        vid,
        !!(preloadedFrames || preloadedAudioFrames) && preloadedAudioFrames?.length === numFrames,
        false,
        true,
        progressiveLoad
      ).then(([capturedFrames, capturedAudioFrames]: [any[], any[]]) => {
        vid.videoRef = videoRef.current;
        setIsLoading(false);
        onLoadedVideo(vid, capturedFrames, capturedAudioFrames);
      });
    }
  };

  const captureAudioFrame = (data: Float64Array, maxMagnitude: number, ctx: any): Promise<string> => {
    return new Promise((resolve) => {
      const width = canvasRef.current!.width;
      const height = canvasRef.current!.height;

      // Background
      ctx.clearRect(0, 0, width, height);
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, width, height);

      // Bar properties
      const spacing = 2;
      const barWidth = 10;

      // Gradient
      const gradient = ctx.createLinearGradient(0, 0, 0, height);
      gradient.addColorStop(0, '#00FFFF');
      gradient.addColorStop(0.5, '#0000FF');
      gradient.addColorStop(1, '#FF00FF');

      // Draw bars
      for (let i = 0; i < data.length; i++) {
        const magnitude = data[i];
        const x = i * (barWidth + spacing);
        const barHeight = (magnitude / maxMagnitude) * height;

        ctx.fillStyle = gradient;
        ctx.fillRect(x, height - barHeight, barWidth, barHeight);
      }

      const frame = canvasRef.current!.toDataURL('image/bmp');
      resolve(frame);
    });
  };

  const captureBothFrames = async (
    captureVideo: Video,
    usePreloadedFrames: boolean,
    loadVideo: boolean,
    loadAudio: boolean,
    progressiveLoad: boolean
  ): Promise<[any[], any[]]> => {
    return await blobUrlToUint8Array(captureVideo.url).then((arr: any) => {
      return Engine.writeFile(captureVideo.storageFilePath, arr).then(async (_: any) => {
        let placeholderFrames: Frame[] = [];
        let placeholderAudioFrames: Frame[] = [];
        const videoElement = videoRef.current;

        if (usePreloadedFrames) {
          placeholderFrames = preloadedFrames ? [...preloadedFrames] : [];
          placeholderAudioFrames = preloadedAudioFrames ? [...preloadedAudioFrames] : [];
        } else {
          const freqData = (await Engine.get_audio_frequencies([
            captureVideo.storageFilePath,
            numFrequencyBins.toString(),
            initialNumFrames.toString(),
          ])) as unknown as Float64Array;

          const maxMagnitude = Math.max(...freqData);
          const freqDataSamples = Array.from({ length: initialNumFrames }, (_, i) =>
            new Float64Array(
              freqData.buffer,
              i * numFrequencyBins * Float64Array.BYTES_PER_ELEMENT,
              numFrequencyBins
            )
          );

          const videoHasAudio = maxMagnitude > 0 && hasAudioComponent;
          setHasAudio(videoHasAudio);

          const ctx = canvasRef.current.getContext('2d');
          const interval = (captureVideo.duration * captureVideo.speed) / initialNumFrames;

          // Create placeholder frames
          for (let i = 0; i < initialNumFrames; i++) {
            if (loadVideo) {
              placeholderFrames.push({ weight: 10, url: '', index: i, time: 0, img: null });
            }
            if (loadAudio) {
              placeholderAudioFrames.push({ weight: 10, url: '', index: i, time: 0, img: null });
            }
          }

          if (loadVideo && progressiveLoad) {
            setFrames(placeholderFrames); // Initial placeholders for frames
          }

          if (loadAudio && progressiveLoad) {
            setAudioFrames(placeholderAudioFrames); // Initial placeholders for audio frames
          }

          // Sequential loading and drawing
          for (let i = 0; i < initialNumFrames; i++) {
            const time = i * interval + 0.1 + captureVideo.framesOffset;

            if (loadAudio) {
              const audioFrame = await captureAudioFrame(freqDataSamples[i], maxMagnitude, ctx);
              const img = await (new Promise<HTMLImageElement | null>((resolve) => {
                const img = new Image();
                img.src = audioFrame;
                img.onload = () => resolve(img);
                img.onerror = () => resolve(null);
              }))
              placeholderAudioFrames[i] = {
                weight: 10,
                url: audioFrame as string,
                index: i,
                time: time,
                img: img,
              };
            }

            if (loadVideo) {
              const videoFrame = await captureVideoFrame(
                videoElement,
                canvasRef.current,
                canvasRef.current.width,
                canvasRef.current.height,
                time
              );

              const img = await (new Promise<HTMLImageElement | null>((resolve) => {
                const img = new Image();
                img.src = videoFrame;
                img.onload = () => resolve(img);
                img.onerror = () => resolve(null);
              }))

              placeholderFrames[i] = {
                weight: 10,
                url: videoFrame as string,
                index: i,
                time: time,
                img: img
              };
            }

            if (progressiveLoad) {
              if (loadAudio) {
                drawFrames(
                  true,
                  placeholderAudioFrames,
                  audioCanvasRefs,
                  audioFrameHeight
                );
              }
              if (loadVideo) {
                drawFrames(
                  true,
                  placeholderFrames,
                  videoCanvasRefs,
                  videoFrameHeight
                );
              }
            }
          }
        }

        if (loadAudio) {
          setAudioFrames([...placeholderAudioFrames]);
        }
        if (loadVideo) {
          setFrames([...placeholderFrames]);
        }
        videoElement.currentTime = captureVideo.framesOffset;
        videoElement.onseeked = () => {};
        return [placeholderFrames, placeholderAudioFrames];
      });
    });
  };

  const renderSubtitlesIcon = () => {
    return (
      <Box
        sx={{
          position: 'absolute',
          top: '5px',
          left: '5px',
          zIndex: 2,
          padding: '3px',
          borderRadius: '5px',
          background: 'black',
          border: '1px solid #1f2e3d',
          boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)',
        }}
      >
        <ChatIcon
          sx={{
            fontSize: 22,
            color: theme.palette.primary.contrastText,
          }}
        />
      </Box>
    );
  };

  const renderTransitionOverlay = () => (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2,
      }}
    >
      {size > 20 && (
        <>
          <CompareArrowsIcon sx={{ color: '#fff', fontSize: 30 }} />
          {size > 105 && (
            <Typography variant="caption" sx={{ color: '#fff', ml: 1 }}>
              Transition
            </Typography>
          )}
        </>
      )}
    </Box>
  );

  // Image loading useEffect for video frames
  useEffect(() => {
    if (frames.length > 0) {
      // If not loading progressively, load all images at once
      const loadImages = async () => {
        const images = await Promise.all(
          frames.map((frame) =>
            new Promise<Frame>((resolve) => {
              const img = new Image();
              img.src = frame.url;
              img.onload = () => resolve({ ...frame, img });
              img.onerror = () => resolve({ ...frame, img: null });
            })
          )
        );
        setCachedImages(images);
      };
      loadImages();
    }
  }, [frames, isLoading]);

  // Image loading useEffect for audio frames
  useEffect(() => {
    if (audioFrames.length > 0) {
      // If not loading progressively, load all images at once
      const loadImages = async () => {
        const images = await Promise.all(
          audioFrames.map((frame) =>
            new Promise<Frame>((resolve) => {
              const img = new Image();
              img.src = frame.url;
              img.onload = () => resolve({ ...frame, img });
              img.onerror = () => resolve({ ...frame, img: null });
            })
          )
        );
        setCachedAudioImages(images);
      };
      loadImages();
    }
  }, [audioFrames, isLoading]);

  const drawFrames = (
  hasContent: boolean,
  framesArray: Frame[],
  canvasRefs: React.RefObject<HTMLCanvasElement[]>,
  frameHeight: number,
  options?: { frameIndex?: number }
) => {
  if (
    !canvasRefs.current ||
    !hasContent ||
    framesArray.length === 0
  ) {
    return;
  }

  const framesPerCanvas = Math.ceil(numFrames / numCanvases);

  for (let canvasIndex = 0; canvasIndex < numCanvases; canvasIndex++) {
    const canvas = canvasRefs.current[canvasIndex];
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        // Set canvas dimensions if not already set
        if (canvas.width !== canvasWidth || canvas.height !== frameHeight) {
          canvas.width = canvasWidth;
          canvas.height = frameHeight;
        }

        // Clear canvas if drawing all frames
        if (options?.frameIndex === undefined) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        // Determine frames in this canvas
        const globalStartFrameIndex = canvasIndex * framesPerCanvas;
        const framesInThisCanvas = Math.min(
          framesPerCanvas,
          numFrames - globalStartFrameIndex
        );

        // Calculate total weight for frames in this canvas
        const frameIndicesInCanvas: number[] = [];
        for (
          let i = globalStartFrameIndex;
          i < globalStartFrameIndex + framesInThisCanvas;
          i++
        ) {
          const frameIndex = Math.floor((i * initialNumFrames) / numFrames);
          frameIndicesInCanvas.push(frameIndex);
        }

        const framesInCanvas = frameIndicesInCanvas.map(
          (frameIdx) => framesArray[frameIdx]
        );

        const totalWeight = framesInCanvas.reduce(
          (sum, frame) => sum + (frame.weight || 1),
          0
        );

        // Draw frames onto canvas
        let x = 0;
        for (let index = 0; index < framesInThisCanvas; index++) {
          const globalFrameIndex = globalStartFrameIndex + index;
          const frameIndex = frameIndicesInCanvas[index];
          const frame = framesArray[frameIndex];
          const frameWeight = framesArray[frameIndex].weight || 1;

          // If drawing a specific frame, skip others
          if (
            options?.frameIndex !== undefined &&
            frameIndex !== options.frameIndex
          ) {
            // Calculate x position even if not drawing to maintain correct position
            x += (frameWeight / totalWeight) * canvasWidth;
            continue;
          }

          if (frame) {
            // Calculate frame width based on weight
            const width = (frameWeight / totalWeight) * canvasWidth;
            const y = 0;
            const height = frameHeight;

            if (frame.img) {
              // Draw the actual image if it exists
              ctx.drawImage(frame.img as HTMLImageElement, x, y, width, height);
            } else {
              // Draw a placeholder rectangle if the image is missing
              ctx.fillStyle = 'rgba(0, 0, 0, 0.1)'; // Light gray or transparent
              ctx.fillRect(x, y, width, height);
            }

            // Draw the border line to the right of the frame
            if (globalFrameIndex < numFrames - 1) {
              ctx.beginPath();
              ctx.moveTo(x + width - 0.5, 0);
              ctx.lineTo(x + width - 0.5, height);
              ctx.strokeStyle = 'white';
              ctx.lineWidth = 1;
              ctx.stroke();
            }

            // Increment x by the width of the frame
            x += width;
          }
        }
      }
    }
  }
};

  useEffect(() => {
  let animationFrameId: number;

  const drawAllFrames = () => {
    drawFrames(
      hasVideo,
      cachedImages,
      videoCanvasRefs,
      videoFrameHeight
    );

    drawFrames(
      hasAudio,
      cachedAudioImages,
      audioCanvasRefs,
      audioFrameHeight
    );
  };

  animationFrameId = requestAnimationFrame(drawAllFrames);

  // Cleanup on unmount or dependency change
  return () => cancelAnimationFrame(animationFrameId);
}, [
  hasVideo,
  cachedImages,
  frames,
  videoCanvasRefs,
  videoFrameHeight,
  hasAudio,
  cachedAudioImages,
  audioFrames,
  audioCanvasRefs,
  audioFrameHeight,
  numFrames,
  numCanvases,
  canvasWidth,
  initialNumFrames,
]);


  return (
    <Box
      sx={{
        pointerEvents: 'none',
        width: '100%',
        display: 'flex',
        height: videoFrameHeight + audioFrameHeight,
        position: 'relative',
      }}
    >
      {/* Render the subtitles icon */}
      {((video instanceof Video || video instanceof Audio) && video.subtitlesActive) &&
        renderSubtitlesIcon()}

      {(isLoading || (uploadProgress !== null && uploadProgress !== 100)) && (
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '60px',
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            zIndex: 1,
            color: '#fff',
          }}
        >
          {/* Circular progress compact */}
          <CircularProgress size={24} sx={{ color: '#00ADEF', mr: 1 }} />

          {uploadProgress !== null && (
            <Box>
              {/* Cloud upload icon with smaller size */}
              <CloudUploadIcon sx={{ fontSize: 24, color: '#fff', mr: 1 }} />

              {/* Upload percentage, sized to fit the space */}
              <Box
                sx={{
                  fontWeight: 'bold',
                  fontSize: '0.9rem',
                }}
              >
                {`${uploadProgress}%`}
              </Box>

              {/* Gradient progress bar at the bottom of the 60px height box */}
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  height: '4px',
                  background: 'linear-gradient(90deg, #00ADEF 0%, #00EFA1 100%)',
                  width: `${uploadProgress}%`,
                  borderRadius: '2px',
                }}
              />
            </Box>
          )}
        </Box>
      )}
      <Card
        sx={{
          overflow: 'hidden',
          flexGrow: 1,
          display: 'flex',
          opacity: 0.0,
        }}
      />
      {video instanceof Transition && (
        <>
          <video
            ref={fromVideo}
            src={video.fromVideo.url}
            preload="auto"
            style={{ display: 'none' }}
            playsInline
          />
          <video
            ref={toVideo}
            src={video.toVideo.url}
            preload="auto"
            style={{ display: 'none' }}
            playsInline
          />
        </>
      )}
      {!(video instanceof Transition) && (
        <video
          ref={videoRef}
          src={video instanceof Video || video instanceof Audio ? video.url : undefined}
          preload="auto"
          style={{ display: 'none' }}
          playsInline
        />
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} />

      {/* Left background overlay */}
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          top: 0,
          left: `${leftBackgroundOffset}px`,
          width: `${-leftBackgroundOffset}px`,
          height: '100%',
          backgroundColor: 'black',
          zIndex: 0,
          opacity: 0.5,
        }}
      />
      {/* Main content area */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          left: `${leftBackgroundOffset}px`,
          width: `${size}px`,
          height: '100%',
          zIndex: -1,
        }}
      >
        {hasVideo && (
          <Box
            sx={{
              flexGrow: 2,
              maxHeight: hasAudio ? videoFrameHeight : '100%',
              minHeight: hasAudio ? videoFrameHeight : '0%',
              width: '100%',
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            {/* Render multiple canvases for video frames */}
            {Array.from({ length: numCanvases }).map((_, i) => (
              <canvas
                key={`videoCanvas${i}`}
                ref={(el) => (videoCanvasRefs.current[i] = el!)}
                style={{ width: `${canvasWidth}px`, height: '100%', display: 'inline-block' }}
              />
            ))}
            {video instanceof Transition && renderTransitionOverlay()}
          </Box>
        )}
        {hasAudio && (
          <Box
            sx={{
              flexGrow: 1,
              minHeight: audioFrameHeight,
              width: '100%',
              display: 'flex',
              overflow: 'hidden',
            }}
          >
            {/* Render multiple canvases for audio frames */}
            {Array.from({ length: numCanvases }).map((_, i) => (
              <canvas
                key={`audioCanvas${i}`}
                ref={(el) => (audioCanvasRefs.current[i] = el!)}
                style={{ width: `${canvasWidth}px`, height: '100%', display: 'inline-block' }}
              />
            ))}
          </Box>
        )}
      </Box>
      {/* Right background overlay */}
      <Box
        sx={{
          display: 'flex',
          position: 'absolute',
          top: 0,
          left: `${size + leftBackgroundOffset - rightBackgroundOffset}px`,
          width: `${rightBackgroundOffset}px`,
          height: '100%',
          backgroundColor: 'black',
          zIndex: 0,
          opacity: 0.5,
        }}
      />
    </Box>
  );
};

export { VideoFramesComponent };
