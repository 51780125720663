import React, { useRef, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { ClosedCaption, ClosedCaptionDisabled, VolumeOff, VolumeUp, Delete, ContentCut, SkipPrevious, PlayArrow, Pause, SkipNext, Add } from '@mui/icons-material';

import EditorIconButton from './EditorIconButton';
import ZoomBar from './ZoomBar';
import { useEditorContext } from './VideosContext';

type VideoEditorControlsProps = {
    maxZoom: number;
    isMobileLayout: boolean,
    subtitlesButtonType: 'add' | 'remove' | 'download';
    volumeButtonType: 'add' | 'remove';
    shouldEnableVideoActions: boolean;
    shouldEnableCutAction?: boolean;
    shouldEnableSubtitlesAction?: boolean;
    shouldEnableDeleteAction?: boolean;
    shouldEnableAudioAction?: boolean;
    handlePlayPause: () => void;
    handleSkipFrame: (direction: 'forward' | 'backward') => void;
    handleCut: () => void;
    handleDelete: () => void;
    handleRemoveSound: () => void;
    handleAddSubtitles: () => void;
    handleZoomChange: (zoomValue: number) => void;
};

const formatTime = (timeInSeconds: number): string => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    const milliseconds = Math.floor((timeInSeconds % 1) * 1000);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}:${milliseconds < 100 ? '0' : ''}${milliseconds < 10 ? '0' : ''}${milliseconds}`;
};

const VideoEditorControls: React.FC<VideoEditorControlsProps> = ({
    maxZoom,
    isMobileLayout,
    shouldEnableVideoActions,
    shouldEnableCutAction = true,
    shouldEnableSubtitlesAction = true,
    shouldEnableDeleteAction = true,
    shouldEnableAudioAction = true,
    subtitlesButtonType,
    volumeButtonType,
    handlePlayPause,
    handleSkipFrame,
    handleCut,
    handleDelete,
    handleRemoveSound,
    handleAddSubtitles,
    handleZoomChange
}) => {
    const { currentTime, runningState, state, totalTracksDuration } = useEditorContext();
    const [presentedTime, setPresentedTime] = useState<number>(0);
    const currentFrameId = useRef<number>(0);

    useEffect(() => {
    }, [subtitlesButtonType, volumeButtonType])
    useEffect(() => {
        setPresentedTime(currentTime.current);
        const pollTimeAndSet = () => {
            setPresentedTime(currentTime.current);
            if (currentFrameId.current) {
                currentFrameId.current = requestAnimationFrame(pollTimeAndSet);
            }
        }
        if (runningState.sliderSyncOn) {
            currentFrameId.current = requestAnimationFrame(pollTimeAndSet);
        } else {
            const fid = currentFrameId.current;
            currentFrameId.current = 0;
            cancelAnimationFrame(fid);
        }
    }, [runningState.sliderSyncOn, state])

    const renderSubtitlesIcon = () => {
        switch (subtitlesButtonType) {
          case 'add':
            return <ClosedCaption sx={{height: '20px', width: '20px'}}/>
          case 'remove':
            return <ClosedCaptionDisabled sx={{height: '20px', width: '20px'}}/>
          case 'download':
            return <ClosedCaption sx={{height: '20px', width: '20px'}}/>
          default:
            return null; // Or any default icon you'd prefer
        }
      };

    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" px={1} flexDirection={'row'} sx={{ paddingLeft: 2, paddingRight: 2 }}>
            {/* Left-aligned icons */}
            <Box display="flex" alignItems="center" flex={1} sx={{height: '100%', minWidth: isMobileLayout ? 0 : undefined}}>
                <EditorIconButton disabled={!shouldEnableVideoActions || !shouldEnableCutAction} isMobile={isMobileLayout} onClick={handleCut}>
                    <ContentCut sx={{height: '20px', width: '20px'}}/>
                </EditorIconButton>
                <EditorIconButton disabled={!shouldEnableVideoActions || !shouldEnableAudioAction} isMobile={isMobileLayout} onClick={handleRemoveSound}>
                    {volumeButtonType === 'add' ? (
                    <VolumeUp sx={{height: '20px', width: '20px'}}/>
                    ) : (
                    <VolumeOff sx={{height: '20px', width: '20px'}}/>
                    )}
                </EditorIconButton>
                <EditorIconButton disabled={!shouldEnableVideoActions || !shouldEnableSubtitlesAction || subtitlesButtonType === 'download'} isLoading={subtitlesButtonType === 'download'} isMobile={isMobileLayout} onClick={handleAddSubtitles}>
                    {renderSubtitlesIcon()}
                </EditorIconButton>
                <EditorIconButton disabled={!shouldEnableDeleteAction || !shouldEnableVideoActions} isMobile={isMobileLayout} onClick={handleDelete}>
                    <Delete sx={{height: '20px', width: '20px'}}/>
                </EditorIconButton>
            </Box>

            {/* Center-aligned icons */}
            <Box display="flex" alignItems="center" justifyContent={'center'} flex={1} sx={{height: '100%', minWidth: isMobileLayout ? 0 : undefined}}>
                {!isMobileLayout && (
                <EditorIconButton isMobile={isMobileLayout} onClick={() => handleSkipFrame('backward')}>
                    <SkipPrevious sx={{height: '20px', width: '20px'}}/>
                </EditorIconButton>
                )}
                <EditorIconButton isMobile={isMobileLayout} onClick={handlePlayPause}>
                    {!runningState.isRunning ? <PlayArrow sx={{height: '20px', width: '20px'}} /> : <Pause sx={{height: '20px', width: '20px'}} />}
                </EditorIconButton>
                {!isMobileLayout && (
                <EditorIconButton isMobile={isMobileLayout} onClick={() => handleSkipFrame('forward')}>
                    <SkipNext sx={{height: '20px', width: '20px'}} />
                </EditorIconButton>
                )}
                {!isMobileLayout && (
                <Box sx={{display: 'flex', flexDireciton: 'row'}}>
                    <Typography variant="body2" sx={{ letterSpacing: '0.05em', fontWeight: 'bold', opacity: 0.6 }}>
                        {formatTime(Math.min(presentedTime, totalTracksDuration))}
                    </Typography>
                    <Typography variant="body2" sx={{ letterSpacing: '0.05em', fontWeight: 'bold', opacity: 0.6, marginLeft: 1, marginRight: 1}}>
                       / 
                    </Typography>
                    <Typography variant="body2" sx={{ letterSpacing: '0.05em', fontWeight: 'bold', opacity: 0.3}}>
                        { formatTime(state.videos.length === 0 ? 0 : totalTracksDuration)}
                    </Typography>
                </Box>
                )}
            </Box>

            <Box display="flex" justifyContent={'end'} alignItems="center" flex={1} sx={{height: '100%', minWidth: isMobileLayout ? 0 : undefined}}>
                <ZoomBar isMobileLayout={isMobileLayout} minZoom={100} maxZoom={maxZoom} initialZoom={0} onZoomChange={handleZoomChange} />
            </Box>
        </Box>
    );
};

export default VideoEditorControls;
