import Media, { Crop, MediaType, NON_SERIALIZABLE_KEY, SERIALIZABLE_FOR_RENDER_KEY, SerializeForRender, nonSerializable } from './Media';
import Video from './Video';

export default class Transition extends Media {
    mediaType: MediaType = 'transition';
    @nonSerializable()
    fromVideo: Video;
    @nonSerializable()
    toVideo: Video;
    @nonSerializable()
    fromStorage: Uint8Array = new Uint8Array();
    @nonSerializable()
    toStorage: Uint8Array = new Uint8Array();
    @SerializeForRender()
    transition: string;
    constructor ({
        id,
        name,
        sha256,
        url,
        type,
        row,
        duration,
        start,
        leftBackgroundOffset, 
        rightBackgroundOffset, 
        fromVideo,
        toVideo,
        startBase, 
        endBase, 
        isResizing, 
        dirty, 
        framesOffset, 
        playOffset, 
        onLoadedCalled,
        effects,
        height,
        width,
        scaledHeight,
        scaledWidth,
        baseHeight,
        baseWidth,
        x,
        y,
        rotation,
        crop,
        transition,
        leftTrim,
        rightTrim,
    }: {
        id?: string,
        name: string,
        sha256: string,
        url: string,
        type: string,
        row: number,
        start: number,
        leftBackgroundOffset: number,
        rightBackgroundOffset: number,
        startBase: number,
        endBase: number,
        fromVideo: any;
        toVideo: any;
        duration: number,
        isResizing: boolean,
        dirty: boolean,
        playOffset?: number;
        framesOffset?: number;
        onLoadedCalled?: boolean;
        effects?: string[];
        height: number;
        width: number;
        scaledHeight: number | null;
        scaledWidth: number | null;
        baseHeight: number;
        baseWidth: number;
        x: number;
        y: number;
        rotation: number;
        crop: Crop;
        transition: string;
        leftTrim?: number;
        rightTrim?: number;
    }) {
        super({
            id, 
            name,
            sha256,
            url,
            type,
            row, 
            duration, 
            start,
            leftBackgroundOffset, 
            rightBackgroundOffset, 
            videoRef: toVideo,
            startBase, 
            endBase, 
            isResizing, 
            dirty, 
            framesOffset, 
            playOffset, 
            onLoadedCalled,
            effects,
            height,
            width,
            scaledHeight,
            scaledWidth,
            baseHeight,
            baseWidth,
            x,
            y,
            rotation,
            crop,
            resizable: true,
            cropable: true,
            uploadComplete: true,
            speed: 1,
            hasVideoComponent: true,
            hasAudioComponent: false,
            leftTrim,
            rightTrim,
        });
        this.fromVideo = fromVideo;
        this.toVideo = toVideo;
        this.transition = transition;
    }

    setRotation = async (degrees: number) => {
        this.rotation = degrees;
    }

    getRotatedCoords = () => {

    }

    hasVideo = (): boolean => {
        return true;
    }

    hasAudio = (): boolean => {
        return false;
    }

    audioEnabled = (): boolean => {
        return false;
    }

    getEffectString = (prependVideoNormalization: boolean = false, addRotation: boolean = false): string => {
        return ``;
    }

    deepCopy = ():  Transition => {
        return new Transition({...this});
    }

    serialize = (): Partial<Transition> => {
        const cleanVideo = this.deepCopy();
        const nonSerializableFields: Array<keyof Transition> = Reflect.getMetadata(NON_SERIALIZABLE_KEY, this) || [];

        nonSerializableFields.forEach((key) => {
        delete cleanVideo[key];
        })

        cleanVideo.fromVideo = {id: this.fromVideo.id, sha256: this.fromVideo.sha256, playOffset: this.fromVideo.playOffset, start: this.fromVideo.start || 0, x: this.fromVideo.x, y: this.fromVideo.y} as any;
        cleanVideo.toVideo = {id: this.toVideo.id, sha256: this.toVideo.sha256, playOffset: this.toVideo.playOffset, start: this.toVideo.start || 0, x: this.toVideo.x, y: this.toVideo.y} as any;

        return cleanVideo;
    }

    serializeForRender = (): Partial<Transition> => {
      const cleanVideo = this.deepCopy();
      const serializableFields: (keyof Transition)[] = Reflect.getMetadata(SERIALIZABLE_FOR_RENDER_KEY, this) || [];

      // need to assign  the x,y cords after the video was rotated (this is how the backend overlays the videos)
      const serialized = serializableFields.reduce((obj, key) => {
        obj[key] = cleanVideo[key];
        return obj;
      }, {} as Partial<Transition>);
      serialized.fromVideo = {id: this.fromVideo.id, sha256: this.fromVideo.sha256, playOffset: this.fromVideo.playOffset, start: this.fromVideo.start || 0, x: this.fromVideo.x, y: this.fromVideo.y} as any;
      serialized.toVideo = {id: this.toVideo.id, sha256: this.toVideo.sha256, playOffset: this.toVideo.playOffset, start: this.toVideo.start || 0, x: this.toVideo.x, y: this.toVideo.y} as any;
      return serialized;
    }

    public static async fromSerialized(serializedMedia: any, options?: any) {
      return new Transition({...serializedMedia});
    }

    requiresWasm = (): boolean => {
        return false;
    }
}
