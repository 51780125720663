
import React, { useState } from 'react';
import {
  Typography,
  Box,
  Divider,
  Paper,
  Button,
  Popover,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import MovieIcon from '@mui/icons-material/Movie';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  height: '100%',
  overflowY: 'auto',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

interface ProjectMenuProps {
  onAddMedia: (file: File) => void;
}

const ProjectMenu: React.FC<ProjectMenuProps> = ({
  onAddMedia,
}) => {
  //const [backgroundColor, setBackgroundColor] = useState<string>('#ffffff');
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleFileChange = (type: 'video' | 'image' | 'audio', event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
        onAddMedia(file);
    }
  };

  const open = Boolean(anchorEl);

  return (
    <Container sx={{ direction: 'ltr' }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontWeight: 'bold', color: 'text.primary' }}
      >
        Add Media
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      {/*}
      <Section elevation={3}>
        <SectionHeader>Project Information</SectionHeader>
        <Typography variant="body1">
          <strong>Project Name:</strong> {projectName}
        </Typography>
        <Typography variant="body1">
          <strong>Duration:</strong> {projectDuration}
        </Typography>
      </Section>
      */}

      {/* Add Media */}
      <Section elevation={3}>
        <SectionHeader>Add Media</SectionHeader>
        {/* Video */}
        <Button
          component="label"  // Here, we use the component label to trigger file input
          variant="outlined"
          startIcon={<MovieIcon />}
          sx={{ marginBottom: 2, justifyContent: 'flex-start', textTransform: 'none', width: '100%' }}
        >
          Add Video
          <input
            type="file"
            accept="video/*"
            hidden
            onChange={(e) => handleFileChange('video', e)}
          />
        </Button>

        {/* Image */}
        <Button
          component="label"  // Here, we use the component label to trigger file input
          variant="outlined"
          startIcon={<AddPhotoAlternateIcon />}
          sx={{ marginBottom: 2, justifyContent: 'flex-start', textTransform: 'none', width: '100%' }}
        >
          Add Image
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => handleFileChange('image', e)}
          />
        </Button>

        {/* Audio */}
        <Button
          component="label"  // Here, we use the component label to trigger file input
          variant="outlined"
          startIcon={<MusicNoteIcon />}
          sx={{ justifyContent: 'flex-start', textTransform: 'none', width: '100%' }}
        >
          Add Audio
          <input
            type="file"
            accept="audio/*"
            hidden
            onChange={(e) => handleFileChange('audio', e)}
          />
        </Button>
      </Section>
    </Container>
  );
};

export default ProjectMenu;
