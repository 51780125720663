import { Segment, MediaLayer } from '../utils/serializer';
import Video from '../components/media/Video';
import Audio from '../components/media/Audio';

import VTT from 'vtt.js';

interface AssEvent {
  startTime: number;
  endTime: number;
  style: string;
  name: string;
  marginL: number;
  marginR: number;
  marginV: number;
  effect: string;
  text: string;
}

interface AssFile {
  info: string[];    // Store the [Script Info] section
  styles: string[];  // Store the [V4+ Styles] section
  events: AssEvent[]; // Store the [Events] section
}

// TODO: if this is needed - we already had some defaults need to check if this is what exists when it's an ASS file (coming from ffmpeg)
const placeholderAssInfo = [
  'ScaledBorderAndShadow: yes'
]
const placeholderAssStyles = [
  'Format: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding',
  'Style: Default,Poppins,32,&H00FFFFFF,&Hffffff,&H0,&H00000000,0,0,0,0,100,100,0,0,2,1,1,8,0,0,0,0'
]

function isWebVTT(content: string) {
  // Check if the content starts with "WEBVTT"
  const firstLine = content.trim().split('\n')[0];
  return firstLine === 'WEBVTT';
}

const parseVTTContent = async (content: string): Promise<AssFile> => {
  return new Promise((resolve) => {
    const cues: any[] = [];
    const parser = new VTT.WebVTT.Parser(window, VTT.WebVTT.StringDecoder())

    parser.oncue = function(cue: any) {
      // return the time in ms, place the text field in Text to align with ASS files
      cues.push({...cue, text: cue.text, startTime: cue.startTime * 1000, endTime: cue.endTime * 1000});
    };
    parser.onflush = function() {
      resolve({events: cues, styles: placeholderAssStyles, info: []});
    }

    parser.parse(content);
    parser.flush();
  })
}

const parseAssEventTime = (timeString: string) => {
  const [hours, minutes, secondsCs] = timeString.split(':');
  const [secs, cs] = secondsCs.split('.');
  const totalMs = (parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(secs)) * 1000 + parseInt(cs) * 10;
  return totalMs;
};

const millisecondsToAssTimestamp = (totalMs: number): string => {
  const hours = Math.floor(totalMs / 3600000);
  const minutes = Math.floor((totalMs % 3600000) / 60000);
  const seconds = Math.floor((totalMs % 60000) / 1000);
  const cs = Math.floor((totalMs % 1000) / 10); // centiseconds

  const hoursString = hours.toString();
  const minutesString = minutes.toString().padStart(2, '0');
  const secondsString = seconds.toString().padStart(2, '0');
  const csString = cs.toString().padStart(2, '0'); // 2 digits for centiseconds

  return `${hoursString}:${minutesString}:${secondsString}.${csString}`;
};

const parseAssEvent = (line: string): AssEvent => {
  // Remove "Dialogue:" prefix and split by comma
  const parts = line.replace('Dialogue:', '').split(',');

  return {
    startTime: parseAssEventTime(parts[1].trim()),
    endTime: parseAssEventTime(parts[2].trim()),
    style: parts[3].trim(),
    name: parts[4].trim(),
    marginL: Number(parts[5].trim()),
    marginR: Number(parts[6].trim()),
    marginV: Number(parts[7].trim()),
    effect: parts[8].trim(),
    text: parts.slice(9).join(',').trim() // Text can have commas
  };
};

// Function to parse ASS file content
const parseAssFile = (assContent: string): AssFile => {
  const lines = assContent.split('\n');
  const file: AssFile = { info: [], styles: [], events: [] };
  let section = ''; // Keep track of current section

  for (let line of lines) {
    line = line.trim();

    // Check for section headers
    if (line.startsWith('[')) {
      section = line;
      continue;
    }

    if (section === '[Script Info]') {
      if (line !== '') {
        file.info.push(line);
      }
    } else if (section === '[V4+ Styles]') {
      if (line !== '') {
        file.styles.push(line);
      }
    } else if (section === '[Events]') {
      if (line.startsWith('Dialogue:')) {
        const event = parseAssEvent(line);
        file.events.push(event);
      }
    }
  }

  return file;
};

const combineAssEvents = (files: AssFile[]): AssEvent[] => {
  const combinedEvents: AssEvent[] = [];

  // Collect and combine events from all files
  for (const file of files) {
    combinedEvents.push(...file.events);
  }

  // Sort events by start time
  combinedEvents.sort((a, b) => a.startTime - b.startTime);

  return combinedEvents;
};

const assEventToDialogue = (event: AssEvent): string => {
  return `Dialogue: 0,${millisecondsToAssTimestamp(event.startTime)},${millisecondsToAssTimestamp(event.endTime)},${event.style || 'Default'},${event.name || ''},${event.marginL || 0},${event.marginR || 0},${event.marginV || 0},${event.effect || ''},${event.text}`;
};

const dumpAssFile = (
  file: AssFile,
  customStyles?: { [propertyName: string]: string },
  customInfo?: { [key: string]: string },
  targetStyleName: string = 'Default' // Name of the style to modify
): string => {
  // Process [Script Info]
  const infoMap = new Map<string, string>();

  // Parse existing info lines into key-value pairs
  file.info.forEach(line => {
    const [key, value] = line.split(':', 2);
    if (key && value !== undefined) {
      infoMap.set(key.trim(), value.trim());
    }
  });

  // Merge or overwrite with customInfo
  if (customInfo) {
    for (const key in customInfo) {
      infoMap.set(key.trim(), customInfo[key].trim());
    }
  }

  // Reconstruct info lines
  const infoLines: any[] = [];
  infoMap.forEach((value, key) => {
    infoLines.push(`${key}: ${value}`);
  });

  // Process [V4+ Styles]
  let styleHeader = '';
  const styleFormat: string[] = [];
  const stylesArray: { [key: string]: string }[] = [];

  if (file.styles.length < 2) {
    // if the styles are empty (there are no attributes, only header or less) - place the placeholder so the logic will work
    file.styles = [...placeholderAssStyles];
  }
  // First, find the 'Format:' line and parse it
  file.styles.forEach(line => {
    if (line.startsWith('Format:')) {
      styleHeader = line;
      styleFormat.push(...line.substring('Format:'.length).split(',').map(s => s.trim()));
    } else if (line.startsWith('Style:')) {
      const styleLine = line.substring('Style:'.length).trim();
      const styleValues = styleLine.split(',');
      const styleObj: { [key: string]: string } = {};
      for (let i = 0; i < styleFormat.length; i++) {
        styleObj[styleFormat[i]] = styleValues[i] ? styleValues[i].trim() : '';
      }
      stylesArray.push(styleObj);
    }
  });

  // Apply customStyles to the target style
  if (customStyles) {
    for (const styleObj of stylesArray) {
      if (styleObj['Name'] === targetStyleName) {
        // Overwrite properties with customStyles
        for (const prop in customStyles) {
          if (styleObj.hasOwnProperty(prop)) {
            styleObj[prop] = customStyles[prop];
          }
        }
      }
    }
  }

  // Reconstruct the style lines
  const styleLines = [];
  if (styleHeader) {
    styleLines.push(styleHeader);
  } else {
    // If no format is specified, define a default one
    styleFormat.push('Name', 'Fontname', 'Fontsize', 'PrimaryColour', 'SecondaryColour', 'OutlineColour', 'BackColour', 'Bold', 'Italic', 'Underline', 'StrikeOut', 'ScaleX', 'ScaleY', 'Spacing', 'Angle', 'BorderStyle', 'Outline', 'Shadow', 'Alignment', 'MarginL', 'MarginR', 'MarginV', 'Encoding');
    styleHeader = 'Format: ' + styleFormat.join(', ');
    styleLines.push(styleHeader);
  }

  for (const styleObj of stylesArray) {
    const styleValues = styleFormat.map(prop => styleObj[prop] !== undefined ? styleObj[prop] : '');
    styleLines.push('Style: ' + styleValues.join(','));
  }

  // Process [Events]
  const eventsHeader = `[Events]`;
  const events = file.events.map(assEventToDialogue).join('\n');

  // Combine all sections
  const scriptInfo = `[Script Info]\n${infoLines.join('\n')}`;
  const styles = `[V4+ Styles]\n${styleLines.join('\n')}`;

  return `${scriptInfo}\n\n${styles}\n\n${eventsHeader}\n${events}`;
};

const produceAssFileForSegments = async (
  segments: Segment[],
  customStyles?: { [propertyName: string]: string },
  customInfo?: { [key: string]: string }
) => {
  const alignedAssFiles = (
    await Promise.all(
      segments.map(async (segment: Segment) => {
        const subtitlesMediaLayer = segment.layers.filter(
          (layer: MediaLayer) =>
            (layer.video instanceof Audio || layer.video instanceof Video) &&
            layer.video.subtitlesActive
        );
        if (subtitlesMediaLayer.length) {
          // The last media in a segment has the higher priority for subtitles presentation
          const activeMediaLayer = subtitlesMediaLayer[subtitlesMediaLayer.length - 1];

          const content = await fetch((activeMediaLayer.video as Video).subtitlesUrl as string).then(
            (res) => res.text()
          );
          let assFile;
          if (isWebVTT(content)) {
            assFile = await parseVTTContent(content);
          } else {
            assFile = parseAssFile(content);
          }

          // 1. Cut events from start and end according to playOffset and duration
          const startTime = activeMediaLayer.video.getPlayOffset(segment.start) * 1000;
          const endTime = activeMediaLayer.video.getPlayOffset(segment.end) * 1000;

          const alignedEvents = assFile.events.filter(
            (event: AssEvent) => event.startTime >= startTime && event.endTime <= endTime
          );

          // 2. Reposition the event start to fit the whole video render timeline
          alignedEvents.forEach((event: AssEvent) => {
            // Add the offset of the event to the start of the segment
            event.startTime = Math.round(segment.start * 1000 + (event.startTime - startTime) / activeMediaLayer.video.speed);
            event.endTime = Math.round(segment.start * 1000 + (event.endTime - startTime) / activeMediaLayer.video.speed);
          });

          // 3. Assign the aligned events to the assFile
          assFile.events = alignedEvents;

          return assFile;
        }
      })
    )
  ).filter((assFile: AssFile | undefined) => assFile) as AssFile[];

  if (!alignedAssFiles.length) {
    return null;
  }

  const combinedEvents = combineAssEvents(alignedAssFiles);

  // Use the first file for the styles and script info, replace the actual events
  const file = alignedAssFiles[0];
  file.events = combinedEvents;

  // Create ASS file contents and return a valid ASS file with the aligned events
  return dumpAssFile(file, customStyles, customInfo);
};

export {
  isWebVTT,
  produceAssFileForSegments
};
