import React from 'react';
import { AppBar, Toolbar } from '@mui/material';


interface AppBarProps {
  title: string;
  color?: 'primary' | 'default' | 'inherit' | 'secondary' | 'transparent' | 'error' | 'info' | 'success' | 'warning';
  backgroundColor?: string,
  children?: React.ReactNode;
  isEditing?: boolean;
  onTitleChange?: (title: string) => void;
}

const ButtonAppBar: React.FC<AppBarProps> = ({ title, color = 'primary', backgroundColor="#fff", children, isEditing = false, onTitleChange }) => {
  return (
    <AppBar position="sticky" color={color} sx={{
        boxShadow: 'none',
        borderBottom: '1px solid lightgray', // Add a light border
        backgroundColor: { backgroundColor },
        left: 'auto',
        right: 'auto',
        maxHeight: '48px'
      }}>
      <Toolbar variant="dense" sx={{paddingLeft: 1, paddingRight: 1}}>
        {children}
      </Toolbar>
    </AppBar>
  );
};

/*
*/
export default ButtonAppBar;

