import Media from '../components/media/Media';
import Transition from '../components/media/Transition';
import { ProjectStateType } from '../components/VideosContext';

type MediaLayer = {
  video: Media;
  rowNumber: number;
  playOffset: number;
  start: number;
  end: number;
  isPartOfTransition: boolean;
}

type Segment = {
  layers: MediaLayer[];
  start: number;
  end: number;
}

const rgbaToHex = (r: number, g: number, b: number, a: number) => {
  const toHex = (value: number) => value.toString(16).padStart(2, '0');
  return `0x${toHex(r)}${toHex(g)}${toHex(b)}${toHex(Math.round(a * 255))}`;
};

const serializeProject = (projectState: ProjectStateType, media: Media[], canvasBackground: [number, number, number, number], canvasAspectRatio: number, canvasWidth: number, canvasHeight: number, videoWidth: number, videoHeight: number, totalDuration: number, subtitles?: any): string => {
  const canvasColorString = rgbaToHex(canvasBackground[0] * 255, canvasBackground[1] * 255, canvasBackground[2] * 255, canvasBackground[3]);
  const serialized = JSON.stringify({
    projectState,
    backgroundColor: canvasColorString,
    videoWidth,
    videoHeight,
    aspectRatio: canvasAspectRatio,
    width: canvasWidth,
    height: canvasHeight,
    totalDuration: totalDuration,
    subtitles: subtitles,
    tracks: media.map((media) => media.serialize())
  })
  return serialized;
}

const serializeSegments = (layers: MediaLayer[], videoDuration: number, canvasBackground: [number, number, number, number], canvasWidth: number, canvasHeight: number, videoWidth: number, videoHeight: number, subtitles?: any): string => {
  const canvasColorString = rgbaToHex(canvasBackground[0] * 255, canvasBackground[1] * 255, canvasBackground[2] * 255, canvasBackground[3]);
  const transitionLayers = layers.filter((layer: MediaLayer) => layer.video instanceof Transition);
  const serializedLayers = layers.map((layer: MediaLayer) => {
    // check if this video layer is a part of a transition - we need to avoid rendering these video layers separately on the backend
    // as they are already rendered by the transition
    const relatedTransitionIdx = transitionLayers.findIndex((transitionLayer: MediaLayer) => (transitionLayer.video as Transition).fromVideo.id === layer.video.id || (transitionLayer.video as Transition).toVideo.id === layer.video.id);
    const isPartOfTransition = relatedTransitionIdx !== -1;

    return {...layer, isPartOfTransition: isPartOfTransition, video: layer.video.serializeForRender(), effectString: layer.video.getEffectString(true, true)};
  })

  const serialized = JSON.stringify({
    backgroundColor: canvasColorString,
    videoDuration,
    videoWidth,
    videoHeight,
    width: canvasWidth,
    height: canvasHeight,
    subtitles: subtitles,
    layers: serializedLayers,
  });
  return serialized;
}

export {
  serializeProject,
  serializeSegments,
  type Segment,
  type MediaLayer
}