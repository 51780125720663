// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import LoginPage from './components/LoginPage';
import RegistrationViaTokenPage from './components/RegistrationViaTokenPage';
import ActivateUserPage from './components/ActivateUserPage';
import RegistrationPage from './components/registration/RegistrationPage';
import MailSentPage from './components/registration/MailSentPage';
import ResetPasswordViaTokenPage from './components/ResetPasswordViaTokenPage';
import Users from './components/Users';
import Home from './components/Home';
import ProtectedRoute from './components/ProtectedRoute';
import LayoutWithNavbar from './components/LayoutWithNavbar';
import { Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

import { EditorContextProvider } from './components/VideosContext';

import mixpanel from 'mixpanel-browser';

import './components/EngineLoader';

import './App.css';

const theme = createTheme({
  components: {
    MuiDataGrid: {
        styleOverrides: {
            root: {
                '& .MuiDataGrid-cell': {
                    cursor: 'default'
                },
                '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                },
                '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                }
            },
        }
    },
  },
  palette: {
    primary: {
      main: '#121e2a', // Replace with your desired primary color
    },
    secondary: {
      main: '#dc004e', // Replace with your desired secondary color
    },
  },
});

// Near entry of your product, init Mixpanel
mixpanel.init('399cd99d8c4fd00a41efd499f77b22a5', {debug: true, track_pageview: true, persistence: 'localStorage'});

const App: React.FC = () => {
  return (
    <EditorContextProvider>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <Router>
            <Routes>
              <Route element={<LayoutWithNavbar/>}>
                <Route path="/login" element={<LoginPage />} />
              </Route>
              <Route path="/" element={<ProtectedRoute />}>
                <Route element={<LayoutWithNavbar/>}>
                  <Route path="/" element={<Navigate to="/home" replace />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/users/:shouldOpenAddUserModal" element={<Users />} />
                    <Route path="/home" element={<Home />} />
                  <Route path="/login" element={<LoginPage />} />
                </Route>
              </Route>
              <Route path="/register" element={<RegistrationPage />} />
              <Route path="/mailsent/:email" element={<MailSentPage />} />
              <Route path="/reset/:token" element={<ResetPasswordViaTokenPage />} />
              <Route path="/register/:token" element={<RegistrationViaTokenPage />} />
              <Route path="/activate/:token" element={<ActivateUserPage />} />
            </Routes>
          </Router>
        </AuthProvider>
    </ThemeProvider>
  </EditorContextProvider>
  );
};

export default App;

