import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { resendRegistration } from '../../api/ServerApi';
import {
  Container,
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Link,
} from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const ZevideoLogo = '../../assets/zevideo-black-no-background.webp';

const theme = createTheme({
  palette: {
    primary: {
      main: '#121e2a', // Replace with your desired primary color
    },
    secondary: {
      main: '#dc004e', // Replace with your desired secondary color
    },
  },
});

const Root = styled('div')({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'url(https://source.unsplash.com/random)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const Paper = styled(Box)({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  padding: theme.spacing(3),
  borderRadius: '8px',
});

const SubmitButton = styled(Button)({
  color: 'blue',
  margin: theme.spacing(3, 0, 2),
});

const Logo = styled('img')({
  width: '100px',
  marginBottom: theme.spacing(2),
});

type Params = {
  email: string;
}

export default function MailSentPage() {
  const { email } = useParams<Params>();
  const [ resendEnabled, setResendEnabled ] = useState(true);

  const handleSubmit = (e: any) => {
    e.preventDefault();

    resendRegistration(email as string).then(() => {
      setResendEnabled(false);
    }).catch((err: any) => {
      console.log("fail");
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img src={ZevideoLogo} alt="Zetanox Logo" style={{ width: '390px', height: '200px' }}/>
          </Box>
          <Container component="main">
            <CssBaseline />
            <Paper>
              <Typography component="h1" variant="h4" sx={{marginBottom: 3}}>
                Verify your email
              </Typography>
              <Typography component="h2" variant="body1">
                We sent an email to {email}.
              </Typography>
              <Typography component="h2" variant="body1">
                Click the link inside to get started.
              </Typography>
              <SubmitButton
                disabled={!resendEnabled}
                onClick={handleSubmit}
                fullWidth
                variant="text"
                color="primary"
              >
                Resend Email
              </SubmitButton>
              <Typography component="h1" variant="body2" color="red" hidden={resendEnabled}>
                An email has been sent to your inbox
              </Typography>
            </Paper>
          </Container>
        </Box>
      </Root>
    </ThemeProvider>
  );
}

