import React, { useEffect, useState, useImperativeHandle, forwardRef } from "react";
import Moveable from "react-moveable";

type DirectionType = 'top' | 'bottom' | 'left' | 'right' | 'ne' | 'nw' | 'se' | 'sw' | null;
type handleDirectionType = 'n' | 's' | 'e' | 'w' | 'ne' | 'nw' | 'se' | 'sw';

const cropDirections = ['left', 'right', 'top', 'bottom'];

interface MoveableComponentProps {
  width: number;
  maxWidth: number;
  maxHorizontalSize: number;
  height: number;
  maxHeight: number;
  maxVerticalSize: number;
  cropDimensions: any;
  initialX: number;
  initialY: number;
  initialRotation: number;
  onDragStart: (x: number, y: number) => void;
  onDrag: (xBefore: number, xAfter: number, yBefore: number, yAfter: number) => void;
  onDragStop: (xBefore: number, xAfter: number, yBefore: number, yAfter: number) => void;
  onResizeStart: (x: number, y: number, width: number, height: number) => void;
  onResize?: (xBefore: number, yBefore: number, widthBefore: number, widthAfter: number, x: number, y: number, width: number, height: number, direction: DirectionType) => Promise<number | undefined>;
  onResizeStop: (xBefore: number, yBefore: number, widthBefore: number, heightBefore:number, xAfter: number, yAfter: number, widthAfter:number, heightAfter:number, direction: DirectionType) => Promise<number | undefined>;
  onRotate: (degrees: number) => void;
  onRotateStop: (degrees: number) => void;
  onSelect: (event: any) => void;
  onMouseDown?: (event: any) => void;
  resizable: boolean;
  rotatable: boolean;
  isMultiSelected: boolean;
  isMultiSelectionBox?: boolean;
  isActive: boolean;
  zIndex: number;
  lockAspectRatio: boolean;
  handles: handleDirectionType[];
  children?: React.ReactNode;
}


const MoveableBox = forwardRef(({
  onDragStart,
  onDrag,
  onDragStop,
  onResizeStart,
  onResize,
  onResizeStop,
  onRotate,
  onRotateStop,
  onSelect,
  onMouseDown,
  width,
  maxWidth,
  maxHorizontalSize,
  height,
  maxHeight,
  maxVerticalSize,
  cropDimensions,
  initialX,
  initialY,
  initialRotation,
  resizable,
  rotatable,
  isMultiSelected,
  isMultiSelectionBox = false,
  isActive,
  zIndex,
  lockAspectRatio,
  handles,
  children
}: MoveableComponentProps, ref) => {
    const [startX, setStartX] = useState<number>(0);
    const [startY, setStartY] = useState<number>(0);
    const [startWidth, setStartWidth] = useState<number>(0);
    const [startHeight, setStartHeight] = useState<number>(0);
    const [direction, setDirection] = useState<DirectionType>(null);
    const [currentX, setCurrentX] = useState<number>(initialX);
    const [currentY, setCurrentY] = useState<number>(initialY);
    const [currentHeight, setCurrentHeight] = useState<number>(height);
    const [currentWidth, setCurrentWidth] = useState<number>(width);
    const [currentRotation, setCurrentRotation] = useState<number>(initialRotation);
    const targetRef = React.useRef<HTMLDivElement>(null);
    const moveableRef = React.useRef<Moveable>(null);

    useEffect(() => {
        setCurrentHeight(height);
        setCurrentWidth(width);
    }, [height, width]);

    useEffect(() => {
        if (moveableRef.current) {
            moveableRef.current.updateRect();
        }
    }, [currentWidth, currentHeight, currentX, currentY, currentRotation]);
    useEffect(() => {
        setCurrentX(initialX);
        setCurrentY(initialY);
        setCurrentRotation(initialRotation);
    }, [initialX, initialY, initialRotation])

    useEffect(() => {
    }, [zIndex, isActive])

    // Expose methods to the parent component using `useImperativeHandle`
    useImperativeHandle(ref, () => ({
        triggerUpdateRect() {
            if (moveableRef.current) {
                moveableRef.current.updateRect();
            }
        }
    }));

    return (
        <div className={`container2 ${isActive ? 'active' : 'inactive'} ${isMultiSelectionBox ? 'multiselectionbox': ''}`}>
            <div
              onMouseDown={(event: React.MouseEvent) => {
                event.stopPropagation();
                if (onMouseDown) {
                    onMouseDown(event);
                }
              }}
              onClick={onSelect}
              className={`target  ${isMultiSelected ? 'multiselectedbox' : ''} ${isMultiSelectionBox ? 'multiselectionbox': ''}`}
              ref={targetRef}
              style={{
                zIndex: zIndex,
                position: 'absolute',
                height: currentHeight,
                width: currentWidth,
                transform: `translate(${currentX}px, ${currentY}px) rotate(${currentRotation}deg)`,
              }}
            >
                {children}
            </div>

            <Moveable
                ref={moveableRef}
                target={targetRef}
                origin={false}
                rotatable={isActive && rotatable}
                resizable={isActive && resizable}
                draggable={isActive}
                keepRatio={lockAspectRatio && !cropDirections.includes(direction || '')}
                throttleResize={1}
                //renderDirections={cropable ? ["nw","n","ne","w","e","sw","s","se"] : ['nw', 'ne', 'sw', 'se']}
                renderDirections={handles}
                onRotate={({ beforeRotate }) => {
                    setCurrentRotation(beforeRotate)
                    onRotate(beforeRotate);
                }}
                onRotateEnd={({ lastEvent }) => {
                    if (lastEvent) {
                        const { rotate } = lastEvent; // Get the final rotation angle
                        setCurrentRotation(rotate);
                        onRotateStop(rotate);
                    }
                }}
                onResize={async (e) => {
                    let direction: DirectionType = null;
                    if (e.direction[0] === 0 && e.direction[1] === -1) {
                        direction = 'top';
                        e.height = Math.min(maxVerticalSize * (1 - cropDimensions.bottom), e.height);
                    } else if (e.direction[0] === 0 && e.direction[1] === 1) {
                        direction = 'bottom';
                        e.height = Math.min(maxVerticalSize * (1 - cropDimensions.top), e.height);
                    } else if (e.direction[0] === -1 && e.direction[1] === 0) {
                        direction = 'left';
                        e.width = Math.min(maxHorizontalSize * (1- cropDimensions.right), e.width);
                    } else if (e.direction[0] === 1 && e.direction[1] === 0) {
                        direction = 'right';
                        e.width = Math.min(maxHorizontalSize * (1 - cropDimensions.left), e.width);
                    } else if (e.direction[0] === 1 && e.direction[1] === -1) {
                        direction = 'ne';
                    } else if (e.direction[0] === -1 && e.direction[1] === -1) {
                        direction = 'nw';
                    } else if (e.direction[0] === 1 && e.direction[1] === 1) {
                        direction = 'se';
                    } else if (e.direction[0] === -1 && e.direction[1] === 1) {
                        direction = 'sw';
                    }
                    e.width = Math.max(3, e.width);
                    e.width = Math.min(maxWidth, e.width);
                    if (direction === 'ne' || direction === 'se' || direction === 'nw' || direction ==='sw' || direction === 'top' || direction === 'bottom') {
                        // only set the height if there should be any change to the height
                        // this is needed to dynamically resize from outside this container by setting
                        // new height and width. otherwise the resize will be overwritten by the component assuming the height should not change
                        // from the resize start under these conditions - so we must override the component assumed height
                        e.height = Math.max(3, e.height);
                        e.height = Math.min(maxHeight, e.height);
                        e.height = Math.floor(e.height);
                        e.target.style.height = `${e.height}px`;
                    }

                    e.width = Math.floor(e.width);

                    e.target.style.width = `${e.width}px`;
                    e.target.style.transform = e.drag.transform;

                    const x = e.drag.translate[0];
                    const y = e.drag.translate[1];

                    if (onResize) {
                        const newHeight = await onResize(
                            startX, startY, startWidth, startHeight,
                            x, y,
                            e.width, e.height, direction as DirectionType);
                        if (newHeight) {
                            e.height = newHeight;
                            setCurrentHeight(newHeight);
                        }
                    }

                    setCurrentWidth(e.width);
                    if (direction === 'ne' || direction === 'se' || direction === 'nw' || direction ==='sw' || direction === 'top' || direction === 'bottom') {
                        // see comment above
                        setCurrentHeight(e.height);
                    }
                    setCurrentX(x)
                    setCurrentY(y);
                    setDirection(direction);
                }}
                onResizeStart={e => {
                    setStartX(currentX);
                    setStartY(currentY);
                    setStartWidth(currentWidth);
                    setStartHeight(currentHeight);
                    onResizeStart(currentX, currentY, currentWidth, currentHeight)
                }}
                onResizeEnd={async (e) => {
                    const newHeight = await onResizeStop(startX, startY, startWidth, startHeight, currentX, currentY, currentWidth, currentHeight, direction);
                    if (newHeight) {
                        //e.height = newHeight;
                        setCurrentHeight(newHeight);
                    }
                }}
                throttleDrag={1}
                edgeDraggable={false}
                onDrag={e => {
                    const x = e.translate[0];
                    const y = e.translate[1];
                    setCurrentX(x);
                    setCurrentY(y);

                    e.target.style.transform = e.transform;
                    onDrag(startX, x, startY, y);
                }}
                onDragStart={e => {
                    setStartX(currentX);
                    setStartY(currentY);
                    onDragStart(currentX, currentY);
                }}
                onDragEnd={e => {
                    onDragStop(startX, currentX, startY, currentY);
                }}
            />
        </div>
    );
});

export {
    type DirectionType,
    cropDirections
}

export default MoveableBox;
