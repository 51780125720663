import React, { ReactElement } from 'react';
import { ButtonBase, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';

interface EditorNavbarIconProps {
  isDisabled: boolean;
  text: string;
  isMobile: boolean;
  hoverColor: string;
  onClick: () => void;
  children: ReactElement<any>; // Restrict to a ReactElement to use `sx` reliably
}

const EditorNavbarIcon: React.FC<EditorNavbarIconProps> = ({ text, isMobile, isDisabled, hoverColor, onClick, children }) => {
  return (
    <Tooltip title="" placement="right">
      <ButtonBase
        onClick={isDisabled ? undefined : onClick}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: isMobile ? '68px' : '100%',
          height: isMobile ? '100%' : '68px',
          marginTop: isMobile ? '0px' : '30px',
          padding: '10px',
          opacity: isDisabled ? 0.5 : 1,
          pointerEvents: isDisabled ? 'none' : 'auto',
          '&:hover .MuiSvgIcon-root': {
            color: isMobile || isDisabled ? '' : hoverColor,
          },
        }}
      >
        <ListItemIcon sx={{ flexGrow: 1, justifyContent: 'center', minWidth: '0'}}>
          {React.cloneElement(children, {
            sx: {
              ...(children.props.sx || {}),
              color: isDisabled ? '#b0b0b0' : children.props.sx?.color,
            },
          })}
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body2" sx={{ fontSize: '0.8rem', color: '#7f7f7f' }}>
              {text}
            </Typography>
          }
          sx={{ fontSize: '2rem', marginTop: isMobile ? '0px' : '8px', marginBottom: 0 }}
        />
      </ButtonBase>
    </Tooltip>
  );
};

export default EditorNavbarIcon;
