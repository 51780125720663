import React from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import DuplicateIcon from '@mui/icons-material/FileCopyOutlined';

type MenuOpenConfig = {
  isOpen: boolean;
  shouldActivateSubtitlesDownload: boolean;
  shouldActivateCopy: boolean;
  shouldActivatePaste: boolean;
  shouldActivateDuplicate: boolean;
  shouldActivateDelete: boolean;
};

interface RightClickMenuProps {
  openConfig: MenuOpenConfig | null;
  position: { mouseX: number | null; mouseY: number | null };
  onClose: () => void;
  onDownloadSubtitles?: () => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
  onPaste?: () => void;
  onCopy?: () => void;
}

const RightClickMenu: React.FC<RightClickMenuProps> = ({
  openConfig,
  position,
  onClose,
  onDownloadSubtitles,
  onDelete,
  onDuplicate,
  onPaste,
  onCopy,
}) => {
  const hotkeyStyle = {
    marginLeft: 'auto',
    fontSize: '0.8rem',
    color: 'gray',
    paddingLeft: '60px'
  };

  return (
    <Menu
      open={openConfig ? openConfig.isOpen : false}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        position.mouseX !== null && position.mouseY !== null
          ? { top: position.mouseY, left: position.mouseX }
          : undefined
      }
      MenuListProps={{
        autoFocusItem: false,
      }}
      PaperProps={{
        sx: {
          minWidth: 300, // Set a reasonable minimum width for the menu
          width: 300,    // Set a fixed width for the menu
        },
      }}
    >
      {onCopy && (
        <MenuItem
          disabled={openConfig ? !openConfig.shouldActivateCopy : false}
          onClick={() => {
            onCopy();
            onClose();
          }}
        >
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Copy" />
          <span style={hotkeyStyle}>Ctrl+C</span>
        </MenuItem>
      )}

      {onPaste && (
        <MenuItem
          disabled={openConfig ? !openConfig.shouldActivatePaste : false}
          onClick={() => {
            onPaste();
            onClose();
          }}
        >
          <ListItemIcon>
            <ContentPasteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Paste" />
          <span style={hotkeyStyle}>Ctrl+V</span>
        </MenuItem>
      )}

      {onDuplicate && (
        <MenuItem
          disabled={openConfig ? !openConfig.shouldActivateDuplicate : false}
          onClick={() => {
            onDuplicate();
            onClose();
          }}
        >
          <ListItemIcon>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Duplicate" />
        </MenuItem>
      )}


      {onDownloadSubtitles && (
        <Divider />
      )}

      {onDownloadSubtitles && (
        <MenuItem
          disabled={openConfig ? !openConfig.shouldActivateSubtitlesDownload : false}
          onClick={() => {
            onDownloadSubtitles();
            onClose();
          }}
        >
          <ListItemIcon>
            <DownloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Download Subtitles" />
        </MenuItem>
      )}

      <Divider />

      {onDelete && (
        <MenuItem
          disabled={openConfig ? !openConfig.shouldActivateDelete : false}
          onClick={() => {
            onDelete();
            onClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
          <span style={hotkeyStyle}>Del</span>
        </MenuItem>
      )}
    </Menu>
  );
};

export default RightClickMenu;

export { type MenuOpenConfig };
