import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardActionArea,
  Typography,
  Grid,
  Slider,
  ToggleButton,
  Box,
  FormControl,
  TextField,
  Popover,
  Autocomplete,
  Divider,
  Tooltip,
  useTheme
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  VerticalAlignTop,
  VerticalAlignCenter,
  VerticalAlignBottom,
  FormatSize,
  Edit,
} from '@mui/icons-material';
import { ChromePicker, ColorResult } from 'react-color';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/material/styles';
import { debounce } from 'lodash';

import { Container, Section, SectionHeader } from './Styles';
import TextMedia from '../media/Text';
import TimelineControls from './TimelineControls';
import { useEditorContext } from '../VideosContext';

type SelectedMediaType = {
  media: TextMedia | null;
  start: number,
  end: number
}

interface TextStyleChangeOptions {
  text: string;
  style: React.CSSProperties;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  start?: number;
  end?: number;
  commit?: boolean;
  calculateWidth?: boolean;
  calculateHeight?: boolean;
}

interface TextOption {
  text: string;
  font: string;
  style: React.CSSProperties;
}

const textOptions: TextOption[] = [
  { text: 'Elegant', font: 'Serif', style: { fontFamily: 'Serif', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Modern', font: 'Sans-serif', style: { fontFamily: 'Arial', fontSize: '22px', fontWeight: 'bold' } },
  { text: 'Bold', font: 'Impact', style: { fontFamily: 'Impact', fontSize: '26px', fontWeight: 'bold' } },
  { text: 'Script', font: 'Cursive', style: { fontFamily: 'Cursive', fontSize: '28px', fontWeight: 'normal' } },
  { text: 'Classic', font: 'Times New Roman', style: { fontFamily: 'Times New Roman', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Techy', font: 'Courier New', style: { fontFamily: 'Courier New', fontSize: '22px', fontWeight: 'normal' } },
  { text: 'Fun', font: 'Comic Sans MS', style: { fontFamily: 'Comic Sans MS', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Retro', font: 'Trebuchet MS', style: { fontFamily: 'Trebuchet MS', fontSize: '26px', fontWeight: 'bold' } },
  { text: 'Classy', font: 'Georgia', style: { fontFamily: 'Georgia', fontSize: '28px', fontWeight: 'normal' } },
  { text: 'Minimal', font: 'Helvetica', style: { fontFamily: 'Helvetica', fontSize: '24px', fontWeight: 'lighter' } },
  { text: 'Elegant Script', font: 'Great Vibes', style: { fontFamily: 'Great Vibes', fontSize: '30px', fontWeight: 'normal' } },
  { text: 'Fancy', font: 'Dancing Script', style: { fontFamily: 'Dancing Script', fontSize: '28px', fontWeight: 'normal' } },
  { text: 'Creative', font: 'Pacifico', style: { fontFamily: 'Pacifico', fontSize: '30px', fontWeight: 'normal' } },
  { text: 'Stylish', font: 'Lobster', style: { fontFamily: 'Lobster', fontSize: '28px', fontWeight: 'bold' } },
  { text: 'Tech', font: 'Roboto', style: { fontFamily: 'Roboto', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Groovy', font: 'Fredoka One', style: { fontFamily: 'Fredoka One', fontSize: '26px', fontWeight: 'bold' } },
  { text: 'Unique', font: 'Abril Fatface', style: { fontFamily: 'Abril Fatface', fontSize: '30px', fontWeight: 'bold' } },
  { text: 'Handwritten', font: 'Shadows Into Light', style: { fontFamily: 'Shadows Into Light', fontSize: '24px', fontWeight: 'normal' } },
  { text: 'Playful', font: 'Chewy', style: { fontFamily: 'Chewy', fontSize: '26px', fontWeight: 'normal' } },
  { text: 'Digital', font: 'Press Start 2P', style: { fontFamily: 'Press Start 2P', fontSize: '20px', fontWeight: 'normal' } },
];

const fontOptions = Array.from(
  new Set([
    'Serif', 'Sans-serif', 'Cursive', 'Arial', 'Verdana', 'Helvetica', 'Tahoma', 'Trebuchet MS', 'Times New Roman',
    'Georgia', 'Garamond', 'Courier New', 'Comic Sans MS', 'Impact', 'Lucida Console',
    'Palatino Linotype', 'Book Antiqua', 'Century Gothic', 'Franklin Gothic Medium',
    'Brush Script MT', 'Segoe UI', 'Microsoft Sans Serif', 'Consolas', 'Futura', 'Optima',
    'Roboto', 'Lato', 'Ubuntu', 'Montserrat', 'Raleway', 'PT Sans', 'Oswald', 'Open Sans',
    'Poppins', 'Noto Sans', 'Playfair Display', 'Merriweather', 'Lora', 'Baskerville',
    'Inconsolata', 'Nunito', 'Quicksand', 'Gill Sans', 'Pacifico', 'Dancing Script',
    'Lobster', 'Caveat', 'Amatic SC', 'Shadows Into Light', 'Great Vibes', 'Indie Flower',
    'Abril Fatface', 'Josefin Sans', 'Righteous', 'Anton', 'Baloo', 'Carter One', 'Fredoka One',
    'Bungee', 'Fira Sans', 'Asap', 'Signika', 'Jost', 'Exo', 'Manrope', 'Alegreya',
    'Rubik', 'Teko', 'Archivo Black', 'Tangerine', 'Rye', 'Special Elite', 'Racing Sans One',
    'Press Start 2P', 'Black Ops One', 'Sigmar One', 'Chewy', 'Bangers', 'Permanent Marker',
    'Rock Salt', 'Sacramento', 'Zeyada', 'Allura', 'Courgette', 'Satisfy', 'Kaushan Script',
    'Cinzel', 'Crimson Text', 'Lobster Two', 'Bitter', 'PT Serif', 'Karla', 'Mukta',
    'Rokkitt', 'Fjalla One', 'Nobile', 'Varela Round', 'Muli', 'Source Serif Pro',
  ])
);

type TextAttributes = {
  color: string;
  backgroundColor: string;
  fontSize: number;
  textAlign: CanvasTextAlign;
  verticalAlign: 'top' | 'middle' | 'bottom';
  fontFamily: string;
  fontWeight: 'normal' | 'bold' | 'lighter';
  fontStyle: 'normal' | 'italic';
  textDecoration: 'none' | 'underline';
  customText: string;
  start: number;
  end: number;
};
const defaultStart = 0;
const defaultEnd = 0;
const initialTextAttributes: TextAttributes = {
  color: '#FFFFFFFF',
  backgroundColor: '#00000000',
  fontSize: 48,
  textAlign: 'center',
  verticalAlign: 'top',
  fontFamily: 'Roboto',
  fontWeight: 'normal',
  fontStyle: 'normal',
  textDecoration: 'none',
  customText: 'Your Text Here',
  start: defaultStart,
  end: defaultEnd
};

interface TextMenuProps {
  selectedMedia: SelectedMediaType | null;
  isMobile: boolean;
  onTextCreated: (text: string, style: React.CSSProperties) => void;
  onTextStyleSelect: (options: TextStyleChangeOptions) => Promise<number | undefined>;
}

const StyledCard = styled(Card)(({ theme }: { theme: Theme }) => ({
  height: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: theme.shadows[3],
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    boxShadow: theme.shadows[6],
    transform: 'scale(1.02)',
  },
}));

const textThrottleTime = 300;
const debouncedOnTextStyleSelect =
  debounce((options: TextStyleChangeOptions, callback) => {
      callback(options)
    }, textThrottleTime)

const TextMenu: React.FC<TextMenuProps> = ({ selectedMedia, isMobile, onTextCreated, onTextStyleSelect }) => {
  const theme = useTheme();

  const [textAttributes, setTextAttributes] = useState<TextAttributes>(initialTextAttributes);
  const [anchorElTextColor, setAnchorElTextColor] = useState<null | HTMLElement>(null);
  const [anchorElBgColor, setAnchorElBgColor] = useState<null | HTMLElement>(null);
  const { totalDuration } = useEditorContext();

  useEffect(() => {
    if (selectedMedia?.media) {
      const { text, style } = selectedMedia.media;
      setTextAttributes({
        customText: text,
        fontSize: parseInt(style?.fontSize?.toString() || initialTextAttributes.fontSize.toString()),
        color: style?.color || initialTextAttributes.color,
        backgroundColor: style?.backgroundColor || initialTextAttributes.backgroundColor,
        fontFamily: style?.fontFamily || initialTextAttributes.fontFamily,
        fontWeight: (style?.fontWeight as 'normal' | 'bold' | 'lighter') || initialTextAttributes.fontWeight,
        fontStyle: (style?.fontStyle as 'normal' | 'italic') || initialTextAttributes.fontStyle,
        textDecoration: (style?.textDecoration as 'none' | 'underline') || initialTextAttributes.textDecoration,
        textAlign: (style?.textAlign as CanvasTextAlign) || initialTextAttributes.textAlign,
        verticalAlign: (style?.verticalAlign as 'top' | 'middle' | 'bottom') || initialTextAttributes.verticalAlign,
        start: selectedMedia.start || defaultStart,
        end: selectedMedia.end || defaultEnd,
      });
    } else {
      setTextAttributes(initialTextAttributes);
    }
  }, [selectedMedia?.media]);

  const handleTextAttributeChange = async (updatedAttributes: Partial<TextAttributes>, commit: boolean = true, debounce: boolean = false) => {
    const newAttributes = { ...textAttributes, ...updatedAttributes };
    setTextAttributes(newAttributes);

    if (selectedMedia) {
      const updatedStyle: React.CSSProperties = {
        fontSize: `${newAttributes.fontSize}px`,
        color: newAttributes.color,
        backgroundColor: newAttributes.backgroundColor,
        textAlign: newAttributes.textAlign,
        verticalAlign: newAttributes.verticalAlign,
        fontFamily: newAttributes.fontFamily,
        fontWeight: newAttributes.fontWeight,
        fontStyle: newAttributes.fontStyle,
        textDecoration: newAttributes.textDecoration,
      };
      if (debounce) {
        debouncedOnTextStyleSelect({text: newAttributes.customText || '', style: updatedStyle, start: updatedAttributes.start, end: updatedAttributes.end, calculateWidth: true, commit: commit}, onTextStyleSelect);
      } else {
        onTextStyleSelect({text: newAttributes.customText || '', style: updatedStyle, start: updatedAttributes.start, end: updatedAttributes.end, calculateWidth: true, commit: commit});
      }
    }
  };

  const handleSelectStyle = (text: string, style: React.CSSProperties) => {
    onTextCreated(text || '', {...initialTextAttributes, ...style });
  };

  // Color Picker Handlers
  const handleTextColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTextColor(event.currentTarget);
  };

  const handleBgColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBgColor(event.currentTarget);
  };

  const handleTextColorClose = () => {
    setAnchorElTextColor(null);
  };

  const handleBgColorClose = () => {
    setAnchorElBgColor(null);
  };

  const handleTextColorChange = (colorResult: ColorResult, commit: boolean) => {
    const { r, g, b, a } = colorResult.rgb;
    handleTextAttributeChange({ color: `rgba(${r},${g},${b},${a})` }, commit);
  };

  const handleBgColorChange = (colorResult: ColorResult, commit: boolean) => {
    const { r, g, b, a } = colorResult.rgb;
    if (a === 0) {
      handleTextAttributeChange({ backgroundColor: 'transparent' }, commit);
    } else {
      handleTextAttributeChange({ backgroundColor: `rgba(${r},${g},${b},${a})` }, commit);
    }
  };
  const triggerTimelineChange = (newStart: number, newEnd: number) => {
    handleTextAttributeChange({ start: newStart, end: newEnd }, true);
  };

  // Render text options
  const renderTextOptions = () => (
    <Box sx={{ direction: 'ltr', paddingBottom: 4 }}>
      <Typography
        variant={'h5'}
        gutterBottom
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Select Text Style
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />

      <Grid container spacing={2}>
        {textOptions.map((option, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <StyledCard>
              <CardActionArea
                onClick={() => handleSelectStyle(option.text, option.style)}
                sx={{
                  height: '100%',
                  width: '100%',
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      ...option.style,
                      textAlign: 'center',
                      color: '#000',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={option.text}
                  >
                    {option.text}
                  </Typography>
                </Box>
              </CardActionArea>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  // Render customization options
  const renderCustomizationOptions = () => (
    <Box sx={{ direction: 'ltr' }}>
      <Typography
        variant={'h5'}
        gutterBottom
        sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
      >
        Customize Text
      </Typography>

      <Divider sx={{ marginBottom: 2 }} />

      <Section elevation={3}>
        <SectionHeader>
            <Edit sx={{ marginRight: 1, color: theme.palette.primary.main }} />
            Edit Text
        </SectionHeader>
        {/* Input for Custom Text */}
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <TextField
            autoFocus={isMobile ? false : true}
            multiline={true}
            label="Enter Your Text"
            minRows={3}
            maxRows={3}
            value={textAttributes.customText}
            onChange={(e) => {
              handleTextAttributeChange({ customText: e.target.value }, false, false)
              handleTextAttributeChange({ customText: e.target.value }, true, true)
            }}
            variant="outlined"
            InputProps={{
              style: { fontSize: '1rem', height: '100px' },
            }}
          />
        </FormControl>
      </Section>

      <Section elevation={3}>
        <SectionHeader>
            <FormatSize sx={{ marginRight: 1, color: theme.palette.primary.main }} />
            Font Settings
        </SectionHeader>
        {/* Font Family Selector */}
        <FormControl fullWidth sx={{ marginBottom: 3 }}>
          <Autocomplete
            options={fontOptions}
            value={textAttributes.fontFamily}
            onChange={(e, newValue) => {
              if (newValue) handleTextAttributeChange({ fontFamily: newValue });
            }}
            renderInput={(params) => <TextField {...params} label="Font Family" variant="outlined" />}
            getOptionLabel={(option) => option as string}
            renderOption={(props, option) => (
              <Box component="li" {...props} style={{ fontFamily: option }}>
                {option}
              </Box>
            )}
          />
        </FormControl>

        {/* Font Size Slider */}
        <Typography gutterBottom variant="subtitle1">
          Font Size: <strong>{textAttributes.fontSize}px</strong>
        </Typography>
        <Slider
          value={textAttributes.fontSize}
          min={12}
          max={300}
          onChange={(e, newValue) => handleTextAttributeChange({ fontSize: newValue as number }, false)}
          onChangeCommitted={(e, newValue) => handleTextAttributeChange({ fontSize: newValue as number }, true)}
          valueLabelDisplay="auto"
          sx={{ marginBottom: 3 }}
        />
      </Section>

      <Section elevation={3}>
        <SectionHeader>
            <FormatBold sx={{ marginRight: 1, color: theme.palette.primary.main }} />
            Font Settings
        </SectionHeader>

        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between',}}>
          {/* Font Styles */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Bold">
              <ToggleButton
                value="bold"
                selected={textAttributes.fontWeight === 'bold'}
                onClick={() =>
                  handleTextAttributeChange({
                    fontWeight: textAttributes.fontWeight === 'bold' ? 'normal' : 'bold',
                  })
                }
              >
                <FormatBold />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Italic">
              <ToggleButton
                value="italic"
                selected={textAttributes.fontStyle === 'italic'}
                onClick={() =>
                  handleTextAttributeChange({
                    fontStyle: textAttributes.fontStyle === 'italic' ? 'normal' : 'italic',
                  })
                }
              >
                <FormatItalic />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Underline">
              <ToggleButton
                value="underline"
                selected={textAttributes.textDecoration === 'underline'}
                onClick={() =>
                  handleTextAttributeChange({
                    textDecoration: textAttributes.textDecoration === 'underline' ? 'none' : 'underline',
                  })
                }
              >
                <FormatUnderlined />
              </ToggleButton>
            </Tooltip>
          </Box>

          {/* Text Alignment */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Align Left">
              <ToggleButton
                value="left"
                selected={textAttributes.textAlign === 'left'}
                onClick={() => handleTextAttributeChange({ textAlign: 'left' })}
              >
                <FormatAlignLeft />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Align Center">
              <ToggleButton
                value="center"
                selected={textAttributes.textAlign === 'center'}
                onClick={() => handleTextAttributeChange({ textAlign: 'center' })}
              >
                <FormatAlignCenter />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Align Right">
              <ToggleButton
                value="right"
                selected={textAttributes.textAlign === 'right'}
                onClick={() => handleTextAttributeChange({ textAlign: 'right' })}
              >
                <FormatAlignRight />
              </ToggleButton>
            </Tooltip>
          </Box>

          {/* Text Vertical Alignment */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Vertical Align Top">
              <ToggleButton
                value="top"
                selected={textAttributes.verticalAlign === 'top'}
                onClick={() => handleTextAttributeChange({ verticalAlign: 'top' })}
              >
                <VerticalAlignTop />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Vertical Align Center">
              <ToggleButton
                value="middle"
                selected={textAttributes.verticalAlign === 'middle'}
                onClick={() => handleTextAttributeChange({ verticalAlign: 'middle' })}
              >
                <VerticalAlignCenter />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Vertical Align Bottom">
              <ToggleButton
                value="bottom"
                selected={textAttributes.verticalAlign === 'bottom'}
                onClick={() => handleTextAttributeChange({ verticalAlign: 'bottom' })}
              >
                <VerticalAlignBottom />
              </ToggleButton>
            </Tooltip>
          </Box>
        </Box>
        <Box>
        {/* Color Pickers */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginLeft: 3 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                width: 36,
                height: 36,
                backgroundColor: textAttributes.color,
                borderRadius: '50%',
                border: '1px solid #ccc',
                cursor: 'pointer',
                margin: '0 auto',
                marginBottom: 1,
              }}
              onClick={handleTextColorClick}
              draggable={false}
              onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
            />
            <Typography variant="subtitle1">
              Text Color
            </Typography>
            <Popover
              open={Boolean(anchorElTextColor)}
              anchorEl={anchorElTextColor}
              onClose={handleTextColorClose}
              draggable={false}
              onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ChromePicker
                color={textAttributes.color}
                onChange={(color: ColorResult) => handleTextColorChange(color, false)}
                onChangeComplete={(color: ColorResult) => {handleTextColorChange(color, true)}}
                disableAlpha={false} />
            </Popover>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                width: 36,
                height: 36,
                backgroundColor: textAttributes.backgroundColor === 'transparent' ? '#f0f0f0' : textAttributes.backgroundColor,
                borderRadius: '50%',
                border: '1px solid #ccc',
                cursor: 'pointer',
                margin: '0 auto',
                marginBottom: 1,
              }}
              draggable={false}
              onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
              onClick={handleBgColorClick}
            />
            <Typography variant="subtitle1">
              Background Color
            </Typography>
            <Popover
              open={Boolean(anchorElBgColor)}
              anchorEl={anchorElBgColor}
              onClose={handleBgColorClose}
              draggable={false}
              onDragStart={(e) => e.preventDefault()} // Prevent dragging callback
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ChromePicker
                color={textAttributes.backgroundColor === 'transparent' ? 'rgba(255,255,255,0)' : textAttributes.backgroundColor}
                onChange={(color: ColorResult) => handleBgColorChange(color, false)}
                onChangeComplete={(color: ColorResult) => handleBgColorChange(color, true)}
                disableAlpha={false}
              />
            </Popover>
          </Box>
        </Box>
      </Box>

      </Box>
      </Section>
      {/* Timeline Controls */}
      <TimelineControls
        start={textAttributes ? textAttributes.start : defaultStart}
        end={textAttributes ? textAttributes.end : defaultEnd}
        maxEnd={totalDuration}
        triggerTimelineChange={triggerTimelineChange}
      />
    </Box>
  );

  return (
    <Container
      sx={{
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {selectedMedia === null ? renderTextOptions() : renderCustomizationOptions()}
    </Container>
  );
};

export {
  type TextStyleChangeOptions
}
export default TextMenu;
