import { Box, IconButton, Button, CircularProgress, Tooltip, TextField, Menu, MenuItem, Divider, ListItemIcon, Typography } from '@mui/material';
import { DownloadOutlined, Undo, Redo, CloudDone, CloudOff, MoreVert, Add } from '@mui/icons-material';
import CommandHistory from './undo/UndoInterface';
import EditorIconButton from './EditorIconButton';
import AspectRatioSelector from './AspectRatioSelector';
import AppBar from './AppBar';
import { useState } from 'react';

interface EditorTopBarProps {
  projectName: string | null;
  lastSaveDate: number | null;
  isMobile: boolean;
  isDownloading: boolean;
  isSaving: boolean;
  isConnected: boolean;
  canvasAspectRatio: number;
  handleChangeProjectName: (name: string) => void;
  handleNewProject: () => void;
  handleAspectRatioSelected: (newRatio: number) => void;
  handleDownloadVideo: () => void;
}

const EditorTopBar: React.FC<EditorTopBarProps> = ({
  projectName,
  lastSaveDate,
  canvasAspectRatio,
  isDownloading,
  isSaving,
  isConnected,
  isMobile,
  handleChangeProjectName,
  handleNewProject,
  handleAspectRatioSelected,
  handleDownloadVideo,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  // State for menu handling
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);

  // Format the date for display in the tooltip
  const formattedDate = lastSaveDate
    ? new Date(lastSaveDate).toLocaleString()
    : 'No save yet';

  const handleNameClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    // onUpdateProjectName(name); // Save the new project name on blur
  };

  // Menu handlers
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleNewProjectClick = () => {
    // Logic to start a new project
    handleMenuClose();
    handleNewProject();
  };

  return (
    <AppBar title={''} color='inherit' isEditing={false} onTitleChange={() => {}}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        width: '100%'
      }}>
        <Box sx={{ display: 'flex', flexGrow: 1, height: '30px', alignItems: 'center', justifyContent: 'left' }}>
          <EditorIconButton isMobile={isMobile} onClick={() => CommandHistory.undo()}>
            <Undo />
          </EditorIconButton>
          <EditorIconButton isMobile={isMobile} onClick={() => CommandHistory.redo()}>
            <Redo />
          </EditorIconButton>
          {!isMobile && (
            <AspectRatioSelector isMobile={isMobile} onSelect={handleAspectRatioSelected} selectedRatio={canvasAspectRatio} />
          )}
          {/* Enhanced Vertical Divider */}
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              marginX: 0.7,
              height: '30px', // Adjust height to fit nearby icons
              borderWidth: '1px', // Increase thickness
              borderRightStyle: 'solid' // Ensures it's visible as a solid line
            }}
          />
          {/* Tooltip and Cloud Icon for Auto-Save Status */}
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            <Tooltip title={`Last saved: ${formattedDate}`} arrow>
              <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                {isSaving ? (
                  <CircularProgress size={16} />
                ) : isConnected ? (
                  <EditorIconButton isMobile={isMobile} onClick={() => {}} color='#000000' cursor='default'>
                    <CloudDone />
                  </EditorIconButton>
                ) : (
                  <EditorIconButton isMobile={isMobile} onClick={() => {}} color='#f44336' cursor='default'>
                    <CloudOff />
                  </EditorIconButton>
                )}
              </Box>
            </Tooltip>
          </Box>
          {/* Menu Icon and Menu */}
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            <IconButton
              color='inherit'
              onClick={handleMenuOpen}
              //sx={{ marginLeft: isMobile ? 0.5 : 0.5 }}
              aria-label='Project options'
            >
              <MoreVert />
            </IconButton>
              <Menu
                anchorEl={menuAnchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                keepMounted
              >
                <MenuItem onClick={handleNewProjectClick}>
                  <ListItemIcon>
                    <Add fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit">New Project</Typography>
                </MenuItem>
                {/* Add other menu items here if needed */}
              </Menu>
          </Box>
          {/* Editable Project Name */}
          <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
            {isEditing ? (
              <TextField
                variant='standard'
                value={projectName}
                autoFocus
                onBlur={handleBlur}
                onChange={(e) => {
                  handleChangeProjectName(e.target.value);
                }}
                sx={{ marginLeft: 1, minWidth: '50px' }}
              />
            ) : (
              <Box sx={{
                display: 'flex', height: '100%', alignItems: 'center', cursor: 'pointer' }} onClick={handleNameClick}>
                <span style={{ fontSize: isMobile ? '1rem' : '1rem', fontWeight: 400 }}>{projectName}</span>
              </Box>
            )}
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexGrow: 0, height: '30px', alignItems: 'center', justifyContent: 'right' }}>
          {/* Download Button */}
        <Button
          disabled={isDownloading}
            variant="outlined"
            color="primary"
            component="span"
            sx={{
              marginLeft: 0.2,
              display: "flex",
              alignItems: "center",
              "@media (max-width: 399px)": {
                ".button-text": { display: "none" }, // Hide text when button is below 120px wide
              },
            }}
            onClick={handleDownloadVideo}
          >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {isDownloading ? (
                <>
                  <CircularProgress size={24} />
                  <span className="button-text" style={{ marginLeft: 10 }}>Rendering</span>
                </>
              ) : (
                <>
                  <DownloadOutlined sx={{ color: "#888888" }} />
                  <span className="button-text" style={{ marginLeft: 10 }}>Download</span>
                </>
              )}
            </Box>
          </Button>
        </Box>
      </Box>
    </AppBar>
  );
};

export default EditorTopBar;
