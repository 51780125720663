import CryptoJS from 'crypto-js';
import mixpanel from 'mixpanel-browser';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import TextMedia from '../components/media/Text';
import Audio from '../components/media/Audio';
import Video from '../components/media/Video';
import ImageMedia from '../components/media/Image';

import { getRenderTasks, getSubtitlesTasks, getSignedSubtitlesDownloadUrl, getSignedUploadUrl } from '../api/ServerApi';
import getEngine from '../components/EffectsEngine';
import Media from '../components/media/Media';
import { MediaLayer, Segment } from './serializer';
import { DirectionType, cropDirections } from '../components/RotatableBox';
import Transition from '../components/media/Transition';

const taskCompleteStatuses = ['STOPPED', 'SUCCEEDED', 'FAILED', 'DEPROVISIONING', 'STOPPING'];

const Engine = await getEngine();

const downloadFromUrl = (url: string, filename: string) => {
  // Create a link element and trigger the download
  const link = document.createElement('a');
  link.href = url;
  link.download = filename; // Set the default filename
  document.body.appendChild(link);
  link.click();

  // Cleanup
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
}

const blobUrlToUint8Array = async (blobUrl: string) => {
  // Step 1: Fetch the Blob from the URL
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // Step 2: Convert the Blob to an ArrayBuffer
  const arrayBuffer = await blob.arrayBuffer();

  // Step 3: Convert the ArrayBuffer to a Uint8Array
  const uint8Array = new Uint8Array(arrayBuffer);

  return uint8Array;
}

const createVideoFd = async (video: any) => {
  const url = video.url;
  const filename = url.split('/').slice(-1); // get the guid for the blob
  return blobUrlToUint8Array(url).then((arr) => {
    return Engine.writeFile(`${filename}.mp4`, arr).then((_: any) => {
      return Engine.open_movie([`${filename}.mp4`]).then((fd: number) => {
        if (fd === -1) {
          console.error('engine returned bad fd')
          return -1;
        }
        return fd;
      });
    })
  });
}

const throttle: any = (callback: (...args: any[]) => any, throttleSeconds: number): (...args: any[]) => any => {
  let lastActivationTime: number | null = null;
  let activeTimeout = false;
  const throttledFunc = (...args: any[]) => {
    const timeNow = Date.now();

    const timeDiff = (lastActivationTime !== null) ?
      ((timeNow - lastActivationTime) / 1000) :
      throttleSeconds + 1;

    if (timeDiff > throttleSeconds) {
      lastActivationTime = timeNow;
      callback(...args);
    } else {
      if (!activeTimeout) {
        // add a call to the function after 300 seconds so we always have
        // a final call after the user stops
        activeTimeout = true;
        setTimeout(() => {
          activeTimeout = false;
          callback(...args);
        }, throttleSeconds);
      }
    }
  }
  return throttledFunc;
}

// Web Crypto API-based implementation
const calculateSha256WebCrypto = async (blob: Blob): Promise<string> => {
  const arrayBuffer = await blob.arrayBuffer();

  try {
    // Use the Web Crypto API to calculate the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);

    // Convert the hashBuffer to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert to byte array
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // Convert bytes to hex string

    return hashHex;
  } catch (error) {
    throw new Error("Web Crypto API not supported or failed.");
  }
};

// Fallback: CryptoJS-based implementation
const calculateSha256CryptoJS = async (blob: Blob): Promise<string> => {
  const arrayBuffer = await blob.arrayBuffer();

  // Convert the ArrayBuffer to a WordArray (required by CryptoJS)
  const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(arrayBuffer));

  // Hash the WordArray using SHA-256
  const hash = CryptoJS.SHA256(wordArray);

  // Convert the hash to a hex string
  return hash.toString(CryptoJS.enc.Hex);
};

// Unified function with fallback
const calculateSha256 = async (blob: Blob): Promise<string> => {
  if (window.crypto && crypto.subtle) {
    try {
      // Attempt to use the Web Crypto API
      return await calculateSha256WebCrypto(blob);
    } catch (error) {
      console.warn('Web Crypto API failed, falling back to CryptoJS:', error);
    }
  }

  // Fallback to CryptoJS if Web Crypto is not available or fails
  return calculateSha256CryptoJS(blob);
};

const uploadVideo = (url: string, video: Blob, fileType: string, onProgress: (percentage: number) => void): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.withCredentials = true;

    // Set up the progress listener
    xhr.upload.onprogress = (event: ProgressEvent) => {
      if (event.lengthComputable) {
        const percentage = Math.round((event.loaded / event.total) * 100);
        onProgress(percentage);
      }
    };

    // Success and error handlers
    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve();  // File uploaded successfully
      } else {
        reject(new Error(`Failed to upload file: ${url}, Status: ${xhr.status}`));
      }
    };

    xhr.onerror = () => reject(new Error('Network error occurred during upload'));

    // Set up and initiate the request
    xhr.open('PUT', url, true);
    xhr.setRequestHeader('Content-Type', fileType);

    // Send the video blob
    xhr.send(video);
  });
};

const uploadMediaSync = async (url: string, video: Blob, fileType: string): Promise<void> => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      body: video,
      headers: {
        'Content-Type': fileType,
      },
      credentials: 'include',
    });

    if (!response.ok) {
      throw new Error(`Failed to upload file: ${url}, Status: ${response.status}`);
    }
  } catch (error: any) {
    throw new Error(`Network error occurred during upload: ${error.message}`);
  }
};

const pollSubtitlesAndDownload = async (videoName: string, pollInterval: number, callback: (url: string | null) => void) => {
  try {
      const info = await getSubtitlesTasks();
      if (info === null) {
          callback(null);
          // if there is no info, something is wrong - the subtitles were not created
          return;
      }

      const foundVideos = info.filter((vid: any) => vid.fileName === videoName);
      if (foundVideos.length === 0) {
        setTimeout(() => pollSubtitlesAndDownload(videoName, pollInterval, callback), pollInterval);
        return;
      }
      const foundVideo = foundVideos[0];

      if (!taskCompleteStatuses.includes(foundVideo.status)) {
        // movie is still pending
        setTimeout(() => pollSubtitlesAndDownload(videoName, pollInterval, callback), pollInterval);
        return;
      }

     // TODO: using the connected video should not happen - add the hash to the return and remove connected video
     const subtitlesResponse = await getSignedSubtitlesDownloadUrl(foundVideo.connectedVideo.hash, 'text/vtt');
     if (!subtitlesResponse.presignedUrl) {
      console.error('Error downloading subtitles, no url in response:', foundVideo.url);
      callback(null);
      return;
     }

     const res = await fetch(subtitlesResponse.presignedUrl, { method: 'GET' });
     if (!res.ok) {
       console.error('Error downloading subtitles:', foundVideo.url);
       // poll every second until we get a response or error
       callback(null);
       return;
     }

     const url = window.URL.createObjectURL(await res.blob());
     callback(url);
  } catch (err) {
      console.error('Error polling rendered video:', err);
  }

  callback(null);
}

const getPendingSubtitleTasks = async (): Promise<any[]> => {
  const info = await getSubtitlesTasks();
  if (info === null) {
      return [];
  }

  return info.filter((vid: any) => !taskCompleteStatuses.includes(vid.status));
}

const pollRenderedVideoAndDownload = async (videoName: string, outputName: string, pollInterval: number, callback: () => void) => {
  try {
      const info = await getRenderTasks();
      if (info === null) {
          callback();
          // if there is no info, something is wrong - the video was not rendered
          return;
      }

      const foundVideos = info.filter((vid: any) => vid.fileName === videoName);
      if (foundVideos.length === 0) {
        setTimeout(() => pollRenderedVideoAndDownload(videoName, outputName, pollInterval, callback), pollInterval);
        return;
      }
      const foundVideo = foundVideos[0];

      if (!taskCompleteStatuses.includes(foundVideo.status)) {
        // movie is still pending
        setTimeout(() => pollRenderedVideoAndDownload(videoName, outputName, pollInterval, callback), pollInterval);
        return;
      }

      const res = await fetch(foundVideo.url, { method: 'GET' });
      if (!res.ok) {
        console.error('Error downloading file:', foundVideo.url);
        // poll every second until we get a response or error
        //setTimeout(() => pollRenderedVideoAndDownload(videoName, pollInterval, callback), pollInterval);
        callback();
        return;
      }
      const url = window.URL.createObjectURL(await res.blob());

      downloadFromUrl(url, outputName);
  } catch (err) {
      console.error('Error polling rendered video:', err);
  }

  callback();
}

const sendLoginTelemetry = (username: string, email: string, tenant: string, fullName: string, isAdmin: boolean) => {
  mixpanel.track('Sign In', {
    'Signin Type': 'Direct',
  });
  mixpanel.identify(email);
  mixpanel.people.set({
    $name: username,
    $email: email,
    tenant: tenant,
  });
}

function splitTextIntoLines(
  ctx: CanvasRenderingContext2D,
  text: string,
  maxWidth: number,
  maxLines?: number,
): { text: string; width: number }[] {
  const lines: { text: string; width: number }[] = [];
  const textSplitLines = text.split('\n');

  textSplitLines.forEach((splitLine: string) => {
    const words = splitLine.split(' ');
    let currentLine = '';
    let testWidth = 0;

    words.forEach((word) => {
      if (!word) {
        // Add back spaces
        word = ' ';
      }
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      const metrics = ctx.measureText(testLine);
      testWidth = metrics.width;

      if (testWidth > maxWidth && currentLine) {
        lines.push({ text: currentLine, width: ctx.measureText(currentLine).width });
        currentLine = '';

        // Check if the word itself is too long
        const wordMetrics = ctx.measureText(word);
        if (wordMetrics.width > maxWidth) {
          // Break the word into characters
          let wordLine = '';
          for (let i = 0; i < word.length; i++) {
            const char = word.charAt(i);
            const testWordLine = wordLine + char;
            const charMetrics = ctx.measureText(testWordLine);

            if (charMetrics.width > maxWidth && wordLine) {
              lines.push({ text: wordLine, width: ctx.measureText(wordLine).width });
              wordLine = char;
            } else {
              wordLine = testWordLine;
            }
          }
          if (wordLine) {
            currentLine = wordLine;
          }
        } else {
          currentLine = word;
        }
      } else if (testWidth > maxWidth) {
        // Word is too long to fit on a line by itself
        // Break the word into characters
        let wordLine = '';
        for (let i = 0; i < word.length; i++) {
          const char = word.charAt(i);
          const testWordLine = wordLine + char;
          const charMetrics = ctx.measureText(testWordLine);

          if (charMetrics.width > maxWidth && wordLine) {
            lines.push({ text: wordLine, width: ctx.measureText(wordLine).width });
            wordLine = char;
          } else {
            wordLine = testWordLine;
          }
        }
        if (wordLine) {
          currentLine = wordLine;
        }
      } else {
        currentLine = testLine;
      }
    });

    if (currentLine) {
      lines.push({ text: currentLine, width: ctx.measureText(currentLine).width });
    }
  });

  // Limit to maxLines if specified
  return maxLines ? lines.slice(0, maxLines) : lines;
}

interface CreateTextImageProps {
  canvas: HTMLCanvasElement;
  text: string;
  style: any;
  scaleFactor: number,
  hPadding: number;
  vPadding: number;
  width?: number;
  height?: number;
}

const createTextImage = async ({
  canvas,
  text,
  style,
  scaleFactor,
  width,
  height,
  hPadding = 0,
  vPadding = 0,
}: CreateTextImageProps): Promise<[string, number, number, number]> => {
  return new Promise((resolve) => {
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
    const scaledHPadding = hPadding * scaleFactor;
    const scaledVPadding = vPadding * scaleFactor;

    const fontSizeScaled = (style.fontSize ? parseInt(style.fontSize) : 24) * scaleFactor;
    // Set the font style and size based on the input style
    const fontSize = `${fontSizeScaled}px`; // Default font size
    const fontWeight = style.fontWeight ? style.fontWeight.toString() : 'normal'; // Default font weight
    const fontStyle = style.fontStyle ? style.fontStyle.toString() : 'normal'; // Default font style
    const fontFamily = style.fontFamily ? style.fontFamily : 'Arial'; // Default font family
    ctx.font = `${fontStyle} ${fontWeight} ${fontSize} ${fontFamily}`;

    // Measure the text dimensions (text width)
    const textWidth = ctx.measureText(text).width;
    const fontSizeNumber = fontSizeScaled; // Convert the font size to number
    const textHeight = fontSizeNumber; // Estimate text height (you can tweak the multiplier)

    const canvasWidth = (width || Math.ceil(textWidth + scaledHPadding));

    // allow the line counting to be larger than the width to prevent 'stuterring' in the UI
    // where it switches number of lines constantly probably due to bug in rounding or other UI related issue...
    const lines = splitTextIntoLines(ctx, text, canvasWidth - scaledHPadding);

    const totalTextHeight = Math.ceil(textHeight * lines.length);

    //height = (height !== undefined) ? height + vPadding : height;
    const canvasHeight = height || (totalTextHeight + scaledVPadding);

    // Resize the canvas to fit the text exactly
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    // Re-set the font after resizing the canvas (as resizing clears the canvas)
    ctx.font = `${fontStyle} ${fontWeight} ${fontSize} ${fontFamily}`;

    // Set the background color if provided
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = style.backgroundColor || '#FFFFFF'; // Default to white if no background color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Set text color
    ctx.fillStyle = style.color || '#000000'; // Default to black if no color is provided

    // Set text alignment (left, center, or right)
    const textAlign = style.textAlign || 'center'; // Default to center
    ctx.textAlign = textAlign as CanvasTextAlign;

    // Calculate x position based on alignment
    let x: number;
    if (textAlign === 'left') {
      x = 5; // Left-aligned: start at the left edge
    } else if (textAlign === 'right') {
      x = canvas.width - 5; // Right-aligned: start at the right edge but give some room (5px) so text isnt cut off
    } else {
      x = canvas.width / 2; // Center-aligned: center of the canvas
    }

    // Set vertical alignment (top, middle, or bottom)
    const verticalAlign = style.verticalAlign || 'middle'; // Default to middle
    if (verticalAlign === 'top') {
      ctx.textBaseline = 'top';
    } else if (verticalAlign === 'bottom') {
      ctx.textBaseline = 'bottom';
    } else {
      ctx.textBaseline = 'middle';
    }

    // Calculate the y position for the text based on vertical alignment
    let y: number;
    if (verticalAlign === 'top') {
      y = scaledVPadding / 2; // Top-aligned: start at the top edge
    } else if (verticalAlign === 'bottom') {
      y = canvas.height - scaledVPadding / 2; // Bottom-aligned: start at the bottom edge
    } else {
      y = (canvas.height) / 2; // Center-aligned: center of the canvas
      //y = 0; // Center-aligned: center of the canvas
    }

    const textNormalizer = textHeight / 32;

    const underlineHeight = 1 * textNormalizer; // Height of the underline
    const underlinePadding = 0; // Space between text and underline
    const drawUnderline = (context: any, xPos: number, yPos: number, lineWidth: number, padding: number) => {
      context.beginPath();
      context.strokeStyle = style.color || 'black'; // Underline color same as text color
      context.lineWidth = underlineHeight;
      ctx.moveTo(xPos, yPos + padding); // Start at the x minus text width
      ctx.lineTo(xPos + lineWidth, yPos + padding); // End at the text's width
      ctx.stroke();
    }

    // Draw each line of text
    lines.forEach((line, index) => {
      // Adjust the y position for each line based on the text height and alignment
      let yOffset = 0;
      if (verticalAlign === 'middle') {
        yOffset = index * textHeight;
        let xOffset: number;
        if (textAlign === 'left') {
          xOffset = 0;
        } else if (textAlign === 'right') {
          xOffset = -line.width;
        } else {
          xOffset = -line.width / 2;
        }
        if (line.text !== ' ' && style.textDecoration === 'underline') {
          drawUnderline(ctx, x + xOffset, y + yOffset + textHeight, line.width, underlinePadding);
        }
      } else if (verticalAlign === 'bottom') {
        yOffset = index * textHeight + (-textHeight * (lines.length - 1));
        let xOffset: number;
        if (textAlign === 'left') {
          xOffset = 0;
        } else if (textAlign === 'right') {
          xOffset = -line.width;
        } else {
          xOffset = -line.width / 2;
        }
        if (line.text !== ' ' && style.textDecoration === 'underline') {
          drawUnderline(ctx, x + xOffset, y - (lines.length - index - 1) * textHeight, line.width, 0);
        }
      } else {
        yOffset = index * textHeight;
        let xOffset: number;
        if (textAlign === 'left') {
          xOffset = 0;
        } else if (textAlign === 'right') {
          xOffset = -line.width;
        } else {
          xOffset = -line.width / 2;
        }
        if (line.text !== ' ' && style.textDecoration === 'underline') {
          //drawUnderline(ctx, x + xOffset, yOffset + textHeight, line.width, 4);
          drawUnderline(ctx, x + xOffset, yOffset + textHeight, line.width, textNormalizer * 6);
        }
      }
      ctx.fillText(line.text, x, y + yOffset);
    });

    // Convert the canvas to a data URL
    const frame = canvas.toDataURL('image/png');

    // Resolve the image data URL and the width of the text
    resolve([frame, canvas.width, canvas.height, totalTextHeight]);
  });
};

const debouncedMediaUpload =
    debounce((name: string, type: string, sha256: string, blobUrl: string) => {
      getSignedUploadUrl(name, type, sha256).then( async (uploadInformation: { generatedName: string, url: string}) => {
        if (uploadInformation.url) {
          const file = await (await fetch(blobUrl)).blob();
          // upload the video
          uploadVideo(uploadInformation.url, file, file.type, () => {});
        }
      })
    }, 2000);

interface applyStyleToTextComponentProps {
  textComponent: TextMedia;
  text: string;
  style: React.CSSProperties;
  scaleFactor: number;
  hPadding: number;
  vPadding: number;
  height?: number;
  width?: number;
  calculateHeight?: boolean;
  calculateWidth?: boolean;
}
const applyStyleToTextComponent = async ({
  textComponent,
  text,
  style,
  scaleFactor,
  hPadding,
  vPadding,
  height,
  width,
  calculateHeight = false,
  calculateWidth = false}: applyStyleToTextComponentProps): Promise<[TextMedia, number, number]> => {
  const canvas = textComponent.videoRef;
  const [image, textWidth, imageHeight, textHeight] = await createTextImage({
    canvas,
    text,
    style,
    scaleFactor,
    width: calculateWidth ? 0 : width || textComponent.scaledWidth as number,
    height: calculateHeight ? 0 : height || textComponent.scaledHeight as number,
    hPadding,
    vPadding,
  });

  const newTextComponent = textComponent.deepCopy();
  newTextComponent.scaleFactor = scaleFactor;
  newTextComponent.vPadding = vPadding;
  newTextComponent.hPadding = hPadding;
  newTextComponent.url = image;
  newTextComponent.style = {...style};

  newTextComponent.text = text;
  newTextComponent.style = style;

  const newWidth = calculateWidth ? textWidth + 1 : Math.max(width || newTextComponent.scaledWidth as number, textWidth);
  newTextComponent.baseWidth = newWidth;
  newTextComponent.width = newWidth;
  newTextComponent.scaledWidth = newWidth;
  const newHeight = (calculateHeight ? textHeight : height || newTextComponent.scaledHeight) as number;
  newTextComponent.scaledHeight = newHeight;
  newTextComponent.height = newHeight;
  newTextComponent.baseHeight = newHeight;

  return new Promise((resolve) => {
    resolve([newTextComponent, imageHeight, textHeight]);
    debouncedMediaUpload(newTextComponent.name, newTextComponent.type, newTextComponent.sha256, newTextComponent.url);
  });
}

const createVideoAudioMedia = async (file: File, sha256: string, videoInfo: any, row: number, serializedVideo?: any): Promise<Video | Audio> => {
  return new Promise((resolve) => {
    const videoURL = URL.createObjectURL(file);

    const video = document.createElement('video');
    video.style.display = 'none';
    video.src = videoURL;

    video.onloadedmetadata = async () => {

      mixpanel.track('videoMetadataLoaded', {
        'name': file.name,
        'size': file.size,
        'type': file.type,
        'src': video.src,
        'duration': video.duration,
      });

      const duration = video.duration;
      const storageFilePath = videoURL.split('/').splice(-1) + '.bmp';
      let newVideoObject: Video | Audio;
      if (file.type.startsWith('video/')) {
        newVideoObject = new Video({name: file.name, left: 0, start: 0, end: duration, videoRef: null, videoFdRef: null, framePendingTime: null, url: videoURL, duration, leftBackgroundOffset: 0, rightBackgroundOffset: 0,
          startBase: 0, endBase: 0, isResizing: false, leftShrink: 0, rightShrink: 0, row: row, framesOffset: 0, playOffset: 0,
          effects: [], isPlaying: false, curVideoTime: 0, dirty: false, height: video.videoHeight, width: video.videoWidth,
          storageFilePath: storageFilePath, storageBuffer: null, scaledHeight: null, scaledWidth: null, baseWidth: 0, baseHeight: 0,
          x: 0, y: 0, rotation: 0, type: file.type, crop: {left: 0, right: 0, top: 0, bottom: 0}, sha256: '', ...serializedVideo
        });
      } else {
        newVideoObject = new Audio({name: file.name, left: 0, start: 0, end: duration, videoRef: null, videoFdRef: null, framePendingTime: null, url: videoURL, duration, leftBackgroundOffset: 0, rightBackgroundOffset: 0,
          startBase: 0, endBase: 0, isResizing: false, leftShrink: 0, rightShrink: 0, row: row, framesOffset: 0, playOffset: 0,
          effects: [], isPlaying: false, curVideoTime: 0, dirty: false, height: video.videoHeight, width: video.videoWidth,
          storageFilePath: storageFilePath, storageBuffer: null, scaledHeight: null, scaledWidth: null, baseWidth: 0, baseHeight: 0,
          x: 0, y: 0, rotation: 0, type: file.type, crop: {left: 0, right: 0, top: 0, bottom: 0}, sha256: '', ...serializedVideo
        });
      }

      const fd = await createVideoFd(newVideoObject);

      newVideoObject.videoFdRef = fd;
      const hasVideoComponent = file.type.startsWith('video/');
      const hasAudioComponent = await Engine.has_audio(fd);
      newVideoObject.hasAudioComponent = hasAudioComponent;
      newVideoObject.hasVideoComponent = hasVideoComponent;
      newVideoObject.sha256 = sha256;
      newVideoObject.subtitlesLoaded = videoInfo ? !!videoInfo.subtitlesUrl : false;
      newVideoObject.subtitlesActive = newVideoObject.subtitlesLoaded;
      resolve(newVideoObject);
    };
  });
}

const createImageMedia = async (image: string, row: number, duration: number, serializedMedia? : any): Promise<ImageMedia> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = image;

    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');

      if (ctx) {
        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0, img.width, img.height);
      }

      fetch(image).then(async (res) => {
        const imageBlob = await res.blob();
        const imageComponent: ImageMedia = new ImageMedia({
          name: "Image",
          videoRef: canvas,
          duration: duration,
          left: 0,
          start: 0,
          end: duration,
          leftBackgroundOffset: 0,
          rightBackgroundOffset: 0,
          startBase: 0,
          endBase: 0,
          isResizing: false,
          leftShrink: 0,
          rightShrink: 0,
          row: row,
          framesOffset: 0,
          playOffset: 0,
          effects: [],
          dirty: false,
          height: img.height,
          width: img.width,
          url: image,
          type: imageBlob.type,
          sha256: '',
          scaledHeight: null,
          scaledWidth: null,
          baseWidth: 0,
          baseHeight: 0,
          x: 0,
          y: 0,
          rotation: 0,
          crop: { left: 0, right: 0, top: 0, bottom: 0 },
          ...serializedMedia
        });


        calculateSha256(imageBlob).then((sha256: string) => {
          imageComponent.sha256 = sha256;
          return resolve(imageComponent);
        });
      });
    };
  });
};

interface DebouncedAction<T, P extends any[]> {
  ({ initialState, latestState }: { initialState: T | null, latestState: T | null }, ...args: P): void;
}

const createDebouncedActionWithInitialState = <T, P extends any[]>(
  action: DebouncedAction<T, P>,
  delay: number
) => {
  let initialState: T | null = null; // Holds initial state for the debounce period
  let latestState: T | null = null;  // Holds the most recent state

  // Debounced function that invokes the action with initial and latest states
  const debouncedFunction = debounce((...args: P) => {
    action({ initialState, latestState }, ...args);
    // Reset initial state for the next debounce cycle
    initialState = null;
  }, delay);

  // Function to trigger the debounced action with the current state
  const triggerDebouncedAction = (previousState: T, currentState: T, ...args: P) => {
    if (initialState === null) {
      initialState = previousState; // Capture initial state only once per cycle
    }
    latestState = currentState; // Update latest state continuously
    debouncedFunction(...args);
  };

  return triggerDebouncedAction;
};

const isUserEditingDuringEvent = (event: KeyboardEvent) => {
    // Check if the target is an input, textarea, or contenteditable element
    const target = event.target as HTMLElement;
    const isEditing =
      target.tagName === 'INPUT' ||
      target.tagName === 'TEXTAREA' ||
      target.isContentEditable;
    return isEditing;
}

// Conversion functions (unchanged)
// Convert RGBA to ASS color (0xAABBGGRR)
const rgbaToAssColor = (rgba: { r: number; g: number; b: number; a: number }) => {
  const a = Math.round((1 - rgba.a) * 255); // Invert alpha for ASS
  const r = rgba.r;
  const g = rgba.g;
  const b = rgba.b;
  const color = (r << 24) | (g << 16) | (b << 8) | a; // Properly order as AABBGGRR
  return color >>> 0; // Ensure unsigned
};

// Convert ASS color to CSS rgba string
const assColorToCSSColor = (assColor: number) => {
  const r = (assColor >> 24) & 0xff; // Invert alpha for CSS
  const g = (assColor >> 16) & 0xff;
  const b = (assColor >> 8) & 0xff;
  const a = 255 - (assColor & 0xff);
  return `rgba(${r}, ${g}, ${b}, ${a / 255})`;
};

// Convert ASS color to CSS rgba string
const assColorToColorArray = (assColor: number): [number, number, number, number] => {
  const r = (assColor >> 24) & 0xff; // Invert alpha for CSS
  const g = (assColor >> 16) & 0xff;
  const b = (assColor >> 8) & 0xff;
  const a = (assColor & 0xff);
  return [r, g, b, a];
};

type Point = {
  x: number,
  y: number
}

const calculateXYDifferenceFromCenter = (
    corners: [Point, Point, Point, Point],
    xCenter: number,
    yCenter: number,
    previousRotationDegrees: number,
    currentRotationDegrees: number
) => {
    // Convert angles to radians
    const prevRad = previousRotationDegrees * (Math.PI / 180);
    const currRad = currentRotationDegrees * (Math.PI / 180);

    // Helper functions to calculate rotated coordinates around the center
    function getRotatedX(x: number, y: number, angleRad: number) {
        return x * Math.cos(angleRad) - y * Math.sin(angleRad);
    }

    function getRotatedY(x: number, y: number, angleRad: number) {
        return x * Math.sin(angleRad) + y * Math.cos(angleRad);
    }

    // Calculate rotated positions for each corner relative to the center
    const prevRotatedCorners = corners.map(corner => ({
        x: xCenter + getRotatedX(corner.x - xCenter, corner.y - yCenter, prevRad),
        y: yCenter + getRotatedY(corner.x - xCenter, corner.y - yCenter, prevRad)
    }));

    const currRotatedCorners = corners.map(corner => ({
        x: xCenter + getRotatedX(corner.x - xCenter, corner.y - yCenter, currRad),
        y: yCenter + getRotatedY(corner.x - xCenter, corner.y - yCenter, currRad)
    }));

    // Calculate the overall x and y differences by comparing each corresponding corner
    const xDifferences = currRotatedCorners.map((curr, i) => curr.x - prevRotatedCorners[i].x);
    const yDifferences = currRotatedCorners.map((curr, i) => curr.y - prevRotatedCorners[i].y);

    // Average the differences to get a single offset value for x and y
    const xDifference = xDifferences.reduce((sum, diff) => sum + diff, 0) / xDifferences.length;
    const yDifference = yDifferences.reduce((sum, diff) => sum + diff, 0) / yDifferences.length;

    // Find the min and max x and y values for the new rotated corners
    const minX = Math.min(...currRotatedCorners.map(corner => corner.x));
    const maxX = Math.max(...currRotatedCorners.map(corner => corner.x));
    const minY = Math.min(...currRotatedCorners.map(corner => corner.y));
    const maxY = Math.max(...currRotatedCorners.map(corner => corner.y));

    // Calculate the new center using the midpoint formula
    const newCenterX = (minX + maxX) / 2;
    const newCenterY = (minY + maxY) / 2;

    // Calculate new width and height based on bounding box of rotated corners
    const newWidth = maxX - minX;
    const newHeight = maxY - minY;

    // Define leftmost X and topmost Y for the rotated rectangle
    const leftmostX = minX;
    const topmostY = minY;

    return { boundingX: leftmostX, boundingY: topmostY, xDifference, yDifference, cornerDifferences: currRotatedCorners, newCenter: { x: newCenterX, y: newCenterY }, newWidth, newHeight };
};

const getActiveSegment = (segments: any[], seconds: number): Segment => {
  const candidateSegments = segments.filter((layer: any) => {
    return (layer.start <= seconds && layer.end >= seconds);
  })
  let curSegment: any = null;
  if (candidateSegments.length) {
    curSegment = candidateSegments[0];
  }
  return curSegment;
}

function waitForSeek(video: HTMLVideoElement): Promise<void> {
  return new Promise((resolve) => {
      if (!video) {
        resolve();
      }
      video.onseeked = () => {
          video.onseeked = null;
          resolve();
      };
  });
}

const handleMediaResize = (x: number, y: number, width: number, height: number, direction: DirectionType, video: Media, crop: boolean = true): Media => {
  const newVideo = video.deepCopy();;

  width = Math.ceil(width);
  height = Math.ceil(height);

  if (crop) {
    switch (direction) {
      case 'top':
        if ((newVideo.baseHeight < height)) {
          return newVideo;
        }
        newVideo.crop.top = (newVideo.baseHeight - height) / newVideo.baseHeight - newVideo.crop.bottom;
        newVideo.crop.top = Math.round((Math.min(Math.max(0, newVideo.crop.top), 1) * 100)) / 100;
        break;
      case 'bottom':
        if ((newVideo.baseHeight < height)) {
          return newVideo;
        }
        newVideo.crop.bottom = (newVideo.baseHeight - height) / newVideo.baseHeight - newVideo.crop.top;
        newVideo.crop.bottom = Math.round((Math.min(Math.max(0, newVideo.crop.bottom), 1) * 100)) / 100;
        break;
      case 'left':
        if ((newVideo.baseWidth < width)) {
          return newVideo;
        }
        newVideo.crop.left = (newVideo.baseWidth - width) / newVideo.baseWidth - newVideo.crop.right;
        newVideo.crop.left = Math.round((Math.min(Math.max(0, newVideo.crop.left), 1) * 100)) / 100;
        break;
      case 'right':
        if ((newVideo.baseWidth < width)) {
          return newVideo;
        }
        newVideo.crop.right = (newVideo.baseWidth - width) / newVideo.baseWidth - newVideo.crop.left;
        newVideo.crop.right = Math.round((Math.min(Math.max(0, newVideo.crop.right), 1) * 100)) / 100;
        break;
    }
  }

  // basically scale this video to new size via engine
  newVideo.x = x;
  newVideo.y = y;
  newVideo.scaledWidth = width;
  newVideo.scaledHeight = height;

  if (!crop) {
    // rescale the baseHeight according to the new height which takes into account that it was cropped
    newVideo.baseHeight = height / (1 - newVideo.crop.top - newVideo.crop.bottom);
    // rescale the baseWidth according to the new width which takes into account that it was cropped
    newVideo.baseWidth = width / (1 - newVideo.crop.left - newVideo.crop.right);
  }
  newVideo.aspectRatio = width / height;
  newVideo.storageBuffer = new Uint8Array(newVideo.scaledWidth * newVideo.scaledHeight * 4);
  return newVideo;
};

const seekAllVideos = async (videos: Media[], currentTime: number, videoSegments: Segment[]): Promise<void>  => {
  return new Promise(async (resolve) => {
    const segmentVideoIds = new Set();
    const curSegment = getActiveSegment(videoSegments, currentTime);

    // Seek all the videos in the same segment as ours to their relative times
    if (curSegment && curSegment.layers.length) {
      const seekedLayers = curSegment.layers.filter((layer: any) => (layer.video instanceof Video || layer.video instanceof Audio) && layer.video.videoRef && layer.video.videoRef.currentTime !== layer.video.getPlayOffset(currentTime));

      seekedLayers.forEach((layer: any) => {
        layer.video.videoRef.currentTime = layer.video.getPlayOffset(currentTime);
        segmentVideoIds.add(layer.video.id);
      });
      const seekPromises = seekedLayers.map((layer: any) => waitForSeek(layer.video.videoRef));

      await Promise.all(seekPromises);
    }

    // Seek all the videos not in the same segment as ours to the end or start, according to closest position to the requested time
    const seekedVideos = videos.filter((video: any) => (video instanceof Video || video instanceof Audio) && video.videoRef && !segmentVideoIds.has(video.id));
    seekedVideos.forEach((video: any) => {
      // Two options:
      // 1. Video to the right, seek it to the start
      // 2. Video to the left, seek it to the end
      const seekTime = video.start > currentTime ? video.getPlayStart() : video.getPlayEnd();
      video.videoRef.pause();
      video.videoRef.currentTime = seekTime;
    });
    const seekPromises = seekedVideos.map((video: any) => waitForSeek(video.videoRef));
    await Promise.all(seekPromises);
    resolve();
  });
};

const duplicateMedia = async (mediaToDuplicate: Media[], currentMedia: Media[]): Promise<Media[]> => {
  return new Promise(async (resolve) => {
    const maxRow = currentMedia.reduce((prevVideo, curVideo) => prevVideo.row < curVideo.row ? prevVideo : curVideo).row;
    const newVideos = await Promise.all(mediaToDuplicate.map(async (video: Media, index: number) => {
      const newMedia = video.deepCopy();
      newMedia.id = uuidv4();
      newMedia.x = video.x + 20;
      newMedia.y = video.y + 20;
      newMedia.row = maxRow + index + 1;
      newMedia.dirty = true;
      newMedia.frames = [...video.frames];
      newMedia.audioFrames = [...video.audioFrames];
      newMedia.onLoadedCalled = false; // it will go through the loading process

      if (video instanceof Video) {
        const fd = await createVideoFd(newMedia);
        if (fd !== -1) {
          newMedia.videoFdRef = fd;
          const effectString = newMedia.getEffectString();
          await Engine.set_effect(fd, newMedia.scaledWidth as number, newMedia.scaledHeight as number, effectString)
        } else {
          console.error(`failed to create fd for ${newMedia.id}`);
        }
      } else if (video instanceof TextMedia) {
        if (video.videoRef) {
          const originalCanvas = video.videoRef;
          const newCanvas = document.createElement('canvas');
          newCanvas.width = originalCanvas.width;
          newCanvas.height = originalCanvas.height;

          // Get the 2D drawing contexts for both canvases
          const newContext = newCanvas.getContext('2d');

          // Copy the content from the original canvas to the new one
          newContext?.drawImage(originalCanvas, 0, 0);

          newMedia.videoRef = newCanvas;
        }
      }
      return newMedia;
    }));
    resolve(newVideos);
  });
}

const normalizeMedia = (medias: Media[], oldDuration: number, newDuration: number) => {
  // normalize all video attributes according to the scale change due to changing the maxDuration (the scale of the movies is according to max movie)
  const normalizedMedia = medias.map((media: Media) => {
    // Calculate the normalizer
    const normalizer = oldDuration / newDuration;
    const newMedia = media.deepCopy();

    //newMedia.leftBackgroundOffset = +(media.leftBackgroundOffset * normalizer).toFixed(3);
    //newMedia.rightBackgroundOffset = +(media.rightBackgroundOffset * normalizer).toFixed(3);
    newMedia.setLeftBackgroundOffset(+(media.getLeftBackgroundOffset() * normalizer).toFixed(3));
    newMedia.setRightBackgroundOffset(+(media.getRightBackgroundOffset() * normalizer).toFixed(3));
    newMedia.startBase = +(media.startBase * normalizer).toFixed(3);
    newMedia.endBase = +(media.endBase * normalizer).toFixed(3);

    return newMedia;
  });
  return normalizedMedia;
}

const resizeTextComponent = async (textComponent: TextMedia, heightBefore: number, heightAfter: number, widthAfter: number, direction: DirectionType, scaleFactor: number, calculateHeight: boolean): Promise<[TextMedia, number]> => {
  return new Promise(async (resolve) => {
    const isCrop = cropDirections.includes(direction || '');
    const fontSize = parseFloat(textComponent.style.fontSize as string);
    const newFontSize = isCrop ? fontSize : Math.floor(fontSize * heightAfter / heightBefore);
    const newVPadding = isCrop ? textComponent.vPadding : textComponent.vPadding * heightAfter / heightBefore;
    let [newTextComponent, imageHeight, _] = await applyStyleToTextComponent({
      textComponent: textComponent,
      text: textComponent.text,
      style: {...textComponent.style, fontSize: `${newFontSize}px`},
      height: heightAfter,
      width: widthAfter,
      scaleFactor,
      hPadding: textComponent.hPadding, // we already have the padding from the creation - no need to add new
      vPadding: newVPadding, // we already have the padding from the creation - no need to add new
      calculateHeight: calculateHeight,
      calculateWidth: false
    });

    newTextComponent.vPadding = textComponent.vPadding;
    newTextComponent.hPadding = textComponent.hPadding;
    resolve([newTextComponent, imageHeight]);
  })
}

const captureVideoFrame = async (video: any, canvas: HTMLCanvasElement, width: number, height: number, time: any): Promise<string> => {
    return new Promise((resolve) => {
        video.currentTime = time;
        return video.onseeked = async () => {
            const ctx = canvas.getContext('2d');
            if (!ctx) {
              resolve('');
              return;
            }
            ctx.clearRect(0, 0, width, height);
            ctx.fillStyle = 'black';
            ctx.fillRect(0, 0, width, height);

            ctx.drawImage(video, 0, 0, width, height);
            const frame = await canvas.toDataURL('image/bmp');
            resolve(frame);
        };
    });
};

const getAdjacentVideos = (time: number, row: number, segments: Segment[]) => {
  let maxLeft = 0;
  let minRight = Infinity;
  let videoLeft = null;
  let videoRight = null;
  for (const segment of segments) {
    for (const layer of segment.layers) {
      if (layer.video instanceof Transition) {
        // ignore transitions when looking for adjacent videos
        continue;
      }
      if (layer.video.row !== row) {
        continue;
      }
      if (layer.end < time && layer.end > maxLeft) {
        videoLeft = layer.video;
        maxLeft = layer.end;
      }
      if (layer.start > time && layer.start < minRight) {
        videoRight = layer.video;
        minRight = layer.end;
      }
    }
  }

  return { videoLeft, videoRight};
}

const calculateConversionMetadata = (video: Media): MediaLayer => {
  const videoStart = +(video.start.toFixed(3));
  const videoEnd = +(video.getEnd().toFixed(3));
  return {
    video: video,
    rowNumber: video.row,
    playOffset: video.getPlayStart(false),
    start: videoStart,
    end: videoEnd,
    // TODO: this is later calculated in the segments - seems bad to assign this a default value here though...
    isPartOfTransition: false,
  };
}

const deleteTransitionForVideo = (video: Video, videos: Media[]): Media[] => {
  let videosCpy = [...videos];
  if (video.transitionId) {
    const transitionIndex = videosCpy.findIndex((vid: Media) => video.transitionId === vid.id);
    if (transitionIndex !== -1) {
      const transition = videosCpy[transitionIndex] as Transition;
      videosCpy = deleteTransition(transition, videosCpy);
    }
  }
  return videosCpy;
}

const deleteTransition = (transition: Transition, videos: Media[]) => {
  const videosCpy = [...videos];
  const transitionIndex = videosCpy.findIndex((vid: Media) => vid.id === transition.id);
  if (transitionIndex === -1) {
    return videosCpy;
  }
  const fromVideoCandidates = videosCpy.filter((vid: Media) => transition.fromVideo.id === vid.id);
  if (!fromVideoCandidates.length) {
    return videosCpy;
  }
  const toVideoCandidates = videosCpy.filter((vid: Media) => transition.toVideo.id === vid.id);
  if (!toVideoCandidates.length) {
    return videosCpy;
  }
  const fromVideo = fromVideoCandidates[0];
  const toVideo = toVideoCandidates[0];
  // remove transition pointers from videos
  fromVideo.transitionId = null;
  toVideo.transitionId = null;
  // delete transition
  videosCpy.splice(transitionIndex, 1);

  return videosCpy;
}

export {
  type Point,
  taskCompleteStatuses,
  throttle,
  downloadFromUrl,
  createTextImage,
  sendLoginTelemetry,
  pollRenderedVideoAndDownload,
  pollSubtitlesAndDownload,
  getPendingSubtitleTasks,
  calculateSha256,
  uploadVideo,
  uploadMediaSync,
  blobUrlToUint8Array,
  createVideoFd,
  createDebouncedActionWithInitialState,
  applyStyleToTextComponent,
  createVideoAudioMedia,
  createImageMedia,
  rgbaToAssColor,
  assColorToCSSColor,
  assColorToColorArray,
  isUserEditingDuringEvent,
  calculateXYDifferenceFromCenter,
  getActiveSegment,
  waitForSeek,
  handleMediaResize,
  seekAllVideos,
  duplicateMedia,
  normalizeMedia,
  resizeTextComponent,
  captureVideoFrame,
  getAdjacentVideos,
  calculateConversionMetadata,
  deleteTransitionForVideo,
  deleteTransition
}
