import React, { createContext, useRef, useState, useContext, MutableRefObject } from 'react';

import Media from './media/Media';
import Video from './media/Video';
import Subtitles from './media/Subtitles';
import { initialASSStyle } from './menus/SubtitlesMenu';
import { Segment, MediaLayer } from '../utils/serializer';
import Transition from './media/Transition';

const initialHeightConstant = 270;
const initialWidthConstant = 270;
const initialAspectRatio = 16/9;
const initialTotalDuration = 60;

const initialSubtitlesContainer = new Subtitles({
      subtitles: [],
      style: {...initialASSStyle, ScaleX: 1, ScaleY: 1},
      url: '',
      row: 0,
      duration: 0,
      start: 0,
      leftBackgroundOffset: 0,
      rightBackgroundOffset: 0,
      videoRef: null,
      startBase: 0,
      endBase: 0,
      isResizing: false,
      dirty: false,
      framesOffset: 0,
      playOffset: 0,
      onLoadedCalled: true,
      effects: [],
      height: 60,
      width: initialWidthConstant,
      scaledHeight: 0,
      scaledWidth: 0,
      baseHeight: 0,
      baseWidth: 0,
      x: 0,
      y: initialHeightConstant - 60,
      rotation: 0,
      crop: {top: 0, bottom: 0, left: 0, right: 0},
    });

type State = {
  videos: Media[],
  subtitlesContainer: Subtitles | null,
  selectedVideoLayers: Media[]
}

type ProjectStateType = {
  name: string,
  id: string | null,
  lastSave: number | null
}

type CanvasStateType = {
  canvasWidth: number,
  canvasHeight: number,
  canvasAspectRatio: number
}

const initialProjectState: ProjectStateType = {name: 'My Video', id: null, lastSave: null };
const initialCanvasState: CanvasStateType = {canvasHeight: initialHeightConstant, canvasWidth: initialWidthConstant, canvasAspectRatio: initialAspectRatio};

const EditorContext = createContext<{
  state: State;
  videoSegments: React.MutableRefObject<Segment[]>;
  currentTime: MutableRefObject<number>;
  initialWidth:MutableRefObject<number>;
  initialHeight:MutableRefObject<number>;
  runningState: {isRunning: boolean, sliderSyncOn: boolean};
  totalDuration: number;
  totalTracksDuration: number;
  canvasBackgroundState: [number, number, number, number];
  projectState: ProjectStateType;
  canvasState: CanvasStateType,
  subtitlesCanvasState: CanvasStateType,
  isDroppingTransition: boolean,
  getCanvasBackground: () => [number, number, number, number];
  setCanvasBackground: (color: [number, number, number, number]) => void;
  getSubtitlesContainer: () => Subtitles;
  setSubtitlesContainer: (container: Subtitles) => void;
  getSelectedVideos: () => Media[];
  getProjectState: () => ProjectStateType;
  setProjectState: (projectState: ProjectStateType) => void;
  setVideos: (newVideos: Media[], selectedVideoId?: string[], onlyRealTime?: boolean, subtitlesContainer?: Subtitles) => void;
  getVideos: () => Media[];
  getCopyBuffer: () => Media[];
  setCopyBuffer: (videos: Media[]) => void;
  setIsDroppingTransition: (isDropping: boolean) => void;
  getIsDroppingTransition: () => boolean;
  setTotalDuration: (value: React.SetStateAction<number>) => void;
  setTotalTracksDuration: (value: React.SetStateAction<number>) => void;
  setCurrentTime: (value: number, forceStateChange?: boolean) => void;
  setGlobalIsRunning: (running: boolean, sliderSyncOn: boolean) => void;
  setSelectedVideoLayers: (selectedVideoIds: string[]) => void;
  setCanvasState: (canvasState: CanvasStateType) => void;
  setSubtitlesCanvasState: (canvasState: CanvasStateType) => void;
}>({
  state: {videos: [] as Media[], subtitlesContainer: null, selectedVideoLayers: []},
  videoSegments: { current: [] },
  currentTime: (null as unknown) as  MutableRefObject<number>,
  initialWidth: (null as unknown) as  MutableRefObject<number>,
  initialHeight: (null as unknown) as  MutableRefObject<number>,
  runningState: {isRunning: false, sliderSyncOn: false},
  totalTracksDuration: initialTotalDuration,
  totalDuration: initialTotalDuration,
  canvasBackgroundState: [0 , 0, 0, 0],
  projectState: initialProjectState,
  canvasState: initialCanvasState,
  subtitlesCanvasState: initialCanvasState,
  isDroppingTransition: false,
  getProjectState: () => initialProjectState,
  setProjectState: () => {},
  getCanvasBackground: () => [0 , 0, 0, 0],
  setCanvasBackground: () => {},
  getSubtitlesContainer: () => initialSubtitlesContainer,
  setSubtitlesContainer: () => {},
  getVideos: () => [],
  setVideos: () => {},
  getSelectedVideos: () => [],
  setSelectedVideoLayers: () => {},
  getCopyBuffer: () => [],
  setCopyBuffer: () => {},
  getIsDroppingTransition: () => false,
  setIsDroppingTransition: () => {},
  setCanvasState: () => {},
  setSubtitlesCanvasState: () => {},
  setTotalDuration: () => {},
  setTotalTracksDuration: () => {},
  setCurrentTime: () => {},
  setGlobalIsRunning: () => {},
});

interface VideosContextType {
  children: React.ReactNode;
}

export const EditorContextProvider: React.FC<VideosContextType> = ({ children }) => {
  const [totalDuration, setTotalDuration] = useState<number>(initialTotalDuration);
  const [totalTracksDuration, setTotalTracksDuration] = useState(initialTotalDuration);
  const [runningState, setRunningState] = useState<{isRunning: boolean, sliderSyncOn: boolean}>({isRunning: false, sliderSyncOn: false});
  const [canvasBackgroundState, setCanvasBackgroundState] = useState<[number, number, number, number]>([0, 0, 0, 1.0]);
  const [projectState, setProjectStateInternal] = useState<ProjectStateType>(initialProjectState);
  const [canvasState, setCanvasState] = useState<CanvasStateType>(initialCanvasState);
  const [subtitlesCanvasState, setSubtitlesCanvasState] = useState<CanvasStateType>(initialCanvasState);
  const [state, setState] = useState<State>({
    videos: [],
    subtitlesContainer: initialSubtitlesContainer,
    selectedVideoLayers: [],
  });
  const [isDroppingTransition, setIsDroppingTransitionState] = useState<boolean>(false);
  const isDroppingTransitionRef = useRef<boolean>(false);

  const initialHeight = useRef<number>(initialHeightConstant);
  const initialWidth = useRef<number>(initialHeightConstant);
  const videosRef = useRef<Media[]>([]);
  const videoSegments = useRef<Segment[]>([]);
  const copyBufferRef = useRef<Media[]>([]);
  const currentTime = useRef<number>(0);

  const projectStateRef = useRef<ProjectStateType>(initialProjectState);
  const subtitlesContainerRef = useRef<Subtitles>(initialSubtitlesContainer);
  const selectedVideosRef = useRef<Media[]>([]);
  const canvasBackgroundRef = useRef<[number, number, number, number]>([0, 0, 0, 1.0]);

  const setCopyBuffer = (videos: Media[]) => {
    copyBufferRef.current = videos.map((video) => video.deepCopy());
  }

  const setRealtimeState = (videos: Video[], selectedVideos: Media[], newSubtitles: Subtitles) => {
    // TODO: need to redesign this - should not keep the segments in a different unsynced variable. has to be 100% synced
    // and i don't like the way it is synced here (slow operation every change)
    // videos have changed, need to sync the videoSegments which are used to render the videos
    videoSegments.current.forEach((segment: Segment) => {
      segment.layers.forEach((layer: MediaLayer) => {
        const videoIndex = videos.findIndex((video) => video.id === layer.video.id);
        if (videoIndex !== -1) {
          layer.video = videos[videoIndex];
        } else {
          layer.video.videoRef = null;
        }
      })
    })
    selectedVideosRef.current = selectedVideos as Video[];
    videosRef.current = videos as Video[];
    subtitlesContainerRef.current = newSubtitles;
  }

  const setCurrentTime = (value: number, forceStateChange: boolean = false) => {
    currentTime.current = value;
    if (forceStateChange) {
      setState({...state});
    }
  }

  const setGlobalIsRunning = (running: boolean, sliderSyncOn: boolean) => {
    setRunningState({isRunning: running, sliderSyncOn});
  }

  const setSelectedVideoLayers = (selectedVideoIds: string[]) => {
    const selectedVideos = getVideos().filter((vid: Media) => selectedVideoIds.includes(vid.id));
    setState((prevState) => ({
      videos: videosRef.current,
      subtitlesContainer: subtitlesContainerRef.current,
      selectedVideoLayers: selectedVideos
    }));
  }

  const setVideos = (newVideos: Media[], selectedVideoIds?: string[], onlyRealTime: boolean = false, subtitlesContainer?: Subtitles) => {
    let selectedVideos: Media[] = [];

    if (subtitlesContainer) {
      const newSubtitles = new Subtitles({...subtitlesContainer, style: subtitlesContainer.subtitles.style, subtitles: subtitlesContainer.subtitles.subtitles})
      subtitlesContainerRef.current = newSubtitles;
      newVideos = newVideos.map((video: Media) => {
        if (video instanceof Video) {
          video.subtitles = { style: {...newSubtitles.subtitles.style }, subtitles: [...newSubtitles.subtitles.subtitles] };
        }
        return video;
      })
    }

    // TODO: optimize...
    newVideos = newVideos.map((video: Media) => {
      if (video instanceof Transition) {
        const fromVideos = newVideos.filter((vid: Media) => vid.id === video.fromVideo.id);
        if (fromVideos.length) {
          video.fromVideo = fromVideos[0] as Video;
        }
        const toVideos = newVideos.filter((vid: Media) => vid.id === video.toVideo.id);
        if (toVideos.length) {
          video.toVideo = toVideos[0] as Video;
        }
      }
      return video;
    })

    if (selectedVideoIds === undefined) {
      // update the existing videos to the new videos according to their id
      selectedVideos = selectedVideosRef.current.map((vid) => {
        const videoIndex = newVideos.findIndex((updatedVid: Media) => vid.id === updatedVid.id);
        if (videoIndex !== -1) {
          return newVideos[videoIndex];
        } else {
          console.error(`selected video ${vid.id} was not found`);
          return vid;
        }
      })
    } else if (selectedVideoIds.includes('subtitles')) {
      selectedVideos = newVideos.filter((vid: Media) => selectedVideoIds.includes(vid.id)).concat([subtitlesContainerRef.current]);
    } else if (selectedVideoIds.length) {
      selectedVideos = newVideos.filter((vid: Media) => selectedVideoIds.includes(vid.id));
    }

    setRealtimeState(newVideos as Video[], selectedVideos, subtitlesContainerRef.current); // Update your real-time state
    if (!onlyRealTime) {
      setState((prevState) => ({
        ...prevState,
        videos: newVideos as Video[], // Only update the `videos` in the state
        subtitlesContainer: subtitlesContainer || prevState.subtitlesContainer,
        selectedVideoLayers: selectedVideos
      }));
    }
  };

  const setSubtitlesContainer = (container: Subtitles) => {
    const newSubtitles = new Subtitles({...container, style: container.subtitles.style, subtitles: container.subtitles.subtitles})
    // update the subtitles container for each video
    const newVideos = videosRef.current.map((video: Media) => {
      if (video instanceof Video) {
        video.subtitles = { style: {...newSubtitles.subtitles.style }, subtitles: [...newSubtitles.subtitles.subtitles] };
      }
      return video;
    })

    // update the selected videos to have the same state as our newly changed videos (i.e replace the existing videos with newly modified ones)
    const existingSelectedIds = selectedVideosRef.current.map((vid: Media) => vid.id);
    const selectedVideosUpdated = newVideos.filter((vid: Media) => existingSelectedIds.includes(vid.id));

    // replace any existing subtitles with the new subtitles container (there should be only one subtitles container at one time)
    const selectedVideos = selectedVideosUpdated.filter((vid) => !(vid instanceof Subtitles)).concat(newSubtitles);

    setRealtimeState(newVideos as Video[], selectedVideos, newSubtitles);
    setState({...state, videos: newVideos as Video[], subtitlesContainer: newSubtitles, selectedVideoLayers: selectedVideos});
  }

  const setCanvasBackground = (color: [number, number, number, number]) => {
    canvasBackgroundRef.current = color;
    setCanvasBackgroundState(color);
  }

  const setProjectState = (projectState: any) => {
    projectStateRef.current = projectState;
    setProjectStateInternal(projectState);
  }

  const setIsDroppingTransition = (value: boolean) => {
    isDroppingTransitionRef.current = value;
    setIsDroppingTransitionState(value)
  }

  const getProjectState = (): ProjectStateType => {
    return projectStateRef.current;
  }

  const getCanvasBackground = (): [number, number, number, number] => {
    return canvasBackgroundRef.current;
  }

  const getSubtitlesContainer = (): Subtitles => {
    return subtitlesContainerRef.current;
  }

  const getVideos = (): Media[] => {
    return videosRef.current;
  }

  const getSelectedVideos = () => {
    return selectedVideosRef.current;
  }

  const getCopyBuffer = () => {
    return copyBufferRef.current;
  }

  const getIsDroppingTransition = () => {
    return isDroppingTransitionRef.current;
  }

  return (
    <EditorContext.Provider value={{
      state,
      videoSegments,
      currentTime: currentTime,
      initialWidth,
      initialHeight,
      runningState,
      totalDuration,
      totalTracksDuration,
      canvasBackgroundState,
      projectState,
      canvasState,
      subtitlesCanvasState,
      isDroppingTransition,
      setCanvasState,
      setSubtitlesCanvasState,
      setTotalDuration,
      setTotalTracksDuration,
      setCurrentTime,
      setProjectState,
      getCanvasBackground,
      setCanvasBackground,
      setGlobalIsRunning,
      setSelectedVideoLayers,
      getSelectedVideos,
      getProjectState,
      getVideos,
      setVideos,
      setSubtitlesContainer,
      getSubtitlesContainer,
      setCopyBuffer,
      getCopyBuffer,
      setIsDroppingTransition,
      getIsDroppingTransition
      }}>
      {children}
    </EditorContext.Provider>
  );
};

export {
  initialAspectRatio,
  initialHeightConstant,
  initialProjectState,
  type ProjectStateType
}
export const useEditorContext = () => useContext(EditorContext);