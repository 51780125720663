
const openDatabase = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request: IDBOpenDBRequest = indexedDB.open("videoDB", 1);
    
    request.onupgradeneeded = function (event: IDBVersionChangeEvent) {
      const db = (event.target as IDBOpenDBRequest).result;
      if (!db.objectStoreNames.contains("videos")) {
        db.createObjectStore("videos", { keyPath: "hash" });
      }
    };
    
    request.onsuccess = function (event: Event) {
      resolve((event.target as IDBOpenDBRequest).result);
    };
    
    request.onerror = function (event: Event) {
      reject("Database failed to open: " + (event.target as IDBOpenDBRequest).error?.message);
    };
  });
}

const saveFileToIndexedDB = async (file: File, hash: string): Promise<string> => {
  const db = await openDatabase();
  
  return new Promise((resolve, reject) => {
    const transaction: IDBTransaction = db.transaction(["videos"], "readwrite");
    const store: IDBObjectStore = transaction.objectStore("videos");

   const data = {
      hash: hash,               // SHA-256 hash key
      blob: file,               // Store the file as a Blob
      name: file.name,          // Save file name for metadata
      lastModified: file.lastModified,
      type: file.type           // Save MIME type for recreation
    };

    const request: IDBRequest<IDBValidKey> = store.put(data);
    
    request.onsuccess = () => resolve("Blob saved successfully.");
    request.onerror = (event: Event) => reject("Error saving blob: " + (event.target as IDBRequest).error?.message);
  });
}

const getFileFromIndexedDB = async (hash: string): Promise<File | null> => {
  const db = await openDatabase();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(["videos"], "readonly");
    const store = transaction.objectStore("videos");

    const request = store.get(hash);
    
    request.onsuccess = (event) => {
      const result = (event.target as IDBRequest<{ blob: Blob, name: string, lastModified: number, type: string }>).result;
      if (result) {
        // Re-create the File from Blob and metadata
        const file = new File([result.blob], result.name, {
          type: result.type,
          lastModified: result.lastModified
        });
        resolve(file);
      } else {
        resolve(null);
      }
    };
    request.onerror = (event) => reject("Error retrieving file: " + (event.target as IDBRequest).error?.message);
  });
}

export {
  openDatabase,
  saveFileToIndexedDB,
  getFileFromIndexedDB
}
