import { useEffect, useState } from 'react';
import {
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Link,
  Divider,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { sendLoginTelemetry } from '../../utils/utils';
import { useAuth } from '../AuthContext';
import { registerUser, registerViaGoogleOAuth, registerViaMicrosoftOAuth } from '../../api/ServerApi';
import config from '../../constants/constants';

import { useGoogleLogin } from '@react-oauth/google';
import { PublicClientApplication, AuthenticationResult } from '@azure/msal-browser';
import MicrosoftIcon from '../../assets/microsoft-logo.png';
import GoogleIcon from '../../assets/google-logo.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ZetanoxLogo = '../../assets/zevideo-black-no-background.webp';

const theme = createTheme({
  palette: {
    primary: {
      main: '#121e2a',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Single overlay covering the entire page
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300, // Ensures the overlay is on top
}));

const Logo = styled('img')(({ theme }) => ({
  width: '60%',
  maxWidth: '200px',
  height: 'auto',
  marginBottom: theme.spacing(1.5),
}));

const SocialButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  textTransform: 'none',
  fontSize: '1rem',
  padding: theme.spacing(1),
  width: '100%',
}));

const SocialIcon = styled('img')({
  width: '20px',
  height: '20px',
  marginRight: '10px',
});

// Microsoft Login Configuration
const msalConfig = {
  auth: {
    clientId: "9b34039d-9b7b-49b7-a5f2-2a1d69586648",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${config.frontendUrl}/oauth/microsoft`
  },
};

let msalInstance: PublicClientApplication | null = null;

export default function RegistrationPage() {
  const { isAuthenticated, loading, login } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [isMsalInitialized, setMsalInitialized] = useState(false);
  const [showManualForm, setShowManualForm] = useState(true);
  const navigate = useNavigate();

  const gtag_report_conversion = (url?: string) => {
    const callback = () => {
      if (typeof url !== 'undefined') {
        window.location.href = url; // Redirect if a URL is provided
      }
    };

    if (typeof (window as any).gtag !== 'undefined') {
      (window as any).gtag('event', 'conversion', {
        'send_to': 'AW-16536972780/EpkOCJHksNoZEOzbuM09',
        'event_callback': callback
      });
    }
    return false;
  };

  // Initialize MSAL instance when the component mounts
  useEffect(() => {
    msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize().then(() => {
      setMsalInitialized(true);
    });
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    registerUser(formData.email, formData.email, formData.email, formData.password)
      .then((data) => {
        console.log('Registration successful:', data);
        navigate(`/mailsent/${formData.email}`);
      })
      .catch((error) => {
        console.error('Registration error:', error);
      });
  };

  const handleGoogleSuccess = (tokenResponse: any) => {
    console.log('Google Login Success:', tokenResponse);
    registerViaGoogleOAuth(tokenResponse.access_token).then((data) => {
      if (data.success === true) {
        sendLoginTelemetry(data.username, data.email, data.tenant, data.fullName, data.isAdmin);
        login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
        gtag_report_conversion('/home');
      }
    });
    // Process Google login data here, e.g., send it to the server
  };

  const handleMicrosoftLogin = async () => {
    if (!isMsalInitialized || !msalInstance) {
      console.error('MSAL not initialized');
      return;
    }

    try {
      const tokenResponse: AuthenticationResult = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "User.Read"],
      });
      console.log('Microsoft Login Success:', tokenResponse);
      registerViaMicrosoftOAuth(tokenResponse.accessToken).then((data) => {
        if (data.success === true) {
          sendLoginTelemetry(data.username, data.email, data.tenant, data.fullName, data.isAdmin);
          login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
          gtag_report_conversion('/home');
        }
      });
      // Process Microsoft login data here
    } catch (error) {
      console.error('Microsoft Login Failed:', error);
    }
  };

  // Custom Google login hook for button click
  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: (error) => console.error('Google Login Failed:', error),
  });

  const toggleManualForm = () => {
    setShowManualForm(!showManualForm);
  };

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <CssBaseline />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              maxWidth: '400px',
              backgroundColor: 'rgba(255, 255, 255, 0.95)',
              padding: 3,
              borderRadius: '10px',
              boxShadow: 5,
            }}
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <Logo src={ZetanoxLogo} alt="Zetanox Logo" />

              <Typography component="h1" variant="h5" gutterBottom>
                Create a Free Account
              </Typography>

              <SocialButton
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => googleLogin()}
              >
                <SocialIcon src={GoogleIcon} alt="Google logo" />
                Sign up with Google
              </SocialButton>
              <SocialButton
                variant="outlined"
                color="primary"
                onClick={handleMicrosoftLogin}
                disabled={!isMsalInitialized}
                sx={{
                  opacity: isMsalInitialized ? 1 : 0.5,
                  cursor: isMsalInitialized ? 'click' : 'not-allowed',
                }}
              >
                <SocialIcon 
                  src={MicrosoftIcon} 
                  alt="Microsoft logo" 
                  style={{
                    opacity: isMsalInitialized ? 1 : 0.5,
                  }}
                />
                Sign up with Microsoft
              </SocialButton>
              <Button
                fullWidth
                onClick={toggleManualForm}
                endIcon={
                  <ExpandMoreIcon
                    style={{
                      transform: showManualForm ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                    }}
                  />
                }
                style={{
                  marginTop: '16px',
                  textTransform: 'none',
                  justifyContent: 'space-between',
                  padding: '12px',
                }}
              >
                Sign up with Email
              </Button>
            </Box>

            <Collapse in={showManualForm} timeout="auto" unmountOnExit>
              <Divider sx={{ margin: '20px 0' }} />
              <Box component="form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ margin: '16px 0' }}
                >
                  Register
                </Button>
              </Box>
            </Collapse>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Root>
    </ThemeProvider>
  );
}
