import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Divider,
  Button,
  CircularProgress,
  TextField,
  Slider,
  ToggleButton,
  Tooltip,
  Popover,
  Autocomplete,
  useTheme,
  FormControl,
  Paper,
} from '@mui/material';
import {
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  CloudUpload,
  Subtitles as SubtitlesIcon,
  AutoFixHigh,
  UploadFile,
  FormatSize,
} from '@mui/icons-material';
import { FormatAlignLeft, FormatAlignCenter, FormatAlignRight } from '@mui/icons-material';
import { ChromePicker, ColorResult } from 'react-color';
import { styled } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';

import Video from '../media/Video';
import Subtitles from '../media/Subtitles';
import { assColorToCSSColor, rgbaToAssColor } from '../../utils/utils';

// ASS_Style interface
interface ASS_Style {
  Name: string;
  FontName: string;
  FontSize: number;
  PrimaryColour: number;
  SecondaryColour: number;
  OutlineColour: number;
  BackColour: number;
  Bold: number;
  Italic: number;
  Underline: number;
  StrikeOut: number;
  ScaleX: number;
  ScaleY: number;
  Spacing: number;
  Angle: number;
  BorderStyle: number;
  Outline: number;
  Shadow: number;
  Alignment: number;
  MarginL: number;
  MarginR: number;
  MarginV: number;
  Encoding: number;
  treat_fontname_as_pattern: number;
  Blur: number;
  Justify: number;
}

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  height: '100%',
  overflowY: 'auto',
}));

const Section = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  backgroundColor: theme.palette.background.default,
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));

interface SubtitleEntry {
  id: string;
  startTime: string;
  endTime: string;
  text: string;
}

const initialASSStyle: ASS_Style = {
  Name: 'Default',
  //FontName: 'Open Sans',
  FontName: 'Alice',
  FontSize: 32,
  PrimaryColour: 0xFFFFFF00, // White
  SecondaryColour: 0x00000000,
  OutlineColour: 0x00000000,
  BackColour: 0x00000000,
  Bold: 0,
  Italic: 0,
  Underline: 0,
  StrikeOut: 0,
  ScaleX: 1,
  ScaleY: 1,
  Spacing: 0,
  Angle: 0,
  BorderStyle: 2,
  Outline: 1,
  Shadow: 1,
  Alignment: 2,
  MarginL: 0,
  MarginR: 0,
  MarginV: 0,
  Encoding: 1,
  treat_fontname_as_pattern: 0,
  Blur: 0,
  Justify: 0,
};

interface SubtitlesMenuProps {
  selectedElement: Video | Subtitles | null;
  isMobile: boolean;
  onSubtitlesGenerate: (style: ASS_Style) => void;
  onSubtitlesUpload: (file: File) => void;
  onSubtitlesStyleChanged: (subtitles: SubtitleEntry[], style: ASS_Style, commit: boolean) => void;
}

const SubtitlesMenu: React.FC<SubtitlesMenuProps> = ({
  selectedElement,
  isMobile,
  onSubtitlesGenerate,
  onSubtitlesUpload,
  onSubtitlesStyleChanged,
}) => {
  const theme = useTheme();

  const [assStyle, setAssStyle] = useState<ASS_Style>(initialASSStyle);
  const [anchorElTextColor, setAnchorElTextColor] = useState<null | HTMLElement>(null);
  const [anchorElBgColor, setAnchorElBgColor] = useState<null | HTMLElement>(null);

  const [subtitles, setSubtitles] = useState<SubtitleEntry[]>([]);
  const [subtitleFileName, setSubtitleFileName] = useState<string>('');

  useEffect(() => {
    if (selectedElement && selectedElement.subtitles) {
      const { subtitles, style } = selectedElement.subtitles;

      setSubtitles(subtitles || []);

      setAssStyle({
        ...initialASSStyle,
        ...style,
      });
    } else {
      setSubtitles([]);
      setAssStyle({
        ...initialASSStyle,
      });
    }
  }, [selectedElement]);

  const handleStyleChange = (updatedAttributes: Partial<ASS_Style>, commit: boolean) => {
    const newStyle = { ...assStyle, ...updatedAttributes };
    setAssStyle(newStyle);

    onSubtitlesStyleChanged(subtitles, newStyle, commit);
  };

  const generateSubtitles = () => {
    onSubtitlesGenerate(assStyle);
  };

  const handleDeleteSubtitle = (id: string) => {
    const updatedSubtitles = subtitles.filter((subtitle) => subtitle.id !== id);
    setSubtitles(updatedSubtitles);
    onSubtitlesStyleChanged(updatedSubtitles, assStyle, true);
  };

  // Handle file upload
  const handleUploadSubtitles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSubtitleFileName(file.name);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        // Parse the subtitle file (assuming SRT format)
        const parsedSubtitles = parseSRT(text);
        setSubtitles(parsedSubtitles);

        onSubtitlesStyleChanged(parsedSubtitles, assStyle, true);
      };
      reader.readAsText(file);

      onSubtitlesUpload(file);
    }
  };

  const parseSRT = (data: string): SubtitleEntry[] => {
    const regex =
      /(\d+)\s+(\d{2}:\d{2}:\d{2}[,.:]\d{3})\s*-->?\s*(\d{2}:\d{2}:\d{2}[,.:]\d{3})\s+([\s\S]*?)(?=\n{2,}|$)/gm;
    let result;
    const entries: SubtitleEntry[] = [];

    while ((result = regex.exec(data)) !== null) {
      const [_, index, startTime, endTime, text] = result;
      entries.push({
        id: uuidv4(),
        startTime: startTime.replace('.', ',').trim(),
        endTime: endTime.replace('.', ',').trim(),
        text: text.trim().replace('\r', ''),
      });
    }

    return entries;
  };

  // Color Picker Handlers
  const handleTextColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTextColor(event.currentTarget);
  };

  const handleBgColorClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElBgColor(event.currentTarget);
  };

  const handleTextColorClose = () => {
    setAnchorElTextColor(null);
  };

  const handleBgColorClose = () => {
    setAnchorElBgColor(null);
  };

  const handleTextColorChange = (colorResult: ColorResult, commit: boolean) => {
    const color = rgbaToAssColor(colorResult.rgb as any);
    handleStyleChange({ PrimaryColour: color }, commit);
  };

  const handleBgColorChange = (colorResult: ColorResult, commit: boolean) => {
    const color = rgbaToAssColor(colorResult.rgb as any);
    handleStyleChange({ BackColour: color }, commit);
  };

  // Font options
  const fontOptions = [
    'Open Sans',
    'Alice',
    'Ubuntu',
    'Poppins',
    'Lora',
    'Roboto',
    'Kanit',
  ];

  // Render generate subtitles option
  const renderGenerateSubtitlesOption = () => (
    <>
    <Typography
      variant={'h5'}
      gutterBottom
      sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
    >
      Add Subtitles
    </Typography>

    <Divider sx={{ marginBottom: 2 }} />
      <Section elevation={3}>
        <SectionHeader>
          <SubtitlesIcon sx={{ marginRight: 1, color: theme.palette.primary.main }} />
          Generate Subtitles
        </SectionHeader>
        <Typography variant="body1" gutterBottom>
          Automatically generate subtitles for your video.
        </Typography>
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Button
            disabled={
              !(
                selectedElement?.hasAudio() &&
                selectedElement?.onLoadedCalled && // finished loading
                selectedElement?.uploadComplete && // finished upload to the server (have the file in S3)
                !selectedElement?.downloadingSubtitles && // no subtitles currently downloading
                !selectedElement?.subtitlesLoaded // no subtitles already exist for this media
              )
            }
            variant="contained"
            color="primary"
            onClick={generateSubtitles}
            startIcon={<AutoFixHigh />}
            sx={{
              width: '100%',
              maxWidth: '300px',
              height: '50px',
              fontSize: '1rem',
            }}
          >
            {selectedElement?.downloadingSubtitles ? (
              <>
                <CircularProgress size={24} color="inherit" sx={{ marginRight: 2 }} />
                Generating...
              </>
            ) : (
              'Generate Subtitles'
            )}
          </Button>
          {selectedElement?.downloadingSubtitles && (
            <Typography variant="body2" sx={{ marginTop: 2 }}>
              Generating subtitles, please wait...
            </Typography>
          )}
        </Box>
      </Section>

      <Section elevation={3}>
        <SectionHeader>
          <CloudUpload sx={{ marginRight: 1, color: theme.palette.primary.main }} />
          Upload Subtitles
        </SectionHeader>
        <Typography variant="body1" gutterBottom>
          Upload your own subtitles file (.vtt, .ass).
        </Typography>
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <input
            disabled = {
              !(
                selectedElement?.hasAudio() &&
                selectedElement?.onLoadedCalled && // finished loading
                selectedElement?.uploadComplete && // finished upload to the server (have the file in S3)
                !selectedElement?.downloadingSubtitles && // no subtitles currently downloading
                !selectedElement?.subtitlesLoaded // no subtitles already exist for this media
              )
            }
            accept=".vtt,.ass"
            style={{ display: 'none' }}
            id="upload-subtitles-file"
            type="file"
            onChange={handleUploadSubtitles}
          />
          <label htmlFor="upload-subtitles-file">
            <Button
              disabled={
                !(
                  selectedElement?.hasAudio() &&
                  selectedElement?.onLoadedCalled && // finished loading
                  selectedElement?.uploadComplete && // finished upload to the server (have the file in S3)
                  !selectedElement?.downloadingSubtitles && // no subtitles currently downloading
                  !selectedElement?.subtitlesLoaded // no subtitles already exist for this media
                )
              }
              variant="outlined"
              color="primary"
              component="span"
              startIcon={<UploadFile />}
              sx={{
                width: '100%',
                maxWidth: '300px',
                height: '50px',
                fontSize: '1rem',
              }}
            >
              Upload Subtitles File
            </Button>
          </label>
          {subtitleFileName && (
            <Typography variant="body2" sx={{ marginTop: 1 }}>
              Uploaded file: {subtitleFileName}
            </Typography>
          )}
        </Box>
      </Section>
    </>
  );

  // Render customization options for existing subtitles
const renderCustomizationOptions = () => (
  <Box sx={{ direction: 'ltr' }}>
    <Typography
      variant={'h5'}
      gutterBottom
      sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
    >
      Customize Subtitles
    </Typography>

    <Divider sx={{ marginBottom: 2 }} />

    {/* Uncomment this section if you want to include subtitle editing */}
    {/*
    <Section elevation={3}>
      <SectionHeader>
        <TextFields sx={{ marginRight: 1, color: theme.palette.primary.main }} />
        Edit Subtitles
      </SectionHeader>
    </Section>
    */}

    {/* Font Settings Section */}
    <Section elevation={3}>
      <SectionHeader>
        <FormatSize sx={{ marginRight: 1, color: theme.palette.primary.main }} />
        Font Settings
      </SectionHeader>

      {/* Font Family Selector */}
      <FormControl fullWidth sx={{ marginBottom: 3 }}>
        <Autocomplete
          options={fontOptions}
          value={assStyle.FontName}
          onChange={(e, newValue) => {
            if (newValue) handleStyleChange({ FontName: newValue }, true);
          }}
          renderInput={(params) => (
            <TextField {...params} label="Font Family" variant="outlined" />
          )}
          getOptionLabel={(option) => option as string}
          renderOption={(props, option) => (
            <Box component="li" {...props} style={{ fontFamily: option }}>
              {option}
            </Box>
          )}
        />
      </FormControl>

      {/* Font Size Slider */}
      <Typography gutterBottom variant="subtitle1">
        Font Size: <strong>{assStyle.FontSize}px</strong>
      </Typography>
      <Slider
        value={assStyle.FontSize}
        min={12}
        max={100}
        onChange={(e, newValue) =>
          handleStyleChange({ FontSize: newValue as number }, false)
        }
        onChangeCommitted={(e, newValue) =>
          handleStyleChange({ FontSize: newValue as number }, true)
        }
        valueLabelDisplay="auto"
        sx={{ marginBottom: 3 }}
      />
    </Section>

    {/* Font Styles Section */}
    <Section elevation={3}>
      <SectionHeader>
        <FormatBold sx={{ marginRight: 1, color: theme.palette.primary.main }} />
        Font Styles
      </SectionHeader>

      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Font Styles */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Bold">
              <ToggleButton
                value="bold"
                selected={assStyle.Bold === 700}
                onClick={() =>
                  handleStyleChange({ Bold: assStyle.Bold === 0 ? 700 : 0 }, true)
                }
              >
                <FormatBold />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Italic">
              <ToggleButton
                value="italic"
                selected={assStyle.Italic === 1}
                onClick={() =>
                  handleStyleChange({
                    Italic: assStyle.Italic === 1 ? 0 : 1
                  }, true)
                }
              >
                <FormatItalic />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Underline">
              <ToggleButton
                value="underline"
                selected={assStyle.Underline === 1}
                onClick={() =>
                  handleStyleChange({
                    Underline: assStyle.Underline === 1 ? 0 : 1
                  }, true)
                }
              >
                <FormatUnderlined />
              </ToggleButton>
            </Tooltip>
          </Box>

          {/* Alignment */}
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Align Left">
              <ToggleButton
                value={5}
                selected={assStyle.Alignment === 1}
                onClick={() => handleStyleChange({ Alignment: 1 }, true)}
              >
                <FormatAlignLeft />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Align Center">
              <ToggleButton
                value={6}
                selected={assStyle.Alignment === 2}
                onClick={() => handleStyleChange({ Alignment: 2 }, true)}
              >
                <FormatAlignCenter />
              </ToggleButton>
            </Tooltip>
            <Tooltip title="Align Right">
              <ToggleButton
                value={7}
                selected={assStyle.Alignment === 3}
                onClick={() => handleStyleChange({ Alignment: 3 }, true)}
              >
                <FormatAlignRight />
              </ToggleButton>
            </Tooltip>
          </Box>
        </Box>

        {/* Color Pickers */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            marginLeft: 3,
          }}
        >
          <Box sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                width: 36,
                height: 36,
                backgroundColor: assColorToCSSColor(
                  assStyle.PrimaryColour
                ),
                borderRadius: '50%',
                border: '1px solid #ccc',
                cursor: 'pointer',
                margin: '0 auto',
                marginBottom: 1,
              }}
              onClick={handleTextColorClick}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
            />
            <Typography variant="subtitle1">Text Color</Typography>
            <Popover
              open={Boolean(anchorElTextColor)}
              anchorEl={anchorElTextColor}
              onClose={handleTextColorClose}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ChromePicker
                color={assColorToCSSColor(assStyle.PrimaryColour)}
                onChange={(color: ColorResult) => {handleTextColorChange(color, false)}}
                onChangeComplete={(color: ColorResult) => {handleTextColorChange(color, true)}}
                disableAlpha={false}
              />
            </Popover>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box
              sx={{
                width: 36,
                height: 36,
                backgroundColor: assColorToCSSColor(
                  assStyle.BackColour
                ),
                borderRadius: '50%',
                border: '1px solid #ccc',
                cursor: 'pointer',
                margin: '0 auto',
                marginBottom: 1,
              }}
              onClick={handleBgColorClick}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
            />
            <Typography variant="subtitle1">Background Color</Typography>
            <Popover
              open={Boolean(anchorElBgColor)}
              anchorEl={anchorElBgColor}
              onClose={handleBgColorClose}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <ChromePicker
                color={assColorToCSSColor(assStyle.BackColour)}
                onChange={(color: ColorResult) => {handleBgColorChange(color, false)}}
                onChangeComplete={(color: ColorResult) => {handleBgColorChange(color, true)}}
                disableAlpha={false}
              />
            </Popover>
          </Box>
        </Box>
      </Box>
    </Section>

    {/* Uncomment this section if you want to include margins */}
    {/*
    <Section elevation={3}>
      <SectionHeader>
        <FormatLineSpacing sx={{ marginRight: 1, color: theme.palette.primary.main }} />
        Margins
      </SectionHeader>

      <Typography gutterBottom variant="subtitle1">
        Left Margin: <strong>{assStyle.MarginL}px</strong>
      </Typography>
      <Slider
        value={assStyle.MarginL}
        min={0}
        max={500}
        onChange={(e, newValue) =>
          handleStyleChange({ MarginL: newValue as number })
        }
        valueLabelDisplay="auto"
        sx={{ marginBottom: 3 }}
      />

      <Typography gutterBottom variant="subtitle1">
        Right Margin: <strong>{assStyle.MarginR}px</strong>
      </Typography>
      <Slider
        value={assStyle.MarginR}
        min={0}
        max={500}
        onChange={(e, newValue) =>
          handleStyleChange({ MarginR: newValue as number })
        }
        valueLabelDisplay="auto"
        sx={{ marginBottom: 3 }}
      />
    </Section>
    */}
  </Box>
);

  return (
    <Container>
      <Box style={{ direction: 'ltr' }}>
        {selectedElement && (selectedElement.subtitlesActive || selectedElement.type === 'subtitles')
          ? renderCustomizationOptions()
          : renderGenerateSubtitlesOption()}
      </Box>
    </Container>
  );
};

export {
  initialASSStyle
}

export default SubtitlesMenu;
