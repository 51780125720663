import { useEffect, useRef } from 'react';

const AutoScrollComponent = ({ scrollRef, shouldScroll }: { scrollRef: any, shouldScroll: any }) => {
  const edgeThreshold = 50; // Pixels from the edge to start auto-scrolling
  const scrollSpeed = 10; // Adjust the scroll speed to your preference
  const requestRef = useRef<number | null>(null); // For managing the animation frame
  let scrollingDirection: string | null = null;

  // Check if the user is near the edge of the container
  const checkEdgeProximity = (e: MouseEvent) => {
    const rect = scrollRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;

    if (x < edgeThreshold) {
      scrollingDirection = 'left';
    } else if (x > rect.width - edgeThreshold) {
      scrollingDirection = 'right';
    } else {
      scrollingDirection = null;
    }

    // Start or stop the scroll loop based on edge proximity and shouldScroll
    if (scrollingDirection && shouldScroll.current) {
      if (requestRef.current === null) {
        requestRef.current = requestAnimationFrame(handleAutoScroll);
      }
    } else {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
        requestRef.current = null;
      }
    }
  };

  // Scroll the container based on the direction set in `scrollingDirection`
  const handleAutoScroll = () => {
    if (scrollingDirection && scrollRef.current && shouldScroll.current) {
      if (scrollingDirection === 'left') {
        scrollRef.current.scrollLeft -= scrollSpeed;
      } else if (scrollingDirection === 'right') {
        scrollRef.current.scrollLeft += scrollSpeed;
      }
      requestRef.current = requestAnimationFrame(handleAutoScroll);
    } else {
      // Stop the loop if no scrolling direction is needed
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
        requestRef.current = null;
      }
    }
  };

  // Start listening to mouse movements
  useEffect(() => {
    const container = scrollRef.current;
    container.addEventListener('mousemove', checkEdgeProximity);

    return () => {
      container.removeEventListener('mousemove', checkEdgeProximity);
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [scrollRef, shouldScroll]);

  return null; // This component only controls behavior, so it returns no UI
};

export default AutoScrollComponent;
