import { useState, useEffect } from 'react';
import {
  CssBaseline,
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  Link,
  Divider,
  Collapse,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { useAuth } from './AuthContext';
import { loginUser, resetUserPassword, registerViaGoogleOAuth, registerViaMicrosoftOAuth } from '../api/ServerApi';

import { useGoogleLogin } from '@react-oauth/google';
import { PublicClientApplication, AuthenticationResult } from '@azure/msal-browser';

import MicrosoftIcon from '../assets/microsoft-logo.png';
import GoogleIcon from '../assets/google-logo.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { sendLoginTelemetry } from '../utils/utils';
import config from '../constants/constants';

const ZevideoLogo = '/assets/zevideo-black-no-background.webp';

const theme = createTheme({
  palette: {
    primary: {
      main: '#121e2a',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Single overlay covering the entire page
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300, // Ensures the overlay is on top
}));

const Logo = styled('img')(({ theme }) => ({
  width: '60%',
  maxWidth: '200px',
  height: 'auto',
  marginBottom: theme.spacing(1.5),
}));

const ContainerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  padding: theme.spacing(2, 2, 4),
  borderRadius: '10px',
  boxShadow: theme.shadows[5],
  margin: theme.spacing(2),
  position: 'relative',
}));

const SocialButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  textTransform: 'none',
  fontSize: '1rem',
  padding: theme.spacing(1),
  width: '100%',
}));

const SocialIcon = styled('img')({
  width: '20px',
  height: '20px',
  marginRight: '10px',
});

// Microsoft Login Configuration
const msalConfig = {
  auth: {
    clientId: "9b34039d-9b7b-49b7-a5f2-2a1d69586648",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: `${config.frontendUrl}/oauth/microsoft`
  },
};

let msalInstance: PublicClientApplication | null = null;

export default function LoginPage() {
  const { login } = useAuth();

  const [isForgotPasswordState, setIsForgotPasswordState] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [isMsalInitialized, setMsalInitialized] = useState(false);
  const [showManualForm, setShowManualForm] = useState(true);

  const navigate = useNavigate();

  // Initialize MSAL instance when the component mounts
  useEffect(() => {
    msalInstance = new PublicClientApplication(msalConfig);
    msalInstance.initialize().then(() => {
      setMsalInitialized(true);
    });
  }, []);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleForgotPasswordSubmit = () => {
    resetUserPassword(formData.email)
      .then((data) => {
        console.log('Reset successful:', data);
      })
      .catch((error) => {
        console.error('Reset error:', error);
      });
    setIsSubmitDisabled(true);
  };

  const handleLoginSubmit = () => {
    loginUser(formData.email, formData.password)
      .then((data) => {
        console.log('Login successful:', data);
        sendLoginTelemetry(data.username, data.email, data.tenant, data.fullName, data.isAdmin);
        login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
        navigate('/home');
      })
      .catch((error) => {
        console.error('Login error:', error);
        // Handle login error, e.g., show an error message
      });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (isForgotPasswordState) {
      handleForgotPasswordSubmit();
    } else {
      handleLoginSubmit();
    }
  };

  // OAuth Handlers
  const handleGoogleSuccess = (tokenResponse: any) => {
    console.log('Google Login Success:', tokenResponse);
    registerViaGoogleOAuth(tokenResponse.access_token)
      .then((data) => {
        if (data.success === true) {
          sendLoginTelemetry(data.username, data.email, data.tenant, data.fullName, data.isAdmin);
          login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
          navigate('/home');
        } else {
          console.error('Google login failed:', data.message);
        }
      })
      .catch((error) => {
        console.error('Google login error:', error);
      });
  };

  const handleMicrosoftLogin = async () => {
    if (!isMsalInitialized || !msalInstance) {
      console.error('MSAL not initialized');
      return;
    }

    try {
      const tokenResponse: AuthenticationResult = await msalInstance.loginPopup({
        scopes: ["openid", "profile", "User.Read"],
      });
      registerViaMicrosoftOAuth(tokenResponse.accessToken).then((data) => {
        if (data.success === true) {
          sendLoginTelemetry(data.username, data.email, data.tenant, data.fullName, data.isAdmin);
          login(data.username, data.tenant, data.email, data.fullName, data.isAdmin);
          navigate('/home');
        }
      });
      // Process Microsoft login data here
    } catch (error) {
      console.error('Microsoft Login Failed:', error);
    }
  };

  // Custom Google login hook for button click
  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSuccess,
    onError: (error) => console.error('Google Login Failed:', error),
  });

  const toggleManualForm = () => {
    if (!isForgotPasswordState) {
      setShowManualForm(!showManualForm);
    }
  };

  const handleBackToLogin = () => {
    setIsForgotPasswordState(false);
    setIsSubmitDisabled(false);
    setFormData({ email: '', password: '' });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Root>
        <ContainerBox>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Logo src={ZevideoLogo} alt="Zevideo Logo" />

            <Typography component="h1" variant="h5" gutterBottom>
              {isForgotPasswordState ? 'Reset Your Password' : 'Sign In to Your Account'}
            </Typography>

            {!isForgotPasswordState && (
              <>
                <SocialButton variant="outlined" color="primary" onClick={() => googleLogin()}>
                  <SocialIcon src={GoogleIcon} alt="Google logo" />
                  Sign in with Google
                </SocialButton>
                <SocialButton
                variant="outlined"
                color="primary"
                onClick={handleMicrosoftLogin}
                disabled={!isMsalInitialized}
                sx={{
                  opacity: isMsalInitialized ? 1 : 0.5,
                  cursor: isMsalInitialized ? 'click' : 'not-allowed',
                }}
              >
                <SocialIcon
                  src={MicrosoftIcon}
                  alt="Microsoft logo"
                  style={{
                    opacity: isMsalInitialized ? 1 : 0.5,
                  }}
                />
                Sign in with Microsoft
              </SocialButton>
              </>
            )}

            {!isForgotPasswordState && (
              <Button
                fullWidth
                onClick={toggleManualForm}
                endIcon={
                  <ExpandMoreIcon
                    style={{
                      transform: showManualForm ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s',
                    }}
                  />
                }
                style={{
                  marginTop: '16px',
                  textTransform: 'none',
                  justifyContent: 'space-between',
                  padding: '12px',
                  width: '100%',
                }}
              >
                {showManualForm ? 'Sign In with Email' : 'Sign in with Email'}
              </Button>
            )}
          </Box>

          <Collapse in={showManualForm || isForgotPasswordState} timeout="auto" unmountOnExit>
            <Divider sx={{ margin: '20px 0' }} />
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </Grid>
                {!isForgotPasswordState && (
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                  </Grid>
                )}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ margin: '16px 0' }}
                disabled={isSubmitDisabled}
              >
                {!isForgotPasswordState ? 'Login' : 'Reset Password'}
              </Button>
              {isSubmitDisabled && (
                <Typography variant="body2" color="secondary" align="center">
                  An email with instructions has been sent to your inbox.
                </Typography>
              )}
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {!isForgotPasswordState && (
                    <Link
                      onClick={() => {
                        setIsForgotPasswordState(true);
                        setShowManualForm(true);
                        setIsSubmitDisabled(false);
                        setFormData({ email: '', password: '' });
                      }}
                      href="#"
                      variant="body2"
                    >
                      Forgot your password?
                    </Link>
                  )}
                  {isForgotPasswordState && (
                    <Link onClick={handleBackToLogin} href="#" variant="body2">
                      Back to Login
                    </Link>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          {/* Privacy Policy Link Positioned at the Bottom Right */}
          <Box
            sx={{
              position: 'absolute',
              bottom: theme.spacing(2),
              right: theme.spacing(2),
            }}
          >
            <Link href="https://zevideoai.com/index.php/privacy" variant="body2">
              Privacy Policy
            </Link>
          </Box>
        </ContainerBox>
      </Root>
    </ThemeProvider>
  );
}
