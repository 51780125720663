import React, { useState, useEffect, useRef, useCallback, CSSProperties } from 'react';
import { ResizableBox } from 'react-resizable';
import { Box, CircularProgress } from '@mui/material';

import mixpanel from 'mixpanel-browser';

import { ASS_Style } from 'jassub';

import { zIndices } from '../constants/constants';
import EditorTopBar from './EditorTopBar';
import VideoLayers, { VideoLayersRef } from './VideoLayers';
import { produceAssFileForSegments } from '../utils/assparser';
import { serializeSegments, Segment, serializeProject, MediaLayer } from '../utils/serializer';
import EffectSelector from './EffectSelector';
import Video from './media/Video';
import ImageMedia from './media/Image';
import Audio from './media/Audio';
import TextMedia from './media/Text';
import Subtitles from './media/Subtitles';
import Media from './media/Media';
import { downloadVideo, getRenderTasks, getRenderSubtitlesUploadUrl, updateProject, fetchProjects } from '../api/ServerApi';
import { pollRenderedVideoAndDownload, taskCompleteStatuses, uploadMediaSync, applyStyleToTextComponent, assColorToColorArray, isUserEditingDuringEvent, getActiveSegment, handleMediaResize, duplicateMedia, normalizeMedia, getAdjacentVideos, calculateConversionMetadata, deleteTransitionForVideo } from '../utils/utils';
import CommandHistory, {
  AddVideoEffectCommand,
  ChangeApsectRatioCommand,
  debounceBackgroundChangeHistoryAction,
  debouncedTextChangeHistoryAction,
  debouncedMediaChangeHistoryAction,
  PasteVideoCommand,
  DeleteVideoCommand,
} from './undo/UndoInterface';
import { initialAspectRatio, initialHeightConstant, initialProjectState, useEditorContext } from './VideosContext';

import getEngine from './EffectsEngine';

import 'react-resizable/css/styles.css';
import { TextStyleChangeOptions } from './menus/TextMenu';
import EditorCanvas, { EditorCanvasRef } from './EditorCanvas';
import Transition from './media/Transition';

const Engine = await getEngine();

type Project = {
  id: string,
  lastSave: number | null
}

const Home: React.FC = () => {
  const numColumns = 200000;
  const topNavbarHeight = 48;
  const videoDownloadWidth = 720;
  const initialVideoControlsHeight = 280;
  // TODO: this is not passed to the actual element - it's not synced! need to sync them
  const minEffectSelectorSize = 500;

  const [isLoadingProject, setIsLoadingProject] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [maxPageHeight, setMaxPageHeight] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [tracksHeight, setTracksHeight] = useState(0);
  const videoLayersRef = useRef<VideoLayersRef>(null);
  const editorCanvasRef = useRef<EditorCanvasRef>(null);
  const resizableParentRef = useRef<any>();

  const traislBeforeResizeBounds = useRef<{boxHeight: number, canvasHeight: number, canvasWidth: number}>({boxHeight: 0, canvasHeight: 0, canvasWidth: 0});

  const autoSaveIntervalId = useRef<number | null>(0);

  const {
    state,
    videoSegments,
    initialWidth,
    initialHeight,
    currentTime,
    totalDuration,
    totalTracksDuration,
    canvasBackgroundState,
    projectState,
    runningState,
    canvasState,
    getCopyBuffer,
    setCopyBuffer,
    getSelectedVideos,
    getProjectState,
    setProjectState,
    setTotalDuration,
    setTotalTracksDuration,
    setCurrentTime,
    setGlobalIsRunning,
    setCanvasBackground,
    getCanvasBackground,
    setVideos,
    getSubtitlesContainer,
    getVideos,
    setSubtitlesCanvasState,
  } = useEditorContext();

  const isMobileLayout = () => {
    return window.innerWidth < 768;
  }

  const checkAndSetMobileLayout = () => {
    setMaxPageHeight(window.innerHeight - topNavbarHeight);
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
      return false;
    }
  };

  const downloadPendingVideosIfExist = () => {
    getRenderTasks().then((videosInfo) => {
      if (videosInfo === null) {
          // if there are no pending videos
          return;
      }
      const pendingVideos = videosInfo.filter((vid: any) => !taskCompleteStatuses.includes(vid.status));
      if (pendingVideos.length > 0) {
        setIsDownloading(true);
      }
      for (const video of pendingVideos) {
        // TODO: need to add the output name to the backend render task... for now we download it with the default name instead of the project name
        pollRenderedVideoAndDownload(video.fileName, initialProjectState.name, 1000, () => {
          // TODO: if we have multiple pending videos, the animation will be canceled by first...
          // Right now this shouldn't even happen as we limit to one video at a time
          setIsDownloading(false);
        });
      }
    })
  }

  const uploadSubtitles = useCallback(async (style: ASS_Style, subtitlesX: number, subtitlesY: number, canvasWidth: number, canvasHeight: number, playResX: number, playResY: number, subtitlesDuration: number) => {
      const {generatedName, url} = await getRenderSubtitlesUploadUrl();
      if (!url) {
        return;
      }

      const assContent = await produceAssFileForSegments(videoSegments.current, {
        Fontsize: style.FontSize.toString(),
        Fontname: style.FontName,
        MarginV: style.MarginV.toString(),
        MarginR: style.MarginR.toString(),
        MarginL: style.MarginL.toString(),
        Outline: style.Outline.toString(),
        Shadow: style.Shadow.toString(),
        BorderStyle: style.BorderStyle.toString(),
        Bold: style.Bold.toString(),
        Italic: style.Italic.toString(),
        Underline: style.Underline.toString(),
        PrimaryColour: assColorToAssColorString(style.PrimaryColour),
        BackColour: assColorToAssColorString(style.BackColour),
        Alignment: alignmentToAssAlignment(style.Alignment).toString(),
        //ScaleX: (style.ScaleX * 100).toString(),
        //ScaleY: (style.ScaleY * 100).toString(),
        ScaleX: (100).toString(),
        ScaleY: (100).toString(),
      }, {
        PlayResX: playResX.toString(),
        PlayResY: playResY.toString(),
        WrapStyle: '1'
      });

      const file = new Blob([assContent as string], { type: 'text/x-ssa' });

      // upload the subtitles
      await uploadMediaSync(url, file, file.type);

      const subtitles = {
        name: generatedName,
        style: style,
        x: subtitlesX,
        y: subtitlesY,
        width: canvasWidth,
        height: canvasHeight,
        canvasWidth: playResX,
        canvasHeight: playResY,
        duration: subtitlesDuration
      };

      return subtitles;
  }, [videoSegments]);

  const initializeAutoSaver = useCallback((interval: number, projectId: string) => {
    if (autoSaveIntervalId.current) {
      window.clearInterval(autoSaveIntervalId.current);
    }

    const saveFunction = async () => {
      const videoWidth = videoDownloadWidth;
      let videoHeight = Math.floor(videoWidth / canvasState.canvasAspectRatio);
      videoHeight += videoHeight % 2; // make sure it's divisable by 2 - needed for scaling on backend

      // upload any modifications to the subtitles
      let subtitles = undefined;
      if (state.subtitlesContainer && state.videos.some((vid: Media) => (vid as Video).subtitlesActive)) {
        const subtitlesContainer = getSubtitlesContainer();
        //subtitles = await uploadSubtitles(subtitlesContainer.subtitles.style, subtitlesContainer.x, subtitlesContainer.y, subtitlesCanvas.current.width, subtitlesCanvas.current.height, totalTracksDuration);

        const subtitlesCanvas: HTMLCanvasElement | null = document.getElementById('subtitlesCanvas') as HTMLCanvasElement | null;
        if (!subtitlesCanvas) {
          console.error('not able to retrieve subtitles canvas');
          return;
        }

        subtitles = await uploadSubtitles(
          subtitlesContainer.subtitles.style,
          subtitlesContainer.x,
          subtitlesContainer.y,
          subtitlesContainer.width,
          subtitlesContainer.height,
          subtitlesCanvas.width,
          subtitlesCanvas.height,
          totalTracksDuration
        );
      }

      const currentTimeMillis = Date.now();

      const newProjectState = {...getProjectState(), lastSave: currentTimeMillis};
      setProjectState(newProjectState);

      // use live videos as this wont work (function has stale state)
      const serializedContext = serializeProject(
        projectState,
        getVideos(),
        getCanvasBackground(),
        canvasState.canvasAspectRatio,
        canvasState.canvasWidth,
        canvasState.canvasHeight,
        videoWidth,
        videoHeight,
        totalDuration,
        subtitles);

      try {
        if (newProjectState.id !== null && newProjectState.name !== null) {
          updateProject(serializedContext, currentTimeMillis, newProjectState.id, newProjectState.name);
        } else {
          console.error('Project name and id must be non-empty');
        }
      } catch (err: any) {
        console.error(err);
      }
    }

    const currentTimeMillis = Date.now();
    if (projectState.lastSave && ((currentTimeMillis - projectState.lastSave) > 15000)) {
      saveFunction();
    }
    autoSaveIntervalId.current = window.setInterval(saveFunction, interval)
  }, [getCanvasBackground, getProjectState, getSubtitlesContainer, getVideos, setProjectState, state.subtitlesContainer, state.videos, totalTracksDuration, uploadSubtitles, totalDuration, canvasState, projectState]);

  useEffect(() => {
    if (autoSaveIntervalId.current) {
      window.clearInterval(autoSaveIntervalId.current);
    }

    const curProject = getProjectState();
    if (curProject.id) {
      initializeAutoSaver(15000, curProject.id);
    }
  }, [totalDuration, canvasState, getProjectState, initializeAutoSaver]);

  const createEmptyProject = useCallback(async (): Promise<Project> => {
    return new Promise(async (resolve) => {
      const videoWidth = videoDownloadWidth;
      let videoHeight = Math.floor(videoWidth / canvasState.canvasAspectRatio);
      videoHeight += videoHeight % 2; // make sure it's divisable by 2 - needed for scaling on backend

      const serializedContext = serializeProject(
        projectState,
        [],
        [0, 0, 0, 1.0],
        canvasState.canvasAspectRatio,
        canvasState.canvasWidth, // TODO: this can change
        canvasState.canvasHeight, // TODO: this can change
        videoWidth,
        videoHeight,
        60,
        undefined);

      // we have no projects - start a new empty project
      const currentTimeMillis = Date.now();
      const project = await updateProject(serializedContext, currentTimeMillis, null, initialProjectState.name);
      if (project && project.id) {
        const newProject = { ...initialProjectState, id: project.id };
        setProjectState(newProject);
      }
      resolve(project);
    });
  }, [setProjectState, canvasState, projectState]);

  const scaleVideosToSize = (videos: Media[], prevWidth: number, prevHeight: number, newWidth: number, newHeight: number): Media[] => {
    // Calculate scale factors for width and height based on canvas size changes
    const widthScale = newWidth / prevWidth;
    const heightScale = newHeight / prevHeight;

    // Scale each video’s dimensions and crop properties
    return videos.map((video: Media) => {
      const newVideo = video.deepCopy();
      newVideo.x *= widthScale;
      newVideo.y *= heightScale;
      newVideo.width *= widthScale;
      newVideo.scaledWidth *= widthScale;
      newVideo.baseWidth *= widthScale;
      newVideo.height *= heightScale;
      newVideo.scaledHeight *= heightScale;
      newVideo.baseHeight *= heightScale;
      newVideo.crop.left *= widthScale;
      newVideo.crop.right *= widthScale;
      newVideo.crop.top *= heightScale;
      newVideo.crop.bottom *= heightScale;
      return newVideo;
    });
  }

  const loadSerializedProject = useCallback(async (project: any): Promise<boolean> => {
    return new Promise(async (resolve) => {
      const projectData = JSON.parse(project.data);
      const serializedTracks = projectData.tracks;

      // TODO: this doesnt work for filters (need to apply setEffect etc. - something is not working correctly for some reason)

      if (!editorCanvasRef.current) {
        return false;
      }

      const { newCanvasWidth, newCanvasHeight } = editorCanvasRef.current.setCanvasAspectRatioFunc(projectData.aspectRatio, undefined, undefined, projectData.subtitles?.canvasWidth, projectData.subtitles?.canvasHeight);
      let subtitlesContainer = undefined;
      if (projectData.subtitles) {
        subtitlesContainer = await Subtitles.fromSerialized(projectData.subtitles);
        setSubtitlesCanvasState({
          canvasWidth: projectData.subtitles.canvasWidth,
          canvasHeight: projectData.subtitles.canvasHeight,
          canvasAspectRatio: projectData.subtitles.canvasWidth / projectData.subtitles.canvasHeight
        });
      }

      const scaleFactor = newCanvasHeight / 640;

      const newVideos = await Promise.all(serializedTracks.map(async (media: Media) => {
        return await Media.unSerialize(media, scaleFactor);
      }));

      const scaledVideos = scaleVideosToSize(newVideos, projectData.width, projectData.height, newCanvasWidth, newCanvasHeight);

      setVideos(scaledVideos, undefined, undefined, subtitlesContainer)

      if (projectData.backgroundColor) {
        const [r, g, b, a] = assColorToColorArray(projectData.backgroundColor);
        setCanvasBackground([r / 255, g / 255, b / 255, a / 255]);
      }

      setProjectState({id: project.id, lastSave: project.lastSave, name: project.name});

      setTotalDuration(projectData.totalDuration);
      initializeAutoSaver(15000, project.id as string);

      // if there are no tracks, we're done
      const isFinishedLoading = projectData.tracks.length === 0;
      resolve(isFinishedLoading);
    });
  }, [setCanvasBackground, setProjectState, setTotalDuration, setVideos, initializeAutoSaver]);

  const loadOrCreateInitialProject = useCallback(() => {
    setIsLoadingProject(true);
    fetchProjects().then(async (projects: any[]) => {
      if (projects && projects.length) {
        const sortedProjects = projects.sort((projA, projB) => projA.lastSave - projB.lastSave);
        const project = sortedProjects[projects.length-1];
        const isFinishedLoading = await loadSerializedProject(project);
        if (isFinishedLoading) {
          setIsLoadingProject(false);
        }
      } else {
        setIsLoadingProject(false);
        const project = await createEmptyProject();
        initializeAutoSaver(15000, project.id as string);
      }
    })
  }, [loadSerializedProject, initializeAutoSaver, createEmptyProject]);

  const calculateVideoSegments = useCallback((layers: any[]): Segment[] => {
    const cuts = [];
    let i = 0;
    for (let layer of layers) {
      cuts.push({type: 'start', value: layer.start, layer: layer, index: i});
      cuts.push({type: 'end', value: layer.end, layer: layer, index: i});
      i++;
    }
    const sortedCuts = cuts.sort((a, b) => a.value - b.value);

    const layerOpenMap = layers.map((l, i) => false);
    const segments: Segment[] = [];
    let lastCut: any = null;
    for (const cut of sortedCuts) {
      layerOpenMap[cut.index] = cut.type === 'start';
      if (lastCut && lastCut.type === cut.type && lastCut.value === cut.value) {
        if (cut.type === 'start') {
          // it's the same cut - need add to last segment instead of creating a new segment
          const segmentLayers = [...segments[segments.length-1].layers,  layers[cut.index]];
          const prioritizedSegmentLayers = segmentLayers.sort((a: MediaLayer, b: MediaLayer) => b.rowNumber - a.rowNumber);
          segments[segments.length-1].layers = prioritizedSegmentLayers;
        }// else {
          // it's a closing of a cut after the same cut - dont need to do anything just move on
        //}
        continue;
      }
      // add all the open layers to the new segment, but make sure that they are not actually ending at the same time of last segment end...
      const segmentLayers = layers.filter((layer: MediaLayer, index: number) => layerOpenMap[index] === true && layer.end !== cut.value)
      const prioritizedSegmentLayers = segmentLayers.sort((a, b) => b.rowNumber - a.rowNumber);
      if (segments.length) {
        // close the last segment - set the end of it to our new segment start
        segments[segments.length-1].end = cut.value;
      }
      segments.push({ layers: prioritizedSegmentLayers, start: cut.value, end: -1});

      lastCut = cut;
    }
    // remove the last segment - it is the last "end" cut which has no length
    const newSegments = segments.slice(0, -1);
    return newSegments.map((segment: Segment) => {
      const transitionLayers = segment.layers.filter((layer: MediaLayer) => layer.video instanceof Transition);
      const newLayers = segment.layers.map((layer: MediaLayer) => {
        const relatedTransitionIdx = transitionLayers.findIndex((transitionLayer: MediaLayer) => (transitionLayer.video as Transition).fromVideo.id === layer.video.id || (transitionLayer.video as Transition).toVideo.id === layer.video.id);
        const isPartOfTransition = relatedTransitionIdx !== -1;
        return {...layer, isPartOfTransition: isPartOfTransition};
      });
      return { ...segment, layers: newLayers };
    })
  }, []);

  const getVideoScaleFactor = (video: Video, widthBase: number): number => {
    if (video.scaledWidth === null) {
      return 1;
    }
    // is video width going out of the screen?
    if (video.x + video.scaledWidth  > widthBase) {
      const overflow = (video.x + video.scaledWidth) - widthBase;

      // what percenage should the new video be from the original
      const scaleFactor = (video.scaledWidth - overflow) / video.scaledWidth;
      return scaleFactor;
    }

    return 1;
  }

  const scaleVideo = async (video: Video, oldHeightBase: number, oldWidthBase: number, newWidthBase: number, scale: boolean =true) => {
    if (video.scaledWidth === null || video.scaledHeight === null) {
      console.error("scaleVideo received video with null scaled width or height");
      return video;
    }
    let scaledWidth = oldWidthBase;
    let scaledHeight = oldHeightBase;
    // no need to change anything if the screen is smaller - just keep everything the same size
    if (Math.ceil(newWidthBase) > Math.ceil(oldWidthBase)) {
      return video;
    }
    // if the video is smaller than the new screen width - don't change anything
    if (Math.ceil(video.scaledWidth) <= Math.ceil(newWidthBase)) {
      return video;
    }

    if (scale) {
      scaledWidth = Math.max(Math.ceil(Math.min(video.scaledWidth, newWidthBase)), Math.ceil(newWidthBase)); // width cant be larger than the canvas area
      video.x = Math.ceil(video.x * newWidthBase / oldWidthBase);
      scaledWidth = Math.ceil(scaledWidth * (newWidthBase / oldWidthBase));
      scaledHeight = Math.ceil(Math.min(scaledWidth / video.aspectRatio, oldHeightBase));
    } else {
      scaledWidth = Math.ceil(oldWidthBase);
      scaledHeight = Math.ceil(oldHeightBase);
    }
    if (!video.dirty && video.scaledWidth === scaledWidth && video.scaledHeight === scaledHeight) {
      // video already at the right scale, scaling not needed
      return video;
    }
    const newSize = scaledWidth * scaledHeight * 4;
    video.storageBuffer = new Uint8Array(newSize);
    video.scaledHeight = scaledHeight;
    video.scaledWidth = scaledWidth;
    video.baseHeight = scaledHeight;
    video.baseWidth = scaledWidth;

    const effectString = video.getEffectString();
    await Engine.set_effect(video.videoFdRef as number, video.scaledWidth as number, video.scaledHeight as number, effectString).then((res: number) => {
      if (res === -1) {
        console.error("failed to set effect");
        return;
      }
    });

    return video;
  }

  const scaleVid = async (video: Video, scaleFactor: number) => {
    if (video.scaledWidth === null || video.scaledHeight === null) {
      console.error('scale vid received bad video');
      return video;
    }
    const scaledWidth = Math.ceil(video.scaledWidth * scaleFactor);
    const scaledHeight = Math.ceil(video.scaledHeight * scaleFactor);

    const newSize = scaledWidth * scaledHeight * 4;
    video.storageBuffer = new Uint8Array(newSize);
    video.scaledHeight = scaledHeight;
    video.scaledWidth = scaledWidth;
    // TODO: no rounding and I think it is not accurate
    video.baseHeight = scaledHeight / (1 - video.crop.top - video.crop.bottom);
    video.baseWidth = scaledWidth / (1 - video.crop.left - video.crop.right);

    if (video.videoFdRef !== null && video.videoFdRef !== undefined) {
      const effectString = video.getEffectString();
      await Engine.set_effect(video.videoFdRef as number, video.scaledWidth, video.scaledHeight, effectString).then((res: number) => {
        if (res === -1) {
          console.error("failed to set effect");
          return;
        }
      });
    }

    return video;
  }

  const scaleNewVideo = async (video: Video, heightBase: number, widthBase: number) => {
    let scaledWidth = Math.ceil(heightBase * video.aspectRatio);
    let scaledHeight;
    if (scaledWidth > widthBase) {
      scaledWidth = Math.ceil(widthBase);
      scaledHeight = Math.ceil(scaledWidth / video.aspectRatio);
    } else {
      scaledHeight = Math.ceil(heightBase);
    }
    if (!video.dirty && video.scaledWidth === scaledWidth && video.scaledHeight === scaledHeight) {
      // video already at the right scale, scaling not needed
      return video;
    }
    const newSize = scaledWidth * scaledHeight * 4;
    video.storageBuffer = new Uint8Array(newSize);
    video.scaledHeight = scaledHeight;
    video.scaledWidth = scaledWidth;
    // TODO: no rounding and I think it is not accurate
    video.baseHeight = scaledHeight / (1 - video.crop.top - video.crop.bottom);
    video.baseWidth = scaledWidth / (1 - video.crop.left - video.crop.right);

    return video;
  }

  const scaleAllVideos = async (videos: Video[], heightBase: number, oldWidthBase: number, newWidthBase: number): Promise<any[]> => {
    let newVideos = await Promise.all(videos.map((vid: Video) => {
      if (vid.scaledWidth === null && vid.hasVideo()) {
        return scaleNewVideo(vid, heightBase, newWidthBase)
      }
      return vid;
    }));

    const scaleFactor = Math.min(...newVideos.map((vid: Media) => {
      if (vid.hasVideo() && !(vid instanceof TextMedia)) {
        return getVideoScaleFactor(vid as Video, newWidthBase);
      }
      // if this is not a scallable type - just return factor of 1 (i.e has no effect)
      return 1;
    }));

    if (scaleFactor) {
      return Promise.all(newVideos.map((video: Video) => {
      if (video.hasVideo()) {
        return scaleVid(video, scaleFactor);
      }
      return video;
      }));
    }

    return Promise.all(newVideos.map((video: Video) => {
      return scaleVideo(video, heightBase, oldWidthBase, newWidthBase);
    }));
  }

  const renderOneFrame = useCallback(async (
    seconds: number,
    shouldSeek: boolean = false,
    reApplyFilters: boolean = false,
    newWidth?: number,
    newHeight?: number,
    segments?: Segment[],
    shouldResizeSubtitles?: boolean
  ) => {
    if (editorCanvasRef.current) {
      await editorCanvasRef.current.renderOneFrame(seconds, shouldSeek, reApplyFilters, newWidth, newHeight, segments, shouldResizeSubtitles);
    }
  }, []);

  const handleLayersChanged = useCallback(async (layers: any[]) => {
    const segments = calculateVideoSegments(layers);

    if (editorCanvasRef.current) {
      await editorCanvasRef.current.loadSegmentSubtitles(segments);
    }

    //let existingVideos = layers.map((layer: any) => layer.video);
    // TODO: these two lines are a cause for vicious loop - each layout change triggers a videos change
    // and these changes can trigger another layout change. need to get rid of this logic and make sure everything gets updated
    // without listening to the layout (logical bug to update videos on layout change as the change in the videos causes the layout change!)
    //existingVideos = await scaleAllVideos(existingVideos, canvasState.canvasHeight, canvasState.canvasWidth, canvasState.canvasWidth);
    //setVideos(existingVideos);

    videoSegments.current = segments;
    await renderOneFrame(currentTime.current, true, true);

    const maxVideoEnd = Math.max(...layers.map((layer) => layer.end));
    setTotalTracksDuration(maxVideoEnd);
  }, [calculateVideoSegments, renderOneFrame, currentTime, setTotalTracksDuration, videoSegments]);

  const handleLayerSelected = (selectedMedia: Media | null, multipleSelectionMode: boolean = false) => {
    if (selectedMedia === null) {
      setVideos(getVideos(), [], false);
    } else {
      if (multipleSelectionMode) {
        // add or remove from existing selection

        const mediaWithoutSelected = state.selectedVideoLayers.filter((vid) => vid.id !== selectedMedia.id);
        if (mediaWithoutSelected.length !== state.selectedVideoLayers.length) {
          // user de-selected a media - remove from the selected videos
          setVideos(getVideos(), mediaWithoutSelected.map((media: Media) => media.id), false);
        } else {
          // user selected a media - add it to the videos
          const ids = Array.from([...state.selectedVideoLayers.map((media: Media) => media.id), selectedMedia.id]);
          setVideos(getVideos(), ids, false);
        }
      } else {
        // choose only this video
        setVideos(getVideos(), [selectedMedia.id], false);
      }
    }
  }

  const handleSliderChange = useCallback(async (event: Event, newValue: number | number[]) => {
    const sliderTime = Array.isArray(newValue) ? newValue[0] : newValue;
    // TODO: change this to get input parameter from the slider so we don't rely on events
    // if on some device we dont get the right event it will be a hard to discover bug...
    const isEventSliderRelease = (event.type === 'mouseup' || event.type === 'touchend');

    if (!editorCanvasRef.current) {
      return;
    }

    // if not running, render the frame
    if (!runningState.isRunning) {
      if (isEventSliderRelease) {
        setGlobalIsRunning(false, false);
      } else {
        // if there is a need to update the runningState, do it
        if (runningState.sliderSyncOn !== true) {
          setGlobalIsRunning(false, true);
        }
      }
    }

    // if the event is a slider move - always change the time
    if (!isEventSliderRelease) {
      //await editorCanvasRef.current.setVideoTime(sliderTime);
      await editorCanvasRef.current.setVideoTime(sliderTime);
    }

    if (!runningState.isRunning) {
      renderOneFrame(sliderTime, true, true, undefined, undefined, undefined, isEventSliderRelease);
    }
  }, [runningState, setGlobalIsRunning, renderOneFrame]);

  const handlePlayPause = useCallback(async () => {

    mixpanel.track('VideoPlayPause', {
     'action': !runningState.isRunning,
    });

    const shouldPlay = !runningState.isRunning;

    if (!editorCanvasRef.current) {
      return;
    }

    setGlobalIsRunning(shouldPlay, shouldPlay);

    if (shouldPlay) {
      editorCanvasRef.current.playVideo();
    } else {
      editorCanvasRef.current.pauseVideo();
    }
  }, [setGlobalIsRunning, runningState]);

  const handleSkipFrame = (direction: 'forward' | 'backward') => {
    const activeVideo = getActiveVideo(videoSegments.current, currentTime.current);
    if (activeVideo && activeVideo.videoRef) {
      const frameTime = 1 / 30; // Assuming 30 FPS
      let newTime;
      if (direction === 'forward') {
        newTime = Math.min(currentTime.current + frameTime, totalTracksDuration);
      } else {
        newTime = Math.max(currentTime.current - frameTime, 0);
      }
      setCurrentTime(newTime, true);

      renderOneFrame(newTime, true, true, undefined, undefined, undefined, true);
    }
  };

  const onEffectSelected = async (effectString: string) => {
    mixpanel.track('Filter', {
     'name': effectString,
    });

    if (state.selectedVideoLayers.length && (state.selectedVideoLayers[0] instanceof Video || state.selectedVideoLayers[0] instanceof Audio)) {
      const videoEffectCommand = new AddVideoEffectCommand(state.selectedVideoLayers[0], effectString, () => {
        renderOneFrame(currentTime.current, false, true);
      });
      CommandHistory.push(videoEffectCommand);

      await state.selectedVideoLayers[0].setEffect(effectString);
      renderOneFrame(currentTime.current, false, true);
    }
  }

  const onTransitionSelected = async (transitionName: string, time: number, duration: number, row: number) => {
    mixpanel.track('Transition', {
     'name': transitionName,
    });

    const { videoLeft, videoRight } = getAdjacentVideos(time, row, videoSegments.current);
    if (!videoLeft || !videoRight) {
      return;
    }

    const originalVideos = state.videos;
    const originalSelectedIds = getSelectedVideos().map((video: Media) => video.id);

    //const videoEffectCommand = new AddVideoEffectCommand(state.selectedVideoLayers[0], effectString, () => {
    //  renderOneFrame(currentTime.current, false, true);
    //});
    //CommandHistory.push(videoEffectCommand);

    const transition: Transition = new Transition({
      name: "Transition",
      transition: transitionName,
      fromVideo: videoLeft,
      toVideo: videoRight,
      duration: duration,
      start: videoLeft.getEnd(),
      leftBackgroundOffset: 0,
      rightBackgroundOffset: 0,
      startBase: 0,
      endBase: 0,
      isResizing: false,
      row: row,
      framesOffset: 0,
      playOffset: 0,
      effects: [],
      dirty: false,
      height: 0,
      width: 0,
      url: videoLeft.url,
      type: 'transition',
      sha256: 'transition',
      scaledHeight: 0,
      scaledWidth: 0,
      baseWidth: 0,
      baseHeight: 0,
      x: 0,
      y: 0,
      rotation: 0,
      crop: { left: 0, right: 0, top: 0, bottom: 0 },
    });
    // remove the right video, so we can replace it with a moved version
    let filteredVideos = state.videos.filter((video: Media) => video.id !== videoRight.id && video.id !== videoLeft.id);
    // remove any existing transitions involving these videos, so we can replace with the new transition
    filteredVideos = filteredVideos.filter((video: Media) =>
      !(video instanceof Transition) ||
        !(video.fromVideo.id === videoLeft.id || video.toVideo.id === videoLeft.id || video.fromVideo.id === videoRight.id || video.toVideo.id === videoRight.id)
      );

    const videoRightCpy = videoRight.deepCopy();
    videoRightCpy.start = transition.start + transition.getPlayDuration();
    videoRightCpy.transitionId = transition.id;
    const videoLeftCpy = videoLeft.deepCopy();
    videoLeftCpy.transitionId = transition.id;
    const newVideos = [...filteredVideos, videoLeftCpy, videoRightCpy, transition];

    const newlySelectedIds = newVideos.map((video: Media) => video.id);
    const pasteCommand = new PasteVideoCommand(originalVideos, newVideos, originalSelectedIds, newlySelectedIds, (changedVideos: Media[], changedIds: string[]) => {
      setVideos(changedVideos, changedIds)
    });

    CommandHistory.push(pasteCommand);

    setVideos(newVideos);

    renderOneFrame(currentTime.current, false, true);
  }

  const onChangeBackground = (color: [number, number, number, number]) => {
    debounceBackgroundChangeHistoryAction(getCanvasBackground(), color, (color: [number, number, number, number]) => {
      setCanvasBackground(color);
      renderOneFrame(currentTime.current, false, true);
    })

    setCanvasBackground(color)
    renderOneFrame(currentTime.current, false, true);
  }

  const handleAspectRatioSelected = async (newRatio: number) => {
    if (!editorCanvasRef.current) {
      return;
    }
    const newCanvasWidth = canvasState.canvasHeight * newRatio;

    const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());

    const newVideos = await scaleAllVideos(state.videos as Video[], canvasState.canvasHeight, canvasState.canvasWidth, newCanvasWidth);

    const addVideosCommand = new ChangeApsectRatioCommand(
      originalVideos,
      newVideos, 0,
      canvasState.canvasAspectRatio,
      newRatio,
      canvasState.canvasWidth,
      newCanvasWidth,
      editorCanvasRef.current.setCanvasAspectRatioFunc,
      (newVideos: Video[]) => {
      scaleAllVideos(newVideos, canvasState.canvasHeight, canvasState.canvasWidth, initialWidth.current).then((vids: Media[]) => {
        setVideos(vids);
      })
    },
    canvasState.canvasHeight,
    canvasState.canvasHeight,
  )

    CommandHistory.push(addVideosCommand);

    setVideos(newVideos);
    editorCanvasRef.current.setCanvasAspectRatioFunc(newRatio)
  }

  const handleVideoAdded = async (video: Video, isFirstMedia: boolean) => {
    if (!video.videoRef) {
      console.error('handleVideoAdded recieved video without videoRef');
    }

    if (!editorCanvasRef.current) {
      return;
    }

    let newVideo = video;
    let newVideos = [...getVideos()];
    // @ts-ignore
    if (((video instanceof Video) || (video instanceof ImageMedia))) {
      if (isFirstMedia && !isLoadingProject) {
        // if first media added and it's not an automated process loading a project
        // we should resize the canvas to fit the aspect ratio of the media
        const newWidth = Math.floor(canvasState.canvasHeight * video.aspectRatio);
        const scaledVideos = await scaleAllVideos([video], canvasState.canvasHeight, canvasState.canvasWidth, newWidth);
        newVideo = scaledVideos[0];
        editorCanvasRef.current.setCanvasAspectRatioFunc(video.aspectRatio, newWidth);
      } else {
        const scaledVideos = await scaleAllVideos([video], canvasState.canvasHeight, canvasState.canvasWidth, canvasState.canvasWidth);
        newVideo = scaledVideos[0];
      }
    }

    const videoIndex = state.videos.findIndex((vid) => vid.id === video.id);
    newVideos[videoIndex] = newVideo;

    // Don't change selection if:
    // 1. The user is currently in a multiple selection mode
    // 2. The project is loading
    if (!isLoadingProject && state.selectedVideoLayers.length < 2) {
      setVideos(newVideos, [video.id]);
    } else {
      setVideos(newVideos)
    }

    renderOneFrame(currentTime.current, false, true);

    if (!newVideos.some((vid: Media) => !vid.onLoadedCalled)) {
      setIsLoadingProject(false);
    }
  }

  const assColorToAssColorString = (assColor: number) => {
    assColor = ((assColor >> 24) & 0xff) | (((assColor >> 16) & 0xff) << 8) | (((assColor >> 8) & 0xff) << 16) | ((assColor & 0xff) << 24);
    // Convert the number to a hexadecimal string and format it as '&HAABBGGRR'
    return `&H${assColor.toString(16).padStart(8, '0').toUpperCase()}`;
  }

  const alignmentToAssAlignment = (alignment: number) => {
    const mapping: {[key: number]: number} = {1: 1, 2: 2, 3: 3};
    return mapping[alignment];
  }

  const handleDownloadVideo = async () => {
    if (state.videos.length === 0) {
      // no videos - do not attempt to download
      return;
    }

    mixpanel.track('Download Video Clicked', {
     'numberOfVideos': state.videos.length
    });

    setIsDownloading(true);
    // set the video resolution
    const videoWidth = videoDownloadWidth;
    let videoHeight = Math.floor(videoWidth / canvasState.canvasAspectRatio);
    videoHeight += videoHeight % 2; // make sure it's divisable by 2 - needed for scaling on backend

    let subtitles = undefined;
    if (state.subtitlesContainer && state.videos.some((vid: Media) => (vid as Video).subtitlesActive)) {
      // need to account for ffmpeg taking the y from the top but allows for - offset to take from bottom.
      // We basically take the subtitlesCanvas.style.top and negate it
      const subtitlesCanvas: HTMLCanvasElement | null = document.getElementById('subtitlesCanvas') as HTMLCanvasElement | null;
      if (!subtitlesCanvas) {
        console.error('not able to retrieve subtitles canvas');
      }
      const normalizedSubtitlesY = (state.subtitlesContainer?.y - canvasState.canvasHeight + state.subtitlesContainer?.height + (canvasState.canvasHeight - subtitlesCanvas.height));
      subtitles = await uploadSubtitles(
        state.subtitlesContainer.subtitles.style,
        state.subtitlesContainer.x,
        normalizedSubtitlesY,
        subtitlesCanvas?.width || state.subtitlesContainer.width,
        subtitlesCanvas?.height || state.subtitlesContainer.height,
        //canvasState.canvasWidth,
        //canvasState.canvasHeight,
        subtitlesCanvas?.width || canvasState.canvasWidth,
        subtitlesCanvas?.height || canvasState.canvasHeight,
        totalTracksDuration
      );
    }

    const layers = state.videos.map((video: Media) => calculateConversionMetadata(video));
    // serialize all segments
    const serializedContext = serializeSegments(layers, totalTracksDuration, getCanvasBackground(), canvasState.canvasWidth, canvasState.canvasHeight, videoWidth, videoHeight, subtitles || undefined);
    downloadVideo(serializedContext).then(({videoName}) => {
      mixpanel.track('Download Video Server Response', {
        'name': videoName
      });

      const outputName: string = getProjectState()?.name || initialProjectState.name;
      // poll every second till video is downloaded or error occurs
      pollRenderedVideoAndDownload(videoName, `${outputName}.mp4`, 1000, () => {
        mixpanel.track('Download Video Finished', {
          'name': videoName
        });
        setIsDownloading(false);
      });
    }).catch((err: any) => {
      setIsDownloading(false);
      console.error(err);
    });
  }

  const handleChangeProjectName = async (name: string) => {
    setProjectState({...projectState, name})
  }

  const handleNewProject = async () => {
    const project = await createEmptyProject();
    loadSerializedProject(project);
    initializeAutoSaver(15000, project.id);
  }

  const onSubtitlesStyleChanged = async (subtitles: any, style: ASS_Style, commit: boolean = true) => {
    if (editorCanvasRef.current) {
      editorCanvasRef.current.onSubtitlesStyleChanged(subtitles, style, commit);
    }
  }

  const onTextStyleChanged = useCallback(async ({
    text, style, width, height, x, y, start, end, calculateWidth, calculateHeight = true, commit
  }: TextStyleChangeOptions): Promise<number | undefined> => {
    return new Promise(async (resolve) => {
      if (state.selectedVideoLayers.length || !(state.selectedVideoLayers[0] instanceof TextMedia)) {
        resolve(undefined);
      }

      const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
      const newVideos = [...state.videos];
      const textComponent = state.selectedVideoLayers[0] as TextMedia;

      // TODO: use resizeTextComponent instead of this code (had issues making it work)
      const fontSize = parseFloat(style.fontSize as string);
      const beforeFontSize = parseFloat(textComponent.style.fontSize as string);
      const newWidth = (textComponent.scaledWidth as number) * fontSize / beforeFontSize;
      if (calculateWidth) {
        // calculate automatically
        width = newWidth;
      }
      const newVPadding = textComponent.vPadding * fontSize / beforeFontSize;

      let [newTextComponent, imageHeight, _] = await applyStyleToTextComponent({
        textComponent,
        text,
        style,
        height,
        width: width !== undefined ? width : undefined,
        hPadding: textComponent.hPadding,
        vPadding: newVPadding,
        scaleFactor: canvasState.canvasHeight / 640,
        calculateWidth: false,
        calculateHeight
      });

      if ((start !== null && start !== undefined) && (end !== null && end !== undefined) && end > start) {
        newTextComponent.start = start;
        const duration = end - start
        newTextComponent.duration = duration;
        newTextComponent.endBase += duration / totalDuration * numColumns;
      }

      imageHeight = height ? height : imageHeight;
      newTextComponent.scaledHeight = imageHeight;
      newTextComponent.height = imageHeight;
      newTextComponent.baseHeight = imageHeight;
      newTextComponent = handleMediaResize(
        x || newTextComponent.x,
        y || newTextComponent.y,
        newTextComponent.baseWidth,
        imageHeight, null, (newTextComponent as unknown) as Video, false) as TextMedia;

      // Cant use the current index because there is a race condition between all the async functions and the initial change
      const selectedIndex = state.videos.findIndex((video: Media) => video.id === newTextComponent.id);
      if (selectedIndex !== -1) {
        newVideos[selectedIndex] = newTextComponent;

        debouncedTextChangeHistoryAction(originalVideos, newVideos, selectedIndex, canvasState.canvasHeight / 640, (videos: Media[]) => {
          setVideos(videos, [newTextComponent.id]);
          renderOneFrame(currentTime.current, false, true);
        });

        // update the text / style in real time (prevent expensive render of basically the entire up due to videos being required by many components)
        //if (textComponent.height !== textHeight || commit) {
        if ((textComponent.text !== text && textComponent.height !== imageHeight) || commit) {
          setVideos(newVideos, [newTextComponent.id], false);
        } else {
          // TODO: i currently do not commit?
          setVideos(newVideos, [newTextComponent.id], true);
          editorCanvasRef.current?.updateBoundingBoxes(newVideos, state.subtitlesContainer);
        }

        renderOneFrame(currentTime.current, false, true);
      }
      resolve(imageHeight);
    });
  }, [canvasState, state, currentTime, totalDuration, setVideos, renderOneFrame]);

  const onToggleSubtitles = async (media: Video | Audio) => {
    if ((media instanceof Video || media instanceof Audio) && media.subtitlesUrl) {
      if (!editorCanvasRef.current) {
        return;
      }

      editorCanvasRef.current.clearAllSubtitles();

      if (!media.subtitlesActive) {
        return;
      }

      editorCanvasRef.current.loadSegmentSubtitles(videoSegments.current);
    }

    renderOneFrame(currentTime.current, false, true);
  }

  const onMediaChanged = async (media: Video, start: number | null, end: number | null, commit: boolean) => {
    const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
    let newVideos = [...state.videos];

    const selectedIndex = state.videos.findIndex((video: Media) => video.id === media.id);

    if (selectedIndex === -1) {
      return;
    }
    const curVideo = state.videos[selectedIndex].deepCopy();

    if (start !== null && end !== null && end > start) {
      curVideo.start = start;
      // calculate the current total media time (excluding truncated edges)
      const currentTimeLength = curVideo.getPlayDuration();
      // calculate the amount of seconds to trunacte from the end
      const truncatedEndTime = currentTimeLength - (end - start);

      // shrink the movie from right according to the number of columns needed to cut
      curVideo.rightTrim += truncatedEndTime;
      curVideo.rightBackgroundOffset += truncatedEndTime / totalDuration * numColumns;
      curVideo.endBase += truncatedEndTime / totalDuration * numColumns;
    }

    const speedChange = media.speed / curVideo.speed;
    if (speedChange) {
      const newSpeed = curVideo.speed / speedChange;
      curVideo.setPlaybackSpeed(newSpeed);
      // if we changed to lower speed, the video might have increased in size over
      // the current total duration lets fix that
      if (curVideo.getEnd() > totalDuration) {
        setTotalDuration(curVideo.getEnd() + 2);
      }

      newVideos = deleteTransitionForVideo(curVideo as Video, newVideos);
    }

    curVideo.speed = media.speed;

    curVideo.videoRef.volume = media.volume;
    curVideo.videoRef.playbackRate = media.speed;

    if (curVideo instanceof Video) {
      curVideo.hue = media.hue;
      curVideo.volume = media.volume;
      curVideo.brightness = media.brightness;
      curVideo.opacity = media.opacity;

      const effectString = curVideo.getEffectString();
      if (curVideo.videoFdRef !== null) {
        Engine.set_effect(curVideo.videoFdRef, curVideo.scaledWidth as number, curVideo.scaledHeight as number, effectString)
      }
    }

    newVideos[selectedIndex] = curVideo;

    debouncedMediaChangeHistoryAction(originalVideos, newVideos, selectedIndex, ((vids: Media[]) => {
      if (originalVideos[selectedIndex].volume !== (newVideos[selectedIndex] as Video).volume) {
        vids[selectedIndex].videoRef.volume = (vids[selectedIndex] as Video).volume;
      }
      const effectString = (vids[selectedIndex] as Video).getEffectString();
      if (curVideo.videoFdRef !== null) {
        Engine.set_effect((vids[selectedIndex] as Video).videoFdRef as number, curVideo.scaledWidth as number, curVideo.scaledHeight as number, effectString)
      }
      setVideos(vids, [vids[selectedIndex].id]);
      renderOneFrame(currentTime.current, false, true);
    }));

    // update the text / style in real time (prevent expensive render of basically the entire up due to videos being required by many components)
    if (commit) {
      setVideos(newVideos, curVideo.id);
    } else {
      setVideos(newVideos, undefined, true);
    }
    renderOneFrame(currentTime.current, false, true);
  }

  const handleDuplicate = async () => {
    if (state.selectedVideoLayers.length === 0) {
      return;
    }

    const newVideos = await duplicateMedia(state.selectedVideoLayers, state.videos);

    const ids = newVideos.map((video: Media) => video.id)
    setVideos([...state.videos, ...newVideos], ids);
  }

  const deleteSubtitles = useCallback(() => {
    if (!editorCanvasRef.current) {
      return;
    }

    const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());

    editorCanvasRef.current.clearAllSubtitles();
    const newVideos = state.videos.map((video: Media) => {
      const newVideo = video.deepCopy();
      if ((newVideo instanceof Video) || (newVideo instanceof Audio)) {
        newVideo.subtitlesUrl = '';
        newVideo.subtitlesActive = false;
        newVideo.subtitlesLoaded = false;
      }
      return newVideo;
    })

    const deleteVideosCommand = new DeleteVideoCommand(originalVideos, newVideos, ['subtitles'], [], 0, 0, (changedVideos: Media[], newIds: string[], newDuration: number) => {
      // TODO: need to select the previous / new ids
      setVideos(changedVideos, newIds);
      if (newIds.includes('subtitles') && editorCanvasRef.current) {
        editorCanvasRef.current.loadSegmentSubtitles(videoSegments.current);
      }
    });

    CommandHistory.push(deleteVideosCommand);

    setVideos(newVideos, []);
  }, [state]);

  const handleDelete = useCallback(async () => {

    mixpanel.track('VideoDelete', {
      'numberOfVideos': state.videos.length,
    });

    if (state.selectedVideoLayers.length === 0) {
      return;
    }

    if (state.selectedVideoLayers.length === 1 && state.selectedVideoLayers[0] instanceof Subtitles) {
      deleteSubtitles();
      return;
    }

    // TODO: for the undo function - when we do several undos we can delete movies and because we restore from a snapshot of videos some of them have old fds that may be closed...
    // need to deal with that before we can close / open using c code handles... for now we dont close resources...
    //if (!!(selectedVideo instanceof Video) as boolean || !!(selectedVideo instanceof Audio)) {
    //  const fd = (selectedVideo as Video).videoFdRef;
    //  if (fd !== null) {
    //    await Engine.close_movie([(fd as unknown) as string]).then((res: number) => {
    //      if (res === -1) {
    //        console.error('failed to close movie');
    //      }
    //    })
    //  }
    //}

    const originalSelectedIds = state.selectedVideoLayers.map((video: Media) => video.id);
    const updatedVideos = [...state.videos].filter((video: Media) => !originalSelectedIds.includes(video.id));

    // the maximum end time of all media
    const newTotalDuration = updatedVideos.length ? Math.max(...updatedVideos.map((video) => video.getEnd())) : 10;

    const normalizedVideos = normalizeMedia(updatedVideos, totalDuration, newTotalDuration);

    const originalVideos = state.videos.map((vid: Media) => vid.deepCopy());
    const deleteVideosCommand = new DeleteVideoCommand(originalVideos, normalizedVideos, originalSelectedIds, [], totalDuration, newTotalDuration, (newVideos: Media[], newIds: string[], newDuration: number) => {
      // TODO: need to select the previous / new ids
      setVideos(newVideos, newIds);
      setTotalDuration(newDuration);
    });

    CommandHistory.push(deleteVideosCommand);

    // resize the tracks to the full video length
    setTotalDuration(newTotalDuration);
    setVideos(normalizedVideos, []);

    if (normalizedVideos.length === 0) {
      // for some reason in mobile when last video is deleted there is no callback
      // this is a hack to fix it...
      handleLayersChanged([]);
    }
  }, [state, setVideos, setTotalDuration, handleLayersChanged, deleteSubtitles, totalDuration]);

  const getActiveVideo = (segments: any[], seconds: number) => {
    let activeVideo = null;
    const activeSegment = getActiveSegment(segments, seconds);
    if (activeSegment && activeSegment.layers.length) {
      activeVideo = activeSegment.layers[0].video;
    }
    return activeVideo;
  }

  const selectAllVideos = useCallback(() => {
    setVideos(getVideos(), getVideos().map((video: Media) => video.id));
  }, [setVideos, getVideos])

  const handleCopy = useCallback(() => {
    // remove subtitles - we do not copy that element...
    const selectedVideos = getSelectedVideos().filter((video: Media) => !(video instanceof Subtitles));

    setCopyBuffer(selectedVideos);
  }, [setCopyBuffer, getSelectedVideos]);

  const handlePaste = useCallback(() => {
    const copiedVideos = getCopyBuffer();
    if (!copiedVideos.length) {
      return;
    }
    const originalVideos = [...getVideos()];
    duplicateMedia(copiedVideos, getVideos()).then((duplicateVideos: Media[]) => {
      const newVideos = [...getVideos(), ...duplicateVideos];

      const originalSelectedIds = getSelectedVideos().map((video: Media) => video.id);
      const newlySelectedIds = duplicateVideos.map((video: Media) => video.id);
      const pasteCommand = new PasteVideoCommand(originalVideos, newVideos, originalSelectedIds, newlySelectedIds, (changedVideos: Media[], changedIds: string[]) => {
        setVideos(changedVideos, changedIds)
      });

      CommandHistory.push(pasteCommand);

      const ids = duplicateVideos.map((video: Media) => video.id)
      setVideos(newVideos, ids);
    })
  }, [getCopyBuffer, getVideos, getSelectedVideos, setVideos]);


  useEffect(() => {
    Engine.on('log', (message: any) => {
        console.log(message);
    });

    // set this for choosing the layout type - mobile or desktop
    checkAndSetMobileLayout();

    const effectSelectorSpace = isMobileLayout() ? 20 : minEffectSelectorSize;

    let cheight = Math.min(window.innerHeight - initialVideoControlsHeight - 150, initialHeightConstant*3); // -100 for the top bars... careful to change this as it messes up the box
    let cwidth = cheight * initialAspectRatio;
    if (cwidth > window.innerWidth - effectSelectorSpace) {
      cwidth = Math.max(Math.min(window.innerWidth - effectSelectorSpace, initialHeightConstant*4), 100);
      cheight = cwidth / initialAspectRatio;
    }
    initialHeight.current = cheight;
    initialWidth.current = cwidth;

    if (editorCanvasRef.current) {
      editorCanvasRef.current.setCanvasAspectRatioFunc(canvasState.canvasAspectRatio, cwidth, cheight);
    }

    window.addEventListener('resize', checkAndSetMobileLayout);

    // Prevent the default context menu globally
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    downloadPendingVideosIfExist();

    loadOrCreateInitialProject();

    // prevent IOS back command when swiping from the left
    const handleTouchEvent = (event: TouchEvent) => {
      // Prevent swipe-back on iOS if the touch starts near the left edge (within 50px)
      if (event.touches[0].clientX < 50) {
        event.preventDefault();
      }
    };
    //window.addEventListener('touchstart', handleTouchEvent, { passive: false });
    window.addEventListener('touchmove', handleTouchEvent, { passive: false });

    return () => {
      window.removeEventListener('resize', checkAndSetMobileLayout);
      window.removeEventListener('touchstart', handleTouchEvent);
      document.removeEventListener('contextmenu', handleContextMenu);
      //window.removeEventListener('touchmove', handleTouchEvent);
    }
  },[]);

  // set up hotkeys
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      /* Events that should always trigger - even if a user is editing text */
      if (event.ctrlKey && event.key === 'z') {
        event.preventDefault(); // Prevent the default browser action (e.g., undo in a text input)
        CommandHistory.undo();
      }

      if (event.ctrlKey && event.key === 'r') {
        event.preventDefault(); // Prevent the default browser action (e.g., reload page)
        CommandHistory.redo();
      }

      const isEditing = isUserEditingDuringEvent(event);

      if (isEditing) {
        // If in a text editing area, skip key handlers
        return;
      }

      /* Events that should not trigger during edit */

      if (event.key === ' ') {
        event.preventDefault(); // Prevent the default browser action (e.g., reload page)
        handlePlayPause();
      }

      if (event.key === 'Delete' || event.key === 'Backspace') {
          event.preventDefault();
          handleDelete();
      }

      if (event.ctrlKey && event.key === 'a') {
        event.preventDefault(); // Prevent the default browser action (e.g., reload page)
        selectAllVideos();
      }

      if (event.ctrlKey && event.key === 'c') {
        event.preventDefault(); // Prevent the default browser action (e.g., reload page)
        handleCopy();
      }

      if (event.ctrlKey && event.key === 'v') {
        event.preventDefault(); // Prevent the default browser action (e.g., reload page)
        handlePaste();
      }
    };
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [handlePlayPause, getCopyBuffer, getVideos, getSelectedVideos, selectAllVideos, setCopyBuffer, setVideos, handleCopy, handleDelete, handlePaste])

  const onTextCreated = (text: string, style: CSSProperties) => {
    videoLayersRef.current?.onTextCreated(text, style);
  }

  return (
    <div style={{ maxHeight: maxPageHeight, height: maxPageHeight, flexDirection: 'column', display: 'flex', flexGrow: 1, maxWidth: '100%' }}>
      <div className="pageContainer">
        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', maxWidth: '100%', overflow: 'hidden' }}>
          <Box sx={{ height: '100%', display: 'flex', flexGrow: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'start', maxWidth: '100%' }}>
            {!isMobile && (
            <EffectSelector
              isDisabled={isLoadingProject}
              // TODO: world ugliest (and craziest) way to get the start and end of the media...
              //       keep it in some variable in the media... or at the very least implement a function to do it
              videoStart={(state.selectedVideoLayers.length) ? state.selectedVideoLayers[0].start : null}
              videoEnd={(state.selectedVideoLayers.length) ? state.selectedVideoLayers[0].getEnd() : null}
              selectedVideo={state.selectedVideoLayers.length === 1 ? state.selectedVideoLayers[0] as Video : null}
              canvasBackgroundColor={canvasBackgroundState}
              onSelect={onEffectSelected}
              onSelectTransition={onTransitionSelected}
              onTextCreated={onTextCreated}
              onTextStyleChanged={onTextStyleChanged}
              onSubtitlesGenerate={videoLayersRef.current ? videoLayersRef.current.handleAddSubtitles : () => {}}
              onSubtitlesUpload={videoLayersRef.current ? videoLayersRef.current.onSubtitlesUpload : () => {}}
              onSubtitlesStyleChanged={onSubtitlesStyleChanged}
              onMediaChanged={onMediaChanged}
              onChangeBackground={onChangeBackground}
              onAddMedia={videoLayersRef.current ? videoLayersRef.current.onAddMedia : () => {}}
              isMobileLayout={isMobile} />) }
            <Box
                sx={{ position: 'relative', display: 'flex', flexGrow: 1, height: '100%', width: '100%', flexDirection: isMobile ? 'column' : 'column', alignItems: 'left', justifyContent: 'center', maxWidth: '100%', overflow: 'hidden' }}
            >
            {isLoadingProject && (
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  maxWidth: '100%',
                  overflow: 'hidden',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  zIndex: zIndices.LoadingOverlay,
                  backgroundColor: 'rgba(0, 0, 0, 0.06)',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  color: 'white',
                }}
              >
                <CircularProgress size={60} sx={{ color: 'white', mb: 2 }} />
                <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Loading Videos...</span>
              </Box>
            )}

              <EditorTopBar
                projectName={projectState?.name || initialProjectState.name}
                lastSaveDate={projectState?.lastSave || initialProjectState.lastSave}
                isMobile={isMobile}
                isSaving={false}
                isConnected={true}
                isDownloading={isDownloading}
                canvasAspectRatio={canvasState.canvasAspectRatio}
                handleChangeProjectName={handleChangeProjectName}
                handleNewProject={handleNewProject}
                handleDownloadVideo={handleDownloadVideo}
                handleAspectRatioSelected={handleAspectRatioSelected}/>
              <EditorCanvas
                ref={editorCanvasRef}
                isMobileLayout={isMobile}
                onTextStyleChanged={onTextStyleChanged}
                onVideoSelected={handleLayerSelected}
                onDelete={handleDelete}
                onDuplicate={handleDuplicate}
                onCopy={handleCopy}
                onPaste={handlePaste}
              />
              <Box ref={resizableParentRef} sx={{ width: '100%' }}>
                <ResizableBox
                  width={Infinity}
                  height={tracksHeight || initialVideoControlsHeight}
                  minConstraints={[0, 220]}
                  maxConstraints={[Infinity, 800]}
                  resizeHandles={['n']}
                  onResizeStart={async (e, data) => {
                    traislBeforeResizeBounds.current = { boxHeight: data.size.height, canvasHeight: canvasState.canvasHeight, canvasWidth: canvasState.canvasWidth };
                  }}
                  onResize={async (e, data) => {
                    let heightMultiplier = traislBeforeResizeBounds.current.boxHeight / data.size.height;
                    const height = Math.floor(traislBeforeResizeBounds.current.canvasHeight * heightMultiplier);
                    heightMultiplier = height / traislBeforeResizeBounds.current.canvasHeight;
                    const width = Math.floor(height * canvasState.canvasAspectRatio);
                    const widthMultiplier = width / traislBeforeResizeBounds.current.canvasWidth;
                    editorCanvasRef.current?.resizeRenderCanvas(width, height, true);
                    const videos = (await Promise.all(state.videos.map(async (vid: Media) => {
                      const newVideo = handleMediaResize(vid.x * widthMultiplier, vid.y * heightMultiplier, (vid.scaledWidth || 0) * widthMultiplier, (vid.scaledHeight || 0) * heightMultiplier, null, vid as Video, false);
                      return newVideo;
                    }))) as Video[];
                    editorCanvasRef.current?.updateBoundingBoxes(videos, state.subtitlesContainer);
                  }}
                  onResizeStop={async (e, data) => {
                    if (!editorCanvasRef.current) {
                      return;
                    }
                    let heightMultiplier = traislBeforeResizeBounds.current.boxHeight / data.size.height;
                    const height = Math.floor(traislBeforeResizeBounds.current.canvasHeight * heightMultiplier);
                    heightMultiplier = height / traislBeforeResizeBounds.current.canvasHeight;
                    const width = Math.floor(height * canvasState.canvasAspectRatio);
                    const widthMultiplier = width / traislBeforeResizeBounds.current.canvasWidth;

                    const newVideos = (await Promise.all(state.videos.map(async (vid: Media) => {
                      const newVideo = handleMediaResize(vid.x * widthMultiplier, vid.y * heightMultiplier, (vid.scaledWidth || 0) * widthMultiplier, (vid.scaledHeight || 0) * heightMultiplier, null, vid as Video, false);
                      if (newVideo instanceof Video && newVideo.videoFdRef !== null && newVideo.videoFdRef !== undefined && newVideo.requiresWasm()) {
                        const effectString = newVideo.getEffectString();
                        await Engine.set_effect(newVideo.videoFdRef as number, newVideo.scaledWidth as number, newVideo.scaledHeight as number, effectString).then((res) => {
                          if (res === -1) {
                            console.error("failed to set effect");
                            return;
                          }
                        });
                      }
                      return newVideo;
                    }))) as Video[];
                    initialWidth.current = width;
                    initialHeight.current = height;

                    editorCanvasRef.current?.setCanvasAspectRatioFunc(canvasState.canvasAspectRatio, width, height)

                    const originalVideos = state.videos;
                    const tracksHeightBefore = traislBeforeResizeBounds.current.boxHeight;
                    const tracksHeightAfter = data.size.height;
                    const addVideosCommand = new ChangeApsectRatioCommand(
                      originalVideos,
                      newVideos,
                      0,
                      canvasState.canvasAspectRatio,
                      canvasState.canvasAspectRatio,
                      traislBeforeResizeBounds.current.canvasWidth,
                      width,
                      (newRatio: number, newWidth?: number, newHeight?: number, tracksHeight?: number) => {
                        editorCanvasRef.current?.setCanvasAspectRatioFunc(newRatio, newWidth, newHeight);
                        if (tracksHeight) {
                          setTracksHeight(tracksHeight);
                        }
                        if (newWidth) {
                          initialWidth.current = newWidth;
                        }
                        if (newHeight) {
                          initialHeight.current = newHeight;
                        }
                      },
                      async (changedVideos: Video[]) => {
                        const updatedVideos = (await Promise.all(changedVideos.map(async (newVideo: Media) => {
                          if (newVideo instanceof Video && newVideo.videoFdRef !== null && newVideo.videoFdRef !== undefined && newVideo.requiresWasm()) {
                            const effectString = newVideo.getEffectString();
                            await Engine.set_effect(newVideo.videoFdRef as number, newVideo.scaledWidth as number, newVideo.scaledHeight as number, effectString).then((res) => {
                              if (res === -1) {
                                console.error("failed to set effect");
                                return;
                              }
                            });
                          }
                          return newVideo;
                        }))) as Video[];

                        setVideos(updatedVideos);
                        renderOneFrame(currentTime.current, false, true);
                      },
                      traislBeforeResizeBounds.current.canvasHeight,
                      height,
                      tracksHeightBefore,
                      tracksHeightAfter,
                    )

                    CommandHistory.push(addVideosCommand);

                    setTracksHeight(tracksHeightAfter);
                    const ids = state.selectedVideoLayers.map((vid: Media) => vid.id);
                    setVideos(newVideos, ids);
                    renderOneFrame(currentTime.current, false, true);
                  }}
                  style={{backgroundColor: '#ffffff', borderTop: '1px solid #ddd'}}
                  handle={isMobile ? (
                    <div
                      style={{
                        position: 'absolute',
                        paddingRight: '16px',
                        paddingLeft: '16px',
                        boxSizing: 'border-box',
                        width: '100%',
                        backgroundColor: '#ffffff', // White background to resemble a card
                        height: '20px',
                        top: '-20px',
                        left: '0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'ns-resize',
                        borderTop: '1px solid #ddd', // Light border for separation
                        borderRadius: '4px 4px 0 0', // Rounded top corners to resemble a card
                      }}
                    >
                      <div
                        style={{
                          width: '35px',
                          height: '4px',
                          borderRadius: '2px',
                          backgroundColor: '#bbb',
                        }}
                      />
                    </div>
                  ) : (
                    // Your desktop handle implementation
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        backgroundColor: 'transparent',
                        height: '10px',
                        top: '0px',
                        left: '0px',
                        cursor: 'ns-resize',
                      }}
                    />
                  )}
                >
                  <VideoLayers
                    ref={videoLayersRef}
                    onToggleSubtitles={onToggleSubtitles}
                    onEffectSelected={onEffectSelected}
                    onTransitionSelected={onTransitionSelected}
                    onVideoAdded={handleVideoAdded}
                    onDuplicate={handleDuplicate}
                    onCopy={handleCopy}
                    onPaste={handlePaste}
                    onDelete={handleDelete}
                    onLayersChanged={handleLayersChanged}
                    onSliderChange={handleSliderChange}
                    onLayerSelected={handleLayerSelected}
                    handlePlayPause={handlePlayPause}
                    handleSkipFrame={handleSkipFrame}
                    onChangeBackground={onChangeBackground}
                    onSubtitlesStyleChanged={onSubtitlesStyleChanged}
                    onMediaChanged={onMediaChanged}
                    onTextStyleChanged={onTextStyleChanged}
                    isRunning={runningState.isRunning}
                    canvasDimensions={{aspectRatio: canvasState.canvasAspectRatio, width: canvasState.canvasWidth, height: canvasState.canvasHeight}}
                    selectedVideos={state.selectedVideoLayers}
                    canvasBackgroundColor={canvasBackgroundState}
                    isMobile={isMobile}
                    numColumns={numColumns}
                    />
                </ResizableBox>
              </Box>
            </Box>

          </Box>
        </Box>
      </div>
    </div>
  );
};


export default Home;
