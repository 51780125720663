import React, { useState } from 'react';
import { Slider, Box, Grid, Tooltip, SliderProps } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

import EditorIconButton from './EditorIconButton';

interface ZoomBarProps {
  minZoom: number;
  maxZoom: number;
  initialZoom: number;
  isMobileLayout: boolean;
  onZoomChange: (value: number) => void;
}

// Custom ValueLabelComponent for the Slider's Tooltip
const ValueLabelComponent: React.FC<SliderProps> = (props: any) => {
  const { children, value } = props;

  return (
    <Tooltip
      open={props.open}
      enterTouchDelay={0}
      placement="top"
      title={<span>{value}%</span>}
      PopperProps={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              altBoundary: true,
              rootBoundary: 'viewport',
              tether: false,
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
};

const ZoomBar: React.FC<ZoomBarProps> = ({ minZoom, maxZoom, initialZoom, isMobileLayout, onZoomChange }) => {
  const [zoomValue, setZoomValue] = useState<number>(initialZoom);

  const handleZoomChange = (_: any, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;
    if (value < 0 || value > 100) {
      // ignore attempt to put value which is out of range of percentage
      return;
    }
    setZoomValue(value);

    // return the multiplier (multiply the width of the component
    // It is calculated such that it will multiply the size of componant by a constant
    // such that at 100% the visible component size will be a one second interval
    const newZoom = Math.pow(maxZoom, value/100);
    onZoomChange(newZoom);
  };

  return (
    <Box sx={{ width: 130, padding: 0, backgroundColor: 'transparent', borderRadius: '10px', height: '100%' }}>
      <Grid container spacing={0} alignItems="center" justifyContent="end" sx={{ height: '100%', margin: 0, width: '100%' }}>
        {/* Zoom Out Icon */}
        <Grid item>
          {isMobileLayout ? (
          <EditorIconButton isMobile={isMobileLayout} onClick={() => handleZoomChange(null, zoomValue - 1)}>
              <ZoomOutIcon sx={{height: '20px', width: '20px'}} />
          </EditorIconButton>
          ) : (
          <ZoomOutIcon onClick={() => handleZoomChange(null, zoomValue - 1)} fontSize="medium" sx={{cursor: 'pointer', width: '35px'}}/>
          )}
        </Grid>

        { !isMobileLayout && (
        <Grid item xs>
          <Slider
            value={zoomValue}
            min={0}
            max={100}
            onChange={handleZoomChange}
            valueLabelDisplay="auto"
            components={{
              ValueLabel: ValueLabelComponent, // Custom ValueLabel with Tooltip
            }}
            aria-labelledby="zoom-bar"
            sx={{
              '& .MuiSlider-thumb': {
                height: 12,
                width: 12,
                backgroundColor: '#fff',
                border: '2px solid currentColor',
                '&:hover': {
                  boxShadow: '0px 0px 0px 8px rgba(0, 0, 0, 0.16)',
                },
              },
              '& .MuiSlider-track': {
                height: 4,
                borderRadius: '4px',
              },
              '& .MuiSlider-rail': {
                height: 4,
                borderRadius: '4px',
              },
            }}
          />
        </Grid>
        )}

        {/* Zoom In Icon */}
        <Grid item>
          {isMobileLayout ? (
          <EditorIconButton isMobile={isMobileLayout} onClick={() => handleZoomChange(null, zoomValue + 1)}>
              <ZoomInIcon sx={{height: '20px', width: '20px'}} />
          </EditorIconButton>
          ) : (
          <ZoomInIcon onClick={() => handleZoomChange(null, zoomValue + 1)} fontSize="medium" sx={{cursor: 'pointer', width: '35px'}}/>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ZoomBar;
