import React, { useState, useEffect } from 'react';
import { Typography, Box, useTheme, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Container } from './Styles';
import TimelineControls from './TimelineControls';
import ImageMedia from '../media/Image';
import Video from '../media/Video';
import EmptyMediaMenu from './EmptyMediaMenu';

// Define the interface for SpeedButton's transient prop
interface SpeedButtonProps {
  $active: boolean;
}

// Styled SpeedButton using transient prop $active
const SpeedButton = styled(Button)<SpeedButtonProps>(({ theme, $active }) => ({
  marginRight: theme.spacing(1),
  backgroundColor: $active ? theme.palette.primary.main : theme.palette.grey[300],
  color: $active ? theme.palette.primary.contrastText : theme.palette.text.primary,
  '&:hover': {
    backgroundColor: $active ? theme.palette.primary.dark : theme.palette.grey[400],
  },
  minWidth: 50, // Consistent button width
}));

type MediaAttributes = {
  start: number;
  end: number;
}

type SelectedMediaType = {
  media: ImageMedia | null;
  start: number,
  end: number
}

interface MediaMenuProps {
  selectedMedia: SelectedMediaType | null;
  mediaStart: number | null,
  mediaEnd: number | null,
  isMobileLayout: boolean;
  onMediaChanged: (media: Video, start: number | null, end: number | null, commit: boolean) => void;
  onAddMedia: (file: File) => void;
}

const ImageMenu: React.FC<MediaMenuProps> = ({
  selectedMedia,
  isMobileLayout,
  onMediaChanged,
  onAddMedia,
}) => {
  const theme = useTheme();
  const [mediaAttributes, setMediaAttributes] = useState<MediaAttributes | null>(null);

  const defaultSpeed = 1;
  const defaultVolume = 1;
  const defaultStart = 0;
  const defaultEnd = 0;
  const defaultOpacity = 1;
  const defaultHue = 0;
  const defaultBrightness = 0;

  useEffect(() => {
    if (selectedMedia) {
      const start = selectedMedia.start || defaultStart;
      const end = selectedMedia.end || defaultEnd;
      setMediaAttributes({
        start,
        end,
      });
    }
  }, [selectedMedia]);

  const handleMediaAttributeChange = (updatedAttributes: Partial<MediaAttributes>, start: number, end: number, commit: boolean) => {
    const newAttributes: any = { ...mediaAttributes, ...updatedAttributes };

    if (selectedMedia) {
      const speed = newAttributes.speed || defaultSpeed;
      const volume = newAttributes.volume || defaultVolume;
      const opacity = newAttributes.opacity || defaultOpacity;
      const hue = newAttributes.hue || defaultHue;
      const brightness = newAttributes.brightness || defaultBrightness;
      setMediaAttributes({
        start,
        end,
      });
      onMediaChanged({...selectedMedia.media as Video, speed, volume, opacity, hue, brightness}, start, end, commit)
    }
  };

  const triggerTimelineChange = (newStart: number, newEnd: number) => {
    if (selectedMedia && mediaAttributes && selectedMedia.media) {
          onMediaChanged({...selectedMedia.media, ...mediaAttributes as any}, newStart, newEnd, true);
    }
  };

  return (
    !selectedMedia ? (
      <EmptyMediaMenu onAddMedia={onAddMedia} />
    ) : (
    <Container>
        <Box style={{ direction: 'ltr' }}>
        <Typography
            variant={isMobileLayout ? 'h6' : 'h5'}
            gutterBottom
            sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}
        >
            Image Settings
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />

        {selectedMedia.media && (
            <Box>
            {/* Timeline Controls */}
            <TimelineControls
              start={mediaAttributes ? mediaAttributes.start : defaultStart}
              end={mediaAttributes ? mediaAttributes.end : defaultEnd}
              maxEnd={(mediaAttributes ? mediaAttributes.start : defaultStart) + selectedMedia?.media.duration}
              triggerTimelineChange={triggerTimelineChange}
            />
            </Box>
        )}
        </Box>
    </Container>
    )
  );
};

export default ImageMenu;
