import React, { useState, useEffect } from 'react';
import {
  Grid,
  CardActionArea,
  Typography,
  Box,
  useTheme,
  Divider,
  CardContent,
  Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Video from '../media/Video';
import { effectsData } from '../Effect';

type FiltersMenuProps = {
  selectedVideo: Video | null;
  isMobileLayout: boolean;
  onSelect: (effectName: string) => void;
};

const FiltersMenu: React.FC<FiltersMenuProps> = ({
  selectedVideo,
  isMobileLayout,
  onSelect,
}) => {
  const [selectedEffect, setSelectedEffect] = useState<string | null>(null);
  const theme = useTheme();

  const isDisabled = !(selectedVideo instanceof Video);

  const StyledCard = styled('div')<{ isDisabled: boolean; isMobileLayout: boolean }>(
    ({ theme, isDisabled, isMobileLayout }) => ({
      position: 'relative',
      height: isMobileLayout ? '460px' : '290px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[3],
      overflow: 'hidden',
      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
      opacity: isDisabled ? 0.5 : 1,
      pointerEvents: isDisabled ? 'none' : 'auto',
      '&:hover': {
        boxShadow: isDisabled ? theme.shadows[3] : theme.shadows[6],
        transform: isDisabled ? 'none' : 'scale(1.02)',
      },
    })
  );

  useEffect(() => {
    if (!selectedVideo || selectedVideo.effects.length === 0) {
      setSelectedEffect(null);
      return;
    }
    setSelectedEffect(selectedVideo.effects[0]);
  }, [selectedVideo]);

  const handleSelect = (effectName: string) => {
    if (!selectedVideo) {
      setSelectedEffect(null);
      return;
    }

    if (selectedEffect === effectName) {
      // Clicking the same filter once it's selected removes the filter
      setSelectedEffect(null);
    } else {
      setSelectedEffect(effectName); // Update the selected effect
    }

    onSelect(effectName);
  };

  const stopPropagation = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box
      onMouseDown={stopPropagation}
      onMouseMove={stopPropagation}
      sx={{
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: '#fafafa',
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          padding: 2,
          direction: 'ltr',
          backgroundColor: '#fafafa',
        }}
      >
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Choose a Filter
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />

        <Grid container spacing={2}>
          {effectsData.map((effect) => (
            <Grid item xs={12} sm={6} key={effect.name}>
              <Tooltip
                title={
                  isDisabled ? 'Please select a video to enable filters' : ''
                }
              >
                <StyledCard
                  isDisabled={isDisabled}
                  isMobileLayout={isMobileLayout}
                  onClick={
                    isDisabled ? undefined : () => handleSelect(effect.name)
                  }
                  sx={{
                    border:
                      effect.name === selectedEffect
                        ? `2px solid ${theme.palette.primary.main}`
                        : 'none',
                  }}
                >
                  <CardActionArea
                    sx={{
                      width: '100%',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'stretch',
                    }}
                  >
                    <Box
                      component="img"
                      src={effect.image}
                      alt={effect.name}
                      sx={{
                        width: '100%',
                        height: isMobileLayout ? '360px' : '200px',
                        objectFit: 'cover',
                      }}
                    />
                    <CardContent
                      sx={{
                        flexGrow: 1,
                      }}
                    >
                      <Typography variant="h6" component="div">
                        {effect.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {effect.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </StyledCard>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default FiltersMenu;
