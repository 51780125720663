import { getSignedVideoDownloadUrl, getVideoInfo } from '../../api/ServerApi';
import { Point, calculateSha256, calculateXYDifferenceFromCenter, createVideoAudioMedia } from '../../utils/utils';
import { effectNameToStringMap } from '../Effect';
import getEngine from '../EffectsEngine';
import Media, { SerializeForRender, nonSerializable, SERIALIZABLE_FOR_RENDER_KEY, NON_SERIALIZABLE_KEY, MediaType } from './Media';
import { getFileFromIndexedDB, saveFileToIndexedDB } from '../localstorage/IndexedDB';

const Engine = await getEngine();

type shrinkType = 'shrinkLeft' | 'shrinkRight' | 'increaseLeft' | 'increaseRight' | null;

type Crop = {
  left: number,
  right: number,
  top: number,
  bottom: number
}

export default class Video extends Media {
  mediaType: MediaType = 'video';
  subtitles: {subtitles: any[], style: any};
  subtitlesUrl: string | null;
  subtitlesLoaded: boolean;
  @SerializeForRender()
  subtitlesActive: boolean;
  downloadingSubtitles: boolean;
  storageFilePath: string;
  @nonSerializable()
  storageBuffer: Uint8Array | null;
  @nonSerializable()
  videoFdRef: number | null;
  isPlaying: boolean;
  framePendingTime: number | null;
  curVideoTime: number;
  @SerializeForRender()
  isMuted: boolean;
  @nonSerializable()
  uploadProgress: number;
  opacity: number;
  hue: number;
  brightness: number;
  @SerializeForRender()
  volume: number;

  // Constructor using named parameters
  constructor({
    id,
    sha256,
    name,
    url,
    type,
    subtitles = {subtitles: [], style: null},
    subtitlesUrl = null,
    subtitlesLoaded = false,
    subtitlesActive = false,
    downloadingSubtitles = false,
    storageFilePath,
    storageBuffer = null,
    duration,
    start,
    leftBackgroundOffset = 0,
    rightBackgroundOffset = 0,
    startBase = 0,
    endBase,
    isResizing = false,
    videoRef = null,
    videoFdRef = null,
    isPlaying = false,
    framePendingTime = null,
    playOffset = 0,
    framesOffset = 0,
    frameCutOffset = 0,
    curVideoTime = 0,
    row,
    effects = [],
    hasVideoComponent = false,
    hasAudioComponent = false,
    isMuted = false,
    uploadProgress = 0,
    height,
    width,
    scaledHeight,
    scaledWidth,
    baseHeight,
    baseWidth,
    x,
    y,
    rotation,
    crop,
    dirty = false,
    onLoadedCalled,
    frames = [],
    audioFrames = [],
    uploadComplete,
    speed = 1,
    brightness = 0,
    hue = 0,
    opacity = 1,
    volume = 1,
    transitionId = null,
    leftTrim,
    rightTrim,
  }: {
    id?: string;
    sha256: string;
    name: string;
    url: string;
    type: string;
    subtitles?: {subtitles: any[], style: any};
    subtitlesUrl?: string | null;
    subtitlesLoaded?: boolean;
    subtitlesActive?: boolean;
    downloadingSubtitles?: boolean;
    storageFilePath: string;
    storageBuffer?: Uint8Array | null;
    duration: number;
    start: number;
    leftBackgroundOffset?: number;
    rightBackgroundOffset?: number;
    startBase?: number;
    endBase: number;
    isResizing?: boolean;
    videoRef?: any;
    videoFdRef?: number | null;
    isPlaying?: boolean;
    framePendingTime?: number | null;
    playOffset?: number;
    framesOffset?: number;
    frameCutOffset?: number;
    curVideoTime?: number;
    row: number;
    effects?: string[];
    dirty?: boolean;
    height: number;
    width: number;
    scaledHeight: number | null;
    scaledWidth: number | null;
    baseHeight: number;
    baseWidth: number;
    x: number;
    y: number;
    rotation: number;
    crop: Crop;
    onLoadedCalled?: boolean;
    hasVideoComponent?: boolean;
    hasAudioComponent?: boolean;
    isMuted?: boolean;
    uploadProgress?: number;
    frames?: any[],
    audioFrames?: any[],
    uploadComplete?: boolean,
    opacity?: number;
    hue?: number;
    brightness?: number;
    volume?: number;
    speed?: number,
    transitionId?: string | null;
    leftTrim?: number;
    rightTrim?: number;
  }) {
    super({
      id,
      name,
      sha256,
      url,
      type,
      row,
      duration,
      start,
      leftBackgroundOffset,
      rightBackgroundOffset,
      videoRef,
      startBase,
      endBase,
      isResizing,
      dirty,
      framesOffset,
      frameCutOffset,
      playOffset,
      onLoadedCalled,
      effects,
      height,
      width,
      scaledHeight,
      scaledWidth,
      baseHeight,
      baseWidth,
      x,
      y,
      rotation,
      crop,
      resizable: true,
      cropable: true,
      frames,
      audioFrames,
      uploadComplete,
      speed,
      hasVideoComponent,
      hasAudioComponent,
      transitionId,
      leftTrim,
      rightTrim,
    });
    // always create new to prevent two videos using the same storage
    let newStorage;
    if (storageBuffer) {
      // TODO: should we copy the memory?
      newStorage = new Uint8Array(storageBuffer.length);
    } else {
      newStorage = new Uint8Array();
    }
    this.subtitles = subtitles;
    this.subtitlesUrl = subtitlesUrl;
    this.subtitlesLoaded = subtitlesLoaded;
    this.subtitlesActive = subtitlesActive;
    this.downloadingSubtitles = downloadingSubtitles;
    this.storageFilePath = storageFilePath;
    this.storageBuffer = newStorage;
    this.videoRef = videoRef;
    this.videoFdRef = videoFdRef;
    this.isPlaying = isPlaying;
    this.framePendingTime = framePendingTime;
    this.curVideoTime = curVideoTime;
    this.isMuted = isMuted;
    this.uploadProgress = uploadProgress;
    this.brightness = brightness;
    this.hue = hue;
    this.opacity = opacity;
    this.volume = volume;
  }

  hasVideo = (): boolean => {
    return this.hasVideoComponent;
  }

  hasAudio = (): boolean => {
    return this.hasAudioComponent;
  }

  audioEnabled = (): boolean => {
    return this.hasAudio() && !this.isMuted;
  }

  // Delete all existing effects and replace with the given effect
  setEffect = async (effect: string): Promise<boolean> => {
    if (effect in effectNameToStringMap) {
      const index = this.effects.indexOf(effect);
      if (index === -1) {
        // delete all effects and replace with this effect
        this.effects = [effect];
      } else {
        // remove the existing effect
        this.effects = [];
      }

      const effectString = this.getEffectString();
      const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
        return res;
      });

      if (res === -1) {
        console.error("failed to set effect");
        return false;
      }
      return true;
    }

    return false;
  }

  // Method to add an effect to the video
  addEffect = async (effect: string): Promise<boolean> => {
    if (effect in effectNameToStringMap) {
      const index = this.effects.indexOf(effect);
      if (index === -1) {
        this.effects.push(effect);
      } else {
        // remove the effect
        this.effects.splice(index, 1);
      }

      const effectString = this.getEffectString();
      const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
        return res;
      });

      if (res === -1) {
        console.error("failed to set effect");
        return false;
      }
      return true;
    }

    return false;
  }

  getEffectString = (prependVideoNormalization: boolean = false, addRotation: boolean = false): string => {
    // TODO: fix that scaledHeight and scaledWidth are not null
    let cropLeft = 0;
    let cropRight = 0;
    let cropTop = 0;
    let cropBottom = 0;
    if (this.scaledHeight && this.scaledWidth) {
      cropLeft = Math.ceil(this.crop.left * this.scaledWidth);
      cropRight = Math.ceil(this.crop.right * this.scaledWidth);
      if (prependVideoNormalization) {
        cropBottom = Math.ceil(this.crop.top * this.scaledHeight);
        cropTop = Math.ceil(this.crop.bottom * this.scaledHeight);
      } else{
        cropBottom = Math.ceil(this.crop.bottom * this.scaledHeight);
        cropTop = Math.ceil(this.crop.top * this.scaledHeight);
      }
    }

    const hueFilter = `hue=h=${this.hue}:s=1:b=${this.brightness}`

    return (
      (prependVideoNormalization ? [`scale=${this.scaledWidth}:${this.scaledHeight}`] : [])
      .concat(
      //this.rotation ? [`rotate=${this.rotation}*PI/180:ow=rotw(iw):oh=roth(ih):c=black, pad='iw*sqrt(2)':'ih*sqrt(2)':(ow-iw)/2:(oh-ih)/2`] : [],
      (this.crop.top || this.crop.bottom || this.crop.left || this.crop.right) ?
        [`crop=${(Math.max((this.scaledWidth || 0)-cropRight-cropLeft, 1))}:${Math.max((this.scaledHeight || 0)-cropTop-cropBottom, 1)}:${cropLeft}:${cropBottom}`] :
        [],
      this.effects.map((effectName: string) => effectNameToStringMap[effectName]),
      hueFilter ? hueFilter : [],
      this.opacity ? `colorchannelmixer=aa=${this.opacity}` : [],
      // scale the image to the current size at the end
      `scale=${this.scaledWidth}:${this.scaledHeight}:flags=fast_bilinear`,
      addRotation ? [`rotate=${this.rotation}*PI/180:ow='iw*abs(cos(${this.rotation}*PI/180))+ih*abs(sin(${this.rotation}*PI/180))':oh='iw*abs(sin(${this.rotation}*PI/180))+ih*abs(cos(${this.rotation}*PI/180)):c=none'`] : [],
      )
      .join(','));
  }

  setCrop = async (direction: 'left' | 'right' | 'top' | 'bottom', offset: number) => {
    this.crop[direction] = offset;
    const effectString = this.getEffectString();
    const res = await Engine.set_effect(this.videoFdRef as number, this.scaledWidth as number, this.scaledHeight as number, effectString).then((res: number) => {
      return res;
    });
    return res;
  }

  setRotation = async (degrees: number) => {
    this.rotation = degrees;
  }

  getRotatedCoords = () => {
    const height = this.scaledHeight || 0;
    const width = this.scaledWidth || 0;
    const relativeCorners: [Point, Point, Point, Point] = [
        { x: 0, y: 0 }, // Top-left
        { x: width, y: 0 },  // Top-right
        { x: width, y: height },   // Bottom-right
        { x: 0, y: height }   // Bottom-left
    ];
    const { xDifference, yDifference, cornerDifferences, newCenter, newWidth, newHeight, boundingX, boundingY } = calculateXYDifferenceFromCenter(relativeCorners,
      width / 2, height / 2, 0, this.rotation
    );
    const corners = cornerDifferences.map((corner: Point) => {
      return {x: this.x + corner.x, y: this.y + corner.y };
    })
    const center = {x: this.x + newCenter.x, y: this.y + newCenter.y };
    return {xCord: xDifference + this.x, yCord: yDifference + this.y, corners, center, width: newWidth, height: newHeight, boundingX: this.x + boundingX, boundingY: this.y + boundingY};
  }

  deepCopy = (): Video => {
    let newStorage;
    if (this.storageBuffer) {
      // TODO: should we copy the memory?
      newStorage = new Uint8Array(this.storageBuffer.length);
    } else {
      newStorage = new Uint8Array();
    }

    return new Video({
      ...this,
      subtitles: { subtitles: [...this.subtitles.subtitles],  style: { ...this.subtitles.style }},
      // add all properties that need to be deepcopied (reference and not values)
      crop: { left: this.crop.left, right: this.crop.right, top: this.crop.top, bottom: this.crop.bottom },
      frames: this.frames.map(frame => ({ ...frame })),
      audioFrames: this.audioFrames.map(frame => ({ ...frame })),
      storageBuffer: newStorage,
    });
  }

  serialize = (): Partial<Video> => {
    const cleanVideo = this.deepCopy();
    const nonSerializableFields: Array<keyof Video> = Reflect.getMetadata(NON_SERIALIZABLE_KEY, this) || [];

    nonSerializableFields.forEach((key) => {
      delete cleanVideo[key];
    })

    return cleanVideo;
  }

  serializeForRender = (): Partial<Video> => {
    const cleanVideo = this.deepCopy();
    const serializableFields: (keyof Video)[] = Reflect.getMetadata(SERIALIZABLE_FOR_RENDER_KEY, this) || [];

    // need to assign  the x,y cords after the video was rotated (this is how the backend overlays the videos)
    const { boundingX, boundingY }  = this.getRotatedCoords();
    cleanVideo.x = boundingX;
    cleanVideo.y = boundingY;
    return serializableFields.reduce((obj, key) => {
      obj[key] = cleanVideo[key];
      return obj;
    }, {} as Partial<Video>);
  }

  public static async fromSerialized(serializedMedia: any, options?: any) {
    let sha256;
    // Try to get from local storage, else download
    let file = await getFileFromIndexedDB(serializedMedia.sha256);
    if (file) {
      sha256 = serializedMedia.sha256;
    } else {
      // not in local storage? download and save
      const presignedUrl = await getSignedVideoDownloadUrl(serializedMedia.sha256);
      file = new File([await (await fetch(presignedUrl)).blob()], serializedMedia.name, {type: serializedMedia.type});
      sha256 = await calculateSha256(file);

      saveFileToIndexedDB(file, sha256)
    }

    const videoInfo = await getVideoInfo(sha256);
    delete serializedMedia.url;
    const newVideoObject = await createVideoAudioMedia(file, sha256, videoInfo, serializedMedia.row, serializedMedia);
    return newVideoObject;
  }

  requiresWasm = (): boolean => {
    return !!(this.effects.length || this.hue || this.brightness || (this.opacity !== undefined && this.opacity !== null && this.opacity !== 1));
  }
}

export {
  type shrinkType
}